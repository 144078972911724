import classNames from 'classnames';
import GiftItem from 'components/giftItem';
import {USER_GIFT_STATUSES} from 'constants/userGift';
import React from 'react';
import {Pagination} from 'react-bulma-components';
import image_default from 'images/image_default.png';
import e_voucher_active from 'images/strongbow/e-voucher-active.png';
import e_voucher from 'images/strongbow/e-voucher.png';
import e_voucher_exp from 'images/strongbow/e_voucher_exp.png';
import moment from 'moment';

const GiftList = ({
  chillPageIndex = 1,
  chillTotalPages = 1,
  onChangeChillPage = () => {},
  dataChill = [],

  evoucherPageIndex = 1,
  evoucherTotalPages = 1,
  onChangeEvoucherPage = () => {},
  dataEvoucher = [],

  dataStore = [],
  storePageIndex = 1,
  storeTotalPages = 1,
  onChangeStorePage = () => {},

  userGiftStatus = null,
  onChangeStatus = () => {},
  onClickUserGift = () => {},
  isLoading = false,
}) => {
  const userGiftStatusesData = [
    {
      key: USER_GIFT_STATUSES.ALL,
      text: 'Tất cả',
    },
    {
      key: USER_GIFT_STATUSES.EXPIRED,
      text: 'Hết hạn',
      isLast: true,
    },
  ];
  return (
    <>
      <div
        className="has-text-centered pb-2"
        style={{
          marginTop: -15,
        }}
      >
        {userGiftStatusesData.map((item) => (
          <button
            key={item.key}
            onClick={() => onChangeStatus(item.key)}
            className={classNames('button is-size-7 is-size-6-tablet', {
              'mr-6 mr-6-mobile': !item.isLast,
              'button-user-gift-is-primary': item.key === userGiftStatus,
              'button-user-gift-is-second': item.key !== userGiftStatus,
            })}
          >
            {item.text}
          </button>
        ))}
      </div>

      {dataChill.length > 0 && (
        <div
          className="gift-chill-bg my-4"
          style={{maxWidth: 360, display: 'block', margin: 'auto'}}
        >
          <div className="columns">
            <div className="column has-text-centered has-title-chill">QUÀ ĐỂ CHILL </div>
          </div>
        </div>
      )}
      <div className="columns is-multiline is-flex-mobile gift-wrapper">
        {dataChill.map((item, index) => {
          const isClickable = [USER_GIFT_STATUSES.EXCHANGED, USER_GIFT_STATUSES.USED].includes(
            item?.status
          );
          return (
            <>
              <div className="column is-6 is-4-desktop is-4-mobile pl-2 pr-2">
                <div className="gift-item">
                  <div
                    className="card-image"
                    onClick={() => {
                      isClickable && onClickUserGift(item);
                    }}
                  >
                    <figure
                      className={classNames('image is-1by1', {
                        'is-clickable': isClickable,
                      })}
                    >
                      <img
                        src={item?.image || image_default}
                        style={{
                          borderRadius: 12,
                        }}
                      />
                    </figure>
                  </div>
                  <div className="gift-item-name">{item.name}</div>
                  <div className="has-text-centered gift-button">
                    {item.status == 1 && (
                      <>
                        <a
                          onClick={() =>
                            item.status === USER_GIFT_STATUSES.EXCHANGED && onClickUserGift(item)
                          }
                        >
                          <button className="button is-fullwidth py-4 button-change-now">
                            {' '}
                            NHẬN NGAY
                          </button>
                        </a>
                      </>
                    )}
                    {item.status == 2 && (
                      <>
                        <a>
                          <button
                            className="button is-fullwidth py-4 button-change-now-more"
                            onClick={() => onClickUserGift(item)}
                          >
                            ĐÃ NHẬN{' '}
                          </button>
                        </a>
                      </>
                    )}
                    {item.status == 9 && (
                      <>
                        <a>
                          <button
                            className="button-add button-add-bg"
                            style={{color: '#323130', fontWeight: 700}}
                          >
                            HẾT HẠN{' '}
                          </button>
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div>
        <Pagination
          current={chillPageIndex}
          showFirstLast
          total={chillTotalPages}
          delta={1}
          showPrevNext={false}
          align={'center'}
          onChange={onChangeChillPage}
        />
      </div>

      {dataEvoucher.length > 0 && (
        <div
          className="gift-chill-bg my-4 mt-6"
          style={{maxWidth: 450, display: 'block', margin: 'auto'}}
        >
          <div className="columns">
            <div className="column has-text-centered pl-0 has-title-chill">E-VOUCHER MUA HÀNG</div>
          </div>
        </div>
      )}
      <div className="columns is-multiline is-flex-mobile gift-wrapper">
        {dataEvoucher.map((item, index) => {
          return (
            <>
              <div className="column is-6 is-6-desktop is-6-mobile pl-2 pr-2">
                <div
                  className="gift-item"
                  style={{position: 'relative'}}
                  onClick={() =>
                    item.status === USER_GIFT_STATUSES.EXCHANGED && onClickUserGift(item)
                  }
                >
                  <div className="card-image">
                    <figure className="image">
                      <img
                        src={item.image || image_default}
                        style={
                          {
                            // height: 105,
                          }
                        }
                      />
                      {/* <a href={`/user-gift/${item.giftId}`}> */}
                      {/* {item.status == 1 && (
                        <>
                          <img
                            src={e_voucher_active}
                            style={{
                              height: 105,
                            }}
                          />
                        </>
                      )}
                      {item.status == 2 && (
                        <>
                          <img
                            src={e_voucher}
                            style={{
                              height: 105,
                            }}
                          />
                        </>
                      )}
                      {item.status == 9 && (
                        <>
                          <img
                            src={e_voucher_exp}
                            style={{
                              height: 105,
                            }}
                          />
                        </>
                      )} */}
                      {/* </a> */}
                    </figure>
                  </div>
                  {/* <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      right: 40,
                      transform: 'translate(0%, -80%)',
                    }}
                  >
                    {item?.name.length > 24 ? (
                      <div className="gift-item-name-evoucher px-2">
                        {item.name.substring(0, 24)}
                        {'...'}
                      </div>
                    ) : (
                      <div className="gift-item-name-evoucher px-2">{item?.name}</div>
                    )}
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 40,
                    }}
                  >
                    <div className="gift-item-reward-evoucher pb-4">
                      {item.status == 9 ? 'HẾT HẠN:' : 'HSD:'}
                      {moment(item.expiryDateTime).format('DD/MM/YYYY')}
                    </div>
                  </div> */}
                </div>
              </div>
            </>
          );
        })}
      </div>
      <Pagination
        current={evoucherPageIndex}
        showFirstLast
        total={evoucherTotalPages}
        delta={1}
        showPrevNext={false}
        align={'center'}
        onChange={onChangeEvoucherPage}
      />

      {dataStore.length > 0 && (
        <div
          className="gift-chill-bg my-4 mt-6"
          style={{maxWidth: 450, display: 'block', margin: 'auto'}}
        >
          <div className="columns">
            <div className="column has-text-centered pl-0 has-title-chill">
              E-VOUCHER TẠI CỬA HÀNG
            </div>
          </div>
        </div>
      )}
      <div className="columns is-multiline is-flex-mobile gift-wrapper">
        {dataStore.map((item, index) => {
          return (
            <>
              <div className="column is-6 is-6-desktop is-6-mobile pl-2 pr-2">
                <div className="gift-item">
                  <div className="card-image">
                    <figure className="image">
                      <a
                        onClick={() =>
                          item.status === USER_GIFT_STATUSES.EXCHANGED && onClickUserGift(item)
                        }
                      >
                        <img
                          src={item?.image || image_default}
                          style={
                            {
                              // borderRadius: 12,
                              // maxHeight: 104,
                            }
                          }
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="gift-item-name">{item.name}</div>
                  <div className="gift-item-reward-evoucher pb-4">
                    {item.status == 9 ? 'HẾT HẠN:' : 'HSD:'}
                    {moment(item.expiryDateTime).format('DD/MM/YYYY')}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div>
        <Pagination
          current={storePageIndex}
          showFirstLast
          total={storeTotalPages}
          delta={1}
          showPrevNext={false}
          align={'center'}
          onChange={onChangeStorePage}
        />
      </div>
    </>
  );
};

export default GiftList;
