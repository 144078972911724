import React, {useEffect} from 'react';
import Layout from '../../components/layout';
import Select from 'react-select';
import {
  getGiftDetailById,
  getGiftViewDetailById,
  getStoreDetailByMerchant,
  getProvinces,
  getDistrictByProvinceCodeAction,
  getWardByDistrictCodeAction,
  createRedeemGift,
  postAllGiftStrongBowSearchAction,
  getGiftAllUserByMerchant,
  getRewardInfoAction,
} from 'appRedux/actions/app';
import {useDispatch, useSelector} from 'react-redux';
import {isLogInSelector, userInfoSelector} from 'appRedux/selectors/auth';
import {Link, useParams} from 'react-router-dom';
import image_default from 'images/image_default.png';
import market from 'images/strongbow/market.png';
import {toast} from 'react-toastify';
import {Modal} from 'react-bulma-components';
import {QRCodeSVG} from 'qrcode.react';
import moment from 'moment';
import exportAsImage, {getSelectOptions} from 'helpers';
import {useNavigate} from 'react-router-dom';
import Contact from 'components/contact';
import classNames from 'classnames';
import {toggleQRCodeModal} from 'appRedux/slices/appSlice';
import {ROUTE_NAME} from 'constants/routes';
import parse from 'html-react-parser';
import {rewardInfoSelector} from 'appRedux/selectors/app';
import {Pagination} from 'react-bulma-components';
import ContactButton from 'components/contactButton';
import FAQS from 'components/FAQs';
import Collapsible from 'react-collapsible';
import phone from 'images/strongbow/phone.png';
import e_voucher_active from 'images/strongbow/e-voucher-active.png';
import e_voucher from 'images/strongbow/e-voucher.png';
import storeTitle from 'images/strongbow/store.png';
import giftDetailTitle from 'images/gift-detail-title.png';
import image_store_pc from 'images/strongbow/pc/18.png';
import bottle from 'images/strongbow/bottle.png';
import leaf from 'images/strongbow/pc/leaf.png';
import {isMobile} from 'react-device-detect';

import balloon from 'images/strongbow/pc/balloon.png';
import {BUCKET_IMAGES} from 'constants/meoChill';

const MODAL_STATES = {
  EXCEED_10K_REDEEM: 'EXCEED_10K_REDEEM',
};

const VoucherDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useNavigate();
  const navigate = useNavigate();
  const isLogIn = useSelector(isLogInSelector);
  const [formData, setFormData] = React.useState({
    giftDetail: '',
    conditionDescription: '',
    useDescription: '',
    allStores: false,
    applicableStores: [],
  });
  const [storeList, setStoreList] = React.useState([]);
  const [storeListFilter, setStoreListFilter] = React.useState([]);
  const [dataProvince, setDataProvince] = React.useState([]);
  const [dataDistrict, setDataDistrict] = React.useState([]);
  const [dataWard, setDataWard] = React.useState([]);
  const [dataFiter, setDataFilter] = React.useState({});
  // const [showPopupSuccess, setShowPopupSusscee] = React.useState(false);
  // const qrRef = React.useRef();
  const [dataGiftUser, setDataGiftUser] = React.useState([]);
  const [dataGiftEvoucherResult, setDataGiftEvoucherResult] = React.useState([]);
  const [numberShowMore, setNumberShowMore] = React.useState(5);
  // const [qrCodeData, setQRCodeData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const userInfo = useSelector(userInfoSelector);
  const rewardInfo = useSelector(rewardInfoSelector);
  const [pages, setPages] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [numberEvoucher, setNumberEvoucher] = React.useState(isMobile ? 6 : 8);
  const [evoucherTotalRecords, setEvoucherTotalRecords] = React.useState(0);
  const [modalState, setModalState] = React.useState(MODAL_STATES.NONE);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (isLogIn) {
      dispatch(
        getGiftDetailById({
          id: params.id,
          callback: (data) => {
            setFormData(data.data);
            listStores(data.data);
          },
        })
      );
      dispatch(
        postAllGiftStrongBowSearchAction({
          body: {
            pageIndex: 1,
            pageSize: numberEvoucher,
            userId: userInfo?.userId,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            giftClassify: 'E_VOUCHER',
            sortBy: [
              {
                fieldName: 'createdTime',
                ascending: false,
              },
            ],
          },
          callback: (error, res) => {
            const {data = [], numOfRecords = 0, numOfPages = 0} = res;
            setEvoucherTotalRecords(numOfRecords);
            setDataGiftUser(data);
            setDataGiftEvoucherResult(data);
          },
        })
      );
      dispatch(getRewardInfoAction());
    } else {
      dispatch(
        getGiftViewDetailById({
          id: params.id,
          callback: (data) => {
            setFormData(data);
            listStores(data);
          },
        })
      );
    }
  }, [isLogIn]);

  useEffect(() => {
    dispatch(
      getProvinces({
        callback: (data) => {
          let provinces = [
            {
              shortName: 'a',
              value: '',
              label: 'Tất cả',
            },
          ];
          data.map((item) => {
            provinces.push({
              label: item.name,
              value: item.code,
              shortName: item.shortName,
            });
          });
          setDataProvince(provinces.sort((a, b) => a.shortName.localeCompare(b.shortName)));
        },
      })
    );
  }, []);

  const listStores = (dataStore) => {
    if (dataStore?.allStores) {
      dispatch(
        getStoreDetailByMerchant({
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          callback: (data) => {
            setStoreList(data.data);
            setStoreListFilter(data.data);
          },
        })
      );
    } else {
      setStoreListFilter(dataStore?.applicableStores);
      setStoreList(dataStore?.applicableStores);
    }
  };

  const onChangeSelect = (name) => (event) => {
    setPerPage(0);
    setCurrentPage(1);
    setPages(10);
    if (name == 'province') {
      setDataFilter({
        ...dataFiter,
        province: event.value,
        district: '',
      });
      if (event.value) {
        getDistrictByProvinceId(event.value);
        setStoreListFilter(storeList?.filter((item) => item.location.province == event.value));
      } else {
        setStoreListFilter(storeList);
      }
    }
    if (name == 'district') {
      setDataFilter({
        ...dataFiter,
        district: event.value,
        ward: '',
      });
      if (event.value) {
        getWardByDistrictId(event.value);
        setStoreListFilter(storeList?.filter((item) => item.location.district == event.value));
      } else {
        setStoreListFilter(
          storeList?.filter((item) => item.location.province == dataFiter.province)
        );
      }
    } else if (name == 'ward') {
      setDataFilter({
        ...dataFiter,
        ward: event.value,
      });
      if (event.value) {
        setStoreListFilter(storeList?.filter((item) => item.location.ward == event.value));
      } else {
        setStoreListFilter(
          storeList?.filter((item) => item.location.district == dataFiter.district)
        );
      }
    }
  };
  const getDistrictByProvinceId = async (provinceCode) => {
    dispatch(
      getDistrictByProvinceCodeAction({
        provinceCode: provinceCode,
        callback: (data) => {
          let districts = [
            {
              label: 'Tất cả',
              value: '',
            },
          ];
          data.map((item) => {
            districts.push({
              label: item.name,
              value: item.code,
            });
          });
          setDataDistrict(districts);
        },
      })
    );
  };
  const getWardByDistrictId = async (districtCode) => {
    dispatch(
      getWardByDistrictCodeAction({
        districtCode: districtCode,
        callback: (data) => {
          let wards = [
            {
              label: 'Tất cả',
              value: '',
            },
          ];
          data.map((item) => {
            wards.push({
              label: item.name,
              value: item.code,
            });
          });
          setDataWard(wards);
        },
      })
    );
  };
  const handleConfirm = () => {
    setIsLoading(!isLoading);
    dispatch(
      createRedeemGift({
        body: {
          giftId: params.id,
          quantity: 1,
          rewardId: formData.rewardId,
        },
        callback: (error, userGift) => {
          setIsLoading(false);
          if (error) {
            // WARNING! this is a very dirty way of triggering popup. Subject to future change
            if (
              error === 'Bạn đã đổi quà có giá trị trên 10000 điểm rồi. Hãy chọn quà khác nhé !'
            ) {
              setModalState(MODAL_STATES.EXCEED_10K_REDEEM);
            } else
              toast.error(error || 'Không thành công! Vui lòng thử lại sau', {
                autoClose: 2000,
                position: 'top-center',
              });
          } else if (userGift) {
            if (formData.giftClassify == 'CHILL_GIFT') {
              navigate(`/info/${userGift.id}`);
            } else {
              dispatch(
                toggleQRCodeModal({
                  ...userGift,
                  showTitle: true,
                  showProfile: true,
                })
              );
            }
          }
        },
      })
    );
  };

  const handleShowMore = () => {
    setNumberShowMore(dataGiftUser.filter((f) => f.canRedeemByMerchantReward).length);
  };
  // const handleShowMoreEvoucher = () => {
  //   setNumberEvoucher(dataGift.filter((f) => f.giftClassify == 'E_VOUCHER').length);
  // };
  const handleShowMoreEvoucher = (total) => {
    let pageData = 0;
    if (total > numberEvoucher * parseInt(pageNumber)) {
      pageData = parseInt(pageNumber) + 1;
    }
    dispatch(
      postAllGiftStrongBowSearchAction({
        body: {
          pageIndex: pageData,
          pageSize: numberEvoucher,
          userId: userInfo?.userId,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          giftClassify: 'E_VOUCHER',
          sortBy: [
            {
              fieldName: 'createdTime',
              ascending: false,
            },
          ],
        },
        callback: (error, res) => {
          const {data = [], numOfRecords = 0, numOfPages = 0} = res;
          setDataGiftEvoucherResult([...dataGiftEvoucherResult, ...data]);
          setPageNumber(pageData);
        },
      })
    );

    // setNumberEvoucher(total);
  };
  const onChangePage = (page) => {
    setCurrentPage(page);
    setPages(page * 10);
    setPerPage(page * 10 - 10);
  };
  const totalPages = Math.ceil(storeListFilter?.length / 10);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: '#D6BA85 !important',
      borderRadius: 30,
      fontWeight: 500,
      color: '#FFF',
      fontSize: 16,
      boxShadow: state.isFocused ? '0 0 0 0.125em rgb(221 164 16 / 25%)' : 'unset',
    }),
    placeholder: (base, state) => ({
      ...base,
      color: '#D6BA85',
    }),
    singleValue: (base, state) => ({
      ...base,
      color: '#83552D',
    }),
  };
  return (
    <Layout headerProps={{index: 1}}>
      <div className="container-chill usergift_info_detail is-relative">
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '30%',
            // right: ,
            right: '5%',
          }}
        >
          <img
            src={bottle}
            alt={'bottle'}
            width={100}
            style={{
              // transform: 'matrix(0.99, 0.14, -0.14, 0.99, 0, 0)',
              transform: 'rotate(10deg)',
            }}
          />
        </div>

        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            marginRight: -120,
          }}
        >
          <img src={leaf} width={320} alt="rightLeaf" style={{transform: 'rotate(-150deg)'}} />
        </div>
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            bottom: 450,
            left: 0,
            marginLeft: -130,
          }}
        >
          <img src={leaf} width={320} style={{}} alt="leftLeaf" />
        </div>
        <div className="container gift-item-detail">
          <div className="container-content">
            <div className=" columns title-image">
              <div className=" column is-5-tablet is-5-desktop is-4-fullhd gap-desktop">
                <div className="">
                  <div className="list-bg-store">
                    <div className="vocher-detail-gift">
                      <div className="clomns">
                        <div className="">
                          <div
                            style={{
                              paddingLeft: 15,
                              paddingRight: 15,
                            }}
                          >
                            <div className="card-image">
                              <div className="card-content">
                                <div className=" has-text-info has-text-weight-bold has-text-centered mb-2">
                                  {formData.name}
                                </div>
                                <div>
                                  <figure className="image is-image-voucher-detal">
                                    {formData?.image ? (
                                      <img
                                        src={formData.image}
                                        style={{
                                          borderRadius: 24,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={image_default}
                                        style={{
                                          borderRadius: 24,
                                        }}
                                      />
                                    )}
                                  </figure>
                                </div>

                                <table
                                  className="table is-fullwidth  has-background-transparent"
                                  style={{
                                    marginTop: 24,
                                    marginBottom: 0,
                                    backgroundColor: 'transparent',
                                  }}
                                >
                                  <tr>
                                    <td
                                      className="pl-0"
                                      style={{
                                        border: 'none',
                                        color: '#343434',
                                        fontSize: 14,
                                        fontWeight: 500,
                                        paddingTop: 0,
                                      }}
                                    >
                                      Số lượng đổi
                                    </td>
                                    <td
                                      className="pr-0"
                                      style={{
                                        textAlign: 'left',
                                        border: 'none',
                                        color: '#704315',
                                        fontSize: 14,
                                        fontWeight: 700,
                                        paddingTop: 0,
                                      }}
                                    >
                                      1 Ưu đãi
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="pl-0"
                                      style={{
                                        border: 'none',
                                        color: '#343434',
                                        fontSize: 14,
                                        fontWeight: 500,
                                        paddingTop: 0,
                                      }}
                                    >
                                      Tổng thanh toán
                                    </td>
                                    <td
                                      className="pr-0"
                                      style={{
                                        textAlign: 'left',
                                        border: 'none',
                                        color: '#704315',
                                        fontSize: 14,
                                        fontWeight: 700,
                                        paddingTop: 0,
                                      }}
                                    >
                                      {formData?.rewardValue
                                        ? formData?.rewardValue.toLocaleString()
                                        : 0}{' '}
                                      điểm
                                    </td>
                                  </tr>
                                </table>
                                <div
                                  className="has-text-centered has-text-weight-medium"
                                  style={{
                                    color: '#83552D',
                                  }}
                                >
                                  Bạn có:{' '}
                                  {rewardInfo?.totalRewardsAvailable
                                    ? rewardInfo?.totalRewardsAvailable.toLocaleString()
                                    : 0}{' '}
                                  điểm
                                </div>
                                <div>
                                  <button
                                    className={classNames(
                                      'button mr-3 is-fullwidth is-primary-custom',
                                      {
                                        'is-loading': isLoading,
                                      }
                                    )}
                                    style={{
                                      color: '#FFF',
                                      padding: '14px 50px',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                                      marginTop: 24,
                                      marginBottom: 24,
                                    }}
                                    onClick={handleConfirm}
                                    disabled={
                                      !isLogIn ||
                                      new Date(formData.expiryDateTime) < new Date() ||
                                      !formData.canRedeemByMerchantReward ||
                                      formData.remainingGifts < 1
                                    }
                                  >
                                    {formData.remainingGifts > 0
                                      ? formData.canRedeemByMerchantReward
                                        ? 'XÁC NHẬN ĐỔI ƯU ĐÃI'
                                        : `Thêm ${formData?.needingMerchantReward || ''} KOIN`
                                      : 'HẾT QUÀ'}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" column content-wrapper">
                <div className=" has-text-centered">
                  <img
                    style={{
                      width: '80%',
                      maxWidth: 400,
                    }}
                    src={giftDetailTitle}
                    alt="Gift title"
                  />
                </div>
                {/* <FAQS /> */}
                <Collapsible trigger="Chi tiết quà tặng" open={true} contentInnerClassName="py-0">
                  <div
                    style={{
                      fontSize: 12,
                      color: '#323130',
                    }}
                  >
                    {parse(formData?.giftDetail)}
                  </div>
                </Collapsible>
                <Collapsible
                  trigger="Hướng dẫn sử dụng"
                  contentInnerClassName="py-0"
                  // className="py-0"
                >
                  <div
                    style={{
                      fontSize: 12,
                      color: '#323130',
                    }}
                  >
                    {parse(formData?.useDescription)}
                  </div>
                </Collapsible>
                <Collapsible trigger="Điều khoản sử dụng" contentInnerClassName="py-0">
                  <div
                    style={{
                      fontSize: 12,
                      color: '#323130',
                    }}
                  >
                    {parse(formData?.conditionDescription)}
                  </div>
                </Collapsible>
              </div>
            </div>

            {/* <div
              className="gift-chill-bg"
              style={{
                display: 'block',
                margin: 'auto',
                maxWidth: 400,
                marginTop: '2rem',
                marginBottom: '1rem',
              }}
            >
              <div
                className="columns"
                style={{
                  marginTop: 24,
                }}
              >
                <div
                  className="column has-text-centered py-1"
                  style={{
                    color: '#fff',
                    fontSize: 20,
                    fontWeight: 700,
                    // marginBottom: 40,
                  }}
                >
                  CỬA HÀNG ÁP DỤNG
                </div>
              </div>
            </div> */}
            <div className=" columns mt-">
              <div className=" column is-5-tablet is-5-desktop is-4-fullhd gap-desktop">
                <div className=" has-text-centered">
                  <img
                    style={{
                      width: '80%',
                      maxWidth: 400,
                    }}
                    src={storeTitle}
                    alt="title"
                  />
                </div>

                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <div className="control">
                        <Select
                          placeholder="Chọn Tỉnh/Thành"
                          options={dataProvince}
                          onChange={onChangeSelect('province')}
                          styles={customStyles}
                          className="select-is-custom mb-5"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <Select
                          name="district"
                          id="district"
                          placeholder="Chọn Quận/Huyện"
                          onChange={onChangeSelect('district')}
                          options={dataDistrict}
                          isDisabled={!dataFiter.province}
                          value={
                            dataFiter.district
                              ? dataDistrict.find((option) => option.value === dataFiter.district)
                              : ''
                          }
                          styles={customStyles}
                          className="select-is-custom mb-5"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <Select
                          placeholder="Chọn Phường/Xã"
                          onChange={onChangeSelect('ward')}
                          options={dataWard}
                          isDisabled={!dataFiter.district}
                          value={
                            dataFiter.ward
                              ? dataWard.find((option) => option.value === dataFiter.ward)
                              : ''
                          }
                          styles={customStyles}
                          className="select-is-custom"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" has-text-centered is-hidden-mobile">
                  <img
                    style={{
                      width: '100%',
                      maxWidth: 600,
                    }}
                    src={image_store_pc}
                    alt="title"
                  />
                </div>
              </div>
              <div className=" column">
                <div className="columns store-wrapper content-wrapper">
                  <div
                    className="column-is-custom list-bg-home"
                    style={{
                      paddingBottom: 60,
                      width: '100%',
                    }}
                  >
                    <div className="custom-table">
                      {storeListFilter?.length > 0 && (
                        <>
                          <div className="column">
                            <div>
                              <div className="">
                                <div className="columns">
                                  <div className="column">
                                    <div>
                                      {storeListFilter
                                        ?.slice(perPage, pages)
                                        ?.map((item, index) => {
                                          return (
                                            <>
                                              <div className="columns is-flex-wrap-wrap">
                                                <div className="column is-12 has-text-weight-semibold column-display-mobile-one pb-0">
                                                  <div
                                                    className="has-text-weight-bold is-size-7 is-size-6-tablet"
                                                    style={{
                                                      color: '#83552D',
                                                      fontSize: 12,
                                                    }}
                                                  >
                                                    {item?.name}
                                                  </div>
                                                </div>
                                                <div className="column is-12 has-text-weight-semibold column-display-mobile pt-1">
                                                  <div
                                                    style={{
                                                      position: 'relative',
                                                    }}
                                                  >
                                                    {item?.location.address && (
                                                      <div
                                                        style={{
                                                          position: 'absolute',
                                                          top: '60%',
                                                          transform: 'translate(0, -50%)',
                                                        }}
                                                      >
                                                        <img
                                                          className="pl-1"
                                                          src={market}
                                                          style={{
                                                            height: 16,
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                    <div
                                                      style={{
                                                        marginLeft: 30,
                                                        color: '#343434',
                                                        fontSize: 12,
                                                      }}
                                                    >
                                                      {item?.location.address}
                                                      {item?.location.wardName
                                                        ? ', ' + item?.location.wardName
                                                        : ''}
                                                      {item?.location.districtName
                                                        ? ', ' + item?.location.districtName
                                                        : ''}
                                                      {item?.location.provinceName
                                                        ? ', ' + item?.location.provinceName
                                                        : ''}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: -15,
                      }}
                    >
                      <Pagination
                        pages={pages}
                        current={currentPage}
                        showFirstLast
                        total={totalPages}
                        delta={1}
                        showPrevNext={false}
                        align={'center'}
                        onChange={(page) => onChangePage(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {dataGiftUser?.filter((f) => f.giftClassify == 'E_VOUCHER').length > 0 && (
              <div
                className="gift-chill-bg"
                style={{
                  display: 'block',
                  margin: 'auto',
                  maxWidth: 450,
                  marginTop: '2rem',
                  marginBottom: '1rem',
                }}
              >
                <div className="columns">
                  <div className="column has-text-centered pl-0 has-title-chill">
                    E-VOUCHER MUA HÀNG
                  </div>
                </div>
              </div>
            )}
            <div className="columns is-multiline is-flex-mobile program-list-voucher">
              {dataGiftEvoucherResult?.map((item, index) => {
                return (
                  <>
                    <div className="column is-6 is-3-desktop is-6-mobile pl-2 pr-2">
                      <a href={`/gift/${item.id}`}>
                        <div className="gift-item" style={{position: 'relative'}}>
                          <div className="card-image">
                            <figure className="image">
                              <img
                                src={item.image || image_default}
                                style={
                                  {
                                    // height: 105,
                                  }
                                }
                              />
                              {/* {item.canRedeemByMerchantReward ? (
                                  <>
                                    <img
                                      src={e_voucher_active}
                                      style={{
                                        height: 105,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={e_voucher}
                                      style={{
                                        height: 105,
                                      }}
                                    />
                                  </>
                                )} */}
                            </figure>
                          </div>
                          {/* <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: 0,
                                right: 40,
                                transform: 'translate(0%, -80%)',
                              }}
                            >
                              {item?.name.length > 24 ? (
                                <div className="gift-item-name-evoucher px-2">
                                  {item.name.substring(0, 24)}
                                  {'...'}
                                </div>
                              ) : (
                                <div className="gift-item-name-evoucher px-2">{item?.name}</div>
                              )}
                            </div>
                            <div
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 40,
                              }}
                            >
                              <div className="gift-item-reward-evoucher pb-4">
                                {item?.rewardValue.toLocaleString()} CHILL KOIN
                              </div>
                            </div> */}
                        </div>
                      </a>
                      <div className="has-text-centered gift-button mx-4 mt-0">
                        {item.canRedeemByMerchantReward ? (
                          <>
                            <a href={`/gift/${item.id}`}>
                              <button className="button is-fullwidth py-4 button-change-now">
                                ĐỔI NGAY
                              </button>
                            </a>
                          </>
                        ) : item.remainingGifts === 0 ? (
                          <button
                            className="button is-fullwidth py-4 button-change-now-more"
                            disabled={true}
                          >
                            HẾT QUÀ
                          </button>
                        ) : (
                          <>
                            <a href={`/gift/${item.id}`}>
                              <button className="button is-fullwidth py-4 button-change-now-more">
                                THÊM {item.needingMerchantReward} KOIN
                              </button>
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            {numberEvoucher * parseInt(pageNumber) < evoucherTotalRecords && (
              <>
                <div className="columns column-button-more">
                  <div className="column has-text-centered">
                    <button
                      className="button-more is-second-custom text-view-more"
                      onClick={() => handleShowMoreEvoucher(evoucherTotalRecords)}
                    >
                      XEM THÊM
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-0">
          <ContactButton />
        </div>

        {/* POPUP MODAL */}
        <div
          className={classNames('modal px-5', {
            'is-active': !!modalState,
          })}
        >
          <div className="modal-background"></div>
          <div className="modal-card ">
            <div style={{position: 'absolute', right: 5, top: 10}}>
              <button
                className="delete"
                aria-label="close"
                onClick={() => setModalState(null)}
              ></button>
            </div>

            {/* START MODAL SECTION */}
            <>
              <div style={{height: 50}} />
              <div className="popup-scanbill-bg-v1">
                <div className=" px-4 has-text-centered pt-6">
                  <img
                    src={BUCKET_IMAGES.OOPS_TITLE}
                    style={{marginTop: -90, width: '90%'}}
                    alt="modal title"
                  />
                  <div className="mb-0 px-4 is-relative">
                    <img
                      src={BUCKET_IMAGES.EXCEED_10K_REDEEM_NEW_CONTENT}
                      alt="modal content"
                      style={{width: '90%'}}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '12%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                      }}
                    >
                      <div
                        style={{
                          color: '#323130',
                        }}
                      >
                        Xem thêm
                      </div>
                      <Link
                        to={ROUTE_NAME.INFO}
                        style={{
                          color: '#2600BF',
                          fontWeight: '600',
                          textDecoration: 'underline',
                        }}
                      >
                        Thể lệ chương trình
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      className="button button-custom is-uppercase is-second-custom button-text-mission has-text-info mt-4 mb-5"
                      onClick={() => {
                        setModalState(MODAL_STATES.NONE);
                        // navigate(ROUTE_NAME.HOME);
                      }}
                      style={{
                        width: '60%',
                        minWidth: 150,
                        maxWidth: 300,
                        fontSize: 16,
                        fontWeight: 700,
                      }}
                    >
                      Trở về
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  position: 'relative',
                  top: '-7%',
                  left: '2%',
                }}
              >
                <img src={balloon} alt="balloon" />
              </div>
            </>
            {/* END MODAL SECTION */}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default VoucherDetail;
