export const storeCheckinDetailSelector = (state) => state.storeCheckin.storeDetail;
export const logoImageSelector = (state) => state.storeCheckin.logoImage;
export const huntingCodeSelector = (state) => state.storeCheckin.huntingCode;
export const huntingDataSelector = (state) => state.storeCheckin.huntingData;
export const modalStateSelector = (state) => state.storeCheckin.modalState;
export const storeCheckinIsScanningSelector = (state) => state.storeCheckin.isScanningLogo;
export const isValidatingHuntingCodeSelector = (state) =>
  state.storeCheckin.isValidatingHuntingCode;
export const rewardReceivedSelector = (state) => state.storeCheckin.checkInReward;
export const isShareFBSelector = (state) => state.storeCheckin.isShareFB;
export const logoUploadIdSelector = (state) => state.storeCheckin.logoUploadId;
export const transactionIdSelector = (state) => state.storeCheckin.transactionId;
export const codeTypeSelector = (state) => state.storeCheckin.codeType;
