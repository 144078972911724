import {CONTACT_INFO} from 'constants/app';
import React from 'react';
import Hotline from 'images/hotline_new.png';

const ContactButton = ({isImage}) => {
  return (
    <>
      <div className="columns">
        <div
          className="column"
          style={{
            textAlign: 'center',
          }}
        >
          {/* <img src={Hotline} /> */}
          <a href={`tel:${CONTACT_INFO.phone}`} className=" customer-text button-radius">
            <img src={Hotline} alt="contact" style={{width: '100%', maxWidth: 520}} />
          </a>
        </div>
      </div>
    </>
  );
};

export default ContactButton;
