import React, { useEffect } from "react";
import Layout from "../../components/layout";
import Carousel from "../../components/carousel";
import "./styles.scss";
import Benner from "images/benner_2.jpg";
import image_default from "images/image_default.png";
import QuickLogin from "components/quickLogin";
import {
  getGiftAllByMerchant,
  getGiftAllUserByMerchant,
} from "appRedux/actions/app";
import { useDispatch, useSelector } from "react-redux";
import { isLogInSelector, userInfoSelector } from "appRedux/selectors/auth";
import ContactButton from "components/contactButton";
import classNames from "classnames";

import lucky_wheel_border from "images/lucky_wheel_border.png";

import WheelComponent from "react-wheel-of-prizes";

import Lucky from "../../components/lucky";
import Winwheels from "../../components/Winwheel";

const VoucherList = () => {
  const dispatch = useDispatch();
  const isLogIn = useSelector(isLogInSelector);
  const [dataGift, setDataGift] = React.useState([]);
  const [dataGiftUser, setDataGiftUser] = React.useState([]);
  const [dataGiftChange, setDataGiftChange] = React.useState([]);
  const [dataGiftUnChange, setDataGiftUnChange] = React.useState([]);

  const [numberOfitemsShown, setNumberOfItemsToShown] = React.useState(15);
  const [numberChangeable, setNumberChangeable] = React.useState(15);
  const [numberUnchangeable, setNumberUnchangeable] = React.useState(5);
  const [tabStatus, setTabStatus] = React.useState(1);
  const [points, setPoints] = React.useState(false);

  useEffect(() => {
    if (isLogIn) {
      dispatch(
        getGiftAllUserByMerchant({
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          callback: (data) => {
            setDataGiftUser(data);
          },
        })
      );
    } else {
      dispatch(
        getGiftAllByMerchant({
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          callback: (data) => {
            setDataGift(data);
          },
        })
      );
    }
  }, [isLogIn]);
  const handleShowMore = () => {
    setNumberOfItemsToShown(dataGift.length);
  };

  const handleShowMoreChange = () => {
    setNumberChangeable(
      dataGiftUser.filter((f) => f.canRedeemByMerchantReward).length
    );
  };

  const handleShowMoreUnChange = () => {
    // if (numberUnchangeable + 1 <= dataGiftUser.filter((f) => !f.canRedeemByMerchantReward).length) {
    //   setNumberUnchangeable(numberUnchangeable + 5);
    // } else {
    //   setNumberUnchangeable(dataGiftUser.filter((f) => !f.canRedeemByMerchantReward).length);
    // }
    setNumberUnchangeable(
      dataGiftUser.filter((f) => !f.canRedeemByMerchantReward).length
    );
  };
  const tabData = [
    {
      key: 1,
      text: "Đổi quà",
    },
    {
      key: 2,
      text: "Đổi lượt quay",
    },
  ];
  const onChangeStatus = (id) => {
    setTabStatus(id);
  };

  return (
    <Layout
      headerProps={{
        index: 1,
      }}
    >
      <Carousel
        axis="horizontal"
        interval="2000"
        showThumbs={false}
        showStatus={false}
        images={[Benner, Benner, Benner]}
      />

      <div className="container-home">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="has-text-centered">
                {tabData.map((item) => (
                  <div
                    key={item.key}
                    onClick={() => onChangeStatus(item.key)}
                    className={classNames("button is-tab", {
                      "mr-2 mr-2-mobile": !item.isLast,
                      "is-tabActive": item.key === tabStatus,
                    })}
                  >
                    {item.text}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {tabStatus == "1" ? (
            <>
              <div className="columns">
                <div className="column">
                  <div className="head-title head-title-top">
                    ĐỔI ĐIỂM LẤY VOUCHER HOẶC QUÀ TẶNG
                  </div>
                  <p>
                    Đừng quên đổi điểm tích lũy nhận quà tặng hấp dẫn từ chương
                    trình. Số điểm tích lũy sẽ được duy trì trong vòng 12 tháng
                    kể từ khi phát sinh giao dịch được ghi nhận trên hệ thống.
                  </p>
                </div>
              </div>
              {isLogIn ? (
                <>
                  {dataGiftUser?.filter((f) => f.canRedeemByMerchantReward)
                    .length > 0 && (
                    <div className="voucher-group-img">
                      <div className="gallery-title">Ưu đãi đổi được ngay</div>
                      <div className="columns is-multiline">
                        {dataGiftUser
                          ?.filter((f) => f.canRedeemByMerchantReward)
                          .slice(0, numberChangeable)
                          .map((item, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="column is-one-quarter-desktop-gift is-half-tablet"
                                  style={{padding: 10}}
                                >
                                  <a href={`/gift/${item.id}`}>
                                    <div
                                      className="card-image"
                                      style={{
                                        position: 'relative',
                                      }}
                                    >
                                      <figure className="image is-image-voucher">
                                        {item?.image ? (
                                          <img src={item.image} alt="" />
                                        ) : (
                                          <img src={image_default} alt="" />
                                        )}
                                      </figure>

                                      <div className="gift-group-tile">
                                        <div className="voucher-name">{item?.name}</div>
                                        <div className="voucher-discount">{item?.discount}</div>
                                        <div className="voucher-group-point">
                                          <span>
                                            <span className="voucher-point">
                                              {item?.rewardValue.toLocaleString()} ĐIỂM
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  )}
                  {numberChangeable <
                    dataGiftUser?.filter((f) => f.canRedeemByMerchantReward)
                      .length && (
                    <>
                      <div className="columns clearfix">
                        <div
                          className="column"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            className="button-view-more"
                            onClick={handleShowMoreChange}
                          >
                            Xem thêm
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  <ContactButton />
                  {dataGiftUser?.filter((f) => !f.canRedeemByMerchantReward)
                    .length > 0 && (
                    <div className="voucher-group-img">
                      <div className="gallery-title">
                        Ưu đãi chưa đủ điều kiện đổi
                      </div>
                      <div className="columns is-multiline">
                        {dataGiftUser
                          ?.filter((f) => !f.canRedeemByMerchantReward)
                          .slice(0, numberUnchangeable)
                          .map((item, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="column is-one-quarter-desktop-gift is-half-tablet"
                                  style={{ padding: 10 }}
                                >
                                  <a href={`/gift/${item.id}`}>
                                    <div
                                      className="card-image"
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <figure className="image is-image-voucher">
                                        {item?.thumbnailImage ? (
                                          <img
                                            src={item.thumbnailImage}
                                            alt=""
                                          />
                                        ) : (
                                          <img src={image_default} alt="" />
                                        )}
                                      </figure>

                                      <div className="gift-group-tile">
                                        <div className="voucher-name">
                                          {item?.name}
                                        </div>
                                        <div className="voucher-discount">
                                          {item?.discount}
                                        </div>
                                        <div className="voucher-group-point">
                                          <span>
                                            <span className="voucher-point">
                                              {item?.rewardValue.toLocaleString()}{" "}
                                              ĐIỂM
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </>
                            );
                          })}
                      </div>
                      {numberUnchangeable <
                        dataGiftUser?.filter(
                          (f) => !f.canRedeemByMerchantReward
                        ).length && (
                        <>
                          <div className="columns clearfix">
                            <div
                              className="column"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <button
                                className="button-view-more"
                                onClick={handleShowMoreUnChange}
                              >
                                Xem thêm
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="voucher-group-img">
                    {dataGift
                      ?.slice(0, numberOfitemsShown)
                      .map((item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="column is-one-quarter-desktop-gift is-half-tablet"
                              style={{ padding: 10 }}
                            >
                              <a href={`/gift/${item.id}`}>
                                <div
                                  className="card-image"
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <figure className="image is-image-voucher">
                                    {item?.thumbnailImage ? (
                                      <img src={item.thumbnailImage} alt="" />
                                    ) : (
                                      <img src={image_default} alt="" />
                                    )}
                                  </figure>

                                  <div className="gift-group-tile">
                                    <div className="voucher-name">
                                      {item?.name}
                                    </div>
                                    <div className="voucher-discount">
                                      {item?.discount}
                                    </div>
                                    <div className="voucher-group-point">
                                      <span>
                                        <span className="voucher-point">
                                          {item?.rewardValue.toLocaleString()}{" "}
                                          ĐIỂM
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </>
                        );
                      })}
                  </div>
                  {numberOfitemsShown < dataGift?.length && (
                    <>
                      <div className="columns clearfix">
                        <div
                          className="column"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            className="button-view-more"
                            onClick={handleShowMore}
                          >
                            Xem thêm
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  <QuickLogin fromHome={false} />

                  <ContactButton />
                </>
              )}
            </>
          ) : isLogIn && (
            <>
              <div className="columns">
                <div className="column">
                  <div className="head-title head-title-top">
                    ĐỔI ĐIỂM LẤY LƯỢT QUAY VÒNG XOAY MAY MẮN
                  </div>
                  <p>
                    Đừng quên đổi điểm tích lũy nhận quà tặng hấp dẫn từ chương
                    trình. Số điểm tích lũy sẽ được duy trì trong vòng 12 tháng
                    kể từ khi phát sinh giao dịch được ghi nhận trên hệ thống.
                  </p>
                </div>
              </div>
              <Lucky />
              <ContactButton />
            </>
          )}
        </div>
      </div>
      <div className="pic-wave-bg"></div>
    </Layout>
  );
};
export default VoucherList;
