import utils from 'utils/apiUtils';
import {SCAN_BILL, STRONG_BOW, CITIZEN_IDENTIFICATION} from './api-definitions';

export const postHistoryScanBillApi = async (payload) =>
  utils.post(SCAN_BILL.postHistoryScanBill, payload);

export const scanBillValidateLimitItemApi = async () => utils.get(SCAN_BILL.validateLimitItem);

export const strongBowScanLogoApi = (payload) => utils.postFormData(STRONG_BOW.scanLogo, payload);

export const strongBowStoreCheckInApi = (payload) => utils.post(STRONG_BOW.checkIn, payload);

export const strongBowStoreShareFBApi = (payload) => utils.post(STRONG_BOW.shareFB, payload);

export const uploadCitizenIdentificationApi = (payload) =>
  utils.postFormData(CITIZEN_IDENTIFICATION.postUploadCitizenIdentification, payload);
export const getCitizenIdentificationByMerchantIdApi = (merchantId) =>
  utils.get(CITIZEN_IDENTIFICATION.getCitizenIdentificationByMerchantId(merchantId));
