export const TAB_DATA = [
  {
    index: 0,
    text: 'Điểm thành viên',
  },
  {
    index: 1,
    text: 'Ưu đãi đã đổi',
  },
  {
    index: 2,
    text: 'Lịch sử giao dịch',
  },
  {
    index: 3,
    text: 'Tình trạng giao hàng',
  },
];
