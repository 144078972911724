import React, {useEffect, useState} from 'react';
import icon_delete from 'images/icon_delete_brown.png';
import {toast} from 'react-toastify';
import camera from 'images/strongbow/camera.png';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {isCitizenIdCardUploadingSelector} from 'appRedux/selectors/app';

const CITIZEN_ID_STATUS = {
  0: 'Đang xác thực',
  1: 'Đã xác thực',
};
const CitizenIdCardUpload = ({
  onSubmitCitizenIdFiles = () => {},
  frontImage,
  backImage,
  citizenId = '',
  handleChangeCitizenIdImage = () => {},
  handleChangeCitizenId = () => {},
  handleDeleteCitizenIdImage = () => {},
  handleDeleteCitizenId = () => {},
  citizenIdFormError = {citizenId: '', frontImage: '', backImage: ''},
  citizenIdStatus,
  // selectedImage = null,
}) => {
  const [dragActive, setDragActive] = useState(false);
  //const [selectedImage, setSelectedImage] = useState(image);
  const isUploading = useSelector(isCitizenIdCardUploadingSelector);
  const [statusButton, setStatusButton] = useState(false);
  const [styleCitizenIdInput, setStyleCitizenIdInput] = useState({});

  useEffect(() => {
    if (!!backImage && !!frontImage && citizenId.length >= 9) {
      setStatusButton(true);
    } else {
      setStatusButton(false);
    }
  }, [backImage, frontImage, citizenId]);

  useEffect(() => {
    if (citizenIdStatus === 0 || citizenIdStatus === 1) {
      setStyleCitizenIdInput({
        WebkitTextFillColor: '#83552D',
        background: '#D6BA85',
        opacity: 1,
      });
    } else if (citizenIdStatus === null || citizenIdStatus === undefined) {
      setStyleCitizenIdInput({});
    }
  }, [citizenIdStatus]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  return (
    <div className="rows">
      <div
        className="mx-auto my-5"
        style={{
          height: 1,
          backgroundColor: '#D6BA85',
          width: '80%',
        }}
      ></div>
      <div className="columns">
        <div className="column is-9-tablet is-offset-1-tablet ">
          <div
            className="mb-3 has-text-weight-bold has-text-info has-text-centered"
            style={{fontSize: 18}}
          >
            Xác thực CMND/CCCD
          </div>
          <div class="field custom-input ">
            <label className="label is-size-7-mobile" style={{fontSize: 14}}>
              Số <span style={{color: 'red'}}>*</span>
            </label>
            <div className="control">
              <input
                className="input input-is-custom has-text-weight-semibold has-text-info is-size-6"
                type="text"
                maxLength={12}
                minLength={9}
                placeholder="Số CMND/CCCD"
                name="citizenId"
                value={citizenId}
                style={styleCitizenIdInput}
                disabled={citizenIdStatus === 0 || citizenIdStatus === 1}
                onChange={handleChangeCitizenId}
              />
              {
                citizenIdFormError.citizenId && (
                  <p className="help mb-0 is-danger">
                    <span>{citizenIdFormError.citizenId}</span>
                  </p>
                )
                /* {get(formik.touched, 'phoneNumber', false) &&
                          !!get(formik.errors, 'phoneNumber') && (
                            <p className="help mb-0 is-danger">
                              <span>{get(formik.errors, 'phoneNumber')}</span>
                            </p>
                          )} */
              }
              <div
                style={{
                  position: 'absolute',
                  top: 6,
                  right: 12,
                  cursor: 'pointer',
                }}
                className={`${
                  citizenIdStatus === 0 || citizenIdStatus === 1 || citizenId.length === 0
                    ? 'is-hidden'
                    : ''
                }`}
                onClick={(e) => {
                  handleDeleteCitizenId(e);
                }}
              >
                <img
                  src={icon_delete}
                  style={{
                    height: 20,
                  }}
                  alt="remove"
                />
              </div>
            </div>
          </div>
          {/* ............. */}
          <div class="field custom-input ">
            <label className="label is-size-7-mobile" style={{fontSize: 14}}>
              Ảnh <span style={{color: 'red'}}>*</span>
            </label>
            <div>
              <form
                className="form-file-idcard-upload"
                id=""
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <div>
                  <input
                    type="file"
                    id="input-file-frontImage-idcard-upload"
                    className="input-file-idcard-upload"
                    accept="image/png, image/gif, image/jpeg"
                    disabled={citizenIdStatus === 0 || citizenIdStatus === 1}
                    onChange={(e) => {
                      handleChangeCitizenIdImage(e, 'frontImage');
                    }}
                    name="frontImage"
                    capture="camera"
                  />
                  <label
                    id="label-file-frontImage-idcard-upload"
                    htmlFor="input-file-frontImage-idcard-upload"
                    className="label-file-idcard-upload is-relative"
                  >
                    {!!frontImage ? (
                      <>
                        <div
                          style={{
                            position: 'absolute',
                            top: -10,
                            right: -10,
                            cursor: 'pointer',
                          }}
                          className={`${
                            citizenIdStatus === 0 || citizenIdStatus === 1 ? 'is-hidden' : ''
                          }`}
                          onClick={(e) => {
                            if (citizenIdStatus !== 0) {
                              handleDeleteCitizenIdImage(e, 'frontImage');
                            }
                          }}
                        >
                          <img
                            src={icon_delete}
                            style={{
                              height: 20,
                            }}
                            alt="remove"
                          />
                        </div>
                        <div
                          key={'123'}
                          className="card-image"
                          style={{
                            height: '100%',
                          }}
                        >
                          <figure
                            className="image"
                            style={{
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={
                                !frontImage
                                  ? ''
                                  : typeof frontImage === 'string'
                                  ? frontImage
                                  : URL.createObjectURL(frontImage)
                              }
                              style={{
                                width: '90%',
                                maxHeight: '90%',
                              }}
                              alt="Front_Image"
                            />
                          </figure>
                        </div>
                      </>
                    ) : (
                      <div>
                        <span className="icon-text">
                          <span className="icon">
                            <img
                              src={camera}
                              style={{
                                height: 20,
                                width: 20,
                              }}
                              alt="camera"
                            />
                          </span>
                          <span style={{fontSize: 16}} className="upload-button">
                            Mặt trước
                          </span>
                        </span>
                      </div>
                    )}
                  </label>
                  {citizenIdFormError.frontImage && (
                    <p className="help mb-0 is-danger">
                      <span>{citizenIdFormError.frontImage}</span>
                    </p>
                  )}
                </div>
                <div className="">
                  <input
                    type="file"
                    id="input-file-backImage-idcard-upload"
                    className="input-file-idcard-upload"
                    accept="image/png, image/gif, image/jpeg"
                    disabled={citizenIdStatus === 0 || citizenIdStatus === 1}
                    onChange={(e) => {
                      handleChangeCitizenIdImage(e, 'backImage');
                    }}
                    name="backImage"
                    capture="camera"
                  />
                  <label
                    id="label-file-backImage-idcard-upload"
                    htmlFor="input-file-backImage-idcard-upload"
                    className="label-file-idcard-upload is-relative"
                  >
                    {!!backImage ? (
                      <>
                        <div
                          style={{
                            position: 'absolute',
                            top: -10,
                            right: -10,
                            cursor: 'pointer',
                          }}
                          className={`${
                            citizenIdStatus === 0 || citizenIdStatus === 1 ? 'is-hidden' : ''
                          }`}
                          onClick={(e) => {
                            handleDeleteCitizenIdImage(e, 'backImage');
                          }}
                        >
                          <img
                            src={icon_delete}
                            style={{
                              height: 20,
                            }}
                            alt="remove"
                          />
                        </div>
                        <div key={'456'} className="card-image" style={{height: '100%'}}>
                          <figure
                            className="image"
                            style={{
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={
                                !backImage
                                  ? ''
                                  : typeof backImage === 'string'
                                  ? backImage
                                  : URL.createObjectURL(backImage)
                              }
                              style={{width: '90%', maxHeight: '90%'}}
                              alt="Back_Image"
                            />
                          </figure>
                        </div>
                      </>
                    ) : (
                      <div>
                        <span className="icon-text">
                          <span className="icon">
                            <img
                              src={camera}
                              style={{
                                height: 20,
                                width: 20,
                              }}
                              alt="camera"
                            />
                          </span>
                          <span style={{fontSize: 16}} className="upload-button">
                            Mặt sau
                          </span>
                        </span>
                      </div>
                    )}
                  </label>
                  {citizenIdFormError.backImage && (
                    <p className="help mb-0 is-danger">
                      <span>{citizenIdFormError.backImage}</span>
                    </p>
                  )}
                </div>
              </form>
              {citizenIdStatus === null || citizenIdStatus === undefined ? (
                <></>
              ) : (
                <div className="mt-4">
                  Trạng thái:{' '}
                  <span
                    style={{
                      color: '#a38906',
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    {CITIZEN_ID_STATUS[citizenIdStatus]}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        {citizenIdStatus === 0 || citizenIdStatus === 1 ? (
          <></>
        ) : (
          <div className="column has-text-centered">
            <button
              type="button"
              className={classNames('button is-primary-custom', {
                'is-loading': isUploading,
                // 'is-defult-custom': !statusButton,
                // 'is-primary-custom': statusButton,
              })}
              style={{
                color: '#FFF',
                padding: '14px 50px',
                fontSize: 16,
                fontWeight: 700,
                textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                width: 250,
              }}
              disabled={
                isUploading || !statusButton || citizenIdStatus === 0 || citizenIdStatus === 1
              }
              // onClick={() => {
              //   formik.setFieldTouched('district', true, true);
              //   formik.handleSubmit();
              // }}
              onClick={() => {
                setStatusButton(false);
                onSubmitCitizenIdFiles();
              }}
            >
              CẬP NHẬT
            </button>
          </div>
        )}
      </div>
      {/* .................. */}
    </div>
  );
};

export default CitizenIdCardUpload;
