export const USER_GIFT_STATUSES = {
  ALL: -1, //TODO: update status
  EXCHANGED: 1,
  USED: 2,
  LOCKED: 3,
  EXPIRED: 9,
};

export const GIFT_CLASSIFY = {
  CHILL_GIFT: 'CHILL_GIFT',
  STORE_GIFT: 'STORE_GIFT',
  E_VOUCHER: 'E_VOUCHER',
};
