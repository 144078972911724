import AppLayout from 'components/layout';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import ContentContact from 'components/footerMeoChill/contact';
import ContactButton from 'components/contactButton';
import './styless.scss';
import icon_delete from 'images/icon_delete_brown.png';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {
  storeCheckinDetailSelector,
  storeCheckinIsScanningSelector,
} from 'appRedux/selectors/storeCheckin';
import {getStoreDetailByCodeAction, scanLogoAction} from 'appRedux/actions/storeCheckin';
import Resizer from 'react-image-file-resizer';
import classNames from 'classnames';
import {
  resetStoreCheckIn,
  updateCodeType,
  updateModalState,
} from 'appRedux/slices/storeCheckinSlice';
import {MODAL_STATES, QR_CODE_TYPE} from 'constants/meoChill';
import {ROUTE_NAME} from 'constants/routes';
import MeoChillModal from 'components/meoChillModal';
import {isLogInSelector, citizenIdInfoSelector} from 'appRedux/selectors/auth';
import {rewardInfoSelector} from 'appRedux/selectors/app';
import warning_popup from 'images/strongbow/pc/warning-popup.png';

const MeoChillCheckIn = () => {
  const {storeId} = useParams();
  const dispatch = useDispatch();
  const storeDetail = useSelector(storeCheckinDetailSelector);
  const isScanningLogo = useSelector(storeCheckinIsScanningSelector);
  const [selectedImage, setSelectedImage] = useState(null);
  const isLogIn = useSelector(isLogInSelector);
  const rewardInfo = useSelector(rewardInfoSelector);
  const citizenIdInfo = useSelector(citizenIdInfoSelector);
  const navigate = useNavigate();
  const handleWarningPolicyEditProfile = () => {
    navigate(ROUTE_NAME.EDIT_PROFILE);
  };

  const setModalState = useCallback(
    (state) => {
      dispatch(updateModalState(state));
    },
    [dispatch]
  );
  const handleUploadMoreBill = (event) => {
    // setBillUploadKey(billUploadKey + 1);
    setModalState(MODAL_STATES.NONE);
    handleChange(event);
  };

  useEffect(() => {
    dispatch(updateCodeType(QR_CODE_TYPE.SCAN_LOGO));
    if (!!storeId) {
      dispatch(
        getStoreDetailByCodeAction({
          storeCode: storeId,
          callback: (error, res) => {
            if (!!error || !res) {
              navigate(ROUTE_NAME.CHILL_MAP);
            }
          },
        })
      );
    }
  }, []);

  const storeInfo = useMemo(() => {
    return [storeDetail?.brandName, storeDetail?.address].filter((item) => item).join(', ');
  }, [storeDetail]);

  const handleChange = (event) => {
    event.preventDefault();
    let files = event.target.files;
    if (event.target.files) {
      let type = event.target.files[0].type;
      if (['image/jpeg', 'image/png', 'image/gif'].includes(type)) {
        validateSelectedFile(files[0]);
      } else {
        toast.error('Vui lòng chọn hình ảnh', {autoClose: 2000, position: 'top-center'});
      }
      event.target.value = '';
    }
  };
  const validateSelectedFile = (selectedFile) => {
    if (!selectedFile) {
      return;
    }

    resizeFile(selectedFile).then((resizedFile) => {
      // const MAX_FILE_SIZE = 5120; // 5MB
      // const fileSizeKiloBytes = resizedFile.size / 1024;

      // if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      //   console.log('Vui lòng chọn hình ảnh nhở hơn 5MB');
      //   // toast.error('Vui lòng chọn hình ảnh nhở hơn 5MB', {autoClose: 2000, position: 'top-center'});
      //   setModalState(MODAL_STATES.REJECT);
      //   return;
      // }
      setSelectedImage(resizedFile);
    });
  };

  const resizeFile = (file) => {
    const SHOULD_RESIZE_FILE_SIZE = 2048;
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes <= SHOULD_RESIZE_FILE_SIZE) {
      return new Promise((resolve) => {
        resolve(file);
      });
    }
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1600,
        'jpeg',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
        300,
        400
      );
    });
  };

  const onSubmitBillDetection = async (file) => {
    const formData = new FormData();
    const image = await resizeFile(file);
    formData.append('FormFile', image);
    dispatch(
      scanLogoAction({
        body: formData,
        storeId: storeDetail?.id,
        callback: (res, err) => {
          setSelectedImage(null);

          return;
        },
      })
    );
  };

  const onDeleteImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedImage(null);
  };
  return (
    <>
      <AppLayout ignoreOver18Check isFooter={false}>
        {isLogIn &&
          (citizenIdInfo?.data === null || citizenIdInfo?.data?.status !== 1) &&
          rewardInfo?.totalRewardsAvailable >= 400 &&
          rewardInfo?.totalRewardsAvailable <= 500 && (
            <span
              onClick={() => {
                handleWarningPolicyEditProfile();
              }}
            >
              <img src={warning_popup} class="fix-mobile" />
            </span>
          )}
        <div className="container-home-meo-chill-checkin">
          {/* <div className="container-home-meo-chill"> */}
          <div className="container pb-0">
            <div className="container-content" style={{marginTop: 385}}>
              <div className="pl-5">
                <img
                  alt="check in"
                  src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Tie%CC%82u%20%C4%91e%CC%82%CC%80%20selfie.png"
                />
              </div>
              {!!storeInfo && (
                <div className="has-text-centered px-6 has-text-meo-chill-store has-text-meo-chill-store-bold is-size-5">
                  {storeInfo}
                </div>
              )}
              <div
                className="has-text-centered has-text-meo-chill-store pt-1"
                style={{fontWeight: 600, fontStyle: 'italic'}}
              >
                điểm danh chiller có mặt!
              </div>

              <div className="pl-4" style={{position: 'relative'}}>
                <form id="form-file-upload-" onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="file"
                    id="input-file-upload-meo-chill-bill"
                    // multiple={true}
                    accept="image/png, image/gif, image/jpeg"
                    onChange={handleChange}
                    capture="camera"
                    style={{display: 'none'}}
                    disabled={isScanningLogo}
                  />
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload-meo-chill-bill"
                    style={{border: 'none'}}
                  >
                    <div style={{position: 'relative'}}>
                      <img src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Chu%CC%A3p%20hi%CC%80nh%203.png" />
                    </div>
                    {selectedImage && (
                      <>
                        <div className="meo-chhill-img-upload">
                          <img src={URL.createObjectURL(selectedImage)} />
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            top: 70,
                            right: 60,
                            cursor: 'pointer',
                          }}
                          onClick={(e) => onDeleteImage(e)}
                        >
                          <img
                            src={icon_delete}
                            style={{
                              height: 24,
                            }}
                            alt="remove"
                          />
                        </div>
                      </>
                    )}
                  </label>
                </form>

                <div
                  className="has-text-centered pl-4"
                  style={{position: 'absolute', right: 0, bottom: 30, left: 0}}
                >
                  <button
                    className={classNames(
                      'button button-custom is-primary-custom button-text-mission',
                      {
                        'is-loading': isScanningLogo,
                      }
                    )}
                    style={{
                      textTransform: 'uppercase',
                      width: 120,
                      color: 'white',
                    }}
                    disabled={isScanningLogo || !selectedImage}
                    onClick={() => onSubmitBillDetection(selectedImage)}
                  >
                    Gửi
                  </button>
                </div>
              </div>
              <div style={{paddingBottom: 60}}>
                <img src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Khung%20gio%CC%9B%CC%80%20va%CC%80ng.png" />
              </div>

              <div
                className="px-6"
                style={{
                  paddingTop: 230,
                }}
              >
                <ContactButton />
              </div>
              <ContentContact />
            </div>
          </div>
        </div>
        <MeoChillModal handleUploadMoreBill={handleUploadMoreBill} />
      </AppLayout>
    </>
  );
};

export default MeoChillCheckIn;
