import utils from 'utils/apiUtils';
import {
  ACTION_RESULT,
  COMPUTE,
  EXCHANGE_REWARD,
  REDEEM_TRANSACTION,
  REFERRAL,
  REFERRAL_LOYALTY,
  REFERRAL_TRANSACTION,
  RETAIL_TRANSACTION,
  REWARD,
  REWARD_HISTORY,
  USER_GIFT,
  USER_PRODUCT,
  DELIVERY_TRANSACTION,
  SCAN_BILL,
  DELIVERY
} from './api-definitions';

export const generateCouponCodeApi = ({payload = {}}) =>
  utils.post(USER_GIFT.postGenerateUserGiftCode, payload);
export const getUsedCouponCodeApi = ({userGiftId}) =>
  utils.get(USER_GIFT.getUserGiftCodeDetail(userGiftId));
export const productSerialUsedApi = ({userGiftId}) =>
  utils.put(USER_GIFT.putUseProductSerial(userGiftId));
export const getRedeemedGiftsApi = ({payload = {}}) => {
  const params = {
    pageIndex: 1,
    pageSize: 50,
    sortBy: [
      {
        fieldName: 'CreatedTime',
        ascending: false,
      },
    ],
    ...payload,
  };

  return utils.post(USER_GIFT.postSearchRedeemGift, params);
};
export const getRedeemedGiftDetailApi = (userGiftId) =>
  utils.get(USER_GIFT.getRedeemedGiftDetail(userGiftId));

export const actionResultTransactionInquireApi = (payload) => {
  const dataPost = {
    receiptNo: payload?.receiptNo,
  };
  return utils.post(ACTION_RESULT.postInquire, dataPost);
};

export const getReferralSumApi = () => utils.get(REFERRAL.getSumOfReferral);
export const postReferralInviteApi = (payload) => utils.post(REFERRAL.postInviteReferral, payload);
export const postReferralInviteLPApi = (payload) =>
  utils.post(REFERRAL_LOYALTY.postInvite, payload);
export const postReferralReInviteLPApi = (payload) =>
  utils.post(REFERRAL_LOYALTY.postReInvite, payload);
export const postReferralReInviteApi = (payload) =>
  utils.post(REFERRAL.postReInviteReferral, payload);
export const postReferralSearchApi = ({payload = {}}) => {
  const dataPost = {
    pageIndex: 1,
    pageSize: 20,
    statuses: [],
    searchText: '',
    sortBy: [
      {
        fieldName: 'CreatedTime',
        ascending: true,
      },
    ],
  };
  const data = {
    ...dataPost,
    ...payload,
  };
  return utils.post(REFERRAL.postSearchReferral, data);
};
export const getInviteReferralByMerchantApi = (merchantId) =>
  utils.get(REFERRAL.getInviteReferralByMerchant(merchantId));

export const getRewardHistoryIdApi = ({ownRewardHistoryId}) =>
  utils.get(REWARD_HISTORY.getRewardHistoryById(ownRewardHistoryId));
export const getLoyaltyProgramByUserApi = ({payload = {}}) =>
  utils.post(REWARD_HISTORY.postSearchLoyaltyProgram, payload);
export const getRewardPointApi = ({payload = {}}) => {
  const params = {
    pageIndex: 1,
    pageSize: 10,
    ...payload,
  };
  return utils.post(REWARD_HISTORY.postOwnRewardHistory, params);
};

export const updateTransactionByUserApi = (payload) =>
  utils.put(RETAIL_TRANSACTION.putUpdateRetailTransaction, payload);
export const getTransactionDetailByIdApi = ({transactionId}) =>
  utils.get(RETAIL_TRANSACTION.getRetailTransactionDetailById(transactionId));
export const createRetailFeedbackApi = (payload) =>
  utils.post(RETAIL_TRANSACTION.postCreateRetailFeedback, payload);

export const verifyExchangeCodeApi = ({userId, exchangeCode}) =>
  utils.get(USER_PRODUCT.getVerifyUserProduct(exchangeCode));
export const userProductExchangeTransactionApi = (payload) =>
  utils.post(USER_PRODUCT.postExchangeTransaction, payload);

export const createRedeemGiftApi = (payload) =>
  utils.post(REDEEM_TRANSACTION.postCreateRedeemGift, payload);

export const getRewardUserListApi = (payload) => utils.get(REWARD.getUserReward);

export const getReferalDetailByIdApi = ({transactionId}) =>
  utils.get(REFERRAL_TRANSACTION.getReferralTransactionById(transactionId));

export const computeRedeemGiftApi = (payload) =>
  utils.post(COMPUTE.postComputeGiftRedemption, payload);

export const createExchangeRewardApi = async (payload) =>
  utils.post(EXCHANGE_REWARD.createExchangeReward(), payload);

export const getTransactionExpiredApi = (payload) =>
  utils.post(REWARD.getTransactionExpired, payload);

export const getExchangeRewardDetailApi = ({transactionId}) =>
  utils.get(EXCHANGE_REWARD.getExchangeRewardDetail(transactionId));

export const getDeliveryTransactionDetailApi = () =>
  utils.get(DELIVERY_TRANSACTION.getDeliveryTransactionDetail);

export const getRewardHistoryScanBillApi = (payload) =>
  utils.post(SCAN_BILL.getRewardHistoryScanBill, payload);
  export const billDetectionApi = (payload) =>
    utils.postFormData('document-upload/api/text-recognizer/bill-upload', payload);
export const createDeliveryApi = (payload) =>
  utils.post(DELIVERY.postCreateDelivery, payload);

export const getDetailDeliveryByIdApi = (id) =>
  utils.get(DELIVERY.getDetailDeliveryById(id));