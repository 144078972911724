import utils from 'utils/apiUtils';
import {
  BRAND,
  CATEGORY,
  CHECK_IN,
  DPOINT_VERSION,
  EXCHANGE_REWARD,
  FAQ,
  FEEDBACK,
  GIFT,
  GIFT_TYPE,
  LOCATION,
  LOYALTY_PROGRAM,
  MEMBERSHIP_POLICY,
  MERCHANT,
  NOTIFICATION_DATA,
  PROMOTION,
  RATING,
  REVIEW,
  SEARCH_KEYWORD,
  STORE,
  SUB_CATEGORY,
  TERMS_CONDITION,
  TIER,
  USER_GUIDE,
} from './api-definitions';

export const likeFAQApi = (payload) => utils.post(FAQ.postLikeFaq, payload);
export const searchFAQApi = (payload) => utils.post(FAQ.postSearchFaq, payload);

export const getTermsConditionsApi = () => utils.get(TERMS_CONDITION.getTermsAndConditionDetails);

export const getSubjectsFeedbackApi = () => utils.get(FEEDBACK.getAllFeedbackCategories);

export const checkInCreateApi = (payload) => utils.post(CHECK_IN.postCreateUserCheckin, payload);
export const checkInCurrentApi = ({payload}) => {
  return utils.get(CHECK_IN.getCurrentUserCheckin, payload);
};

export const getGiftZoneApi = () => utils.get(GIFT.getGiftZone);
export const getMaxMinFilterGiftApi = () => utils.get(GIFT.getMaxMinReward);
export const getMaxMinFilterGiftByMerchantApi = ({merchantId}) =>
  utils.get(GIFT.getMaxMinRewardByMerchant(merchantId));
export const getDetailGiftApi = ({giftId}) => utils.get(GIFT.getGiftDetail(giftId));
export const getAllGiftListApi = (payload) => utils.post(GIFT.postGiftsOnTopMobile, payload);
export const getGiftListByTypeApi = (payload = {}) => {
  const params = {
    pageIndex: 1,
    pageSize: 10,
    ...payload,
  };
  return utils.post(GIFT.postSearchGiftsByFilterSettings, params);
};
export const getGiftByMerchantApi = (payload) => utils.post(GIFT.postSearchGiftsMobile, payload);

export const getAllGiftTypeApi = () => utils.get(GIFT_TYPE.getAllGiftTypes);

export const getGiftByKeywordApi = ({payload}) => utils.post(GIFT.getGiftByKeyword, payload);
export const createRatingApi = (payload) => utils.post(RATING.postCreateRating, payload);
export const getReviewSummarizeApi = ({payload = {}}) =>
  utils.post(RATING.postSummarizeRating, payload);
export const getReviewListApi = ({payload = {}}) => {
  const params = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: [
      {
        fieldName: 'CreatedTime',
        ascending: false,
      },
    ],
    ...payload,
  };

  return utils.post(RATING.postSearchRating, params);
};

export const getLoyaltyProgramIdApi = ({programId}) =>
  utils.get(LOYALTY_PROGRAM.getLoyaltyProgramById(programId));
export const getMobileLoyaltyProgramIdApi = ({programId}) =>
  utils.get(LOYALTY_PROGRAM.getLoyaltyProgramDetailById(programId));
export const getLoyaltyProgramByKeywordApi = ({payload}) =>
  utils.post(LOYALTY_PROGRAM.getLoyaltyProgramByKeyword, payload);
export const getMerchantShopApi = ({merchantId}) => utils.get(MERCHANT.getMerchantById(merchantId));
export const getMerchantDetailMobileApi = ({merchantId}) =>
  utils.get(MERCHANT.getMerchantDetailMobileById(merchantId));

export const getMerchantIdTierApi = ({programId}) => utils.get(TIER.getTiersByProgram(programId));
export const getDetailsTierIdApi = ({tierId}) => utils.get(TIER.getTierDetailById(tierId));

export const getStoreListByMerchant = (payload) => utils.post(STORE.postSearchStore, payload);

export const getLoyaltyProgramByMerchantApi = ({merchantId}) =>
  utils.get(LOYALTY_PROGRAM.getLoyaltyProgramsByMerchantId(merchantId));

export const getAllMerchantBrandsApi = () => utils.get(BRAND.getAllBrands);

export const getNotiTypeAllApi = () => utils.get(NOTIFICATION_DATA.getAllNotificationTypes);

export const getUserGuideApi = ({displayLocation}) =>
  utils.get(USER_GUIDE.getUserGuideDetails(displayLocation));

export const getMembershipPolicyApi = ({merchantId}) =>
  utils.get(MEMBERSHIP_POLICY.getMembershipPolicyDetailByMerchantId(merchantId));

export const getProductCategoryApi = () => utils.get(CATEGORY.getAllCategories);

export const getSubCategoryGiftApi = (payload) =>
  utils.post(SUB_CATEGORY.postSearchSubCategory, payload);

export const promotionSearchForGiftApi = ({payload = {}}) => {
  const dataPost = {
    pageIndex: 1,
    pageSize: 20,
    sortBy: [],
  };

  const data = {
    ...dataPost,
    ...payload,
  };
  return utils.post(PROMOTION.postSearchPromotionForGift, data);
};
export const promotionSearchForUserApi = ({payload = {}}) => {
  const dataPost = {
    pageIndex: 1,
    pageSize: 20,
    sortBy: [],
  };

  const data = {
    ...dataPost,
    ...payload,
  };
  return utils.post(PROMOTION.postSearchPromotionForUser, data);
};

export const getProvincesApi = async () => utils.get(LOCATION.getProvinces);
export const getDistrictApi = async () => utils.get(LOCATION.getDistricts);
// get district by code province
export const getDistrictCodeApi = ({provinceCode}) =>
  utils.get(LOCATION.getDistrictByProvinceCode(provinceCode));
export const getWardApi = async ({id}) => utils.get(LOCATION.getWardsByDistrict(id));

export const getAppVersionForceUpdateApi = async (params) =>
  utils.get(DPOINT_VERSION.getAppVersionForceUpdate(params));

export const getAllExchangeRewardApi = async () =>
  utils.get(EXCHANGE_REWARD.getAllExchangeReward());

export const getAllExchangeRewardByMerchantApi = async (params) =>
  utils.get(EXCHANGE_REWARD.getAllExchangeRewardByMerchantId(), params);

export const checkCanViewScreenApi = ({merchantId}) =>
  utils.get(EXCHANGE_REWARD.checkCanViewScreen(merchantId));

export const getExchangeRewardRedeemGiftApi = async (params) =>
  utils.get(EXCHANGE_REWARD.getExchangeRewardRedeemGift, params);
export const getSearchPopularKeywordApi = () => utils.get(SEARCH_KEYWORD.getSearchPopularKeyword());
export const getHistoryPopularKeywordApi = ({userId}) =>
  utils.get(SEARCH_KEYWORD.getSearchHistoryKeyword(userId));
export const deleteSearchPopularKeywordApi = ({userId}) =>
  utils.get(SEARCH_KEYWORD.deleteSearchHistoryKeyword(userId));
export const getReviewByScreenApi = ({screenCode}) =>
  utils.get(REVIEW.getReviewByScreen(), {screenCode});
export const submitReviewApi = (payload) => utils.post(REVIEW.submitReview(), payload);
export const markReviewAsShowApi = ({screenCode, reviewId}) =>
  utils.post(REVIEW.markReviewAsShow(), {screenCode, reviewId});

export const getStoreDetailByMerchantApi = (merchantId) =>
  utils.get(STORE.getStoreDetailByMerchant(merchantId));
