import {createSlice} from '@reduxjs/toolkit';
import {
  getUserProfileAction,
  updateUserProfileAction,
  verifyOTPAction,
  uploadCitizenIdentificationAction,
  getCitizenIdByMerchantIdAction,
} from 'appRedux/actions/auth';
import {signOutAction} from 'appRedux/actions/global';
import {setAuthToken} from 'utils/apiUtils';
// import {setAuthToken} from 'utils/apiUtils';

const initialState = {
  userInfo: {},
  accountInfo: {},
  isLogIn: false,
  citizenIdInfo: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [getUserProfileAction.fulfilled]: (state, {payload}) => {
      if (payload?.data) {
        const data = payload?.data || {};
        state.userInfo = {...data, fullName: data?.memberName || ''};
        // state.userInfo = payload?.data;
        state.isLogIn = true;
      }
    },
    [updateUserProfileAction.fulfilled]: (state, {payload}) => {
      const info = payload || {};
      state.userInfo = {
        ...state.userInfo,
        ...info,
        dateOfBirth: state.userInfo?.dateOfBirth,
        fullName: info?.memberName || '',
      };
    },
    [verifyOTPAction.fulfilled]: (state, {payload}) => {
      state.accountInfo = payload?.data?.data;
      state.isLogIn = true;
    },
    [signOutAction]: (state) => {
      setAuthToken();
      localStorage.removeItem('accessToken');
      localStorage.removeItem('warning-policy');
      state.accountInfo = {};
      state.isLogIn = false;
      state.userInfo = {};
    },
    [getCitizenIdByMerchantIdAction.fulfilled]: (state, {payload}) => {
      // if (payload?.data) {
      const data = payload?.data || {};
      state.citizenIdInfo = {...data};
      // }
    },
    [uploadCitizenIdentificationAction.fulfilled]: (state, {payload}) => {
      // const info = payload || {};
      state.citizenIdInfo = {
        ...state.citizenIdInfo, status: 0
      };
    },
  },
});

const {actions, reducer} = authSlice;
// export const {signOutAction} = actions;

export default reducer;
