import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  codeTypeSelector,
  huntingDataSelector,
  isShareFBSelector,
  logoUploadIdSelector,
  modalStateSelector,
  rewardReceivedSelector,
  transactionIdSelector,
} from 'appRedux/selectors/storeCheckin';
import {shareFacebookAction} from 'appRedux/actions/storeCheckin';
import {get} from 'lodash';
import classNames from 'classnames';
import {resetStoreCheckIn, updateModalState} from 'appRedux/slices/storeCheckinSlice';
import {BUCKET_IMAGES, MODAL_STATES, QR_CODE_TYPE} from 'constants/meoChill';
import {toggleLoginModal} from 'appRedux/slices/appSlice';
import balloon from 'images/strongbow/pc/balloon.png';
import {ROUTE_NAME} from 'constants/routes';
import {getRewardInfoAction} from 'appRedux/actions/app';
import {isAndroid, isIOS} from 'react-device-detect';

const MeoChillModal = ({handleUploadMoreBill = () => {}}) => {
  const dispatch = useDispatch();
  const modalState = useSelector(modalStateSelector);
  const isShareFB = useSelector(isShareFBSelector);
  const logoUploadId = useSelector(logoUploadIdSelector);
  const transactionId = useSelector(transactionIdSelector);
  const codeType = useSelector(codeTypeSelector);
  const huntingData = useSelector(huntingDataSelector);
  const rewardReceived = useSelector(rewardReceivedSelector);
  const [modalData, setModalData] = useState({imageChill: '', total: ''});
  const navigate = useNavigate();
  const [isSharing, setIsSharing] = useState(false);

  const shareHref = useMemo(() => {
    if (isAndroid) {
      return 'fb://faceweb/f?href=https%3A%2F%2Fm.facebook.com%2Fsharer.php%3Fu%3Dhttps%3A%2F%2Fstrongbowchillkoin.com%2Fmeo-chill';
    }
    if (isIOS) {
      return 'fb://share/?link=https%3A%2F%2Fstrongbowchillkoin.com%2Fmeo-chill';
    }
    return '';
  }, []);

  const setModalState = useCallback(
    (state) => {
      dispatch(updateModalState(state));
    },
    [dispatch]
  );

  const getRewardInfo = (imageChill = null) => {
    dispatch(
      getRewardInfoAction({
        callback: (res) => {
          const total = get(res, 'totalRewardsAvailable', 0);
          setModalData({imageChill, total});
        },
      })
    );
  };

  useEffect(() => {
    const chillKoins = {
      2: BUCKET_IMAGES.TWO_CHILL,
      4: BUCKET_IMAGES.FOUR_CHILL,
      8: BUCKET_IMAGES.EIGHT_CHILL,
    };
    const imageChill = get(chillKoins, rewardReceived);
    if (!!rewardReceived && !!imageChill) {
      getRewardInfo(imageChill);
    }
  }, [rewardReceived]);

  const onShare = useCallback(() => {
    if (isSharing) {
      return;
    }
    setIsSharing(true);
    setTimeout(() => {
      dispatch(
        shareFacebookAction({
          logoUploadId,
          transactionId,
          callback: (err, res) => {
            setIsSharing(false);
            console.log('🚀 ~ file: index.js ~ line 107 ~ dispatch ~ err', err);
            console.log('🚀 ~ file: index.js ~ line 106 ~ dispatch ~ res', res);
          },
        })
      );
    }, 10000);
  }, [isSharing, logoUploadId, transactionId]);

  const modalMemo = useMemo(() => {
    if (modalState === MODAL_STATES.NONE) return null;

    switch (modalState) {
      case MODAL_STATES.LOGIN:
        return (
          <>
            <div
              className={classNames('modal px-5', {
                'is-active': !!modalState,
              })}
            >
              <div className="modal-background"></div>
              <div className="modal-card ">
                <div style={{position: 'absolute', right: 15, top: 60}}>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => setModalState(MODAL_STATES.NONE)}
                  ></button>
                </div>
                <div style={{height: 50}} />
                <div className="popup-scanbill-bg">
                  <div className="px-5 has-text-centered pt-6">
                    <img
                      src={BUCKET_IMAGES.CONGRATS_TITLE}
                      // className=""
                      alt="modal title"
                      style={{marginTop: -90}}
                    />

                    <div className="mb-0 px-4">
                      <img
                        src={BUCKET_IMAGES.CONGRATS_CONTENT}
                        alt="modal content"
                        style={{width: '80%'}}
                      />
                    </div>
                    <div className=" has-text-weight-semibold is-size-4 has-text-black" style={{}}>
                      Đăng nhập ngay,
                      <br /> tích điểm liền tay!
                    </div>
                    {codeType !== QR_CODE_TYPE.NONE && (
                      <>
                        {codeType === QR_CODE_TYPE.SCAN_LOGO && (
                          <>
                            <div
                              className=" is-italic has-text-info is-size-4"
                              style={{
                                fontWeight: '900',
                              }}
                            >
                              4 Chill Koin
                            </div>
                            <div className="is-italic has-text-success is-size-6 my-2">
                              Đặc biệt khung giờ vàng{' '}
                              <span className="has-text-weight-bold">6pm-9pm</span>
                            </div>
                            <div
                              className=" is-italic has-text-info is-size-4"
                              style={{
                                fontWeight: '900',
                              }}
                            >
                              8 Chill Koin
                            </div>
                          </>
                        )}
                        {codeType === QR_CODE_TYPE.HUNTING && (
                          <>
                            <div
                              className=" is-italic has-text-info is-size-3 my-2"
                              style={{
                                fontWeight: '900',
                              }}
                            >
                              4 Chill Koin
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <div>
                      <button
                        className="button is-uppercase button-custom is-primary-custom button-text-mission mt-2"
                        onClick={() => {
                          dispatch(updateModalState(MODAL_STATES.NONE));
                          dispatch(toggleLoginModal(true));
                        }}
                        style={{
                          width: '60%',
                          minWidth: 150,
                          maxWidth: 300,
                        }}
                      >
                        Đăng nhập
                      </button>
                    </div>
                    <div>
                      <button
                        className="button button-custom is-uppercase is-second-custom button-text-mission has-text-info mt-2 mb-5"
                        onClick={() => setModalState(MODAL_STATES.NONE)}
                        style={{
                          width: '60%',
                          minWidth: 150,
                          maxWidth: 300,
                        }}
                      >
                        Trở về
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      case MODAL_STATES.SCAN_BILL_FAILED:
        return (
          <>
            <div
              className={classNames('modal px-5', {
                'is-active': !!modalState,
              })}
            >
              <div className="modal-background"></div>
              <div className="modal-card ">
                <div style={{position: 'absolute', right: 15, top: 60}}>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => setModalState(MODAL_STATES.NONE)}
                  ></button>
                </div>
                <div style={{height: 50}} />
                <div className="popup-scanbill-bg">
                  <div
                    style={{
                      position: 'absolute',
                      bottom: -5,
                      left: 10,
                    }}
                  >
                    <img src={balloon} />
                  </div>
                  <div className=" px-4 has-text-centered pt-5">
                    <img
                      src={BUCKET_IMAGES.SCAN_BILL_FAILED_TITLE}
                      // className=""
                      alt="modal title"
                      style={{marginTop: -90, width: '90%'}}
                    />

                    <div className="mb-4 mt-0 pl-4">
                      <img
                        src={BUCKET_IMAGES.SCAN_BILL_FAILED_CONTENT}
                        alt="modal content"
                        // style={{width: '90%'}}
                      />
                    </div>
                    <div>
                      <form className="">
                        <input
                          type="file"
                          id="input-file-upload-bill-fail"
                          accept="image/png, image/gif, image/jpeg"
                          capture="camera"
                          onChange={handleUploadMoreBill}
                          style={{
                            display: 'none',
                          }}
                        />
                      </form>
                      <label
                        htmlFor="input-file-upload-bill-fail"
                        className="button is-uppercase button-custom is-primary-custom button-text-mission mt-2 mb-6"
                        style={{
                          width: '60%',
                          minWidth: 150,
                          maxWidth: 300,
                          padding: '10px 30px',
                          fontSize: 16,
                          fontWeight: 700,
                          display: 'block',
                          textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                          margin: 'auto',
                        }}
                      >
                        Chụp lại
                      </label>
                    </div>
                  </div>
                </div>
                <div style={{height: 20}} />
              </div>
            </div>
          </>
        );

      case MODAL_STATES.CHECK_IN_ALREADY:
        return (
          <>
            <div
              className={classNames('modal px-5', {
                'is-active': !!modalState,
              })}
            >
              <div className="modal-background"></div>
              <div className="modal-card ">
                <div style={{position: 'absolute', right: 15, top: 60}}>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => setModalState(MODAL_STATES.NONE)}
                  ></button>
                </div>
                <div style={{height: 50}} />
                <div className="popup-scanbill-bg-v1">
                  <div
                    style={{
                      position: 'absolute',
                      bottom: -5,
                      left: 10,
                    }}
                  >
                    <img src={balloon} alt="balloon" />
                  </div>
                  <div className=" px-4 has-text-centered pt-6">
                    <img
                      src={BUCKET_IMAGES.CHECK_IN_ALREADY_TITLE}
                      // className=""
                      alt="modal title"
                      style={{marginTop: -90, width: '90%'}}
                    />

                    <div className="mb-0 px-4">
                      <img
                        src={
                          codeType === QR_CODE_TYPE.SCAN_LOGO
                            ? BUCKET_IMAGES.CHECK_IN_ALREADY_CONTENT
                            : BUCKET_IMAGES.HUNTING_ALREADY_CONTENT
                        }
                        alt="modal content"
                        style={{width: '90%'}}
                      />
                    </div>
                    <div>
                      <button
                        className="button button-custom is-uppercase is-second-custom button-text-mission has-text-info mt-2 mb-5"
                        onClick={() => {
                          setModalState(MODAL_STATES.NONE);
                          navigate(ROUTE_NAME.HOME);
                        }}
                        style={{
                          width: '60%',
                          minWidth: 150,
                          maxWidth: 300,
                        }}
                      >
                        Trang Chủ
                      </button>
                    </div>
                  </div>
                </div>
                <div style={{height: 20}} />
              </div>
            </div>
          </>
        );

      case MODAL_STATES.SUCCESS:
        return (
          <>
            <div
              className={classNames('modal px-5', {
                'is-active': !!modalState,
              })}
            >
              <div className="modal-background"></div>
              <div className="modal-card ">
                <div style={{position: 'absolute', right: 15, top: 60}}>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => setModalState(MODAL_STATES.NONE)}
                  ></button>
                </div>
                <div style={{height: 50}} />
                <div className="popup-scanbill-bg-meo">
                  <div className="px-5 has-text-centered pt-6">
                    <img
                      src={BUCKET_IMAGES.CONGRATS_TITLE}
                      // className=""
                      alt="modal title"
                      style={{marginTop: -90}}
                    />
                    <div
                      style={{
                        padding: '13px 26px',
                        backgroundColor: '#FFFAE6',
                        margin: '0px 12px 12px 12px',
                        borderRadius: 10,
                      }}
                    >
                      {codeType === QR_CODE_TYPE.SCAN_LOGO ? (
                        <div className=" has-text-weight-semibold" style={{color: '#323130'}}>
                          Chiller đã tích <br /> thành công
                        </div>
                      ) : (
                        <div>
                          <div
                            className=" has-text-weight-bold is-size-6"
                            style={{color: '#323130'}}
                          >
                            Chiller đã săn thành công Mẹo Chill tại
                            <br />
                            <span className=" has-text-weight-semibold ">
                              {huntingData?.brandName || ''}
                            </span>
                          </div>
                        </div>
                      )}
                      {!!modalData.imageChill && <img src={modalData.imageChill}></img>}
                      {!!modalData && (
                        <>
                          <div className=" has-text-weight-semibold" style={{color: '#323130'}}>
                            Tổng điểm Chill:
                          </div>
                          <div className="has-text-info is-uppercase is-size-4 has-text-weight-bold">
                            {modalData.total} CHILL KOIN
                          </div>
                        </>
                      )}
                    </div>
                    {!!shareHref && (
                      <div>
                        {isShareFB ? (
                          <div className="px-4 has-text-black has-text-weight-medium">
                            Chiller đã được nhận điểm thưởng khi chia sẻ qua Facebook
                          </div>
                        ) : (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            className="button disabled is-uppercase button-custom is-primary-custom button-text-mission mb-3"
                            onClick={onShare}
                            href={shareHref}
                            style={{
                              width: 140,
                              backgroundImage:
                                'url(https://storage.googleapis.com/strongbow_miniapp/MeoChill/Share%20FB.png)',
                            }}
                          />
                        )}
                      </div>
                    )}
                    <div>
                      <button
                        className="button is-uppercase button-custom is-primary-custom button-text-mission mt-2"
                        onClick={() => {
                          navigate(ROUTE_NAME.CHILL);
                        }}
                        style={{
                          width: '60%',
                          minWidth: 200,
                          maxWidth: 300,
                        }}
                      >
                        Đổi điểm nhận quà
                      </button>
                    </div>
                    <div>
                      <button
                        className="button button-custom is-uppercase is-second-custom button-text-mission has-text-info mt-2 mb-6"
                        onClick={() => navigate(ROUTE_NAME.HOME)}
                        style={{
                          width: '60%',
                          minWidth: 200,
                          maxWidth: 300,
                        }}
                      >
                        Tích thêm chill koin
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      case MODAL_STATES.ID_VERIFY_REQUIRED:
        return (
          <>
            <div
              className={classNames('modal px-5', {
                'is-active': !!modalState,
              })}
            >
              <div className="modal-background"></div>
              <div className="modal-card ">
                <div style={{height: 50}} />
                <div style={{position: 'absolute', right: 5, top: 10}}>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => setModalState(MODAL_STATES.NONE)}
                  ></button>
                </div>

                <>
                  <div className="popup-scanbill-bg-v1">
                    <div className=" px-4 has-text-centered pt-6">
                      <img
                        src={BUCKET_IMAGES.WAIT_TITLE}
                        style={{marginTop: -90, width: '90%'}}
                        alt="modal title"
                      />
                      <div className="mb-0 px-4">
                        <img
                          src={BUCKET_IMAGES.ID_NOT_VERIFIED_CONTENT}
                          alt="modal content"
                          style={{width: '90%'}}
                        />
                      </div>
                      <div>
                        <button
                          className="button is-uppercase button-custom is-primary-custom button-text-mission mt-2"
                          onClick={() => {
                            setModalState(MODAL_STATES.NONE);
                            navigate(ROUTE_NAME.EDIT_PROFILE);
                          }}
                          style={{
                            width: '60%',
                            minWidth: 150,
                            maxWidth: 300,
                            fontSize: 16,
                            fontWeight: 700,
                          }}
                        >
                          Cập nhật CMND
                        </button>
                        <button
                          className="button button-custom is-uppercase is-second-custom button-text-mission has-text-info mt-4 mb-5"
                          onClick={() => {
                            setModalState(MODAL_STATES.NONE);
                          }}
                          style={{
                            width: '60%',
                            minWidth: 150,
                            maxWidth: 300,
                            fontSize: 16,
                            fontWeight: 700,
                          }}
                        >
                          Trở về
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'relative',
                      top: '-7%',
                      left: '2%',
                    }}
                  >
                    <img src={balloon} alt="balloon" />
                  </div>
                </>
              </div>
            </div>
          </>
        );

      case MODAL_STATES.ID_VERIFY_PENDING:
        return (
          <>
            <div
              className={classNames('modal px-5', {
                'is-active': !!modalState,
              })}
            >
              <div className="modal-background"></div>
              <div className="modal-card ">
                <div style={{height: 50}} />
                <div style={{position: 'absolute', right: 5, top: 10}}>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => setModalState(MODAL_STATES.NONE)}
                  ></button>
                </div>

                <>
                  <div className="popup-scanbill-bg-v1">
                    <div className=" px-4 has-text-centered pt-6">
                      <img
                        src={BUCKET_IMAGES.WAIT_TITLE}
                        style={{marginTop: -90, width: '90%'}}
                        alt="modal title"
                      />
                      <div className="mb-0 px-4">
                        <img
                          src={BUCKET_IMAGES.ID_PENDING_CONTENT}
                          alt="modal content"
                          style={{width: '90%'}}
                        />
                      </div>
                      <div>
                        <button
                          className="button button-custom is-uppercase is-second-custom button-text-mission has-text-info mt-4 mb-5"
                          onClick={() => {
                            setModalState(MODAL_STATES.NONE);
                          }}
                          style={{
                            width: '60%',
                            minWidth: 150,
                            maxWidth: 300,
                            fontSize: 16,
                            fontWeight: 700,
                          }}
                        >
                          Trở về
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'relative',
                      top: '-7%',
                      left: '2%',
                    }}
                  >
                    <img src={balloon} alt="balloon" />
                  </div>
                </>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  }, [modalState, modalData, isShareFB, onShare, codeType, huntingData]);

  useEffect(() => {
    return () => {
      dispatch(resetStoreCheckIn());
    };
  }, []);

  return modalMemo;
};

export default MeoChillModal;
