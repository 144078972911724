import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Layout from '../../components/layout';
import {Link, useLocation, useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import Select from 'react-select';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {get} from 'lodash';
import {titleCase} from 'utils/stringUltils';
import ContactButton from 'components/contactButton';
import {toast} from 'react-toastify';
import {toggleIsLoadingOverlay} from 'appRedux/slices/appSlice';
import {
  createDeliveryAction,
  getUserGiftDetailByIdAction,
  getDetailDeliveryByIdAction,
} from 'appRedux/actions/app';
import image_banner from 'images/gift-info-title.png';
import bottle from 'images/strongbow/bottle.png';
import leaf from 'images/strongbow/pc/leaf.png';

const Transaction = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const info = location?.state?.info || null;
  const [dataProvince, setDataProvince] = React.useState([]);
  const [dataDistrict, setDataDistrict] = React.useState([]);
  const [dataWard, setDataWard] = React.useState([]);
  const [dataDistrictFilter, setDataDistrictFilter] = React.useState([]);
  const [dataWardFilter, setDataWardFilter] = React.useState([]);
  const [showPopupWin, setShowPopupWin] = React.useState(true);
  const [formData, setFormData] = React.useState({});
  const [giftName, setGiftName] = React.useState('');
  React.useEffect(() => {
    dispatch(toggleIsLoadingOverlay(true));
    dispatch(
      getDetailDeliveryByIdAction({
        id: params.id,
        callback: (data) => {
          dispatch(toggleIsLoadingOverlay(false));
          if (data) {
            setFormData(data?.data.receiverInfo || null);
            setGiftName(data?.data.giftName);
          }
        },
      })
    );
  }, []);
  React.useEffect(() => {
    const getProvice = async () => {
      fetch(process.env.REACT_APP_VN_POST_PROVINCE)
        .then((res) => res.json())
        .then((data) => {
          let provinces = [];
          data.map((item) => {
            provinces.push({
              label: item.TenTinhThanh,
              value: item.MaTinhThanh,
            });
          });
          setDataProvince(provinces.sort((a, b) => a.label.localeCompare(b.label)));
        });
    };
    getProvice();
    const getDistrict = async () => {
      fetch(process.env.REACT_APP_VN_POST_DISTRICT)
        .then((res) => res.json())
        .then((data) => {
          let district = [];
          data.map((item) => {
            district.push({
              label: item.TenQuanHuyen,
              value: item.MaQuanHuyen,
              code: item.MaTinhThanh,
            });
          });
          setDataDistrict(district);
        });
    };
    getDistrict();
    const getWard = async () => {
      fetch(process.env.REACT_APP_VN_POST_WARD)
        .then((res) => res.json())
        .then((data) => {
          let wards = [];
          data.map((item) => {
            wards.push({
              value: item.MaPhuongXa,
              label: item.TenPhuongXa,
              code: item.MaQuanHuyen,
            });
          });
          setDataWard(wards);
        });
    };
    getWard();
  }, []);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? '#D6BA85' : '#D6BA85',
      borderRadius: 30,
      fontWeight: 500,
      color: '#FFF',
      fontSize: 12,
    }),
  };

  return (
    <Layout>
      <div className="container-chill usergift_info_detail">
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '30%',
            right: 0,
          }}
        >
          <img
            src={bottle}
            width={170}
            style={{
              transform: 'matrix(0.99, 0.14, -0.14, 0.99, 0, 0)',
            }}
          />
        </div>
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '20%',
            left: 0,
            marginLeft: -173,
          }}
        >
          <img src={leaf} width={412} style={{}} />
        </div>
        <div className="container">
          <div className="title-image has-text-centered">
            <img
              style={{
                width: '55%',
                maxWidth: 400,
              }}
              src={image_banner}
              alt="title"
            />
          </div>
          <div className="container-content">
            {/* <div
              className="gift-chill-bg"
              style={{
                marginTop: 100,
              }}
            >
              <div className="columns">
                <div
                  className="column has-text-centered"
                  style={{
                    color: '#fff',
                    fontSize: 20,
                    fontWeight: 700,
                    marginBottom: 30,
                  }}
                >
                  ĐỔI THÀNH CÔNG
                </div>
              </div>
            </div> */}
            <div
              className="has-text-centered"
              style={{
                fontSize: 14,
                color: '#343434',
                fontWeight: 500,
              }}
            >
              <span>Ưu đãi: </span>
              <span
                className=" is-size-6 is-size-5-tablet"
                style={{color: '#704315', fontWeight: 700}}
              >
                {giftName}
              </span>
              {/* {!info && (
                <div
                  style={{
                    color: '#1D1D1D',
                    paddingTop: 16,
                  }}
                >
                  Bạn vui lòng điền thông tin nhận quà nhé!
                </div>
              )} */}
            </div>
            <div
              className="columns"
              style={{
                marginTop: 15,
              }}
            >
              <div className="column  is-8-tablet is-offset-2-tablet is-half-desktop is-offset-3-desktop">
                <div class="field custom-input">
                  <label className="label">
                    Họ và tên<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input input-is-custom"
                      type="text"
                      placeholder="Họ và tên"
                      value={formData.fullName}
                      disabled={true}
                    />
                  </div>
                </div>
                <div class="field custom-input">
                  <label className="label">
                    Số điện thoại liên hệ<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input input-is-custom"
                      type="text"
                      maxLength={10}
                      placeholder="Số điện thoại liên hệ"
                      value={formData.phoneNumber}
                      disabled={true}
                    />
                  </div>
                </div>

                <div class="field custom-input">
                  <label className="label">
                    CMND/CCCD<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input input-is-custom"
                      type="text"
                      placeholder="CMND/CCCD"
                      value={formData.personalIdentifier}
                      disabled={true}
                    />
                  </div>
                </div>
                <div class="field">
                  <label className="label">
                    Tỉnh/Thành<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <Select
                      placeholder="Chọn Tỉnh/Thành"
                      options={dataProvince}
                      styles={customStyles}
                      className="select-is-custom"
                      value={
                        dataProvince
                          ? dataProvince.find((option) => option.value === formData.provinceCode)
                          : ''
                      }
                      isDisabled={true}
                    />
                  </div>
                </div>
                <div class="field">
                  <label className="label">
                    Quận/Huyện<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <Select
                      placeholder="Chọn Quận/Huyện"
                      styles={customStyles}
                      options={dataDistrictFilter}
                      className="select-is-custom"
                      value={
                        formData.districtCode
                          ? dataDistrict?.find((option) => option.value === formData.districtCode)
                          : ''
                      }
                      isDisabled={true}
                    />
                  </div>
                </div>
                <div class="field">
                  <label className="label">
                    Phường/Xã<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <Select
                      placeholder="Chọn Phường/Xã"
                      options={dataWardFilter}
                      styles={customStyles}
                      className="select-is-custom"
                      value={
                        formData.wardCode
                          ? dataWard?.find((option) => option.value === formData.wardCode)
                          : ''
                      }
                      isDisabled={true}
                    />
                  </div>
                </div>
                <div class="field custom-input">
                  <label className="label">
                    Địa chỉ cư trú<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input input-is-custom"
                      type="text"
                      placeholder="Địa chỉ cư trú"
                      value={formData.address}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column has-text-centered">
                <button
                  className="button is-primary-custom is-capitalized"
                  style={{
                    color: '#FFF',
                    padding: '14px 50px',
                    fontSize: 16,
                    fontWeight: 700,
                    textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                    width: 250,
                  }}
                  onClick={() => {
                    navigate('/user-profile?tab=3');
                  }}
                >
                  TRỞ VỀ
                </button>
              </div>
            </div>
            {/* {!info && (
              <>
                <div className="columns">
                  <div className="column has-text-centered">
                    <button
                      className="button is-primary-custom"
                      style={{
                        color: '#FFF',
                        padding: '14px 50px',
                        fontSize: 16,
                        fontWeight: 700,
                        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                        width: 250,
                      }}
                      onClick={() => {
                        formik.submitForm();
                      }}
                    >
                      GỬI YÊU CẦU CHUYỂN QUÀ
                    </button>
                  </div>
                </div>
                <div className="columns">
                  <div className="column has-text-centered">
                    <a href="/chill">
                      <button
                        className="button is-second-custom"
                        style={{
                          color: '#83552D',
                          padding: '14px 50px',
                          fontSize: 16,
                          fontWeight: 700,
                          textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                          width: 250,
                        }}
                      >
                        NHẬN QUÀ SAU
                      </button>
                    </a>
                  </div>
                </div>
              </>
            )}
            {info && (
              <>
                <div className="columns">
                  <div className="column has-text-centered">
                    <button
                      className="button is-primary-custom is-capitalized"
                      style={{
                        color: '#FFF',
                        padding: '14px 50px',
                        fontSize: 16,
                        fontWeight: 700,
                        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                        width: 250,
                      }}
                      onClick={() => {
                        navigate(-1);
                        // formik.submitForm();
                      }}
                    >
                      TRỞ VỀ
                    </button>
                  </div>
                </div>
              </>
            )} */}
            <div className="mt-3">
              <ContactButton />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Transaction;
