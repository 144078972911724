import * as Yup from 'yup';
import {checkForSpecialChar} from 'utils/stringUltils';
import moment from 'moment';

const requireText = 'Bắt buộc';
Yup.addMethod(Yup.string, 'dateOfBirth', function (errorMessage, year = -4) {
  return this.test(`test-date-of-birth`, errorMessage, function (value) {
    const minDate = moment().add(year, 'y');
    const date = moment(value);
    return date.isBefore(minDate);
  });
});
export const REGISTER_FORM_SCHEME = Yup.object().shape({
  fullName: Yup.string()
    .test('regex', 'Vui lòng không bỏ trống, nhập số hoặc nhập kí tự đặc biệt', (val) => {
      if (!/\S/.test(val)) {
        // Didn't find something other than a space which means it's empty
        return false;
      }
      // Check special character
      if (checkForSpecialChar(val)) {
        return false;
      }
      // check number in string
      return !/\d/.test(val);
    })
    .required(requireText),
});
export const EDIT_FORM_SCHEME = Yup.object().shape({
  fullName: Yup.string()
    .test('regex', 'Vui lòng không bỏ trống, nhập số hoặc nhập kí tự đặc biệt', (val) => {
      if (!/\S/.test(val)) {
        // Didn't find something other than a space which means it's empty
        return false;
      }
      // Check special character
      if (checkForSpecialChar(val)) {
        return false;
      }
      // check number in string
      return !/\d/.test(val);
    })
    .required(requireText),

  email: Yup.string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email sai định dạng. Vui lòng nhập lại!')
    .required(requireText),
  dateOfBirth: Yup.string().required(requireText).dateOfBirth('Ngày sinh không hợp lệ!'),
  province: Yup.object().required(requireText).nullable(true),
  district: Yup.object().required(requireText).nullable(true),
  ward: Yup.object().required(requireText).nullable(true),
  address: Yup.string().required(requireText),
});

export const LOGIN_FORM_SCHEME = Yup.object().shape({
  phoneNumber: Yup.string()
    .required(requireText)
    .matches(/^[0]/, 'Số điện thoại phải bắt đầu bằng số 0')
    .matches(/^[0][0-9]{0,9}\b|^[0-9][0-9]{0,9}$/, 'Số điện thoại không hợp lệ')
    .test('Is positive?', 'Số điện thoại không hợp lệ', (value) => value >= 0),
  // .matches(
  //   /^[0][0-9]{9,9}\b|^[1-9][0-9]{9,9}\b|^[a-z][a-z0-9]{4,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/,
  //   "Số điện thoại không hợp lệ"
  // ),
});

export const REGISTER_INPUTS = [
  // {
  //   type: 'tel',
  //   placeholder: 'Nhập số điện thoại',
  //   label: 'Số điện thoại',
  //   fieldName: 'phoneNumber',
  //   maxLength: 10,
  //   isRequired: true,
  // },
  {
    isOTP: true,
    type: 'otp',
    placeholder: 'Nhập mã',
    label: 'Nhập mã OTP',
    fieldName: 'otp',
    maxLength: 6,
    isRequired: true,
  },
  {
    type: 'text',
    placeholder: 'Nhập họ và tên',
    label: 'Họ và tên',
    fieldName: 'fullName',
    isRequired: true,
    className: 'is-capitalized',
  },
  {
    type: 'date',
    placeholder: 'Chọn ngày sinh',
    label: 'Ngày sinh',
    fieldName: 'dateOfBirth',
    isRequired: true,
  },
  {
    type: 'select',
    placeholder: 'Giới tính',
    label: 'Giới tính',
    fieldName: 'gender',
    isRequired: true,
  },
  {
    type: 'text',
    placeholder: 'Nhập email',
    label: 'Email',
    fieldName: 'email',
    isRequired: true,
  },
  {
    type: 'select',
    placeholder: 'Chọn tỉnh / thành phố',
    label: 'Tỉnh/Thành phố',
    fieldName: 'province',
    isRequired: true,
  },
  {
    type: 'select',
    placeholder: 'Chọn quận / huyện',
    label: 'Quận/Huyện',
    fieldName: 'district',
    isRequired: true,
  },
  {
    type: 'select',
    placeholder: 'Chọn phường / xã',
    label: 'Phường/Xã',
    fieldName: 'ward',
    isRequired: true,
  },
  {
    type: 'text',
    placeholder: 'Nhập địa chỉ',
    label: 'Địa chỉ cư trú',
    fieldName: 'address',
    isRequired: true,

    // isFull: true,
  },
];
export const EDIT_INPUTS = [
  {
    type: 'text',
    placeholder: 'Nhập họ và tên',
    label: 'Họ và tên',
    fieldName: 'fullName',
    isRequired: true,
    className: 'is-capitalized',
  },
  {
    type: 'date',
    placeholder: 'Chọn ngày sinh',
    label: 'Ngày sinh',
    fieldName: 'dateOfBirth',
    isRequired: true,
    // isDisabled: true,
  },
  {
    type: 'select',
    placeholder: 'Giới tính',
    label: 'Giới tính',
    fieldName: 'gender',
    isRequired: true,
  },
  {
    type: 'text',
    placeholder: 'Nhập email',
    label: 'Email',
    fieldName: 'email',
    isRequired: true,
  },
  {
    type: 'select',
    placeholder: 'Chọn tỉnh / thành phố',
    label: 'Tỉnh/Thành phố',
    fieldName: 'province',
  },
  {
    type: 'select',
    placeholder: 'Chọn quận / huyện',
    label: 'Quận/Huyện',
    fieldName: 'district',
  },
  {
    type: 'select',
    placeholder: 'Chọn phường / xã',
    label: 'Phường/Xã',
    fieldName: 'ward',
  },
  {
    type: 'text',
    placeholder: 'Nhập địa chỉ',
    label: 'Địa chỉ cư trú',
    fieldName: 'address',
  },
];

export const MODAL_STATES = {
  REGISTER: 'REGISTER',
  LOGIN: 'LOGIN',
  OTP: 'OTP',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  EDIT: 'EDIT',
  QR_CODE: 'QR_CODE',
  CONDITIONS: 'CONDITIONS',
  OPTIONOTP: 'OPTIONOTP',
};

export const MODAL_WIDTHS = {
  REGISTER: 400,
  LOGIN: 400,
  OTP: 400,
  REGISTER_SUCCESS: 400,
  EDIT: 600,
  QR_CODE: 421,
  CONDITIONS: 1110,
  OPTIONOTP: 400,
};

export const POPUP_STYLE = {
  LOGIN: {
    right: 10,
    position: 'absolute',
    zIndex: 2,
    bottom: 30,
  },
  OPTIONOTP: {
    right: 10,
    position: 'absolute',
    zIndex: 2,
    bottom: 30,
  },
  OTP: {
    right: 10,
    position: 'absolute',
    zIndex: 2,
    bottom: 30,
  },
  REGISTER: {
    right: 10,
    position: 'absolute',
    zIndex: 2,
    bottom: 35,
  },
  QR_CODE: {
    right: 10,
    position: 'absolute',
    zIndex: 2,
    bottom: 30,
  },
};
