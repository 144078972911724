import React from "react";
import classNames from "classnames";
import { Pagination } from "react-bulma-components";

const BillStatus = ({ data = []
}) => {
  const [isLoading, setIsLoading ] = React.useState(false);
  const [pages, setPages] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(0);
  const onChangePage = (page) => {
    setCurrentPage(page);
    setPages(page * 10);
    setPerPage(page * 10 - 10);
    window.scrollTo(0, 0);
  };
  const totalPages = Math.ceil(data.length / 10);
  return (
    <>
      <div className="columns is-justify-content-center">
        <div
          className="column-is-custom list-bg-home transaction-wrapper"
          style={{
            marginLeft: -10,
            marginRight: -10,
            paddingBottom: 40,
          }}
        >
          <div className="custom-table">
            <table className="table is-fullwidth">
              <tr>
                <th className=" has-text-weight-bold">Tên quà</th>
                <th className=" has-text-weight-bold">Mã vận đơn </th>
                <th className=" has-text-weight-bold">Trạng thái</th>
              </tr>
              {data?.map((item, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{item?.giftName}</td>
                      <td>
                        <a href={`/transaction/${item?.deliveryTransactionId}`}>
                          {item?.orderCode}
                        </a>
                      </td>
                      <td>
                        <a href={item.statusInfoLink} target="_blank" rel="noopener noreferrer">
                          {item?.statusInfoLink.substring(0, 20)}
                          {'...'}
                        </a>
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
          <Pagination
            current={currentPage}
            showFirstLast
            total={totalPages}
            delta={1}
            showPrevNext={false}
            align={'center'}
            onChange={(page) => onChangePage(page)}
          />
        </div>
      </div>
    </>
  );
};
export default BillStatus;
