import React from 'react';
import Layout from 'components/layout';
import {toggleOver18} from 'appRedux/slices/appSlice';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ROUTE_NAME} from 'constants/routes';
import {isMobile} from 'react-device-detect';
import logo from 'images/logo.png';
import bottle_18 from 'images/strongbow/pc/element-18.png';
import chill_18 from 'images/strongbow/pc/18.png';
import see_you from 'images/strongbow/pc/see_you.png';
import tnc_thank from 'images/strongbow/New/tnc-thank.png';
import khinh_khi_cau from 'images/strongbow/New/khinh-khi-cau.png';
import apple from 'images/strongbow/New/apple.png';
import emoji2 from 'images/strongbow/New/emoji2.png';
import tohome from 'images/strongbow/New/tohome.png';
import leaf from 'images/strongbow/New/leaf.png';
import island from 'images/strongbow/New/island.png';
import decor__master2 from 'images/strongbow/New/decor__master2.png';
const SeeYouAgain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleConfirm = (bool) => {
    if (bool) {
      dispatch(toggleOver18(bool));
    } else {
      navigate(ROUTE_NAME.EDIT_PROFILE);
    }
  };
  return (
    <>
      {isMobile ? (
        <>
          <Layout
            headerProps={{
              index: 0,
            }}
            ignoreOver18Check
          >
            <div className="container-home pb-4 px-4 " style={{marginTop: 0, paddingTop: 80}}>
              <div
                className="container again-bg-home"
                style={{
                  paddingTop: 270,
                  paddingRight: 32,
                  paddingLeft: 32,
                  maxWidth: 600,
                  paddingBottom: 24,
                }}
              >
                <div className="columns" style={{marginBottom: -30}}>
                  <div className="column has-text-centered mt-6">
                    <button
                      className={'button is-primary-custom is-uppercase'}
                      style={{
                        color: '#FFF',
                        padding: '14px 50px',
                        fontSize: 16,
                        fontWeight: 700,
                        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                        // width: 250,
                      }}
                      onClick={() => {
                        window.location.href = './';
                      }}
                    >
                      Về trang chủ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        </>
      ) : (
        <>
          <div className="header">
            <div className="header-inr">
              <a className="logo pic" href="./">
                <img src={logo} width={159} />
              </a>
            </div>
          </div>
          <div className="page">
            <div className="banner-box">
              <div className="tnc__news--wrap">
                <div className="pc pic island island__tnc island__tnc__news">
                  <img className="cm" src={island} alt="Island" />
                  <div className="pc pic decor decor__tnc1">
                    <img
                      class="pc"
                      width="89"
                      height="205"
                      src={decor__master2}
                      alt="Khinh khí cầu"
                    />
                  </div>
                  <div className="pc pic decor decor__tnc3">
                    <img
                      class="pc"
                      width="89"
                      height="205"
                      src={decor__master2}
                      alt="Khinh khí cầu"
                    />
                  </div>
                </div>
                <div className="banner-thank" style={{display: 'block'}}>
                  <div className="pc pic thank-letter">
                    <img width="623" height="547" src={tnc_thank} alt="letter" />
                  </div>
                  <div className="brief">
                    <div>
                      Vì <span className="highlight">chưa đồng ý</span> với những <br />
                      <span className="highlight">chính sách của Strongbow</span> nên <br />
                      mình tạm dừng ở đây nhé.
                    </div>
                  </div>
                  <div className="pic decor__thank">
                    <img width="64" height="115" src={khinh_khi_cau} alt="Khinh khí cầu" />
                  </div>
                  <div className="sp pic decor__thank1">
                    <img width="64" height="115" src={khinh_khi_cau} alt="Khinh khí cầu" />
                  </div>
                  <div className="sp pic decor__thank2">
                    <img width="64" height="115" src={khinh_khi_cau} alt="Khinh khí cầu" />
                  </div>

                  <div className="pic thank-apple">
                    <img width="92" height="103" src={apple} alt="apple" />
                  </div>

                  <div className="sp pic thank-apple1">
                    <img width="92" height="103" src={apple} alt="apple" />
                  </div>

                  <div className="pic thank-emoji1">
                    <img width="59" height="59" src={emoji2} alt="emoji" />
                  </div>

                  <div className="pic thank-emoji2">
                    <img width="59" height="59" src={emoji2} alt="emoji" />
                  </div>
                  <div className="buts" style={{justifyContent: 'center'}}>
                    <a href="./" class="pic to-home">
                      <img width="221" height="60" src={tohome} alt="Về trang chủ" />
                    </a>
                  </div>
                </div>
                <div className="pic new__leaf" style={{bottom: '-17%'}}>
                  <img width="211" height="163" src={leaf} alt="Leaf" />
                </div>
              </div>
            </div>
          </div>
          {/* <div
            style={{
              position: 'absolute',
              top: 30,
              left: 30,
            }}
          >
            <img src={logo} width={159} />
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
          >
            <img src={bottle_18} width={270} />
          </div>
          <div className="container-chill">
            <div className="container">
              <div className="container-see">
                <div
                  className="columns"
                  style={{
                    position: 'relative',
                    top: '50%',
                    transform: 'translate(0, -50%)',
                  }}
                >
                  <div className="column is-6">
                    <div>
                      <img src={chill_18} />
                    </div>
                  </div>
                  <div className="column is-6">
                    <div>
                      <img src={see_you} />
                    </div>
                    <div className="columns" style={{marginTop: -70}}>
                      <div className="column has-text-centered">
                        <button
                          className={'button is-primary-custom is-uppercase'}
                          style={{
                            color: '#FFF',
                            padding: '14px 50px',
                            fontSize: 20,
                            fontWeight: 700,
                            textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                            // width: 250,
                          }}
                          onClick={() => {
                            navigate(ROUTE_NAME.HOME);
                          }}
                        >
                          Về trang chủ
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </>
  );
};

export default SeeYouAgain;
