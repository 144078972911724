import React, {useEffect} from 'react';
import image_default from 'images/image_default.png';
import icon_next from 'images/strongbow/icon_next.png';
import icon_prev from 'images/strongbow/icon_previou.png';
import e_voucher_active from 'images/strongbow/e-voucher-active.png';
import e_voucher from 'images/strongbow/e-voucher.png';
import './styles.scss';
import {Link} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
const GIFT = ({
  data = [],
  dataChill = [],
  chillPageSize = 0,
  chillTotalRecords = 0,
  handleShowMoreChill = () => {},

  dataVoucher = [],
  voucherTotalRecords = 0,
  numberEvoucher = 1,
  numberEvoucherStart = 0,
  handlePrev = () => {},
  handleNext = () => {},

  dataStore = [],
  storePageSize = 0,
  storeTotalRecords = 0,
  handleShowMoreEvoucherStore = () => {},
}) => {
  return (
    <>
      {isMobile ? (
        <>
          {dataChill.length > 0 && (
            <div className="gift-chill-bg">
              <div className="columns">
                <div
                  className="column has-text-centered py-1"
                  style={{
                    color: '#fff',
                    fontSize: 20,
                    fontWeight: 700,
                    marginBottom: 15,
                  }}
                >
                  QUÀ ĐỂ CHILL
                </div>
              </div>
            </div>
          )}
          <div className="columns is-multiline is-flex-mobile">
            {dataChill.map((item, index) => {
              return (
                <>
                  <div className="column is-6 is-3-desktop is-4-mobile px-2">
                    <div className="gift-item">
                      <div className="card-image">
                        <figure className="image is-1by1">
                          <Link to={`/gift/${item.id}`}>
                            <img
                              src={item?.image || image_default}
                              style={{
                                borderRadius: 12,
                              }}
                            />
                          </Link>
                        </figure>
                      </div>
                      <div className="gift-item-name">{item.name}</div>
                      <div className="gift-item-reward">
                        {item?.rewardValue.toLocaleString()} CHILL KOIN
                      </div>
                      <div className="has-text-centered gift-button">
                        {item.canRedeemByMerchantReward ? (
                          <>
                            <Link to={`/gift/${item.id}`}>
                              <button className="button is-fullwidth py-4 button-change-now">
                                ĐỔI NGAY
                              </button>
                            </Link>
                          </>
                        ) : item.remainingGifts === 0 ? (
                          <button
                            className="button is-fullwidth py-4 button-change-now-more"
                            disabled={true}
                          >
                            HẾT QUÀ
                          </button>
                        ) : (
                          <>
                            <Link to={`/gift/${item.id}`}>
                              <button className="button is-fullwidth py-4 button-change-now-more">{`THÊM ${
                                item?.needingMerchantReward || ''
                              } KOIN`}</button>
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          {chillTotalRecords > chillPageSize && (
            <>
              <div className="columns column-button-more">
                <div className="column has-text-centered">
                  <button
                    className="button-more is-second-custom"
                    onClick={() => handleShowMoreChill(chillTotalRecords)}
                  >
                    Xem thêm
                  </button>
                </div>
              </div>
            </>
          )}
          {dataVoucher.length > 0 && (
            <div className="gift-chill-bg">
              <div className="columns">
                <div
                  className="column has-text-centered py-1"
                  style={{
                    color: '#fff',
                    fontSize: 20,
                    fontWeight: 700,
                    marginBottom: 15,
                  }}
                >
                  E-VOUCHER MUA HÀNG
                </div>
              </div>
            </div>
          )}
          <div
            className="columns is-multiline is-flex-mobile"
            style={{
              position: 'relative',
              marginLeft: -15,
              marginRight: -15,
            }}
          >
            {dataVoucher?.slice(numberEvoucherStart, numberEvoucher).map((item, index) => {
              return (
                <>
                  <div className="column is-6 is-3-desktop is-6-mobile px-1">
                    <Link to={`/gift/${item.id}`}>
                      <div className="gift-item" style={{position: 'relative'}}>
                        <div className="card-image">
                          <figure className="image">
                            <img
                              src={item.image || image_default}
                              style={
                                {
                                  // height: 105,
                                }
                              }
                            />
                            {/* {item.canRedeemByMerchantReward ? (
                          <>
                            <img
                              src={item.image}
                              style={{
                                // height: 105,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={e_voucher}
                              style={{
                                height: 105,
                              }}
                            />
                          </>
                        )} */}
                          </figure>
                        </div>
                        {/* <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        right: 40,
                        transform: 'translate(0%, -80%)',
                      }}
                    >
                      {item?.name.length > 24 ? (
                        <div className="gift-item-name-evoucher px-2">
                          {item.name.substring(0, 24)}
                          {'...'}
                        </div>
                      ) : (
                        <div className="gift-item-name-evoucher px-2">{item?.name}</div>
                      )}
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 40,
                      }}
                    >
                      <div className="gift-item-reward  pb-4">
                        {item?.rewardValue.toLocaleString()} CHILL KOIN
                      </div>
                    </div> */}
                      </div>
                    </Link>

                    <div className="has-text-centered gift-button mx-4">
                      {item.canRedeemByMerchantReward ? (
                        <>
                          <Link to={`/gift/${item.id}`}>
                            <button className="button is-fullwidth py-4 button-change-now">
                              ĐỔI NGAY
                            </button>
                          </Link>
                        </>
                      ) : item.remainingGifts === 0 ? (
                        <button
                          className="button is-fullwidth py-4 button-change-now-more"
                          disabled={true}
                        >
                          HẾT QUÀ
                        </button>
                      ) : (
                        <>
                          <Link to={`/gift/${item.id}`}>
                            <button className="button is-fullwidth py-4 button-change-now-more">
                              THÊM {item.needingMerchantReward} KOIN
                            </button>
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
            {numberEvoucherStart > 0 &&
              (dataVoucher.slice(numberEvoucherStart, numberEvoucher).length > 3 ? (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      top: '46%',
                      left: 0,
                    }}
                    onClick={() => handlePrev(4)}
                  >
                    <img width={10} src={icon_prev} />
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      top: '30%',
                      left: -5,
                    }}
                    onClick={() => handlePrev(4)}
                  >
                    <img width={10} src={icon_prev} />
                  </div>
                </>
              ))}

            {numberEvoucher < voucherTotalRecords && (
              <div
                style={{
                  position: 'absolute',
                  top: '46%',
                  right: 0,
                }}
                onClick={() => handleNext(4)}
              >
                <img width={10} src={icon_next} />
              </div>
            )}
          </div>
          {dataStore.length > 0 && (
            <div className="gift-chill-bg">
              <div className="columns">
                <div
                  className="column has-text-centered py-1"
                  style={{
                    color: '#fff',
                    fontSize: 20,
                    fontWeight: 700,
                    marginBottom: 15,
                  }}
                >
                  E-VOUCHER TẠI CỬA HÀNG
                </div>
              </div>
            </div>
          )}
          <div className="columns is-multiline is-flex-mobile">
            {dataStore.map((item, index) => {
              return (
                <>
                  <div
                    className="column is-6 is-3-desktop is-6-mobile"
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    <div className="gift-item">
                      <div className="card-image">
                        <figure className="image">
                          <Link to={`/gift/${item.id}`}>
                            <img
                              src={item?.image || image_default}
                              style={{
                                borderRadius: 12,
                                maxHeight: 104,
                              }}
                            />
                          </Link>
                        </figure>
                      </div>
                      <div className="gift-item-name">{item.name}</div>
                      <div className="gift-item-reward">
                        {item?.rewardValue.toLocaleString()} CHILL KOIN
                      </div>
                      {/* <div className="gift-item-reward">THÊM {item.needingMerchantReward} KOIN</div> */}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          {storeTotalRecords > storePageSize && (
            <>
              <div className="columns column-button-more">
                <div className="column has-text-centered">
                  <button
                    className="button-more is-second-custom"
                    onClick={() => handleShowMoreEvoucherStore(storeTotalRecords)}
                  >
                    Xem thêm
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {dataChill.length > 0 && (
            <div
              className="gift-chill-bg mt-5"
              style={{
                maxWidth: 270,
                display: 'block',
                margin: 'auto',
              }}
            >
              <div className="has-title-chill">QUÀ ĐỂ CHILL</div>
            </div>
          )}
          <div className="columns is-multiline is-flex-mobile mt-4 program-list-voucher">
            {dataChill.map((item, index) => {
              return (
                <>
                  <div className="column is-6 is-2-desktop is-4-mobile px-2">
                    <div className="gift-item">
                      <div className="card-image">
                        <figure className="image is-1by1">
                          <Link to={`/gift/${item.id}`}>
                            <img
                              src={item?.image || image_default}
                              style={{
                                borderRadius: 12,
                              }}
                            />
                          </Link>
                        </figure>
                      </div>
                      <div className="gift-item-name" style={{fontSize: 14}}>
                        {item.name}
                      </div>
                      <div className="gift-item-reward" style={{fontSize: 14}}>
                        {item?.rewardValue.toLocaleString()} CHILL KOIN
                      </div>
                      <div className="has-text-centered gift-button">
                        {item.canRedeemByMerchantReward ? (
                          <>
                            <Link to={`/gift/${item.id}`}>
                              <button
                                className="button is-fullwidth py-4 button-change-now"
                                //style={{fontSize: 16}}
                              >
                                ĐỔI NGAY
                              </button>
                            </Link>
                          </>
                        ) : item.remainingGifts === 0 ? (
                          <button
                            className="button is-fullwidth button-change-now-more"
                            disabled={true}
                          >
                            HẾT QUÀ
                          </button>
                        ) : (
                          <>
                            <Link to={`/gift/${item.id}`}>
                              <button
                                className="button is-fullwidth button-change-now-more"
                                //style={{fontSize: 16}}
                              >{`THÊM ${
                                item?.needingMerchantReward
                                  ? item?.needingMerchantReward.toLocaleString()
                                  : ''
                              } KOIN`}</button>
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          {chillTotalRecords > chillPageSize && (
            <>
              <div className="columns column-button-more">
                <div className="column has-text-centered">
                  <button
                    className="button-more is-second-custom"
                    style={{fontSize: 16}}
                    onClick={() => handleShowMoreChill(chillTotalRecords)}
                  >
                    XEM THÊM
                  </button>
                </div>
              </div>
            </>
          )}
          {dataVoucher.length > 0 && (
            <div
              className="gift-chill-bg mt-5"
              style={{
                maxWidth: 440,
                display: 'block',
                margin: 'auto',
              }}
            >
              <div className="has-title-chill">E-VOUCHER MUA HÀNG</div>
            </div>
          )}
          <div
            className="columns is-multiline is-flex-mobile mt-4 program-list-voucher"
            style={{
              position: 'relative',
            }}
          >
            {dataVoucher?.slice(numberEvoucherStart, numberEvoucher).map((item, index) => {
              return (
                <>
                  <div className="column is-6 is-3-desktop is-6-mobile px-2">
                    <Link to={`/gift/${item.id}`}>
                      <div className="gift-item" style={{position: 'relative'}}>
                        <div className="card-image">
                          <figure className="image">
                            <img src={item.image || image_default} />
                          </figure>
                        </div>
                      </div>
                    </Link>

                    <div className="has-text-centered gift-button mx-4  mt-0">
                      {item.canRedeemByMerchantReward ? (
                        <>
                          <Link to={`/gift/${item.id}`}>
                            <button
                              className="button is-fullwidth py-4 button-change-now"
                              //style={{fontSize: 16}}
                            >
                              ĐỔI NGAY
                            </button>
                          </Link>
                        </>
                      ) : item.remainingGifts === 0 ? (
                        <button
                          className="button is-fullwidth button-change-now-more"
                          disabled={true}
                        >
                          HẾT QUÀ
                        </button>
                      ) : (
                        <>
                          <Link to={`/gift/${item.id}`}>
                            <button
                              className="button is-fullwidth button-change-now-more"
                              //style={{fontSize: 16}}
                            >
                              THÊM{' '}
                              {item.needingMerchantReward
                                ? item.needingMerchantReward.toLocaleString()
                                : ''}{' '}
                              KOIN
                            </button>
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
            {numberEvoucherStart > 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: '46%',
                  left: 0,
                }}
                onClick={() => handlePrev(8)}
              >
                <img width={10} src={icon_prev} />
              </div>
            )}

            {numberEvoucher < voucherTotalRecords && (
              <div
                style={{
                  position: 'absolute',
                  top: '46%',
                  right: 0,
                }}
                onClick={() => handleNext(8)}
              >
                <img width={10} src={icon_next} />
              </div>
            )}
          </div>
          {dataStore.length > 0 && (
            <div
              className="gift-chill-bg mt-5"
              style={{
                maxWidth: 490,
                display: 'block',
                margin: 'auto',
              }}
            >
              <div className="has-title-chill">E-VOUCHER TẠI CỬA HÀNG</div>
            </div>
          )}
          <div className="columns is-multiline is-flex-mobile mt-4 program-list-voucher">
            {dataStore.map((item, index) => {
              return (
                <>
                  <div
                    className="column is-6 is-3-desktop is-6-mobile"
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    <div className="gift-item">
                      <div className="card-image">
                        <figure className="image">
                          <Link to={`/gift/${item.id}`}>
                            <img
                              src={item?.image || image_default}
                              style={{
                                borderRadius: 12,
                                maxHeight: 165,
                              }}
                            />
                          </Link>
                        </figure>
                      </div>
                      <div className="gift-item-name" style={{fontSize: 14}}>
                        {item.name}
                      </div>
                      <div className="gift-item-reward" style={{fontSize: 14}}>
                        {item?.rewardValue.toLocaleString()} CHILL KOIN
                      </div>
                      {/* <div className="gift-item-reward">THÊM {item.needingMerchantReward} KOIN</div> */}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          {storeTotalRecords > storePageSize && (
            <>
              <div className="columns column-button-more">
                <div className="column has-text-centered">
                  <button
                    className="button-more is-second-custom"
                    //style={{fontSize: 16}}
                    onClick={() => handleShowMoreEvoucherStore(storeTotalRecords)}
                  >
                    XEM THÊM
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default GIFT;
