import React, {useEffect} from 'react';
import Layout from '../../components/layout';
import HistoryPrizeList from 'components/historyPrizeList';
import {
  billDetectionAction,
  getLuckDrawRewardDetailAction,
  getRewardHistoryScanBillAction,
} from 'appRedux/actions/app';
import ContactButton from 'components/contactButton';
import {useDispatch} from 'react-redux';
import {isMobile, isDesktop} from 'react-device-detect';
import image_banner_pc from 'images/strongbow/pc/banner-pc-2.png';
import leaf from 'images/strongbow/pc/leaf.png';
import './styles.scss';

const Index = () => {
  const dispatch = useDispatch();
  const [luckDraws, setLuckDraws] = React.useState([]);
  const [luckPageIndex, setLuckPageIndex] = React.useState(1);
  const [luckDrawTotalPages, setLuckDrawTotalPages] = React.useState(0);
  const [historyScanBill, setHistoryScanBill] = React.useState([]);
  const [historyPageIndex, setHistoryPageIndex] = React.useState(1);
  const [historyTotalPages, setHistorTotalPages] = React.useState(0);
  const [strSearch, setStrSearch] = React.useState('');
  useEffect(() => {
    dispatch(
      getLuckDrawRewardDetailAction({
        body: {
          pageIndex: luckPageIndex,
          pageSize: 10,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          searchText: strSearch,
          sortBy: [
            {
              fieldName: 'CreatedTime',
              ascending: false,
            },
          ],
        },
        callback: (error, res) => {
          const {data} = res;
          setLuckDrawTotalPages(data.numOfPages);
          setLuckDraws(data?.data || []);
        },
      })
    );
  }, [luckPageIndex, strSearch]);
  useEffect(() => {
    dispatch(
      getRewardHistoryScanBillAction({
        body: {
          pageIndex: historyPageIndex,
          pageSize: 10,
          searchText: strSearch,
          programId: process.env.REACT_APP_PROGRAM_ID,
          sortBy: [
            {
              fieldName: 'TotalValue',
              ascending: false,
            },
          ],
        },
        callback: (error, res) => {
          const {data} = res;
          setHistoryScanBill(data.data);
          setHistorTotalPages(data.numOfPages);
        },
      })
    );
  }, [historyPageIndex, strSearch]);
  const handleSearch = (e) => {
    setLuckPageIndex(1);
    setHistoryPageIndex(1);
    setStrSearch(e);
  };
  return isMobile ? (
    <Layout>
      <div className="container-chill">
        <div className="container">
          <div className="container-content">
            <HistoryPrizeList
              data={luckDraws}
              currentPage={luckPageIndex}
              totalPages={luckDrawTotalPages}
              onChangePage={setLuckPageIndex}
              dataBill={historyScanBill}
              currentPageHistory={historyPageIndex}
              totalPagesHistory={historyTotalPages}
              onChangePageHistory={setHistoryPageIndex}
              onChangesrSearch={handleSearch}
            />
          </div>
          <div className="">
            <ContactButton />
          </div>
        </div>
      </div>
    </Layout>
  ) : isDesktop ? (
    <Layout>
      <div className="container-chill-pc">
        <div
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            <img src={image_banner_pc} style={{width: '100%'}} />
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            left: -100,
            bottom: 220,
          }}
        >
          <img
            src={leaf}
            style={{
              width: '45%',
              transform: 'rotate(125deg)',
            }}
          />
        </div>

      <div className="container">
        <HistoryPrizeList
          data={luckDraws}
          currentPage={luckPageIndex}
          totalPages={luckDrawTotalPages}
          onChangePage={setLuckPageIndex}
          dataBill={historyScanBill}
          currentPageHistory={historyPageIndex}
          totalPagesHistory={historyTotalPages}
          onChangePageHistory={setHistoryPageIndex}
          onChangesrSearch={handleSearch}
        />
      </div>

      <div className="container">
        <ContactButton />
      </div>
      </div>
    </Layout>
  ) : (
    ''
  );
};
export default Index;
