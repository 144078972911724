import React from 'react';
import Layout from '../../components/layout';
import image_banner from 'images/strongbow/info/info_bg.png';
import './styles.scss';
const Index = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className="container-chill-pc">
        <div className="container">
          <div className="title-image has-text-centered">
            <img
              style={{
                width: '55%',
                maxWidth: 400,
              }}
              src={image_banner}
              alt="title"
            />
          </div>
          <div className="container-content">
            <div className="info_container">
              <p>
                <b>1. Tên chương trình khuyến mại: “SĂN ĐIỂM CHILL, PHIÊU QUÀ XỊN”</b>
                <br />
                <br />
                <b>2. Thời gian khuyến mại:</b> Từ ngày 20/10/2022 đến ngày 28/02/2023
                <br />
                <br />
                <b>3. Địa bàn (phạm vi) khuyến mại:</b> Các cửa hàng tiện lợi Circle K, GS25, 7 –
                Eleven trên địa bàn TP Hà Nội, TP Hồ Chí Minh
                <br />
                <br />
                <b>4. Hình thức khuyến mại:</b> Tích lũy điểm đổi quà và Quay vòng quay may mắn
                <br />
                <br />
                <b>5. Đối tượng tham gia</b>
                <br />
                <b>&emsp;a. Khách hàng của chương trình khuyến mãi:</b> Công dân có quốc tịch Việt
                Nam trên 18 tuổi, có đủ năng lực hành vi dân sự và hiện đang sinh sống tại Việt Nam.
                <br />
                <b>&emsp;b. Đối tượng không được tham gia chương trình khuyến mãi:</b> Nhân viên
                Công ty TNHH nhà máy Heineken Việt Nam, Công ty TNHH Bia và Nước Giải Khát Heineken
                Việt Nam, Công ty TNHH HVBL Đà Nẵng, Công ty TNHH HVBL Tiền Giang cũng như các Công
                ty con hoặc các Công ty liên doanh, liên kết của HVBL (sau đây gọi chung là
                “HVBLs”), tất cả các đơn vị phân phối chính thức của HVBLs, nhân viên công ty quảng
                cáo và công ty dịch vụ cho HVBLs, cũng như cha, mẹ, vợ, chồng, con của những người
                này không được tham gia chương trình này.
                <br />
                <br />
                <b>6. Cơ cấu giải thưởng</b>
                <br />
                <div className=" table-container">
                  <table
                    className="table is-bordered is-fullwidth"
                    style={{backgroundColor: 'transparent'}}
                  >
                    <tr>
                      <th>Cơ cấu Giải thưởng</th>
                      <th>Nội dung giải thưởng</th>
                      <th>Trị giá giải thưởng (VNĐ)</th>
                      <th>Số lượng giải thưởng</th>
                      <th>Thành tiền (VNĐ)</th>
                    </tr>
                    <tr>
                      <td colSpan={5} className=" has-text-weight-bold has-text-centered">
                        Giải may mắn
                      </td>
                    </tr>
                    <tr>
                      <td>May mắn 1</td>
                      <td>Iphone 14 Pro 128 G</td>
                      <td>30,990,000</td>
                      <td>1</td>
                      <td>30,990,000</td>
                    </tr>
                    <tr>
                      <td>May mắn 2</td>
                      <td>Airpod Pro 2022</td>
                      <td>6,990,000</td>
                      <td>2</td>
                      <td>13,980,000</td>
                    </tr>
                    <tr>
                      <td>May mắn 3</td>
                      <td>Máy chiếu phim Beecube X Strongbow</td>
                      <td>5,500,000</td>
                      <td>2</td>
                      <td>11,000,000</td>
                    </tr>
                    <tr>
                      <td>May mắn 4</td>
                      <td>Túi tote Strongbow</td>
                      <td>150,000</td>
                      <td>50</td>
                      <td>7,500,000</td>
                    </tr>
                    <tr>
                      <td>May mắn 5</td>
                      <td>Mũ Strongbow</td>
                      <td>110,000</td>
                      <td>500</td>
                      <td>55,000,000</td>
                    </tr>
                    <tr>
                      <td>May mắn 6</td>
                      <td>Túi chéo Strongbow</td>
                      <td>150,000</td>
                      <td>100</td>
                      <td>15,000,000</td>
                    </tr>
                    <tr>
                      <td>May mắn 7</td>
                      <td>Vé xem phim CGV</td>
                      <td>90,000</td>
                      <td>110</td>
                      <td>9,900,000</td>
                    </tr>
                    <tr>
                      <td colSpan={4} className=" has-text-weight-bold has-text-centered">
                        TỔNG CỘNG
                      </td>
                      <td className=" has-text-weight-bold">148,370,000</td>
                    </tr>
                  </table>
                </div>
                <div className=" table-container">
                  <table
                    className="table is-bordered is-fullwidth"
                    style={{backgroundColor: 'transparent'}}
                  >
                    <tr>
                      <th>Cơ cấu Giải thưởng</th>
                      <th>Nội dung giải thưởng</th>
                      <th>Trị giá giải thưởng (VNĐ)</th>
                      <th>Số lượng giải thưởng</th>
                      <th>Thành tiền (VNĐ)</th>
                      <th>Số điểm đổi thưởng tương ứng</th>
                    </tr>
                    <tr>
                      <td rowSpan={17} style={{verticalAlign: 'middle'}}>
                        Giải tích lũy
                      </td>
                      <td>Dây đeo thẻ Strongbow</td>
                      <td>50,000</td>
                      <td>400</td>
                      <td>20,000,000</td>
                      <td>150</td>
                    </tr>
                    <tr>
                      <td>Mũ Strongbow</td>
                      <td>110,000</td>
                      <td>500</td>
                      <td>55,000,000</td>
                      <td>300</td>
                    </tr>
                    <tr>
                      <td>Túi chéo Strongbow</td>
                      <td>150,000</td>
                      <td>400</td>
                      <td>60,000,000</td>
                      <td>350</td>
                    </tr>
                    <tr>
                      <td>Máy chiếu phim Strongbow</td>
                      <td>5,500,000</td>
                      <td>3</td>
                      <td>16,500,000</td>
                      <td>18,000</td>
                    </tr>
                    <tr>
                      <td>Túi tote Strongbow</td>
                      <td>150,000</td>
                      <td>150</td>
                      <td>22,500,000</td>
                      <td>350</td>
                    </tr>
                    <tr>
                      <td>Loa Marshall</td>
                      <td>7,990,000</td>
                      <td>3</td>
                      <td>23,970,000</td>
                      <td>15,000</td>
                    </tr>
                    <tr>
                      <td>Iphone 14 Pro</td>
                      <td>30,990,000</td>
                      <td>1</td>
                      <td>30,990,000</td>
                      <td>40,000</td>
                    </tr>
                    <tr>
                      <td>MacBook Pro M2 2022 13 inch</td>
                      <td>35,990,000</td>
                      <td>2</td>
                      <td>71,980,000</td>
                      <td>40,500</td>
                    </tr>
                    <tr>
                      <td>Apple Watch Ultra GPS</td>
                      <td>23,990,000</td>
                      <td>2</td>
                      <td>47,980,000</td>
                      <td>30,000</td>
                    </tr>
                    <tr>
                      <td>Airpod Pro 2022</td>
                      <td>6,990,000</td>
                      <td>5</td>
                      <td>34,950,000</td>
                      <td>10,000</td>
                    </tr>
                    <tr>
                      <td>Vé xem phim CGV</td>
                      <td>90,000</td>
                      <td>200</td>
                      <td>18,000,000</td>
                      <td>60</td>
                    </tr>
                    <tr>
                      <td>Voucher GOT IT 10k</td>
                      <td>10,000</td>
                      <td>300</td>
                      <td>3,000,000</td>
                      <td>40</td>
                    </tr>
                    <tr>
                      <td>Voucher GOT IT 25k</td>
                      <td>25,000</td>
                      <td>200</td>
                      <td>5,000,000</td>
                      <td>100</td>
                    </tr>
                    <tr>
                      <td>Voucher GOT IT 50k</td>
                      <td>50,000</td>
                      <td>200</td>
                      <td>10,000,000</td>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>Voucher TIKI 10k</td>
                      <td>10,000</td>
                      <td>300</td>
                      <td>3,000,000</td>
                      <td>40</td>
                    </tr>
                    <tr>
                      <td>Voucher TIKI 25k</td>
                      <td>25,000</td>
                      <td>200</td>
                      <td>5,000,000</td>
                      <td>100</td>
                    </tr>
                    <tr>
                      <td>Voucher TIKI 50k</td>
                      <td>50,000</td>
                      <td>200</td>
                      <td>10,000,000</td>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className=" has-text-weight-bold has-text-centered">
                        TỔNG CỘNG
                      </td>
                      <td className=" has-text-weight-bold">3,066</td>
                      <td className=" has-text-weight-bold">437,780,000</td>
                      <td></td>
                    </tr>
                  </table>
                </div>
                Tất cả giải thưởng không thể quy đổi thành tiền mặt. Màu sắc của giải thường sẽ tùy
                vào thuộc quyền quyết định của ban tổ chức. Khách hàng không được lựa chọn màu sắc
                của quà tặng. Số lượng quà tặng có hạn, chương trình có thể sẽ kết thúc sớm hơn thời
                gian dự kiến khi quà tặng đã được tặng hết.
                <br />
                <br />
                <b>7. Nội dung chi tiết thể lệ chương trình khuyến mại</b>
                <br />
                Trong thời gian khuyến mại, khách hàng mua sản phẩm khuyến mại trên địa bàn khuyến
                mãi sẽ nhận được điểm thưởng tương ứng.
                <br />
                Khách hàng tích lũy điểm thưởng để đổi quà tặng hoặc các lượt quay vòng xoay may mắn
                để có cơ hội nhận được các giải thưởng của chương trình.
                <br />
                &emsp; - Cơ cấu tính điểm thưởng như sau:
                <div className=" table-container">
                  <table
                    className="table is-bordered is-fullwidth"
                    style={{backgroundColor: 'transparent'}}
                  >
                    <tr>
                      <th>Số lượng sản phẩm mua</th>
                      <th>Số điểm thưởng/ 1 sản phẩm</th>
                      <th>Ví dụ</th>
                    </tr>
                    <tr>
                      <td>Mua từ 1 đến 5 chai/lon Strongbow các loại</td>
                      <td className=" has-text-weight-bold">10 điểm/ 1 chai hoặc lon</td>
                      <td>1 lon = 10 điểm</td>
                    </tr>
                    <tr>
                      <td>Mua từ 6 đến 11 chai/lon Strongbow các loại</td>
                      <td className=" has-text-weight-bold">11 điểm/ 1 chai hoặc lon</td>
                      <td>1 lốc = 6 lon = 66 điểm</td>
                    </tr>
                    <tr>
                      <td>Mua từ 12 đến 23 chai/lon Strongbow các loại</td>
                      <td className=" has-text-weight-bold">12 điểm/ 1 chai hoặc lon</td>
                      <td>2 lốc = 12 lon = 144 điểm</td>
                    </tr>
                    <tr>
                      <td>Mua từ 24 chai/lon Strongbow các loại trở lên</td>
                      <td className=" has-text-weight-bold">15 điểm/ 1 chai hoặc lon</td>
                      <td>1 thùng = 24 lon = 360 điểm</td>
                    </tr>
                  </table>
                </div>
                {/* <br /> */}
                <b>8. Nội dung chi tiết thể lệ chương trình khuyến mại</b>
                <div className=" table-container">
                  <table
                    className="table is-bordered is-fullwidth"
                    style={{backgroundColor: 'transparent'}}
                  >
                    <tr>
                      <th>Bước thực hiện</th>
                      <th>Chi tiết</th>
                    </tr>
                    <tr>
                      <td className=" is-underlined has-text-weight-bold">Bước 1</td>
                      <td className="">
                        Khách hàng quét mã QR trên bao bì lốc 6 lon hoặc tại cửa hàng tiện lợi để
                        truy cập trang chương trình khuyến mãi
                        <br />
                        Xác nhận đủ 18 tuổi để tiếp tục tham gia chương trình.
                        <br />
                        Khách hàng sử dụng số điện thoại hoặc tài khoản Zalo để tham gia chương
                        trình.
                        <br />
                        -Số điện thoại khách hàng nhập hoặc tài khoản Zalo mà khách hàng đăng kí sẽ
                        được dùng để tạo tài khoản nhằm tham gia chương trình khuyến mại. Tài khoản
                        này sẽ được sử dụng để xác định người quay số. Số CMND/ CCCD/ Passport và Họ
                        tên khách hàng đăng ký kèm với tài khoản đăng nhập số điện thoại trên hệ
                        thống/ tài khoản Zalo phải trùng khớp với thông tin cung cấp khi làm thủ tục
                        trao thưởng. Lưu ý: Mã QR chỉ dùng để dẫn đến đường link tham gia chương
                        trình, không chứa bằng chứng xác định trúng thưởng và không ảnh hưởng đến
                        kết quả xác định trúng thưởng
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="">
                        Sau khi đăng nhập, khách hàng tải hóa đơn mua hàng lên hệ thống. Trang web{' '}
                        <a href="https://strongbowchillkoin.com" target="_blank" rel="noreferrer">
                          Strongbowchillkoin.com
                        </a>{' '}
                        sẽ kiểm tra thông tin hóa đơn và phản hồi về điểm thưởng cho khách hàng
                        <br />
                        Trong vòng 2 phút kể từ khi khách hàng tải hóa đơn lên hệ thống thì sẽ thông
                        báo cho khách hàng số điểm tích thành công.
                        <br />
                        Nếu hóa đơn không hợp lệ, trong vòng 24h kể từ khi khách hàng tải hóa đơn
                        lên hệ thống sẽ có thông tin gửi đến khách hàng với nội như sau: “Không
                        thành công! Hóa đơn chưa hợp lệ. Bộ phận CSKH sẽ liên hệ bạn thông qua tin
                        nhắn SMS hoặc tài khoản Zalo của khách hàng tham gia chương trình
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="has-text-weight-bold">
                        Các bước tham gia và nhận quà chương trình tích lũy
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        Sau khi tích điểm thưởng, khách hàng vào mục ĐỔI ĐIỂM trên menu trang{' '}
                        <a href="https://strongbowchillkoin.com" target="_blank" rel="noreferrer">
                          Strongbowchillkoin.com
                        </a>{' '}
                        để xem thông tin và đổi điểm thưởng sang các quà tặng tương ứng
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="has-text-weight-bold">
                        Các bước tham gia và nhận quà chương trình may mắn
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        Sau khi tích điểm thưởng, khách hàng vào mục ĐỔI ĐIỂM trên menu trang{' '}
                        <a href="https://strongbowchillkoin.com" target="_blank" rel="noreferrer">
                          Strongbowchillkoin.com
                        </a>{' '}
                        để xem thông tin và đổi điểm thưởng sang vòng quay may mắn.
                        <br />
                        50 chill koin = 1 lượt quay may mắn
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        Trang web{' '}
                        <a href="https://strongbowchillkoin.com" target="_blank" rel="noreferrer">
                          Strongbowchillkoin.com
                        </a>{' '}
                        sẽ có phần mềm được lập trình sẵn để xoay ngẫu nhiên và sẽ xác định khách
                        hàng trúng giải trong số lượng giải thưởng còn lại của chương trình, sau đó
                        thông báo cho khách hàng. Khách hàng tiến hành cung cấp thông tin để được
                        trao thưởng
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        Trang web{' '}
                        <a href="https://strongbowchillkoin.com" target="_blank" rel="noreferrer">
                          Strongbowchillkoin.com
                        </a>{' '}
                        sẽ có phần mềm được lập trình sẵn để xoay ngẫu nhiên và sẽ xác định khách
                        hàng trúng giải trong số lượng giải thưởng còn lại của chương trình, sau đó
                        thông báo cho khách hàng. Khách hàng tiến hành cung cấp thông tin để được
                        trao thưởng
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        Trong trường hợp khách hàng không trúng giải, khách hàng sẽ nhận được màn
                        hình chúc bạn may mắn lần sau
                      </td>
                    </tr>
                  </table>
                </div>
                &emsp;&emsp;- Thời hạn cuối cùng để khách hàng mua hàng, tải hóa đơn lên website để
                nhận điểm thưởng là 23:00 ngày 28/02/2023 và thời hạn cuối cùng tích điểm thưởng là
                đến hết 23:30 ngày 28/02/2023.
                <br />
                &emsp;&emsp;- Số CMND/CCCD Người tham dự nhập trên hệ thống sẽ được Công ty sử dụng
                để xác nhận tính hợp lệ của người trúng thưởng.
                <br />
                &emsp;&emsp;- Công ty có quyền từ chối trao giải thưởng nếu phát hiện Người tham dự
                dưới 18 tuổi. Giải thưởng bị huỷ sẽ được cộng dồn vào các ngày tiếp theo tuỳ thuộc
                thời gian chương trình diễn ra
                <br />
                &emsp;&emsp;- Mỗi số điện thoại chỉ được đăng ký cùng một số CMND duy nhất
                <br />
                <br />
                <b>9. Thời gian, cách thức phát hành bằng chứng xác định trúng thưởng</b>
                <br />
                Trong thời gian khuyến mãi, khách hàng mua sản phẩm khuyến mãi sẽ nhận được hóa đơn
                tại quầy thanh toán. Hóa đơn phải được in ra từ hệ thống bán hàng của các chuỗi cửa
                hàng tiện lợi tham gia chương trình (theo danh sách đính kèm)
                <br />
                <br />
                <b>10. Quy định về bằng chứng xác định trúng thưởng:</b>
                <br />
                Hóa đơn hợp lệ để tham gia chương trình phải là hóa đơn gốc được in ra từ chuỗi cửa
                hàng tiện lợi Circle K, 7-Eleven, Gs25.
                <br />
                Hóa đơn phải còn nguyên vẹn, không rách rời, không cạo sửa, tẩy xóa, bôi, vẽ, hay
                làm mờ hoặc bị những lý do tương tự. Mỗi hóa đơn chỉ được sử dụng để tích điểm 01
                lần.
                <br />
                Thông tin hiển thị trên hóa đơn bắt buộc phải hiển thị đầy đủ, rõ ràng bao gồm:
                thông tin mã hóa đơn, logo của cửa hàng tiện lợi đã mua, ngày tháng xuất hóa đơn,
                tên sản phẩm đã mua (hiển thị đầy đủ theo thông tin hệ thống của cửa hàng tiện lợi),
                số tiền, số lượng sản phẩm.
                <br />
                <br />
                <b>11. Thời gian, địa điểm và cách thức xác định trúng thưởng</b>
                <div className=" table-container">
                  <table
                    className="table is-bordered is-fullwidth"
                    style={{backgroundColor: 'transparent'}}
                  >
                    <tr>
                      <th></th>
                      <th>Hình thức Tích lũy đổi quà</th>
                      <th>Hình thức Vòng quay may mắn</th>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Thời gian xác định trúng thưởng</td>
                      <td colSpan={2}>
                        Trong suốt thời gian khuyến mãi (từ 08:00 ngày 20/10/2022 đến 23:59 phút
                        ngày 28/02/2023),
                      </td>
                    </tr>
                    <tr>
                      <td>
                        khi tích lũy đủ điểm thưởng để đổi thành quà tặng. Hệ thống sẽ gửi hình ảnh
                        xác nhận khách hàng là người trúng giải
                      </td>
                      <td>
                        khi tích lũy đủ điểm thưởng để đổi thành lượt quay Vòng xoay may mắn, khách
                        hàng có thể tham gia Vòng xoay may mắn bất cứ lúc nào. Hệ thống sẽ xác định
                        khách hàng là người trúng giải.
                      </td>
                    </tr>
                    <tr>
                      <td>Địa điểm xác định trúng thưởng</td>
                      <td colSpan={2}>Ngay tại địa điểm diễn ra chương trình khuyến mại</td>
                    </tr>
                    <tr>
                      <td>Cách thức xác định trúng thưởng</td>
                      <td colSpan={2}>
                        Sau khi khách hàng đăng nhập, đăng tải hóa đơn thành công, hệ thống sẽ tiến
                        hành tích điểm cho khách hàng.
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        Khách hàng sử dụng điểm để đổi sang quà tặng với số điểm tương ứng. Hệ thống
                        sẽ gửi tin nhắn để xác nhận khách hàng là người trúng thưởng.
                      </td>
                      <td>
                        Khách hàng sử dụng điểm để đổi sang vòng quay may mắn.
                        <br />
                        Phần mềm được lập trình sẵn sẽ xoay ngẫu nhiên để chọn ra giải thưởng mà
                        khách hàng trúng trong số lượng giải thưởng còn lại của chương trình. Vòng
                        quay dừng ở ô nào thì khách hàng trúng giải đó
                      </td>
                    </tr>
                  </table>
                </div>
                <b>12. Thông báo trúng thưởng</b>
                <br />
                Sau khi xác nhận trúng thưởng, hệ thống sẽ tự động gửi tin nhắn SMS, hoặc thông qua
                tin nhắn Zalo từ tài khoản STRONGBOW vào số điện thoại khách hàng đã đăng ký với
                chương trình để thông báo trúng thưởng,
                <br />
                Công ty sẽ công bố công khai, cập nhật liên tục số lượng, cơ cấu cụ thể các giải
                thưởng còn lại tính đến thời điểm khách hàng tham gia chương trình khuyến mại trên
                website Strongbowchillkoin.com
                <br />
                Nội dung tin nhắn: Chúc mừng bạn đã trúng thưởng [phần quà] từ CTKM SĂN ĐIỂM CHILL,
                PHIÊU QUÀ XỊN của Strongbow
                <br />
                <br />
                <b>13. Thủ tục trao thưởng</b>
                <br />
                <ul>
                  <li className="ml-6" style={{listStyleType: 'square'}}>
                    Đối với phần quà Dây đeo thẻ, Mũ Strongbow, Túi chéo Strongbow, Máy chiếu phim
                    Strongbow, Túi tote Strongbow, Loa Marshall:
                  </li>
                  {/* <br /> */}
                  Trong vòng 05 ngày sau khi nhận được thông báo trúng thưởng từ chương trình, người
                  trúng thưởng truy cập vào trang web Strongbowchillkoin.com để đăng ký thông tin
                  nhận quà theo đường bưu điện. Công ty sẽ gửi quà đến địa chỉ mà khách hàng đã đăng
                  ký thông tin theo đường bưu điện trong vòng 14 ngày sau khi khách hàng điền đầy đủ
                  thông tin theo hệ thống trên trang web Strongbowchillkoin.com yêu cầu.
                  <br />
                  Chúng tôi không chịu trách nhiệm đối với các trường hợp thất lạc giải thưởng do
                  khách hàng cung cấp sai thông tin cá nhân. Công ty sẽ được xem là hoàn tất nghĩa
                  vụ trao giải khi đã gửi giải thưởng theo đúng thông tin địa chỉ mà người thắng
                  giải đã đăng ký lúc tham gia chương trình và có xác nhận gửi đi của bưu điện.
                  Trong trường hợp quà tặng được gửi qua đường bưu điện nhưng bị hoàn trả, bưu cục
                  sẽ hoàn trả và tính vào giải thưởng tồn đọng của toàn bộ chương trình.
                  <br />
                  &emsp;&emsp;- Địa điểm trao thưởng: Chuyển phát theo đường bưu điện đến tận tay
                  khách hàng. Theo thông tin khách hàng đã đăng ký.
                  <br />
                  &emsp;&emsp;- Cách thức trao thưởng: Chuyển phát bưu điện (Toàn bộ chi phí chuyển
                  phát sẽ được ban tổ chức chịu trách nhiệm)
                  <br />
                  &emsp;&emsp;- Thủ tục trao thưởng: Khách hàng cung cấp giấy tờ tùy thân (bao gồm
                  CMND/ CCCD) theo những thông tin đã đăng ký khi trúng giải của chương trình.
                  <li className="ml-6 mt-2" style={{listStyleType: 'square'}}>
                    Đối với phần quà Vé xem phim CGV, Voucher Tiki, Voucher GOT IT:
                  </li>
                  Trao thưởng trực tiếp vào tài khoản thuê bao/ tài khoản Zalo tham gia dự thưởng
                  trong vòng khoảng 5 phút sau khi nhận được thông báo trúng thưởng từ hệ thống
                  <br />
                  &emsp;&emsp;- Địa điểm trao thưởng: Trao thưởng trực tiếp vào tài khoản thuê bao/
                  tài khoản Zalo tham gia dự thưởng
                  <br />
                  &emsp;&emsp;- Cách thức trao thưởng và thủ tục trao thưởng: Gửi thông tin về mã
                  Voucher trúng giải vào tài khoản thuê bao/ tài khoản Zalo tham gia chương trình
                  của khách hàng
                  <li className="ml-6 mt-2" style={{listStyleType: 'square'}}>
                    <b>Đối với phần quà Iphone 14 Pro,</b> MacBook Pro M2 2022 13 inch, Apple Watch
                    Ultra GPS, <b>Airpod Pro 2022:</b>
                  </li>
                  Trong vòng 05 ngày sau khi thông báo trúng thưởng cho người trúng thưởng, công ty
                  sẽ liên lạc trực tiếp đến người trúng thưởng bằng điện thoại/zalo theo số điện
                  thoại người trúng thưởng tham gia chương trình để hướng dẫn trao giải.
                  <br />
                  Người trúng thưởng cần cung cấp đầy đủ các thông tin theo yêu cầu thông qua Zalo
                  của Strongbow trong vòng 03 ngày sau khi nhận được cuộc gọi hướng dẫn nhận giải từ
                  chương trình. Các thông tin bao gồm:
                  <li className="ml-6" style={{listStyleType: 'circle'}}>
                    Họ và tên.
                  </li>
                  <li className="ml-6" style={{listStyleType: 'circle'}}>
                    Số điện thoại (Cần cung cấp đúng số điện thoại vì đây là kênh thông tin duy nhất
                    Công ty dùng để liên lạc với người tham gia về việc nhận thưởng).
                  </li>
                  <li className="ml-6" style={{listStyleType: 'circle'}}>
                    Mã số thuế cá nhân.
                  </li>
                  <li className="ml-6" style={{listStyleType: 'circle'}}>
                    Địa chỉ nhận quà.
                  </li>
                  <li className="ml-6" style={{listStyleType: 'circle'}}>
                    Hình ảnh chụp 2 mặt CMND/Căn cước công dân/Hộ chiếu.
                  </li>
                  Trong vòng 14 ngày sau khi tổng đài liên lạc và người trúng thưởng đã cung cấp đầy
                  đủ các thông tin theo yêu cầu, giải thưởng sẽ được chuyển phát bưu điện đến tận
                  nhà khách hàng trúng thưởng theo thông tin mà người trúng thưởng đã cung cấp.
                  <br />
                  <b>Quy định về các khoản khấu trừ khi nhận giải:</b>
                  <br />
                  Đối với giải thưởng Iphone 14 Pro, MacBook Pro M2 2022 13 inch, Apple Watch Ultra
                  GPS sẽ không có giá trị quy đổi thành tiền mặt, khách hàng cần phải đóng thuế thu
                  nhập cá nhân cho khoản thu nhập bất thường là 10% phần giá trị vượt 10 triệu đồng.
                  Thủ tục với cơ quan thuế sẽ được công ty dịch vụ của HVBL hỗ trợ thực hiện.
                  <br />
                  Người trúng giải phải thanh toán tất cả mọi khoản thuế, lệ phí có liên quan đến
                  giải thưởng theo quy định pháp luật Việt Nam hiện hành, kể cả thuế thu nhập (nếu
                  có) khi nhận giải. Ngoài ra người trúng giải phải tự trả mọi khoản phí tổn như đi
                  lại, ăn ở, vận chuyển giải thưởng... (nếu có) cho việc nhận giải của mình.
                  <br />
                  Nếu khách hàng trúng thưởng đồng ý, Công ty TNHH Nhà máy Bia Heineken Việt Nam
                  (HVBL) sẽ sử dụng tên, hình ảnh và địa chỉ của mình vào mục đích báo cáo theo quy
                  định và quảng cáo mà HVBL không phải trả cho người này bất kỳ một khoản chi phí
                  nào.
                  <br />
                  Thời hạn kết thúc trao thưởng: Chậm nhất 45 ngày kể từ ngày kết thúc chương trình
                  khuyến mại
                </ul>
                <br />
                <b>
                  14. Đầu mối giải đáp thắc mắc cho khách hàng về các vấn đề liên quan đến chương
                  trình khuyến mại :{' '}
                </b>
                <br />
                Mọi thắc mắc về chương trình, Người tham dự có thể liên hệ với Công ty TNHH Nhà máy
                bia Heineken Việt Nam thông qua Hotline: 19001845 (từ 9:00 đến 17:00 , từ thứ 2 đến
                thứ 6 hàng tuần, trừ ngày lễ), bộ phận chăm sóc người tham dự hoặc tại fanpage:{' '}
                <a
                  href="https://www.facebook.com/StrongbowVietnam"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.facebook.com/StrongbowVN
                </a>
                <br />
                <br />
                <b>15 Các quy định khác:</b>
                <br />
                <b>&emsp;15.1 Quyền và trách nhiệm của Người tham dự</b>
                <br />- Người tham dự cần phải đọc và hiểu rõ thể lệ của Chương trình, đồng ý và
                tuân theo quy định bản thể lệ này. Người tham dự cam kết các thông tin cung cấp cho
                chương trình là chính xác và hợp pháp.
                <br />- Nếu cần, Người tham dự sẽ được yêu cầu đồng ý sử dụng cookie và chia sẻ dữ
                liệu cá nhân của công ty. Để xem thêm thông tin về những cookie và chính sách bảo vệ
                quyền riêng tư công ty sử dụng và cách công ty sử dụng chúng, vui lòng xem lại Chính
                sách cookie riêng biệt của BTC bằng cách nhấn vào đường dẫn Strongbowchillkoin.com
                /the-le (Chính sách bảo vệ quyền riêng tư).
                <br />- Bằng cách tham gia, Người tham dự đồng ý và tuân theo quy định của bản thể
                lệ này. Trường hợp được Người tham dự trúng thưởng đồng ý, Công ty TNHH Nhà Máy Bia
                HEINEKEN Việt Nam (HVBL) sẽ sử dụng thông tin cá nhân Người tham dự; sử dụng, sao
                chép, sửa đổi, cắt ghép, cải biên hình ảnh; truyền đạt hình ảnh hoặc một phần hình
                ảnh với mục đích truyền thông, quảng cáo thương mại trên các kênh truyền thông liên
                quan đến nhãn hàng.
                <br />
                <b>
                  &emsp;15.2 Quyền và trách nhiệm của Công ty TNHH Nhà máy bia Heineken Việt Nam
                </b>
                <br />- Công ty TNHH Nhà máy bia Heineken Việt Nam sẽ không chịu trách nhiệm đối với
                việc giải thưởng bị thất lạc do thông tin Người tham dự cung cấp không chính xác,
                thiếu thông tin hoặc không liên lạc được với Người tham dự khi nhân viên trao giải
                thưởng đến giao quà.
                <br />- Trường hợp xảy ra sự kiện bất khả kháng theo quy định pháp luật (bao gồm
                nhưng không giới hạn do thiên tai, lũ lụt, sét đánh, khủng bố tấn công máy chủ làm
                thất thoát dữ liệu của người dự thi, …), Công ty TNHH Nhà máy bia Heineken Việt Nam
                có thể thay đổi hoặc hủy bỏ Chương trình nếu được cơ quan quản lý nhà nước có thẩm
                quyền xác nhận và thông báo với Người tham dự trong thời gian sớm nhất.
                <br />- Nếu có bất kỳ thay đổi nào về thể lệ của Chương trình, Công ty TNHH Nhà máy
                bia Heineken Việt Nam sẽ cập nhật và thông báo trên website Strongbowchillkoin.com
                sau khi được cơ quan quản lý nhà nước có thẩm quyền xác nhận. Công ty TNHH Nhà máy
                bia Heineken Việt Nam có trách nhiệm bảo mật thông tin cá nhân cho Người tham dự
                cuộc thi này, không chia sẻ cho bên thứ ba và chỉ sử dụng cho mục đích trao đổi
                thông tin giữa Công ty TNHH Nhà máy bia Heineken Việt Nam và Người tham dự theo
                chính sách bảo vệ quyền riêng tư tại trang Strongbowchillkoin.com.
                <br />- Công ty TNHH Nhà máy bia Heineken Việt Nam có toàn quyền gỡ bỏ những hình
                ảnh/ tài khoản của Người tham dự nếu phát hiện và chứng minh được thông tin không
                đúng sự thật hoặc có chứa nội dung vi phạm quy định thể lệ này.
                <br />
                <b>&emsp;15.3 Quy định chung</b>
                <br />- Mọi thắc mắc về chương trình, Người tham dự có thể liên hệ với Công ty TNHH
                Nhà máy bia Heineken Việt Nam thông qua Hotline: 19001845, bộ phận chăm sóc người
                tham dự hoặc tại fanpage: https://www.facebook.com/StrongbowVN
                <br />- Khách hàng trúng thưởng cần cung cấp đầy đủ, chính xác các thông tin được
                yêu cầu để phục vụ việc kiểm tra, thẩm định tính hợp lệ của việc trúng thưởng.
                <br />- Công Ty TNHH nhà máy bia Heineken Việt Nam được sử dụng tên, hình ảnh của
                người tiêu dùng trúng thưởng vào mục đích quảng cáo thương mại khi có sự đồng ý của
                Khách hàng.
                <br />- Công Ty TNHH Nhà Máy Bia Heineken Việt Nam hoàn toàn chịu trách nhiệm trong
                việc quản lý khâu in ấn, tính chính xác của bằng chứng xác định trúng thưởng lưu
                thông trong thời gian khuyến mại. Việc tổ chức Chương trình phải đảm bảo tính công
                bằng, minh bạch và khách quan đúng theo quy định của pháp luật hiện hành.
                <br />- Khi phát sinh tranh chấp, khiếu nại liên quan đến Chương trình, Công Ty TNHH
                nhà náy bia Heineken Việt Nam sẽ đứng ra trực tiếp giải quyết theo quy định của pháp
                luật hiện hành.
                <br />- Công Ty TNHH nhà máy bia Heineken Việt Nam có trách nhiệm báo cáo kết quả
                thực hiện khuyến mại trong thời hạn 45 ngày kể từ ngày kết thúc thời hạn trao
                thưởng, lưu trữ và chịu trách nhiệm về chứng từ, tài liệu liên quan đến báo cáo theo
                đúng quy định của pháp luật để phục vụ công tác thanh tra, kiểm tra, giám sát.
                <br />- Đối với những giải thưởng không có người trúng thưởng Công Ty TNHH nhà máy
                bia Heineken Việt Nam có trách nhiệm nộp 50% giá trị đã công bố của giải thưởng đó
                vào ngân sách Nhà nước theo quy định tại khoản 4 Điều 96 Luật Thương Mại./.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Index;
