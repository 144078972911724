import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Layout from 'components/layout';
import {Link, useLocation, useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import Select from 'react-select';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {get} from 'lodash';
import {titleCase} from 'utils/stringUltils';
import ContactButton from 'components/contactButton';
import {toast} from 'react-toastify';
import {
  createDeliveryAction,
  getProvincesAction,
  getWardByDistrictCodeAction,
  getDistrictByProvinceCodeAction,
} from 'appRedux/actions/app';
import {userInfoSelector, citizenIdInfoSelector} from 'appRedux/selectors/auth';
import moment from 'moment';
import {GENDER_OPTIONS} from 'constants/gender';
import {getSelectOptions} from 'helpers';
import {checkForSpecialChar} from 'utils/stringUltils';
import {
  getUserProfileAction,
  updateUserAvatarAction,
  updateUserProfileAction,
  uploadCitizenIdentificationAction,
  getCitizenIdByMerchantIdAction,
} from 'appRedux/actions/auth';
import classNames from 'classnames';
import {userProfileModalSelector, isCitizenIdCardUploadingSelector} from 'appRedux/selectors/app';
import image_avatar_placeholder from 'images/image_avatar_placeholder.png';
import upload from 'images/upload.png';
import Resizer from 'react-image-file-resizer';
import image_banner from 'images/edit-profile-title.png';
import bottle from 'images/strongbow/bottle.png';
import leaf from 'images/strongbow/pc/leaf.png';
import balloon from 'images/strongbow/pc/balloon.png';
import CitizenIdCardUpload from 'components/citizenIdCardUpload';

const requireText = 'Bắt buộc';
Yup.addMethod(Yup.string, 'dateOfBirth', function (errorMessage, year = -4) {
  return this.test(`test-date-of-birth`, errorMessage, function (value) {
    const minDate = moment().add(-year, 'y');
    const date = moment(value);
    return date.isBefore(minDate);
  });
});
const EDIT_FORM_SCHEME = Yup.object().shape({
  fullName: Yup.string()
    .test('regex', 'Vui lòng không bỏ trống, nhập số hoặc nhập kí tự đặc biệt', (val) => {
      if (!/\S/.test(val)) {
        // Didn't find something other than a space which means it's empty
        return false;
      }
      // Check special character
      if (checkForSpecialChar(val)) {
        return false;
      }
      // check number in string
      return !/\d/.test(val);
    })
    .required(requireText),

  email: Yup.string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email sai định dạng. Vui lòng nhập lại!')
    .required(requireText),
  // dateOfBirth: Yup.string()
  //   .required(requireText)
  //   .dateOfBirth('Người dưới 18 tuổi không được uống rượi, bia!', 18),
  province: Yup.object().required(requireText).nullable(true),
  district: Yup.object().required(requireText).nullable(true),
  ward: Yup.object().required(requireText).nullable(true),
  address: Yup.string().required(requireText),
});

// Validation Citizen ID Form
// const EDIT_CITIZEN_ID_FORM_SCHEME = Yup.object().shape({
// citizenId: Yup.string().required(requireText),
// frontImage: Yup.object().required(requireText).nullable(true),
// backImage: Yup.object().required(requireText).nullable(true),
// });

const EditProfile = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useSelector(userInfoSelector);
  const userProfileModalData = useSelector(userProfileModalSelector);
  const provinces = useSelector((state) => state.app.masterData.provinces || []);
  const info = location?.state?.info || null;
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const citizenIdInfo = useSelector(citizenIdInfoSelector);
  const [citizenIdFormError, setCitizenIdFormError] = useState({
    citizenId: '',
    frontImage: '',
    backImage: '',
  });
  const isUploading = useSelector(isCitizenIdCardUploadingSelector);

  useEffect(() => {
    dispatch(getUserProfileAction());
    dispatch(getCitizenIdByMerchantIdAction());
  }, []);

  React.useEffect(() => {
    if (!provinces?.length) {
      dispatch(getProvincesAction());
    }
    dispatch(
      getDistrictByProvinceCodeAction({
        provinceCode: userInfo?.province,
        callback: (data) => {
          const items = getSelectOptions(data, 'code', 'name');
          setDistricts(items);
          formik.setFieldValue(
            'district',
            items.find((item) => item?.code === userInfo?.district) || null
          );
        },
      })
    );
    dispatch(
      getWardByDistrictCodeAction({
        districtCode: userInfo?.district,
        callback: (data) => {
          const items = getSelectOptions(data, 'code', 'name');
          setWards(items);
          formik.setFieldValue('ward', items.find((item) => item?.code === userInfo?.ward) || null);
        },
      })
    );
  }, [provinces?.length, userInfo?.district, userInfo?.ward]);

  const onChangeProvince = (provinceCode) => {
    formik.setFieldValue('district', null);
    formik.setFieldValue('ward', null);
    setDistricts([]);
    setWards([]);
    dispatch(
      getDistrictByProvinceCodeAction({
        provinceCode,
        callback: (data) => {
          setDistricts(getSelectOptions(data, 'code', 'name'));
        },
      })
    );
  };

  const onChangeDistrict = (districtCode) => {
    formik.setFieldValue('ward', null);
    formik.setFieldValue('ward', null);
    setWards([]);
    dispatch(
      getWardByDistrictCodeAction({
        districtCode,
        callback: (data) => {
          setWards(getSelectOptions(data, 'code', 'name'));
        },
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: userInfo?.phoneNumber || '',
      //dateOfBirth: userInfo?.dateOfBirth ? moment(userInfo?.dateOfBirth).format('YYYY-MM-DD') : '',
      gender: GENDER_OPTIONS.find((item) => item.value === userInfo?.gender),
      fullName: userInfo?.fullName || '',
      address: userInfo?.address || '',
      email: userInfo?.email || '',
      province: provinces.find((item) => item?.code === userInfo?.province) || null,
      ward: null,
      district: null,
    },
    enableReinitialize: true,

    validationSchema: EDIT_FORM_SCHEME,
    onSubmit: (values) => {
      if (selectedImage) {
        resizeFile(selectedImage).then((value) => {
          dispatch(
            updateUserAvatarAction({
              ProgramId: process.env.REACT_APP_PROGRAM_ID,
              Avatar: value.slice(value.indexOf(',') + 1),
            })
          );
        });
      }
      const payload = {
        memberName: titleCase(values?.fullName || ''),
        dateOfBirth: moment(values?.dateOfBirth).format('YYYY-MM-DD'),
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        address: values?.address,
        province: values?.province?.value,
        district: values?.district?.value,
        ward: values?.ward?.value,
        userId: userInfo?.userId,
        programId: userInfo?.programId,
        gender: userInfo?.gender,
      };
      // handleUpdateProfile(payload);

      // ........................
      let isValid = validateProfileFormWithRewardValue(userInfo?.rewardValue);
      if (isValid) {
        handleUpdateProfile(payload);
      }
    },
  });
  const handleUpdateProfile = (payload) => {
    dispatch(updateUserProfileAction(payload));
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: '#D6BA85 !important',
      borderRadius: 30,
      fontWeight: 500,
      color: '#FFF',
      fontSize: 16,
      boxShadow: state.isFocused ? '0 0 0 0.125em rgb(221 164 16 / 25%)' : 'unset',
    }),
    // placeholder: (base, state) => ({
    //   ...base,
    //   color: '#D6BA85',
    // }),
    singleValue: (base, state) => ({
      ...base,
      color: 'rgb(54,54,54)',
      fontWeight: 400,
    }),
  };
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        'jpeg',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64',
        400,
        400
      );
    });
  const onChangeImage = (event) => {
    event.preventDefault();
    let files = event.target.files;
    if (event.target.files) {
      let type = event.target.files[0].type;
      if (['image/jpeg', 'image/png', 'image/gif'].includes(type)) {
        setSelectedImage(files[0]);
      } else {
        toast.error('Vui lòng chọn hình ảnh', {autoClose: 2000, position: 'top-center'});
      }
      event.target.value = '';
    }
  };
  // Start Control Upload Citizen Identification

  const citizenIdFormik = useFormik({
    initialValues: {
      citizenId: citizenIdInfo?.numberCard || '',
      frontImage: citizenIdInfo?.frontImage || null,
      backImage: citizenIdInfo?.backImage || null,
    },
    enableReinitialize: true,

    // validationSchema: EDIT_CITIZEN_ID_FORM_SCHEME,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('NumberCard', values?.citizenId);
      formData.append('BackImage', values?.backImage);
      formData.append('FrontImage', values?.frontImage);
      formData.append('MerchantId', userInfo?.merchantId);
      let isValid = validateCitizenIdForm(values);
      if (isValid) {
        if(!isUploading || citizenIdInfo?.status !== 0 || citizenIdInfo?.status !== 1){
          handleloadCitizenIdentification(formData);
        }
      }
      /*
      const formData = new FormData();
      formData.append('NumberCard', values?.citizenId);
      formData.append('BackImage', values?.backImage);
      formData.append('FrontImage', values?.frontImage);
      formData.append('MerchantId', userInfo?.merchantId);
*/
      // if (selectedImage) {
      //   resizeFile(selectedImage).then((value) => {
      //     dispatch(
      //       updateUserAvatarAction({
      //         ProgramId: process.env.REACT_APP_PROGRAM_ID,
      //         Avatar: value.slice(value.indexOf(',') + 1),
      //       })
      //     );
      //   });
      // }

      // const payload = {
      //   NumberCard: values?.citizenId,
      //   BackImage: values?.backImage,
      //   FrontImage: values?.frontImage,
      //   MerchantId: userInfo?.merchantId,
      // };

      // handleloadCitizenIdentification(formData);
    },
  });
  const handleloadCitizenIdentification = async (payload) => {
    await dispatch(uploadCitizenIdentificationAction({body: payload}));
    await dispatch(getCitizenIdByMerchantIdAction());
  };

  const onChangeCitizenIdImage = (event, name = '') => {
    event.preventDefault();
    if (event.target.files) {
      let type = event.target.files[0].type;
      if (['image/jpeg', 'image/png', 'image/gif'].includes(type)) {
        citizenIdFormik.setFieldTouched(name, true, true);
        citizenIdFormik.setFieldValue(name, event.target.files[0]);
      } else {
        toast.error('Vui lòng chọn hình ảnh', {autoClose: 2000, position: 'top-center'});
      }
      event.target.value = '';
    }
  };

  const onDeleteCitizenIdImage = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    citizenIdFormik.setFieldTouched(name, true, true);
    citizenIdFormik.setFieldValue(name, null);
  };

  const onChangeCitizenId = (e) => {
    let citizenIdVal = e.target.value ? e.target.value.replace(/\D/g, '') : '';
    citizenIdFormik.setFieldTouched('citizenId', true, true);
    citizenIdFormik.setFieldValue('citizenId', citizenIdVal);
  };

  const onDeleteCitizenId = (e) => {
    e.preventDefault();
    citizenIdFormik.setFieldTouched('citizenId', true, true);
    citizenIdFormik.setFieldValue('citizenId', '');
  };

  const validateCitizenIdForm = (values) => {
    let isValid = true;
    let error = {};
    if (!values.citizenId) {
      error.citizenId = 'Bắt buộc';
      isValid = false;
    } else {
      error.citizenId = '';
    }
    if (!values.frontImage) {
      error.frontImage = 'Bắt buộc';
      isValid = false;
    } else {
      error.frontImage = '';
    }
    if (!values.backImage) {
      error.backImage = 'Bắt buộc';
      isValid = false;
    } else {
      error.backImage = '';
    }
    setCitizenIdFormError(error);
    return isValid;
  };
  // End Upload Citizen Identification

  // Start Control Upload Profile With Reward Value
  const validateProfileFormWithRewardValue = (rewardValue) => {
    let isValid = true;
    if (citizenIdInfo?.status === 0 || citizenIdInfo?.status === 1) {
      return isValid;
    } else {
      if (rewardValue > 500) {
        let isValidCitizenId = validateCitizenIdForm(citizenIdFormik.values);
        if (isValidCitizenId) {
          citizenIdFormik.handleSubmit();
          return isValid;
        } else {
          isValid = false;
        }
      } else if (rewardValue <= 500) {
        if (
          !!citizenIdFormik.values.citizenId &&
          !!citizenIdFormik.values.frontImage &&
          !!citizenIdFormik.values.backImage
        ) {
          citizenIdFormik.handleSubmit();
          return isValid;
        }
      }
    }
    return isValid;
  };
  // End Control Upload Profile With Reward Value

  return (
    <Layout>
      <div className="container-chill usergift_info_detail is-relative">
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '50%',
            // right: ,
            left: '10%',
          }}
        >
          <img
            src={bottle}
            width={60}
            style={{
              // transform: 'matrix(0.99, 0.14, -0.14, 0.99, 0, 0)',
              transform: 'matrix(0.97, -0.23, 0.23, 0.97, 0, 0)',
            }}
          />
        </div>

        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '50%',
            right: 0,
            marginRight: -40,
          }}
        >
          <img src={leaf} width={200} alt="rightLeaf" style={{transform: 'rotate(180deg)'}} />
        </div>
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '10%',
            left: 0,
            marginLeft: -80,
          }}
        >
          <img src={leaf} width={200} style={{}} alt="leftLeaf" />
        </div>
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '20%',
            right: '5%',
            // marginLeft: -80,
          }}
        >
          <img src={balloon} width={100} style={{}} alt="balloon" />
        </div>
        <div className="container edit-profile">
          <div className="title-image has-text-centered">
            <img
              style={{
                width: '55%',
                maxWidth: 400,
              }}
              src={image_banner}
              alt="title"
            />
          </div>
          <div className="container-content mt-4">
            <div className=" columns">
              <div className=" column is-4-tablet is-4-desktop is-3-fullhd">
                <div className=" is-flex is-justify-content-center" style={{}}>
                  <div className=" is-relative">
                    <figure className="" style={{height: '100%'}}>
                      <img
                        width={100}
                        src={
                          selectedImage
                            ? URL.createObjectURL(selectedImage)
                            : userInfo?.avatar
                            ? userInfo?.avatar
                            : image_avatar_placeholder
                        }
                        alt="Placeholder"
                        className="avatar"
                      />
                    </figure>

                    <input
                      type="file"
                      id="input-file-upload"
                      // multiple={true}
                      accept="image/png, image/gif, image/jpeg"
                      onChange={onChangeImage}
                    />
                    <label htmlFor="input-file-upload" className=" mt-4">
                      <img
                        width={40}
                        htmlFor="input-file-upload"
                        src={upload}
                        alt="Placeholder"
                        className="upload-icon is-clickable"
                        style={{position: 'absolute', bottom: 0, right: 0}}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="column">
                <div
                  className="columns"
                  style={{
                    marginTop: 15,
                  }}
                >
                  <div className="column is-9-tablet is-offset-1-tablet ">
                    <div class="field custom-input">
                      <label className="label is-size-7-mobile" style={{fontSize: 14}}>
                        Họ và tên<span style={{color: 'red'}}>*</span>
                      </label>
                      <div className="control">
                        <input
                          className="input input-is-custom is-size-6"
                          type="text"
                          placeholder="Họ và tên"
                          value={formik.values.fullName}
                          onChange={(e) => {
                            formik.setFieldTouched('fullName', true, true);
                            formik.setFieldValue('fullName', e.target.value);
                          }}
                          style={{
                            textTransform: 'capitalize'
                          }}
                          // disabled={!!info}
                        />
                        {get(formik.touched, 'fullName', false) &&
                          !!get(formik.errors, 'fullName') && (
                            <p className="help mb-0 is-danger">
                              <span>{get(formik.errors, 'fullName')}</span>
                            </p>
                          )}
                      </div>
                    </div>
                    {/* <div class="field custom-input">
                  <label className="label">
                    Ngày sinh<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input input-is-custom"
                      type="date"
                      placeholder="Ngày sinh"
                      value={formik.values.dateOfBirth}
                      onChange={(e) => {
                        formik.setFieldTouched('dateOfBirth', true, true);
                        formik.setFieldValue('dateOfBirth', e.target.value);
                      }}
                      // disabled={!!info}
                    />
                    {get(formik.touched, 'dateOfBirth', false) &&
                      !!get(formik.errors, 'dateOfBirth') && (
                        <p className="help mb-0 is-danger">
                          <span>{get(formik.errors, 'dateOfBirth')}</span>
                        </p>
                      )}
                  </div>
                </div> */}
                    <div class="field custom-input ">
                      <label className="label is-size-7-mobile" style={{fontSize: 14}}>
                        Số điện thoại liên hệ<span style={{color: 'red'}}>*</span>
                      </label>
                      <div className="control">
                        <input
                          className="input input-is-custom has-text-weight-semibold has-text-info is-size-6"
                          type="text"
                          maxLength={10}
                          placeholder="Số điện thoại liên hệ"
                          value={formik.values.phoneNumber}
                          onChange={(e) => {
                            formik.setFieldTouched('phoneNumber', true, true);
                            formik.setFieldValue('phoneNumber', e.target.value);
                          }}
                          disabled
                          style={{
                            WebkitTextFillColor: '#83552D',
                            background: '#D6BA85',
                            opacity: 1,
                          }}
                        />
                        {get(formik.touched, 'phoneNumber', false) &&
                          !!get(formik.errors, 'phoneNumber') && (
                            <p className="help mb-0 is-danger">
                              <span>{get(formik.errors, 'phoneNumber')}</span>
                            </p>
                          )}
                      </div>
                    </div>
                    <div class="field custom-input ">
                      <label className="label is-size-7-mobile" style={{fontSize: 14}}>
                        Email<span style={{color: 'red'}}>*</span>
                      </label>
                      <div className="control">
                        <input
                          className="input input-is-custom is-size-6"
                          type="text"
                          placeholder="Email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldTouched('email', true, true);
                            formik.setFieldValue('email', e.target.value);
                          }}
                        />
                        {get(formik.touched, 'email', false) && !!get(formik.errors, 'email') && (
                          <p className="help mb-0 is-danger">
                            <span>{get(formik.errors, 'email')}</span>
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div class="field custom-input">
                  <label className="label">CMND/CCCD</label>
                  <div className="control">
                    <input
                      className="input input-is-custom"
                      type="text"
                      placeholder="CMND/CCCD"
                      disabled={!!info}
                    />
                  </div>
                </div> */}
                    <div class="field">
                      <label className="label is-size-7-mobile" style={{fontSize: 14}}>
                        Tỉnh/Thành<span style={{color: 'red'}}>*</span>
                      </label>
                      <div className="control">
                        <Select
                          placeholder="Chọn Tỉnh/Thành"
                          options={provinces}
                          styles={customStyles}
                          className="select-is-custom"
                          value={formik?.values?.province}
                          onChange={(item) => {
                            formik.setFieldTouched('province', true, true);
                            onChangeProvince(item?.value);
                            formik.setFieldValue('province', item);
                            // formik.setFieldValue('districtCode', '');
                            // setDataDistrictFilter(dataDistrict.filter((f) => f.code == e.value));
                          }}
                          isDisabled={!!info}
                        />
                        {get(formik.touched, 'province', false) &&
                          !!get(formik.errors, 'province') && (
                            <p className="help mb-0 is-danger">
                              <span>{get(formik.errors, 'province')}</span>
                            </p>
                          )}
                      </div>
                    </div>
                    <div class="field">
                      <label className="label is-size-7-mobile" style={{fontSize: 14}}>
                        Quận/Huyện<span style={{color: 'red'}}>*</span>
                      </label>
                      <div className="control">
                        <Select
                          placeholder="Chọn Quận/Huyện"
                          styles={customStyles}
                          options={districts}
                          className="select-is-custom"
                          value={formik?.values?.district}
                          onChange={(item) => {
                            formik.setFieldTouched('district', true, true);
                            onChangeDistrict(item?.value);
                            formik.setFieldValue('district', item);
                          }}
                          isDisabled={!formik.values.province}
                        />
                        {get(formik.touched, 'district', false) &&
                          !!get(formik.errors, 'district') && (
                            <p className="help mb-0 is-danger">
                              <span>{get(formik.errors, 'district')}</span>
                            </p>
                          )}
                      </div>
                    </div>
                    <div class="field">
                      <label className="label is-size-7-mobile" style={{fontSize: 14}}>
                        Phường/Xã<span style={{color: 'red'}}>*</span>
                      </label>
                      <div className="control">
                        <Select
                          placeholder="Chọn Phường/Xã"
                          options={wards}
                          styles={customStyles}
                          className="select-is-custom"
                          value={formik.values.ward}
                          onChange={(e) => {
                            formik.setFieldTouched('ward', true, true);
                            formik.setFieldValue('ward', e);
                          }}
                          isDisabled={!formik.values.district}
                        />
                        {get(formik.touched, 'ward', false) && !!get(formik.errors, 'ward') && (
                          <p className="help mb-0 is-danger">
                            <span>{get(formik.errors, 'ward')}</span>
                          </p>
                        )}
                      </div>
                    </div>
                    <div class="field custom-input is-size-6">
                      <label className="label is-size-7-mobile" style={{fontSize: 14}}>
                        Địa chỉ cư trú<span style={{color: 'red'}}>*</span>
                      </label>
                      <div className="control">
                        <input
                          className="input input-is-custom is-size-6"
                          type="text"
                          placeholder="Địa chỉ cư trú"
                          value={formik.values.address}
                          onChange={(e) => {
                            formik.setFieldTouched('address', true, true);
                            formik.setFieldValue('address', e.target.value);
                          }}
                          disabled={!!info}
                        />
                        {get(formik.touched, 'address', false) && !!get(formik.errors, 'address') && (
                          <p className="help mb-0 is-danger">
                            <span>{get(formik.errors, 'address')}</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {true && (
                  <>
                    <div className="columns">
                      <div className="column has-text-centered">
                        <button
                          className={classNames('button is-primary-custom', {
                            'is-loading': userProfileModalData?.isUpdating || isUploading,
                          })}
                          style={{
                            color: '#FFF',
                            padding: '14px 50px',
                            fontSize: 16,
                            fontWeight: 700,
                            textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                            width: 250,
                          }}
                          onClick={() => {
                            formik.setFieldTouched('district', true, true);
                            formik.handleSubmit();
                          }}
                          disabled={
                            userProfileModalData?.isUpdating || isUploading
                          }
                        >
                          CẬP NHẬT
                        </button>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column has-text-centered">
                        <button
                          className="button is-second-custom"
                          style={{
                            color: '#83552D',
                            padding: '14px 50px',
                            fontSize: 16,
                            fontWeight: 700,
                            textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                            width: 250,
                          }}
                          onClick={() => {
                            navigate(-1);
                            // formik.submitForm();
                          }}
                        >
                          TRỞ VỀ
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {/* Upload Citizen Identification */}
                <CitizenIdCardUpload
                  onSubmitCitizenIdFiles={citizenIdFormik.handleSubmit}
                  frontImage={citizenIdFormik.values.frontImage}
                  backImage={citizenIdFormik.values.backImage}
                  citizenId={citizenIdFormik.values.citizenId}
                  handleChangeCitizenIdImage={onChangeCitizenIdImage}
                  handleChangeCitizenId={onChangeCitizenId}
                  handleDeleteCitizenIdImage={onDeleteCitizenIdImage}
                  handleDeleteCitizenId={onDeleteCitizenId}
                  citizenIdFormError={citizenIdFormError}
                  citizenIdStatus={citizenIdInfo?.status}
                />

                {/*  .....END Upload Citizen Identification ....... */}
              </div>
            </div>

            <div className="mt-3">
              <ContactButton />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default EditProfile;
