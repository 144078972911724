import React from 'react';
import {Button, Drawer} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Logo from 'images/footer_banner.png';
import {useDispatch, useSelector} from 'react-redux';
import {showDrawerSelector, isOver18Selector} from 'appRedux/selectors/app';
import {toggleLoginModal, toggleShowDrawer} from 'appRedux/slices/appSlice';
import {ROUTE_NAME} from 'constants/routes';
import {Link, useNavigate} from 'react-router-dom';
import {isLogInSelector, userInfoSelector} from 'appRedux/selectors/auth';
import {signOutAction} from 'appRedux/actions/global';
import classNames from 'classnames';
const menuItems = [
  {
    id: 1,
    text: 'Về Trang chủ',
    path: ROUTE_NAME.HOME,
    show: true,
  },
  {
    id: 9,
    text: 'Mẹo Chill',
    path: ROUTE_NAME.CHILL_MAP,
    show: true,
  },
  {
    id: 2,
    text: 'Thông tin Chương trình',
    path: ROUTE_NAME.PROGRAM,
    show: true,
  },
  {
    id: 3,
    text: 'Đổi Chill Koin',
    path: ROUTE_NAME.CHILL,
    show: true,
  },
  {
    id: 4,
    text: 'Danh sách trúng giải',
    path: ROUTE_NAME.LIST_PRIZE,
    show: true,
  },
  {
    id: 5,
    text: 'Cửa hàng',
    path: ROUTE_NAME.STORE,
    show: true,
  },
];

const menuProfileItems = [
  {
    id: 5,
    text: 'Điểm thành viên',
    path: ROUTE_NAME.USER_PROFILE,
    show: true,
  },
  {
    id: 6,
    text: 'Voucher của tôi',
    path: `${ROUTE_NAME.USER_PROFILE}?tab=1`,
    show: true,
  },
  {
    id: 7,
    text: 'Lịch sử điểm',
    path: `${ROUTE_NAME.USER_PROFILE}?tab=2`,
    show: true,
  },
  {
    id: 8,
    text: 'Tình trạng giao hàng',
    path: `${ROUTE_NAME.USER_PROFILE}?tab=3`,
    show: true,
  },
];

const AppDrawer = ({children}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpen = useSelector(showDrawerSelector);
  const userInfo = useSelector(userInfoSelector);
  const isLogIn = useSelector(isLogInSelector);
  const isOver = useSelector(isOver18Selector);
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(toggleShowDrawer(open));
  };
  const handleShowLoginModal = () => {
    dispatch(toggleShowDrawer(false));
    dispatch(toggleLoginModal(true));
  };
  const handleSignOut = () => {
    dispatch(toggleShowDrawer(false));
    dispatch(signOutAction());
    //navigate(ROUTE_NAME.HOME);
    window.location.href = './';
  };
  return (
    <>
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={toggleDrawer(false)}
        classes={{
          paper: 'has-background-black',
        }}
      >
        <div
          className="has-background-black px-4 is-flex is-flex-direction-column pb-6"
          style={{minHeight: '100vh', maxWidth: '100vw'}}
        >
          <header className="p-4 has-text-weight-bold text-md has-text-white is-clickable">
            <CloseIcon
              onClick={toggleDrawer(false)}
              className=" is-size-1 has-text-white cursor is-clickable"
            />
          </header>
          <div className=" has-text-centered py-4">
            <img src={Logo} alt="logo" style={{width: 180}} />
          </div>
          <div className="p-4 has-text-weight-bold text-md  has-text-white  is-flex is-justify-content-space-between has-text-centered  is-flex-direction-column is-flex-grow-1 is-align-items-center">
            <div>
              {(isOver || isLogIn) && (
                <>
                  {menuItems.map((item) => {
                    if (item.id === 3 && !isLogIn) return null;
                    return (
                      <Link to={item.path} className="has-text-white">
                        <div
                          key={item.id}
                          onClick={toggleDrawer(false)}
                          className=" text-center text-md mb-4 is-clickable"
                        >
                          {item.text}
                        </div>
                      </Link>
                    );
                  })}
                </>
              )}

              {!isLogIn && isOver && (
                <>
                  <button
                    className={classNames('is-clickable is-uppercase mt-6 py-4', {
                      'is-primary-custom': true,
                      'has-text-white': true,
                      'is-second-custom': false,
                    })}
                    style={{
                      color: '#83552D',
                      // padding: '14px 30px',
                      fontSize: 16,
                      fontWeight: 700,
                      width: '100%',
                      textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                      borderRadius: 10,
                    }}
                    onClick={handleShowLoginModal}
                  >
                    Đăng ký
                  </button>
                  <button
                    className={classNames('is-clickable is-uppercase mt-4 py-4', {
                      'is-primary-custom': false,
                      'has-text-white': false,
                      'is-second-custom': true,
                    })}
                    style={{
                      color: '#83552D',
                      // padding: '14px 30px',
                      fontSize: 16,
                      fontWeight: 700,
                      width: '100%',
                      textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                      borderRadius: 10,
                    }}
                    onClick={handleShowLoginModal}
                  >
                    Đăng nhập
                  </button>
                </>
              )}
            </div>
            {isLogIn && (
              <div className="w-full is-flex is-flex-direction-column  align-items-center">
                <div
                  onClick={toggleDrawer(false)}
                  style={{
                    textTransform: 'uppercase',
                    background:
                      'linear-gradient(180deg, #EDC487 -10.31%, #E4B06B 34.9%, #D48E3C 99.96%)',
                    borderRadius: 4,
                  }}
                  className="text-center text-md mb-8 is-clickable rounded-full is-rounded p-2 my-4 "
                >
                  <Link
                    to={ROUTE_NAME.USER_PROFILE}
                    style={{
                      color: '#83552D',
                    }}
                  >
                    {`Hello, ${userInfo?.fullName || 'Chiller'} `}
                  </Link>
                </div>
                {/* {menuProfileItems.map((item) => {
                  return (
                    <div
                      onClick={toggleDrawer(false)}
                      className=" text-center text-md mb-4 is-clickable"
                    >
                      <Link to={item.path} className="has-text-white">
                        {item.text}
                      </Link>
                    </div>
                  );
                })} */}
                <div
                  // onClick={toggleDrawer(false)}
                  style={{borderTop: '1px solid white'}}
                  className="text-center text-md is-clickable w-full py-2"
                ></div>
                <div
                  onClick={() => {
                    // toggleDrawer(false);
                    handleSignOut();
                  }}
                  className=" text-center text-md  is-clickable"
                >
                  Đăng xuất
                </div>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};
export default AppDrawer;
