import React from 'react';
import classNames from 'classnames';
const Popup = ({isOpen = false, children, onChange = () => {}, top = 8, right = 0}) => {
  const styles = {
    position: 'absolute',
    right: right,
    top: top,
    zIndex: 2,
  };
  return (
    <>
      <div
        className={classNames('modal px-5', {
          'is-active': isOpen,
        })}
      >
        <div className="modal-background"></div>
        <div className="modal-card" style={{maxWidth: 400}}>
          <div style={styles}>
            <button
              className="delete"
              aria-label="close"
              onClick={() => onChange(!isOpen)}
            ></button>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};
export default Popup;
