export const BUCKET_IMAGES = {
  SCAN_STORE_FAILED_TITLE:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/Thu%CC%9B%CC%89%20la%CC%A3i%20nhe%CC%81.png',
  SCAN_STORE_FAILED_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/Ko%20ho%CC%9B%CC%A3p%20le%CC%A3%CC%82.png',
  CONGRATS_TITLE:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/Chu%CC%81c%20mu%CC%9B%CC%80ng.png',
  CONGRATS_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/%C4%90a%CC%83%20ba%CC%86%CC%81t%20tro%CC%A3n%20me%CC%A3o%20chill.png',
  SCAN_BILL_FAILED_TITLE:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/Co%CC%81%20gi%CC%80%20%C4%91o%CC%81%20sai%20sai.png',
  SCAN_BILL_FAILED_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/popup%20loi.png',
  CHECK_IN_ALREADY_TITLE:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/Tie%CC%82%CC%81c%20tha%CC%A3%CC%82t.png',
  SCAN_BILL_DUPLICATED_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/bill_duplicated.png',
  SCAN_BILL_DUPLICATED_TITLE:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/bill_duplicated_title.png',
  HUNTING_ALREADY_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/SCAN_LOGO_ALREADY_CONTENT.png',
  CHECK_IN_ALREADY_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/He%CC%82%CC%81t%20lu%CC%9Bo%CC%9B%CC%A3t.png',
  SHARE_FB: 'https://storage.googleapis.com/strongbow_miniapp/MeoChill/Share%20FB.png',
  TWO_CHILL: 'https://storage.googleapis.com/strongbow_miniapp/MeoChill/2_CHiLL.png',
  FOUR_CHILL: 'https://storage.googleapis.com/strongbow_miniapp/MeoChill/4_CHiLL.png',
  EIGHT_CHILL: 'https://storage.googleapis.com/strongbow_miniapp/MeoChill/8_CHiLL.png',
  ZALO_INSTRUCTION_TITLE:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/zalo-instruction-title.png',
  ZALO_INSTRUCTION_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/zalo-instruction-content.png',
  ZALO_INSTRUCTION_APPLE:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/zalo-instruction-apple.png',
  SCAN_BILL_REJECTED_EXCEED_48_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/scan_hon_48_chai.png',
  OOPS_TITLE: 'https://storage.googleapis.com/strongbow_miniapp/MeoChill/10K_title.png',
  EXCEED_10K_REDEEM_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/10K_content.png',
  WAIT_TITLE: 'https://storage.googleapis.com/strongbow_miniapp/MeoChill/update_id_title.png',
  ID_NOT_VERIFIED_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/update_id_content.png',
  ID_PENDING_CONTENT: 'https://storage.googleapis.com/strongbow_miniapp/MeoChill/CMND.png',
  EXPIRED_DATE_TITLE:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/Expired_Date_Title.png',
  EXPIRED_DATE_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/Expire_Date_Content.png',
  EXCEED_10K_REDEEM_NEW_CONTENT:
    'https://storage.googleapis.com/strongbow_miniapp/MeoChill/reject_gifts.png'
};

export const MODAL_STATES = {
  NONE: null,
  CHECK_IN_ALREADY: 1,
  SCAN_BILL_FAILED: 2,
  SUCCESS: 3,
  LOGIN: 4,
  ID_VERIFY_REQUIRED: 5,
  ID_VERIFY_PENDING: 6,
};
export const QR_CODE_TYPE = {
  NONE: null,
  SCAN_LOGO: 'SCAN_LOGO',
  HUNTING: 'HUNTING',
};

export const ERR_MSG_RESPONSE = {
  IDENTITY_PENDING: 'Thẻ CCCD/CMND của bạn đang chờ xét duyệt, vui lòng thử lại sau',
  IDENTITY_NOT_UPLOAD: 'Điểm bạn đã vượt mốc 500 điểm. Vui lòng cập nhật thẻ CCCD/CMND để tiếp tục tích điểm',
};
