import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  strongBowScanLogoApi,
  strongBowStoreCheckInApi,
  strongBowStoreShareFBApi,
} from 'apis/document';
import {
  createHuntingCodeRewardApi,
  getStoreDetailByCodeApi,
  shareFacebookApi,
  validateHuntingCodeApi,
} from 'apis/thirdParty';
import {toggleLoginModal} from 'appRedux/slices/appSlice';
import {updateModalState} from 'appRedux/slices/storeCheckinSlice';
import {MODAL_STATES} from 'constants/meoChill';

export const getStoreDetailByCodeAction = createAsyncThunk(
  'storeCheckin/getStoreDetailByCode',
  async (payload = {storeCode: '', callback: null}, {rejectWithValue, fulfillWithValue}) => {
    const {callback, storeCode} = payload;
    try {
      const res = await getStoreDetailByCodeApi(storeCode);
      if (!!res?.data && !!res?.isSuccess) {
        callback && callback(null, res.data);
        return fulfillWithValue(res.data);
      } else {
        throw new Error('Thông tin cửa hàng không hợp lệ!');
      }
    } catch (error) {
      callback && callback(error, null);

      return rejectWithValue(error?.message || 'Thông tin cửa hàng không hợp lệ!');
    }
  }
);

export const validateHuntingCodeAction = createAsyncThunk(
  'storeCheckin/validateHuntingCodeAction',
  async (
    payload = {code: '', callback: null},
    {rejectWithValue, fulfillWithValue, dispatch, getState}
  ) => {
    const {callback, code} = payload;
    try {
      const res = await validateHuntingCodeApi(code);
      console.log('🚀 ~ file: storeCheckin.js:45 ~ res', res);
      if (!!res?.data && !!res?.isSuccess) {
        const isLogIn = getState()?.auth?.isLogIn;
        if (isLogIn) {
          dispatch(createHuntingCodeRewardAction({code: res?.data?.qrCode}));
        } else {
          dispatch(updateModalState(MODAL_STATES.LOGIN));
        }
        callback && callback(null, res.data);
        return fulfillWithValue(res.data);
      } else {
        throw new Error('Thông tin cung cấp không hợp lệ!');
      }
    } catch (error) {
      callback && callback(error, null);

      return rejectWithValue(error?.message || 'Thông tin cung cấp không hợp lệ!');
    }
  }
);

export const scanLogoAction = createAsyncThunk(
  'storeCheckin/scanLogo',
  async (
    payload = {body: {}, callback: null, storeId: ''},
    {rejectWithValue, fulfillWithValue, dispatch, getState}
  ) => {
    const {callback, body, storeId} = payload;
    try {
      const res = await strongBowScanLogoApi(body);
      if (!!res && !!res?.isSuccess && !!res?.data) {
        const isLogIn = getState()?.auth?.isLogIn;
        if (isLogIn) {
          dispatch(storeCheckInAction({link: res?.data?.data, storeId}));
        } else {
          dispatch(updateModalState(MODAL_STATES.LOGIN));
          // dispatch(toggleLoginModal(true));
        }
        callback && callback(res?.data, null);
        return fulfillWithValue(res?.data);
      } else {
        throw new Error('Scan Logo thất bại!');
      }
    } catch (error) {
      callback && callback(null, error);
      return rejectWithValue(
        typeof error === 'string' ? error : error?.message || 'Có gì đó sai sai!'
      );
    }
  }
);

export const storeCheckInAction = createAsyncThunk(
  'storeCheckin/storeCheckIn',
  async (
    payload = {link: '', storeId: '', callback: null},
    {rejectWithValue, fulfillWithValue}
  ) => {
    const {callback, link, storeId} = payload;
    if (!link || !storeId) return;
    try {
      const res = await strongBowStoreCheckInApi({
        linkImage: link,
        strongbowStoreId: storeId,
      });
      if (!!res?.data && !!res?.isSuccess) {
        callback && callback(null, res.data);
        return fulfillWithValue(res.data);
      } else {
        throw new Error('Thông tin check in không hợp lệ!');
      }
    } catch (error) {
      callback && callback(error, null);

      return rejectWithValue(error);
    }
  }
);

export const createHuntingCodeRewardAction = createAsyncThunk(
  'storeCheckin/createHuntingCodeReward',
  async (payload = {code: '', callback: null}, {rejectWithValue, fulfillWithValue}) => {
    const {callback, code} = payload;
    if (!code) return;
    try {
      const res = await createHuntingCodeRewardApi({
        qrCode: code,
        merchantId: process.env.REACT_APP_MERCHANT_ID,
      });
      if (!!res?.data && !!res?.isSuccess) {
        callback && callback(null, res.data);
        return fulfillWithValue(res.data);
      } else {
        throw new Error('Thông tin không hợp lệ!');
      }
    } catch (error) {
      callback && callback(error, null);

      return rejectWithValue(error);
    }
  }
);

export const shareFacebookAction = createAsyncThunk(
  'storeCheckin/shareFacebook',
  async (
    payload = {logoUploadId: '', callback: null, transactionId: ''},
    {rejectWithValue, fulfillWithValue}
  ) => {
    const {callback, logoUploadId, transactionId} = payload;
    if (!!logoUploadId) {
      try {
        const res = await strongBowStoreShareFBApi({
          logoUploadId,
        });
        if (!!res?.data && !!res?.isSuccess) {
          callback && callback(null, res.data);
          return fulfillWithValue(res.data);
        } else {
          throw new Error('');
        }
      } catch (error) {
        callback && callback(error, null);
        return rejectWithValue(error);
      }
    }
    if (!!transactionId) {
      try {
        const res = await shareFacebookApi({
          transactionId,
        });
        console.log('🚀 ~ file: storeCheckin.js:177 ~ res', res);
        if (!!res?.isSuccess) {
          callback && callback(null, res.data);
          return fulfillWithValue(res.data);
        } else {
          throw new Error('');
        }
      } catch (error) {
        callback && callback(error, null);
        return rejectWithValue(error);
      }
    }
  }
);
