import {createSlice} from '@reduxjs/toolkit';

import {
  createHuntingCodeRewardAction,
  getStoreDetailByCodeAction,
  scanLogoAction,
  shareFacebookAction,
  storeCheckInAction,
  validateHuntingCodeAction,
} from 'appRedux/actions/storeCheckin';
import {MODAL_STATES, QR_CODE_TYPE, ERR_MSG_RESPONSE} from 'constants/meoChill';
import {toast} from 'react-toastify';

const initialState = {
  storeDetail: null,
  isScanningLogo: false,
  logoImage: null,
  modalState: MODAL_STATES.NONE,
  codeType: QR_CODE_TYPE.NONE,
  checkInReward: '',
  logoUploadId: '',
  isShareFB: false,
  isValidatingHuntingCode: false,
  huntingCode: '',
  transactionId: '',
  huntingData: null,
};

const storeCheckinSlice = createSlice({
  name: 'storeCheckin',
  initialState,
  reducers: {
    resetStoreCheckIn: (state, {}) => {
      state.storeDetail = null;
      state.logoImage = null;
      state.isScanningLogo = false;
      state.modalState = MODAL_STATES.NONE;
      state.isShareFB = false;
      state.logoUploadId = '';
      state.transactionId = '';
      state.codeType = QR_CODE_TYPE.NONE;
      state.checkInReward = '';
      state.huntingCode = '';
      state.huntingData = null;
      state.isValidatingHuntingCode = false;
    },
    updateModalState: (state, {payload}) => {
      if (payload === MODAL_STATES.NONE) {
        state.isShareFB = false;
        state.logoUploadId = '';
        state.transactionId = '';
      }
      state.modalState = payload;
    },
    updateCodeType: (state, {payload}) => {
      state.codeType = payload;
    },
  },
  extraReducers: {
    [getStoreDetailByCodeAction.pending]: (state, {payload}) => {},
    [getStoreDetailByCodeAction.fulfilled]: (state, {payload}) => {
      state.storeDetail = payload;
    },
    [getStoreDetailByCodeAction.rejected]: (state, {payload}) => {
      state.storeDetail = null;
    },
    [scanLogoAction.pending]: (state, {payload}) => {
      state.isScanningLogo = true;
    },
    [scanLogoAction.fulfilled]: (state, {payload}) => {
      state.isScanningLogo = false;
      state.logoImage = payload?.data;
    },
    [scanLogoAction.rejected]: (state, {payload}) => {
      state.isScanningLogo = false;
      state.modalState = MODAL_STATES.SCAN_BILL_FAILED;
    },
    [storeCheckInAction.pending]: (state, {payload}) => {
      state.isScanningLogo = true;
    },
    [storeCheckInAction.fulfilled]: (state, {payload}) => {
      if (!!payload?.data?.errorMessage || !payload?.data?.points) {
        toast(payload?.data?.errorMessage || 'Check in không thành công!', {type: 'error'});
      } else {
        state.isScanningLogo = false;
        state.modalState = MODAL_STATES.SUCCESS;
        state.checkInReward = payload?.data?.points || '';
        state.logoUploadId = payload?.data?.logoUploadId;
        //toast('Check In thành công!', {type: 'success'});
      }
    },
    [storeCheckInAction.rejected]: (state, {payload}) => {
      state.modalState =
        payload?.type === 1
          ? payload?.message === ERR_MSG_RESPONSE.IDENTITY_PENDING
            ? MODAL_STATES.ID_VERIFY_PENDING
            : payload?.message === ERR_MSG_RESPONSE.IDENTITY_NOT_UPLOAD
            ? MODAL_STATES.ID_VERIFY_REQUIRED
            : MODAL_STATES.CHECK_IN_ALREADY
          : MODAL_STATES.SCAN_BILL_FAILED;
      state.isScanningLogo = false;
    },
    [shareFacebookAction.fulfilled]: (state, {payload}) => {
      state.checkInReward = '2';
      state.isShareFB = true;
    },
    [validateHuntingCodeAction.pending]: (state, {payload}) => {
      state.isValidatingHuntingCode = true;
    },
    [validateHuntingCodeAction.fulfilled]: (state, {payload}) => {
      state.huntingCode = payload?.qrCode;
      state.huntingData = payload;
      state.isValidatingHuntingCode = false;
    },
    [validateHuntingCodeAction.rejected]: (state, {payload}) => {
      state.isValidatingHuntingCode = false;
      toast(payload, {type: 'error'});
    },
    [validateHuntingCodeAction.pending]: (state, {payload}) => {
      state.isValidatingHuntingCode = true;
    },
    [createHuntingCodeRewardAction.fulfilled]: (state, {payload}) => {
      const isScanned = payload?.isScanned;
      if (isScanned === true) {
        state.modalState = MODAL_STATES.CHECK_IN_ALREADY;
      } else if (isScanned === false) {
        state.modalState = MODAL_STATES.SUCCESS;
        state.checkInReward = payload?.rewardValue || 4;
        state.transactionId = payload?.transactionId;
      }
    },
    [createHuntingCodeRewardAction.rejected]: (state, {payload}) => {
      if (payload?.message === ERR_MSG_RESPONSE.IDENTITY_PENDING) {
        state.modalState = MODAL_STATES.ID_VERIFY_PENDING;
      } else if (payload?.message === ERR_MSG_RESPONSE.IDENTITY_NOT_UPLOAD) {
        state.modalState = MODAL_STATES.ID_VERIFY_REQUIRED;
      }
      toast(payload, {type: 'error'});
    },
  },
});

const {actions, reducer} = storeCheckinSlice;

export const {resetStoreCheckIn, updateModalState, updateCodeType} = actions;

export default reducer;
