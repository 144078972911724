import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Layout from '../../components/layout';
import {Link, useLocation, useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import Select from 'react-select';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {get} from 'lodash';
import {titleCase} from 'utils/stringUltils';
import ContactButton from 'components/contactButton';
import {toast} from 'react-toastify';
import {createDeliveryAction, getUserGiftDetailByIdAction} from 'appRedux/actions/app';
import {ROUTE_NAME} from 'constants/routes';
import image_banner from 'images/gift-info-title.png';
import bottle from 'images/strongbow/bottle.png';
import leaf from 'images/strongbow/pc/leaf.png';

const CREATE_FORM_SCHEME = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Vui lòng nhập số điện thoại')
    .matches(/^[0]/, 'Số điện thoại phải bắt đầu bằng số 0')
    .matches(
      /^[0][0-9]{9,9}\b|^[1-9][0-9]{9,9}\b|^[a-z][a-z0-9]{4,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/,
      'Số điện thoại không hợp lệ'
    ),
  fullName: Yup.string().required('Vui lòng nhập họ và tên'),
  address: Yup.string().required('Vui lòng nhập điạ chỉ cư trú'),
  wardCode: Yup.string().required('Vui lòng chọn phường/xã'),
  districtCode: Yup.string().required('Vui lòng chọn quận/huyện'),
  provinceCode: Yup.string().required('Vui lòng chọn tỉnh/thành'),
  personalIdentifier: Yup.string().required('Vui lòng chọn nhập CMND/CCCD'),
});

const InfoUserGift = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const info = location?.state?.info || null;
  const [dataProvince, setDataProvince] = React.useState([]);
  const [dataDistrict, setDataDistrict] = React.useState([]);
  const [dataWard, setDataWard] = React.useState([]);
  const [dataDistrictFilter, setDataDistrictFilter] = React.useState([]);
  const [dataWardFilter, setDataWardFilter] = React.useState([]);
  const [showPopupWin, setShowPopupWin] = React.useState(true);
  const [formData, setFormData] = React.useState({});

  React.useEffect(() => {
    dispatch(
      getUserGiftDetailByIdAction({
        id: params.id,
        callback: (data) => {
          setFormData(data?.data || null);
        },
      })
    );
    const getProvice = async () => {
      fetch(process.env.REACT_APP_VN_POST_PROVINCE)
        .then((res) => res.json())
        .then((data) => {
          let provinces = [];
          data.map((item) => {
            provinces.push({
              label: item.TenTinhThanh,
              value: item.MaTinhThanh,
            });
          });
          setDataProvince(provinces.sort((a, b) => a.label.localeCompare(b.label)));
        });
    };
    getProvice();
    const getDistrict = async () => {
      fetch(process.env.REACT_APP_VN_POST_DISTRICT)
        .then((res) => res.json())
        .then((data) => {
          let district = [];
          data.map((item) => {
            district.push({
              label: item.TenQuanHuyen,
              value: item.MaQuanHuyen,
              code: item.MaTinhThanh,
            });
          });
          setDataDistrict(district);
        });
    };
    getDistrict();
    const getWard = async () => {
      fetch(process.env.REACT_APP_VN_POST_WARD)
        .then((res) => res.json())
        .then((data) => {
          let wards = [];
          data.map((item) => {
            wards.push({
              value: item.MaPhuongXa,
              label: item.TenPhuongXa,
              code: item.MaQuanHuyen,
            });
          });
          setDataWard(wards);
        });
    };
    getWard();
  }, []);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      fullName: '',
      address: '',
      wardCode: '',
      districtCode: '',
      provinceCode: '',
      personalIdentifier: '',
      ...info,
    },
    enableReinitialize: true,

    validationSchema: CREATE_FORM_SCHEME,
    onSubmit: (values) => {
      // console.log('🚀 ~ file: infoUserGift.js ~ line 116 ~ InfoUserGift ~ values', values);
      // return;
      const payload = {
        fullName: titleCase(values?.fullName || ''),
        phoneNumber: values?.phoneNumber,
        address: values?.address,
        wardCode: values?.wardCode,
        districtCode: values?.districtCode,
        provinceCode: values?.provinceCode,
        personalIdentifier: values?.personalIdentifier,
      };
      handleCreaterGift(payload);
    },
  });
  const handleCreaterGift = (payload) => {
    dispatch(
      createDeliveryAction({
        body: {
          userGiftId: params.id,
          ReceiverInfo: payload,
        },
        callback: (error, res) => {
          if (error) {
            toast.error(error?.message || 'Không thành công! Vui lòng thử lại sau', {
              autoClose: 3000,
              position: 'top-center',
            });
          } else {
            navigate(`${ROUTE_NAME.USER_PROFILE}?tab=3`, {replace: true});
            toast('Gửi yêu cầu nhận thành công!', {
              autoClose: 3000,
              position: 'top-center',
              type: 'success',
            });
          }
        },
      })
    );
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: '#D6BA85 !important',
      borderRadius: 30,
      fontWeight: 500,
      color: '#FFF',
      fontSize: 16,
      boxShadow: state.isFocused ? '0 0 0 0.125em rgb(221 164 16 / 25%)' : 'unset',
    }),
    singleValue: (base, state) => ({
      ...base,
      color: 'rgb(54,54,54)',
      fontWeight: 400,
    }),
  };
  return (
    <Layout>
      <div className="container-chill usergift_info_detail">
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '30%',
            right: 0,
          }}
        >
          <img
            src={bottle}
            width={170}
            style={{
              transform: 'matrix(0.99, 0.14, -0.14, 0.99, 0, 0)',
            }}
          />
        </div>
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '20%',
            left: 0,
            marginLeft: -173,
          }}
        >
          <img src={leaf} width={412} style={{}} />
        </div>
        <div className="container">
          <div className="title-image has-text-centered">
            <img
              style={{
                width: '55%',
                maxWidth: 400,
              }}
              src={image_banner}
              alt="title"
            />
          </div>
          <div className="container-content">
            {/* <div
              className="gift-chill-bg"
              style={{
                marginTop: 100,
              }}
            >
              <div className="columns">
                <div
                  className="column has-text-centered"
                  style={{
                    color: '#fff',
                    fontSize: 20,
                    fontWeight: 700,
                    marginBottom: 30,
                  }}
                >
                  ĐỔI THÀNH CÔNG
                </div>
              </div>
            </div> */}
            <div
              className="has-text-centered"
              style={{
                fontSize: 14,
                color: '#343434',
                fontWeight: 500,
              }}
            >
              <span>Ưu đãi: </span>
              <span
                className=" is-size-6 is-size-5-tablet"
                style={{color: '#704315', fontWeight: 700}}
              >
                {formData.name}
              </span>
              {!info && (
                <div
                  style={{
                    color: '#1D1D1D',
                    paddingTop: 16,
                  }}
                >
                  Bạn vui lòng điền thông tin nhận quà nhé!
                </div>
              )}
            </div>
            <div
              className="columns"
              style={{
                marginTop: 15,
              }}
            >
              <div className="column is-8-tablet is-offset-2-tablet is-half-desktop is-offset-3-desktop">
                <div class="field custom-input1">
                  <label className="label">
                    Họ và tên<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input input-is-custom"
                      type="text"
                      placeholder="Họ và tên"
                      value={formik.values.fullName}
                      onChange={(e) => {
                        formik.setFieldTouched('fullName', true, true);
                        formik.setFieldValue('fullName', e.target.value);
                      }}
                      disabled={!!info}
                    />
                    {get(formik.touched, 'fullName', false) && !!get(formik.errors, 'fullName') && (
                      <p className="help mb-0 is-danger">
                        <span>{get(formik.errors, 'fullName')}</span>
                      </p>
                    )}
                  </div>
                </div>
                <div class="field custom-input1">
                  <label className="label">
                    Số điện thoại liên hệ<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input input-is-custom"
                      type="tel"
                      maxLength={10}
                      placeholder="Số điện thoại liên hệ"
                      value={formik.values.phoneNumber}
                      onChange={(e) => {
                        formik.setFieldTouched('phoneNumber', true, true);
                        formik.setFieldValue('phoneNumber', e.target.value);
                      }}
                      disabled={!!info}
                    />
                    {get(formik.touched, 'phoneNumber', false) &&
                      !!get(formik.errors, 'phoneNumber') && (
                        <p className="help mb-0 is-danger">
                          <span>{get(formik.errors, 'phoneNumber')}</span>
                        </p>
                      )}
                  </div>
                </div>

                <div class="field custom-input1">
                  <label className="label">
                    CMND/CCCD<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input input-is-custom"
                      type="number"
                      placeholder="CMND/CCCD"
                      value={formik.values.personalIdentifier}
                      onChange={(e) => {
                        formik.setFieldTouched('personalIdentifier', true, true);
                        formik.setFieldValue('personalIdentifier', e.target.value);
                      }}
                      disabled={!!info}
                    />
                    {get(formik.touched, 'personalIdentifier', false) &&
                      !!get(formik.errors, 'personalIdentifier') && (
                        <p className="help mb-0 is-danger">
                          <span>{get(formik.errors, 'personalIdentifier')}</span>
                        </p>
                      )}
                  </div>
                </div>
                <div class="field">
                  <label className="label">
                    Tỉnh/Thành<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <Select
                      placeholder="Chọn Tỉnh/Thành"
                      options={dataProvince}
                      styles={customStyles}
                      className="select-is-custom"
                      value={
                        dataProvince
                          ? dataProvince.find(
                              (option) => option.value === formik.values.provinceCode
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('provinceCode', e.value, true).then(() => {
                          formik.setFieldTouched('provinceCode', true);
                          formik.setFieldValue('districtCode', '');
                          setDataDistrictFilter(dataDistrict.filter((f) => f.code === e.value));
                        });
                      }}
                      isDisabled={!!info}
                    />
                    {get(formik.touched, 'provinceCode', false) &&
                      !!get(formik.errors, 'provinceCode') && (
                        <p className="help mb-0 is-danger">
                          <span>{get(formik.errors, 'provinceCode')}</span>
                        </p>
                      )}
                  </div>
                </div>
                <div class="field">
                  <label className="label">
                    Quận/Huyện<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <Select
                      placeholder="Chọn Quận/Huyện"
                      styles={customStyles}
                      options={dataDistrictFilter}
                      className="select-is-custom"
                      value={
                        formik.values.districtCode
                          ? dataDistrict?.find(
                              (option) => option.value === formik.values.districtCode
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('districtCode', e.value).then(() => {
                          formik.setFieldTouched('districtCode', true, true);
                          formik.setFieldValue('wardCode', '');
                          setDataWardFilter(dataWard.filter((f) => f.code == e.value));
                        });
                      }}
                      isDisabled={!formik.values.provinceCode || !!info}
                    />
                    {get(formik.touched, 'districtCode', false) &&
                      !!get(formik.errors, 'districtCode') && (
                        <p className="help mb-0 is-danger">
                          <span>{get(formik.errors, 'districtCode')}</span>
                        </p>
                      )}
                  </div>
                </div>
                <div class="field">
                  <label className="label">
                    Phường/Xã<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <Select
                      placeholder="Chọn Phường/Xã"
                      options={dataWardFilter}
                      styles={customStyles}
                      className="select-is-custom"
                      value={
                        formik.values.wardCode
                          ? dataWard?.find((option) => option.value === formik.values.wardCode)
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldTouched('wardCode', true, true);
                        formik.setFieldValue('wardCode', e.value);
                      }}
                      isDisabled={!formik.values.districtCode || !!info}
                    />
                    {get(formik.touched, 'wardCode', false) && !!get(formik.errors, 'wardCode') && (
                      <p className="help mb-0 is-danger">
                        <span>{get(formik.errors, 'wardCode')}</span>
                      </p>
                    )}
                  </div>
                </div>
                <div class="field custom-input1">
                  <label className="label">
                    Địa chỉ cư trú<span style={{color: 'red'}}>*</span>
                  </label>
                  <div className="control">
                    <input
                      className="input input-is-custom"
                      type="text"
                      placeholder="Địa chỉ cư trú"
                      value={formik.values.address}
                      onChange={(e) => {
                        formik.setFieldTouched('address', true, true);
                        formik.setFieldValue('address', e.target.value);
                      }}
                      disabled={!!info}
                    />
                    {get(formik.touched, 'address', false) && !!get(formik.errors, 'address') && (
                      <p className="help mb-0 is-danger">
                        <span>{get(formik.errors, 'address')}</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!info && (
              <>
                <div className="columns">
                  <div className="column has-text-centered">
                    <button
                      className="button is-primary-custom"
                      style={{
                        color: '#FFF',
                        padding: '14px 50px',
                        fontSize: 16,
                        fontWeight: 700,
                        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                        width: 250,
                      }}
                      onClick={() => {
                        formik.submitForm();
                      }}
                    >
                      GỬI YÊU CẦU CHUYỂN QUÀ
                    </button>
                  </div>
                </div>
                {/* <div className="columns">
                  <div className="column has-text-centered">
                    <a href="/chill">
                      <button
                        className="button is-second-custom"
                        style={{
                          color: '#83552D',
                          padding: '14px 50px',
                          fontSize: 16,
                          fontWeight: 700,
                          textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                          width: 250,
                        }}
                      >
                        NHẬN QUÀ SAU
                      </button>
                    </a>
                  </div>
                </div> */}
              </>
            )}
            {true && (
              <>
                <div className="columns">
                  <div className="column has-text-centered">
                    <button
                      className="button is-second-custom is-capitalized"
                      style={{
                        color: '#FFF',
                        padding: '14px 50px',
                        fontSize: 16,
                        fontWeight: 700,
                        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                        width: 250,
                      }}
                      onClick={() => {
                        navigate(-1);
                        // formik.submitForm();
                      }}
                    >
                      TRỞ VỀ
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className="mt-3">
              <ContactButton />
            </div>
          </div>
        </div>
      </div>

      {/* <Modal show={showPopupWin} closeOnBlur closeOnEsc onClose={handleCloseModal}>
        <Modal.Card style={{maxWidth: 400}}></Modal.Card>
      </Modal> */}
    </Layout>
  );
};
export default InfoUserGift;
