import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './styles.scss';
import {Modal} from 'react-bulma-components';
import {resetReferralInfo, resetModal as resetModalAction} from 'appRedux/slices/appSlice';
import {useFormik} from 'formik';
import classNames from 'classnames';
import OtpInput from 'react18-input-otp';
import moment from 'moment';
import {get} from 'lodash';
import {
  generateUserGiftCodeAction,
  getDistrictByProvinceCodeAction,
  getProvincesAction,
  getUserReferralInfoByPhoneNumber,
  getWardByDistrictCodeAction,
} from 'appRedux/actions/app';
import Select from 'react-select';
import {COLORS} from 'constants/colors';
import exportAsImage, {getSelectOptions} from 'helpers';
import {
  REGISTER_FORM_SCHEME,
  MODAL_STATES,
  REGISTER_INPUTS,
  MODAL_WIDTHS,
  LOGIN_FORM_SCHEME,
  EDIT_INPUTS,
  EDIT_FORM_SCHEME,
  POPUP_STYLE,
} from './constanst';
import {Link, useNavigate} from 'react-router-dom';
import {ROUTE_NAME} from 'constants/routes';
import {QRCodeSVG} from 'qrcode.react';
import iconCongrats from 'images/icon_congrats.png';
import {userInfoSelector} from 'appRedux/selectors/auth';
import {GENDER_OPTIONS} from 'constants/gender';
import {userProfileModalSelector} from 'appRedux/selectors/app';
import {
  generateOTPAction,
  registerAction,
  updateUserProfileAction,
  verifyOTPAction,
  generateOTPStrongBowAction,
  loginStrogBowAction,
} from 'appRedux/actions/auth';
import Countdown from 'react-countdown';
import {OTP_TYPES} from 'constants/otp';
import {REFERRAL_TYPES} from 'constants/referral';
import {titleCase} from 'utils/stringUltils';
import icon_clear from 'images/strongbow/icon_clear.png';
import hello_title from 'images/strongbow/hello.png';
import register_title from 'images/strongbow/register_title.png';
import otp_title from 'images/strongbow/OTP.png';
import success from 'images/strongbow/gift_success.png';
import {toast} from 'react-toastify';
import {
  huntingCodeSelector,
  logoImageSelector,
  storeCheckinDetailSelector,
} from 'appRedux/selectors/storeCheckin';
import {getRewardInfoAction} from 'appRedux/actions/app';
import {createHuntingCodeRewardAction, storeCheckInAction} from 'appRedux/actions/storeCheckin';
import {getCitizenIdByMerchantIdAction} from 'appRedux/actions/auth';
import {localStorageSetItem} from 'utils/storage';

import {MODAL_STATES as MEOCHILL_MODAL_STATES} from 'constants/meoChill';
import {updateModalState} from 'appRedux/slices/storeCheckinSlice';

const COUNTDOWN_DELAY = 30000;

const UserProfileModal = ({phoneNumber}) => {
  const userProfileModalData = useSelector(userProfileModalSelector);
  const show = userProfileModalData?.show;
  const provinces = useSelector((state) => state.app.masterData.provinces || []);
  const referralInfo = userProfileModalData?.referralInfo;
  const reduxModalState = userProfileModalData?.modalState;
  const userInfo = useSelector(userInfoSelector);

  const logoImage = useSelector(logoImageSelector);
  const huntingCode = useSelector(huntingCodeSelector);
  const storeDetail = useSelector(storeCheckinDetailSelector);

  const userGift = userProfileModalData?.userGift;

  const [modalState, setModalState] = useState('');
  const [currentRequestType, setCurrentRequestType] = useState('');

  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [otp, setOtp] = useState('');

  const [qrCodeData, setQRCodeData] = useState(null);

  const qrRef = useRef();
  const [countDownKey, setCountDownKey] = useState(0);
  const [countDownDate, setCountDownDate] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(resetModalAction());
  };
  useEffect(() => {
    setModalState(reduxModalState);
    dispatch(getRewardInfoAction());
    if (reduxModalState === MODAL_STATES.QR_CODE && userGift) {
      dispatch(
        generateUserGiftCodeAction({
          userGiftId: userGift.id,
          callback: (data) => {
            setQRCodeData(data);
          },
        })
      );
    }
  }, [show]);

  useEffect(() => {
    if (modalState === MODAL_STATES.REGISTER) {
      setCountDownDate(Date.now() + COUNTDOWN_DELAY);
      setCountDownKey(countDownKey + 1);
    }
  }, [modalState]);

  useEffect(() => {
    if (show) {
      if (reduxModalState === MODAL_STATES.LOGIN && !!phoneNumber) {
        dispatch(getUserReferralInfoByPhoneNumber({phoneNumber}));
      } else if (reduxModalState === MODAL_STATES.EDIT) {
        dispatch(
          getDistrictByProvinceCodeAction({
            provinceCode: userInfo?.province,
            callback: (data) => {
              const items = getSelectOptions(data, 'code', 'name');
              setDistricts(items);
              editFormik.setFieldValue(
                'district',
                items.find((item) => item?.code === userInfo?.district)
              );
            },
          })
        );
        dispatch(
          getWardByDistrictCodeAction({
            districtCode: userInfo?.district,
            callback: (data) => {
              const items = getSelectOptions(data, 'code', 'name');
              setWards(items);
              editFormik.setFieldValue(
                'ward',
                items.find((item) => item?.code === userInfo?.ward) || null
              );
            },
          })
        );
      }
    }
    if (!show) {
      resetModal();
    } else if (!provinces?.length) {
      dispatch(getProvincesAction());
    }
  }, [show, provinces?.length, reduxModalState]);

  const onChangeProvince = (provinceCode) => {
    formik.setFieldValue('district', null);
    formik.setFieldValue('ward', null);
    editFormik.setFieldValue('district', null);
    editFormik.setFieldValue('ward', null);
    setDistricts([]);
    setWards([]);
    dispatch(
      getDistrictByProvinceCodeAction({
        provinceCode,
        callback: (data) => {
          setDistricts(getSelectOptions(data, 'code', 'name'));
        },
      })
    );
  };

  const onChangeDistrict = (districtCode) => {
    formik.setFieldValue('ward', null);
    editFormik.setFieldValue('ward', null);
    setWards([]);
    dispatch(
      getWardByDistrictCodeAction({
        districtCode,
        callback: (data) => {
          setWards(getSelectOptions(data, 'code', 'name'));
        },
      })
    );
  };

  const handleResendOTP = (phoneNumber) => {
    dispatch(
      generateOTPAction({
        phoneNumber,
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      fullName: '',
    },
    enableReinitialize: true,
    validationSchema: REGISTER_FORM_SCHEME,
    onSubmit: (values) => {
      setModalState(MODAL_STATES.OPTIONOTP);
    },
  });

  const editFormik = useFormik({
    initialValues: {
      fullName: userInfo?.fullName,
      dateOfBirth: userInfo?.dateOfBirth ? moment(userInfo?.dateOfBirth).format('YYYY-MM-DD') : '',
      gender: GENDER_OPTIONS.find((item) => item.value === userInfo?.gender),
      email: userInfo?.email,
      province: provinces.find((item) => item?.code === userInfo?.province) || null,
      district: null, // districts.find((item) => item?.code === userInfo?.district) || null,
      ward: null, //wards.find((item) => item?.code === userInfo?.ward) || null,
      address: userInfo?.address,
    },
    enableReinitialize: true,

    validationSchema: EDIT_FORM_SCHEME,
    onSubmit: (values) => {
      const payload = {
        fullName: titleCase(values?.fullName || ''),
        gender: values?.gender?.value,
        dateOfBirth: moment(values?.dateOfBirth).format('YYYY-MM-DD'),
        email: values?.email,
        province: values?.province?.value,
        district: values?.district?.value,
        ward: values?.ward?.value,
        address: values?.address,
      };
      dispatch(updateUserProfileAction(payload));
    },
  });

  const loginFormik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    enableReinitialize: true,

    validationSchema: LOGIN_FORM_SCHEME,
    onSubmit: (values, actions) => {
      if (values.phoneNumber.length < 10 || values.phoneNumber.length > 10) {
        actions.setErrors({phoneNumber: 'Số điện thoại không hợp lệ'});
      } else {
        handleGenerateOtp(values.phoneNumber, true);
      }
    },
  });

  const handleGenerateOtp = (phoneNumber, shouldCallback = false) => {
    if (!phoneNumber) return;
    dispatch(
      loginStrogBowAction({
        phoneNumber,
        callback: shouldCallback
          ? (otpType) => {
              switch (otpType) {
                case OTP_TYPES.QUICK_LOGIN:
                  setModalState(MODAL_STATES.OPTIONOTP);
                  break;
                case OTP_TYPES.REGISTER:
                  setModalState(MODAL_STATES.REGISTER);
                  break;

                default:
                  break;
              }
            }
          : null,
      })
    );
  };
  const handleGenerateOtpStrongbow = (requestType) => {
    setCurrentRequestType(requestType);
    setIsLoading(true);
    dispatch(
      generateOTPStrongBowAction({
        phoneNumber: getLoginPhoneNumber(),
        fullName: getLoginFullName() ? titleCase(getLoginFullName()) : '',
        requestType,
        callback: (otpType) => {
          switch (otpType) {
            case OTP_TYPES.QUICK_LOGIN:
              setModalState(MODAL_STATES.OTP);
              break;
            case OTP_TYPES.REGISTER:
              setModalState(MODAL_STATES.REGISTER);
              break;
            default:
              break;
          }
        },
      })
    );
    setIsLoading(false);
  };
  const resetModal = () => {
    formik.resetForm();
    loginFormik.resetForm();
    editFormik.resetForm();
    setOtp('');
    setQRCodeData(null);
    setDistricts([]);
    setWards([]);
    dispatch(resetReferralInfo());
    setCurrentRequestType('');
  };

  const handleSubmitOpt = () => {
    if (otp.length === 6) {
      dispatch(
        verifyOTPAction({
          phoneNumber: getLoginPhoneNumber(),
          otp,
          callback: async (res) => {
            // debugger;
            if (res?.accessToken) {
              const link = logoImage;
              const storeId = storeDetail?.id;

              // debugger;
              // console.log(userRewardData);

              if (res) {
                setOtp('');

                const [rewardInfo, citizenId] = await Promise.allSettled([
                  dispatch(getRewardInfoAction()).unwrap(),
                  dispatch(getCitizenIdByMerchantIdAction()).unwrap(),
                ]).then((res) => res.map((item) => item.value));

                if (
                  (citizenId?.data === null || citizenId?.data?.status !== 1) &&
                  rewardInfo?.totalRewardsAvailable >= 400 &&
                  rewardInfo?.totalRewardsAvailable <= 500
                ) {
                  localStorageSetItem('warning-policy', true);
                } else {
                  localStorageSetItem('warning-policy', false);
                }
                if (!!link && !!storeId) {
                  if (
                    rewardInfo?.totalRewardsAvailable > 500 &&
                    (!citizenId?.data || citizenId?.data?.status === 99)
                  ) {
                    // show id required popup
                    dispatch(updateModalState(MEOCHILL_MODAL_STATES.ID_VERIFY_REQUIRED));
                  } else if (
                    rewardInfo?.totalRewardsAvailable > 500 &&
                    citizenId?.data?.status === 0
                  ) {
                    // show pending id popup
                    dispatch(updateModalState(MEOCHILL_MODAL_STATES.ID_VERIFY_PENDING));
                  } else {
                    dispatch(
                      storeCheckInAction({
                        link,
                        storeId,
                      })
                    );
                  }
                }
              }
              if (!!huntingCode) {
                dispatch(createHuntingCodeRewardAction({code: huntingCode}));
              }
            }
          },
        })
      );
    }
  };

  const getLoginPhoneNumber = () => {
    return loginFormik.values.phoneNumber || userProfileModalData.phoneNumber;
  };
  const getLoginFullName = () => {
    return formik.values.fullName || userProfileModalData.fullName;
  };

  const countDownRenderer = ({seconds, completed}) => {
    if (completed) {
      // Render a completed state
      return (
        <a
          className="link has-text-primary has-text-weight-medium is-underlined"
          onClick={() => {
            setCountDownDate(Date.now() + COUNTDOWN_DELAY);
            setCountDownKey(countDownKey + 1);
            handleGenerateOtp(getLoginPhoneNumber());
          }}
        >
          Gửi lại mã OTP
        </a>
      );
    } else {
      // Render a countdown
      return (
        <span>
          Gửi lại sau{' '}
          <span className=" has-text-warning has-text-weight-semibold">{`${seconds} giây`}</span>{' '}
          (tối đa 5 lần)
        </span>
      );
    }
  };

  const renderEditProfileContent = () => {
    const locationOptions = {
      province: getSelectOptions(provinces, 'code', 'shortName'),
      district: districts,
      ward: wards,
      gender: GENDER_OPTIONS,
    };
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold">
          Sửa thông tin
        </div>
        <div className="title is-5 has-text-black has-text-weight-bold mb-2">
          Số điện thoại đăng ký <span className="has-text-primary">{'0907090909'}</span>
        </div>
        <div>
          <div className="columns is-multiline">
            {EDIT_INPUTS.map(
              (
                {
                  type,
                  label,
                  placeholder,
                  fieldName,
                  isFull,
                  maxLength,
                  isRequired,
                  isDisabled,
                  className,
                },
                index
              ) => {
                if (type === 'select') {
                  return (
                    <div className="field column is-6" key={`edit_input_${index}`}>
                      <label className="label">
                        {label} {isRequired && <span className=" has-text-danger">*</span>}
                      </label>
                      <div className="control">
                        <Select
                          placeholder={label}
                          options={get(locationOptions, fieldName, [])}
                          key={`edit_input_${index}`}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: COLORS.primary,
                              primary25: COLORS.primary25,
                              primary50: COLORS.primary50,
                              primary75: COLORS.primary75,
                            },
                          })}
                          onChange={(item) => {
                            editFormik.setFieldTouched(fieldName, true, true);
                            if (item?.value !== get(editFormik.values, fieldName, '')?.value) {
                              if (fieldName === 'province') {
                                onChangeProvince(item?.value);
                              } else if (fieldName === 'district') {
                                onChangeDistrict(item?.value);
                              }
                              editFormik.setFieldValue(fieldName, item);
                            }
                          }}
                          value={get(editFormik.values, fieldName, '')}
                        />
                      </div>
                      {get(editFormik.touched, fieldName, false) &&
                        !!get(editFormik.errors, fieldName) && (
                          <p className="help mb-0 is-danger">
                            <span>{get(editFormik.errors, fieldName)}</span>
                          </p>
                        )}
                    </div>
                  );
                }
                return (
                  <div
                    className={classNames('field column is-6', {
                      'is-12': isFull,
                    })}
                    key={`edit_input_${index}`}
                  >
                    <label className="label">
                      {label} {isRequired && <span className=" has-text-danger">*</span>}
                    </label>
                    <div className="control">
                      <input
                        className={`input ${className || ''}`}
                        maxLength={maxLength}
                        value={get(editFormik.values, fieldName, '')}
                        type={type}
                        placeholder={placeholder}
                        onChange={(e) => {
                          editFormik.setFieldTouched(fieldName, true, true);
                          editFormik.setFieldValue(fieldName, e.target.value);
                        }}
                        disabled={isDisabled}
                      />
                    </div>
                    {get(editFormik.touched, fieldName, false) &&
                      !!get(editFormik.errors, fieldName) && (
                        <p className="help mb-0 is-danger">
                          <span>{get(editFormik.errors, fieldName)}</span>
                        </p>
                      )}
                  </div>
                );
              }
            )}
            <div className="column is-12 has-text-centered">
              <button
                className="button button-small mr-3 is-primary is-outlined is-disabled"
                onClick={handleCloseModal}
              >
                Quay về
              </button>
              <button
                className={classNames('button button-small is-primary', {
                  'is-loading': userProfileModalData?.isUpdating,
                })}
                onClick={() => {
                  // editFormik.submitForm();
                  editFormik.handleSubmit();
                }}
                disabled={
                  !editFormik.isValid || !editFormik.dirty || userProfileModalData?.isUpdating
                }
              >
                Cập nhật
              </button>
            </div>
          </div>
        </div>
      </Modal.Card.Body>
    );
  };

  const renderRegisterSuccessContent = () => {
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold is-uppercase">
          Đăng ký thành công!
        </div>
        <div className="has-text-centered mb-5">
          <img src={iconCongrats} width={200} alt="logo" />
        </div>
        <div className="has-text-centered has-text-black has-text-weight-medium mb-5">
          Chào mừng bạn đến với{' '}
          <span className="has-text-primary">Chương Trình Thành Viên NUTIPOINT.</span> Hãy bắt đầu
          tích điểm để quy đổi vô vàn quà tặng!
        </div>
        <div>
          <div className="columns is-multiline">
            <div className="column is-12 has-text-centered">
              <Link to={ROUTE_NAME.GIFT}>
                <button className="button mr-3 is-primary  is-fullwidth" onClick={handleCloseModal}>
                  Xem Ưu đãi & Quà
                </button>
              </Link>
            </div>
            <div className="column is-12 has-text-centered">
              <Link to={ROUTE_NAME.USER_PROFILE}>
                <button
                  className="button is-primary is-outlined is-fullwidth"
                  onClick={handleCloseModal}
                >
                  Tài khoản
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Modal.Card.Body>
    );
  };

  // canhlv
  const renderLoginContent = () => {
    return (
      <>
        <header className="is-flex is-justify-content-center" style={{position: 'relative'}}>
          <img src={hello_title} alt="title" className="hello-title-img" />
          <button
            className="delete"
            aria-label="close"
            onClick={handleCloseModal}
            style={get(POPUP_STYLE, modalState)}
          ></button>
        </header>

        <div className="userbg pl-5" style={{paddingTop: 70, marginTop: -50}}>
          <div>
            <div className="has-text-centered has-text-black mb-5 pl-2 title-popup-login">
              Chiller ơi, nhập số điện thoại để tiếp tục nhé!
            </div>
            <form
              onSubmit={() => {
                loginFormik.submitForm();
              }}
            >
              <div className="columns is-multiline column-is-custom">
                <div className="field column is-12 pl-6 pr-6 mb-0">
                  <div className="control has-icons-right has-icons-right-custom">
                    <input
                      className="input control input-is-custom"
                      maxLength={10}
                      value={get(loginFormik.values, 'phoneNumber', '')}
                      type={'tel'}
                      placeholder={'Nhập số điện thoại'}
                      ref={(input) => input && input.focus()}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          loginFormik.setFieldTouched('phoneNumber', true, true);
                          loginFormik.setFieldValue('phoneNumber', e.target.value);
                        }
                      }}
                      style={{borderColor: '#D6BA85'}}
                    />

                    {!!loginFormik?.values?.phoneNumber?.length && (
                      <span
                        className="icon is-small is-right"
                        style={{
                          pointerEvents: 'all',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          loginFormik.resetForm();
                        }}
                      >
                        <img
                          src={icon_clear}
                          style={{
                            height: 18,
                          }}
                        />
                      </span>
                    )}
                  </div>
                  <div
                    className="help is-danger"
                    style={{
                      height: 10,
                    }}
                  >
                    {get(loginFormik.touched, 'phoneNumber', false) &&
                      !!get(loginFormik.errors, 'phoneNumber') && (
                        <div>
                          <span>{get(loginFormik.errors, 'phoneNumber')}</span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="column is-12 has-text-centered pb-6 pl-6 pr-6 mt-0">
                  <button
                    className={classNames(' button button-popup-login is-clickable is-uppercase', {
                      'is-primary-custom': loginFormik.isValid,
                      'has-text-white': true,
                      'is-defult-custom': !loginFormik.isValid,
                      'is-loading': userProfileModalData?.isLoggingIn,
                    })}
                    disabled={!loginFormik.isValid || userProfileModalData?.isLoggingIn}
                    onClick={(e) => {
                      e.preventDefault();
                      loginFormik.submitForm();
                    }}
                  >
                    Tiếp tục
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };
  const renderRegisterContent = () => {
    return (
      <>
        <div className=" is-flex is-justify-content-center" style={{position: 'relative'}}>
          <img src={register_title} alt="title" className="register-title-img" />
          <button
            className="delete"
            aria-label="close"
            onClick={handleCloseModal}
            style={get(POPUP_STYLE, modalState)}
          ></button>
        </div>
        <div className="userbg pl-5" style={{paddingTop: 70, marginTop: -60}}>
          <form
            onSubmit={() => {
              formik.submitForm();
            }}
          >
            <div className="columns is-multiline column-is-custom">
              <div className="field column is-12 pl-6 pr-6 pt-5">
                <div className="control has-icons-right has-icons-right-custom ">
                  <input
                    className="input control input-is-custom is-capitalized"
                    // maxLength={10}
                    value={get(formik.values, 'fullName', '')}
                    // type={'tel'}
                    placeholder={'Nhập tên '}
                    ref={(input) => input && input.focus()}
                    onChange={(e) => {
                      formik.setFieldTouched('fullName', true, true);
                      formik.setFieldValue('fullName', e.target.value);
                    }}
                    style={{borderColor: '#D6BA85'}}
                  />

                  {!!formik?.values?.fullName?.length && (
                    <span
                      className="icon is-small is-right"
                      style={{
                        pointerEvents: 'all',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        formik.resetForm();
                      }}
                    >
                      <img
                        src={icon_clear}
                        style={{
                          height: 18,
                        }}
                      />
                    </span>
                  )}
                </div>
                {get(formik.touched, 'fullName', false) && !!get(formik.errors, 'fullName') && (
                  <p className="help mb-0 is-danger">
                    <span>{get(formik.errors, 'fullName')}</span>
                  </p>
                )}
              </div>
              <div className="column is-12 has-text-centered pb-6 pl-6 pr-6">
                <button
                  className={classNames(
                    ' button button-popup-login is-clickable is-uppercase py-4',
                    {
                      'is-primary-custom': true,
                      'has-text-white': true,
                    }
                  )}
                  // disabled={!loginFormik.isValid || userProfileModalData?.isLoggingIn}
                  onClick={(e) => {
                    // setModalState(MODAL_STATES.OTP);
                    e.preventDefault();
                    formik.submitForm();
                    // loginFormik.handleSubmit();
                  }}
                  // onClick={handleShowLoginModal}
                >
                  Tiếp tục
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  };
  const renderOptionOTP = () => {
    return (
      <>
        <div className=" is-flex is-justify-content-center" style={{position: 'relative'}}>
          <img src={otp_title} alt="title" className="otp-title-img" />
          <button
            className="delete"
            aria-label="close"
            onClick={handleCloseModal}
            style={get(POPUP_STYLE, modalState)}
          ></button>
        </div>
        <div className="userbg" style={{paddingTop: 70, marginTop: -60}}>
          <div className="has-text-centered pt-5 pl-4 title-popup-otp">
            <div>Mã xác thực OTP sẽ được gửi qua</div>
            <div style={{marginBottom: 24}}>
              App Zalo của SĐT{' '}
              <span class="has-text-weight-bold" style={{fontSize: 19}}>
                {getLoginPhoneNumber()}
              </span>
            </div>
            <div className="columns">
              <div className="column is-12">
                <button
                  className={classNames(' button button-popup-login is-primary-custom', {
                    'is-loading': userProfileModalData.isGeneratingOTP,
                  })}
                  onClick={() => handleGenerateOtpStrongbow('STRONGBOW_ZNS')}
                  disabled={userProfileModalData.isGeneratingOTP}
                >
                  TIẾP TỤC
                </button>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12">
                <button
                  className={classNames(' button button-popup-login is-second-custom', {
                    'is-loading': userProfileModalData.isGeneratingOTP,
                  })}
                  style={{
                    color: '#83552D',
                  }}
                  onClick={() => handleGenerateOtpStrongbow('STRONGBOW_SMS')}
                  disabled={userProfileModalData.isGeneratingOTP}
                >
                  GỬI QUA SMS
                </button>
              </div>
            </div>
            <div className="columns">
              <div className="column pb-5">
                <a
                  className="is-primary has-text-primary is-underlined has-text-weight-bold"
                  onClick={handleCloseModal}
                >
                  <span>Trở về</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderOtpContent = () => {
    return (
      <>
        <div className=" is-flex is-justify-content-center" style={{position: 'relative'}}>
          <img src={otp_title} alt="title" className="otp-title-img" />
          <button
            className="delete"
            aria-label="close"
            onClick={handleCloseModal}
            style={get(POPUP_STYLE, modalState)}
          ></button>
        </div>
        <div
          className="userbg"
          style={{paddingTop: 70, marginTop: -60, paddingLeft: 28, paddingRight: 15}}
        >
          <div
            className="has-text-centered has-text-weight-bold pt-3"
            style={{color: '#343434', fontSize: 18, paddingBottom: 16}}
          >
            Nhập mã xác thực OTP
          </div>
          <div className="has-text-centered " style={{paddingBottom: 16, color: '#343434'}}>
            <div style={{fontWeight: 500}}>Nhập mã OTP được gửi tới</div>
            <div style={{fontSize: 28}} className="has-text-weight-bold">
              {getLoginPhoneNumber()}
            </div>
          </div>

          <div>
            <div className="columns is-multiline">
              <div className="column is-12" style={{paddingLeft: 25}}>
                <div>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    isInputNum
                    inputStyle="inputStyle p-1 has-text-primary"
                    focusStyle="focusStyle"
                    shouldAutoFocus
                    onSubmit={handleSubmitOpt}
                    containerStyle="is-justify-content-space-between"
                  />
                </div>
              </div>
              <div className="column is-12 has-text-centered">
                <a
                  className="is-primary has-text-primary is-underlined has-text-weight-bold"
                  onClick={() => {
                    handleGenerateOtpStrongbow(currentRequestType);
                  }}
                >
                  <span>Gửi lại mã OTP</span>
                </a>
              </div>
              <div className="column is-12 has-text-centered pb-5">
                <button
                  className={classNames('button button-popup-login is-primary-custom', {
                    'is-loading': userProfileModalData?.isOTPVerifying,
                  })}
                  disabled={otp?.length !== 6}
                  onClick={handleSubmitOpt}
                >
                  TIẾP TỤC
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  //  -- canhlv
  const renderQRCodeContent = () => {
    return (
      <>
        <div className=" is-flex is-justify-content-center" style={{position: 'relative'}}>
          <img src={success} alt="title" style={{width: '80%', zIndex: 1}} />
          <button
            className="delete"
            aria-label="close"
            onClick={handleCloseModal}
            style={get(POPUP_STYLE, modalState)}
          ></button>
        </div>
        <div>
          <div className="list-bg-home">
            <div
              style={{
                paddingTop: 40,
                paddingBottom: 10,
                marginTop: -70,
              }}
            >
              <div
                style={{
                  width: 255,
                  minHeight: 520,
                  margin: 'auto',
                }}
              >
                {true && (
                  <div ref={qrRef}>
                    <table
                      className="table is-fullwidth"
                      style={{
                        marginTop: 32,
                        marginBottom: 16,
                      }}
                    >
                      <tr>
                        <td
                          className="pl-0"
                          style={{
                            border: 'none',
                            color: '#343434',
                            fontSize: 14,
                            fontWeight: 500,
                            paddingTop: 0,
                          }}
                        >
                          Ưu đãi
                        </td>
                        <td
                          className="pr-0"
                          style={{
                            textAlign: 'right',
                            border: 'none',
                            color: '#704315',
                            fontSize: 14,
                            fontWeight: 700,
                            paddingTop: 0,
                          }}
                        >
                          {userGift.name}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="pl-0"
                          style={{
                            border: 'none',
                            color: '#343434',
                            fontSize: 14,
                            fontWeight: 500,
                            paddingTop: 0,
                          }}
                        >
                          Tổng thanh toán
                        </td>
                        <td
                          className="pr-0"
                          style={{
                            textAlign: 'right',
                            border: 'none',
                            color: '#704315',
                            fontSize: 14,
                            fontWeight: 700,
                            paddingTop: 0,
                          }}
                        >
                          {userGift?.rewardValue ? userGift?.rewardValue.toLocaleString() : 0} điểm
                        </td>
                      </tr>
                    </table>
                    <div className="has-text-centered mb-5 subtitle has-text-weight-normal has-text-grey-dark is-7">
                      Mã được gửi đến bạn qua SMS. Mã chỉ dùng được 1 lần.
                    </div>
                    <div className=" is-flex is-justify-content-center">
                      <div className="p-2 has-background-primary" style={{borderRadius: 5}}>
                        <div
                          className="p-3 has-background-white"
                          style={{paddingBottom: 0, borderRadius: 5}}
                        >
                          <QRCodeSVG value={qrCodeData?.code} />
                        </div>
                      </div>
                    </div>
                    <div
                      className="has-text-centered has-text-info"
                      style={{
                        color: '#83552D',
                        fontSize: 16,
                        paddingTop: 10,
                        paddingBottom: 15,
                        wordBreak: 'break-all',
                      }}
                    >
                      {qrCodeData?.code?.includes?.('https://') ||
                      qrCodeData?.code?.includes?.('http://') ? (
                        <a href={qrCodeData?.code} target="_blank" rel="noreferrer">
                          {qrCodeData?.code}
                        </a>
                      ) : (
                        qrCodeData?.code
                      )}
                      {/* <a
                        href="https://dgvdigital.atlassian.net/browse/STRONG-315"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://dgvdigital.atlassian.net/browse/STRONG-315
                      </a>
                      {qrCodeData?.code} */}
                    </div>
                  </div>
                )}
                <Link to={`${ROUTE_NAME.CHILL}?tab=2`}>
                  <button
                    onClick={handleCloseModal}
                    className={classNames('button is-primary-custom is-fullwidth  mb-4', {})}
                    style={{
                      color: '#FFF',
                      fontWeight: 700,
                    }}
                  >
                    Đổi thêm ưu đãi
                  </button>
                </Link>
                {userGift?.showProfile && (
                  <>
                    <Link to={`${ROUTE_NAME.USER_PROFILE}?tab=1`}>
                      <button
                        onClick={handleCloseModal}
                        className={classNames('button is-second-custom is-fullwidth ', {})}
                        style={{
                          color: '#83552d',
                          fontWeight: 700,
                        }}
                      >
                        Xem ưu đãi của tôi
                      </button>
                    </Link>
                  </>
                )}
                <div className="has-text-centered mb-4" style={{marginTop: 15}}>
                  <a
                    style={{color: '#83552D', textDecoration: 'underline'}}
                    onClick={() => {
                      exportAsImage(qrRef.current, `${userGift?.name || 'gift'}`);
                    }}
                  >
                    Tải ảnh ưu đãi về máy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderConditionContent = () => {
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <>
          <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold is-uppercase">
            CHƯƠNG TRÌNH KHÁCH HÀNG THÂN THIẾT NUTIFOOD NGÔI NHÀ DINH DƯỠNG (NUTIPOINT)
          </div>
          <div
            className="columns hide-scroll"
            style={{maxHeight: 'calc(100vh - 300px)', overflow: 'auto'}}
          >
            <div className="column">
              <div className="card" style={{border: '1px solid #d2d2d2'}}>
                <div className="card-content" style={{fontSize: 12}}>
                  <div
                    style={{
                      color: '#DDA410',
                      fontWeight: 600,
                      paddingBottom: 12,
                    }}
                  >
                    1. THÔNG TIN CHƯƠNG TRÌNH
                  </div>
                  <p>
                    Tiên phong ứng dụng tinh hoa, thành tựu khoa học, nghiên cứu và phát triển các
                    giải pháp dinh dưỡng chuẩn u đáp ứng nhu cầu và thể trạng đặc thù của trẻ em và
                    người tiêu dùng Việt Nam, Nutifood không ngừng tự hoàn thiện để nâng tầm trải
                    nghiệm mua sắm của khách hàng tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh
                    Dưỡng.
                  </p>
                  <p>
                    Để tri ân sự ủng hộ nhiệt tình của khách hàng, Nutifood trân trọng giới thiệu
                    chương trình khách hàng thân thiết Nutipoint với những ưu đãi & đặc quyền hấp
                    dẫn dành riêng cho thành viên.
                  </p>
                  <div
                    style={{
                      color: '#DDA410',
                      fontWeight: 600,
                      paddingBottom: 12,
                    }}
                  >
                    2. ĐỐI TƯỢNG THAM GIA
                  </div>
                  <p>
                    Tất cả khách hàng mua sắm và có đăng ký thông tin tham gia chương trình khách
                    hàng thân thiết Nutipoint tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng
                    toàn quốc hoặc trên{' '}
                    <a href="https://nutifoodshop.com" target="_blank" rel="noopener noreferrer">
                      {' '}
                      website www.nutifoodshop.com
                    </a>
                  </p>
                  <div
                    style={{
                      color: '#DDA410',
                      fontWeight: 600,
                      paddingBottom: 12,
                    }}
                  >
                    3. CÁC LỢI ÍCH ĐẶC QUYỀN VÀ TIÊU CHÍ XÉT HẠNG THÀNH VIÊN
                  </div>
                  <p>
                    Tất cả thành viên chương trình khách hàng thân thiết Nutipoint tích lũy doanh số
                    mua hàng tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng toàn quốc hoặc trên
                    website www.nutifoodshop.com sẽ nhận được các đặc quyền sau đây:
                  </p>
                  <p>
                    Doanh số tích lũy để tính điểm là tổng số tiền khách hàng thanh toán thực tế
                    theo từng hóa đơn mua sắm tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng
                    toàn quốc hoặc trên website www.nutifoodshop.com
                  </p>
                  <p>
                    Hiệu lực voucher: khách hàng sử dụng voucher mua hàng tại hệ thống cửa hàng
                    Nutifood Ngôi Nhà Dinh Dưỡng toàn quốc hoặc trên website www.nutifoodshop.com,
                    trong thời gian voucher có hiệu lực.
                  </p>
                  <p>
                    Giá trị của voucher đã bao gồm thuế GTGT và không có giá trị quy đổi thành tiền
                    mặt.
                  </p>
                  <p>
                    Tất cả đặc quyền, thể lệ tham gia chương trình khách hàng thân thiết Nutipoint
                    có thể thay đổi theo nội dung được Nutifood thông báo chính thức tại từng thời
                    điểm.
                  </p>
                  <div
                    style={{
                      color: '#DDA410',
                      fontWeight: 600,
                      paddingBottom: 12,
                    }}
                  >
                    4. NGUYÊN TẮC BẢO MẬT
                  </div>
                  <p>
                    Khách hàng cần cung cấp thông tin (họ tên, ngày tháng năm sinh, số điện thoại,
                    địa chỉ, email) và đồng ý để Nutifood thu thập, lưu trữ, xử lý các thông tin
                    trên để phục vụ cho các chương trình: khách hàng thân thiết, Marketing và nâng
                    cao chất lượng sản phẩm/dịch vụ cho khách hàng của Nutifood.
                  </p>
                  <p>
                    Nutifood chỉ sử dụng thông tin khách hàng vào mục đích phục vụ cho các chương
                    trình: khách hàng thân thiết, Marketing và nâng cao chất lượng sản phẩm/dịch vụ
                    cho khách hàng của Nutifood.
                  </p>
                  <p>
                    Nutifood lưu giữ bảo mật và không tiết lộ thông tin khách hàng cho bên thứ ba.
                  </p>
                  <div
                    style={{
                      color: '#DDA410',
                      fontWeight: 600,
                      paddingBottom: 12,
                    }}
                  >
                    5. CÁC ĐIỀU KHOẢN KHÁC
                  </div>
                  <p>
                    Khách hàng có trách nhiệm cung cấp chính xác và đầy đủ thông tin cho nhân viên
                    để đăng ký hoặc khi thanh toán để được hưởng những ưu đãi, đặc quyền hấp dẫn
                    dành cho thành viên chương trình khách hàng thân thiết Nutipoint.
                  </p>
                  <p>Việc đăng ký thành viên là hoàn toàn miễn phí.</p>
                  <p>
                    Mỗi khách hàng được cấp 1 mã khách hàng duy nhất trong thời gian chương trình,
                    theo số điện thoại đăng ký.
                  </p>
                  <p>
                    Nutifood không chịu trách nhiệm khi khách hàng quên hoặc cung cấp sai thông tin
                    trong quá trình thanh toán dẫn đến việc không được hưởng những ưu đãi, đặc quyền
                    hấp dẫn dành cho thành viên chương trình khách hàng thân thiết Nutipoint.
                  </p>
                  <p>
                    Khách hàng tham gia chương trình khách hàng thân thiết Nutipoint được quyền tham
                    gia cùng lúc với các chương trình khuyến mãi khác.
                  </p>
                  <p>
                    Chương trình sẽ được Nutifood thông báo đến khách hàng thông qua kênh
                    Facebook/Website/Ấn phẩm tại cửa hàng.
                  </p>
                  <p>
                    Các nội dung ưu đãi, đặc quyền của chương trình chương trình khách hàng thân
                    thiết Nutipoint sẽ được Nutifood thông báo đến khách hàng thông qua kênh
                    Facebook/Website/Ấn phẩm tại cửa hàng.
                  </p>
                  <p>
                    Để được hưởng ưu đãi, đặc quyền của chương trình chương trình khách hàng thân
                    thiết Nutipoint, tại thời điểm thanh toán, khách hàng đọc họ tên & số điện thoại
                    để xác minh thành viên Nutipoint, nhân viên nhập số điện thoại khách vào máy
                    tính tiền sẽ được giảm giá theo chương trình trực tiếp trên hóa đơn hoặc thụ
                    hưởng các quyền lợi khác.
                  </p>
                  <p>
                    Điểm tích lũy sẽ được cập nhật trong vòng 48h kể từ khi phát sinh giao dịch tại
                    hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng toàn quốc. Đối với đơn hàng trên
                    website www.nutifoodshop.com, điểm tích lũy sẽ được cập nhật trong vòng 72h kể
                    từ khi đơn hàng được xác nhận Đã Giao Thành Công. Ngay sau khi điểm tích lũy
                    được cập nhật thành công, hạng thành viên của khách hàng nếu thỏa đủ điều kiện
                    lên hạng sẽ được cập nhật lên tương ứng.
                  </p>
                  <p>
                    Chương trình chỉ áp dụng tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng toàn
                    quốc và website www.nutifoodshop.com. Không áp dụng cho các đơn hàng trên sàn
                    thương mại điện tử.
                  </p>
                  <p>
                    Không được chuyển nhượng điểm và không được dùng điểm hoặc dùng các quyền lợi
                    thành viên để quy đổi thành tiền mặt dưới mọi hình thức
                  </p>
                  <div
                    style={{
                      color: '#DDA410',
                      fontWeight: 600,
                      paddingBottom: 12,
                    }}
                  >
                    6. LIÊN HỆ VỚI NUTIFOOD
                  </div>
                  <p>1900 638 013</p>
                </div>
              </div>
            </div>
          </div>
          <div className=" has-text-centered">
            <button
              style={{
                background: '#fff',
                border: '1px solid #DDA410',
                borderRadius: 5,
                paddingTop: 16,
                paddingBottom: 16,
                paddingLeft: 30,
                paddingRight: 30,
                color: '#DDA410',
              }}
              onClick={() => {
                setModalState(MODAL_STATES.REGISTER);
              }}
            >
              Quay lại trang đăng ký
            </button>
          </div>
        </>
      </Modal.Card.Body>
    );
  };
  return (
    <>
      {modalState === MODAL_STATES.LOGIN ||
      modalState === MODAL_STATES.REGISTER ||
      modalState === MODAL_STATES.OPTIONOTP ||
      modalState === MODAL_STATES.OTP ||
      modalState === MODAL_STATES.QR_CODE ? (
        <>
          <div className={classNames('modal px-5', {'is-active': show})}>
            <div className="modal-background"></div>
            <div className="modal-card" style={{maxWidth: get(MODAL_WIDTHS, modalState)}}>
              <section style={{position: 'relative'}}>
                {/* <div style={get(POPUP_STYLE, modalState)}>
                  <button className="delete" aria-label="close" onClick={handleCloseModal}></button>
                </div> */}
                {modalState === MODAL_STATES.LOGIN && renderLoginContent()}
                {modalState === MODAL_STATES.REGISTER && renderRegisterContent()}
                {modalState === MODAL_STATES.OPTIONOTP && renderOptionOTP()}
                {modalState === MODAL_STATES.OTP && renderOtpContent()}
                {modalState === MODAL_STATES.QR_CODE && renderQRCodeContent()}
              </section>
            </div>
          </div>
        </>
      ) : (
        <>
          <Modal show={show} closeOnBlur closeOnEsc onClose={handleCloseModal}>
            <Modal.Card style={{maxWidth: get(MODAL_WIDTHS, modalState)}}>
              {/* {modalState === MODAL_STATES.REGISTER && renderRegisterContent()} */}
              {modalState === MODAL_STATES.REGISTER_SUCCESS && renderRegisterSuccessContent()}
              {/* {modalState === MODAL_STATES.LOGIN && renderLoginContent()} */}
              {/* {modalState === MODAL_STATES.OTP && renderOtpContent()} */}
              {modalState === MODAL_STATES.EDIT && renderEditProfileContent()}
              {/* {modalState === MODAL_STATES.QR_CODE && renderQRCodeContent()} */}
              {modalState === MODAL_STATES.CONDITIONS && renderConditionContent()}
              {/* {modalState === MODAL_STATES.OPTIONOTP && renderOptionOTP()} */}
            </Modal.Card>
          </Modal>
        </>
      )}
    </>
  );
};

export default UserProfileModal;
