import React from 'react';
import {Pagination} from 'react-bulma-components';
import moment from 'moment';
// import title_image from 'images/reward-info-title.png';
import image_banner from 'images/user-reward-title.png';

const RewardOverview = ({rewardInfo = {}}) => {
  const rewardValues = [
    {text: 'Điểm khả dụng', value: rewardInfo?.totalRewardsAvailable, isActive: true},
    {text: 'Đã dùng', value: rewardInfo?.totalRewardsUsed},
    {text: 'Hết hạn', value: rewardInfo?.totalRewardsExpired},
  ];
  return (
    <>
      <div className="has-text-centered mb-4">
        <img
          style={{
            width: '55%',
            maxWidth: 400,
          }}
          src={image_banner}
          alt="title"
        />
      </div>
      <div className="columns is-mobile">
        {rewardValues.map((item, index) => {
          return (
            <div className="column is-4" key={index}>
              <div
                className="card1 has-text-centered"
                style={{
                  borderRight: index + 1 < rewardValues.length ? '2px solid #83552D' : undefined,
                }}
              >
                <div className="card-content p-0">
                  <div
                    style={{
                      fontSize: 22,
                    }}
                    className={`title has-text-weight-bold is-size-3-tablet is-size-4 mb-2 ${
                      item?.isActive ? 'has-text-info' : 'has-text-black'
                    }`}
                  >
                    {item?.value.toLocaleString()}
                  </div>
                  <div className="title  has-text-black is-size-7 is-size-6-tablet">
                    {item.text}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <div
          className="columns is-justify-content-center"
          style={{
            marginLeft: 0,
            marginRight: 0,
          }}
        >
          <div className="column bg-expiry reward-table-wrapper">
            <div className="expiry-table">
              <table className="table is-fullwidth">
                <tr>
                  <th
                    className=" is-size-7 is-size-6-tablet"
                    style={{
                      fontSize: 10,
                      color: '#323130',
                      fontWight: 700,
                      paddingTop: 21,
                    }}
                  >
                    Điểm sắp hết hạn
                  </th>
                  <th className="has-text-right">
                    <span
                      style={{
                        color: '#83552D',
                        fontSize: 24,
                        fontWight: 700,
                      }}
                    >
                      {rewardInfo?.data?.reduce((prev, curr) => prev + curr?.totalValue || 0, 0)}
                    </span>
                    <span
                      style={{
                        fontSize: 14,
                        fontWight: 700,
                      }}
                      className=" is-size-7 is-size-6-tablet"
                    >
                      {' '}
                      điểm
                    </span>
                  </th>
                </tr>
                {rewardInfo?.data?.map((item, index) => (
                  <tr key={`table_key_${item?.id || index}`}>
                    <td
                      className=" is-size-7 is-size-6-tablet"
                      style={{fontSize: 12, fontWeight: 500, color: '#323130', paddingTop: 10}}
                    >{`Tháng ${moment(item.expiryDateTime).format('MM-YYYY')}`}</td>
                    <td
                      style={{fontSize: 14, fontWeight: 700, color: '#323130'}}
                      className="has-text-right is-size-7 is-size-6-tablet"
                    >
                      {item?.totalValue} điểm
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
        {/* <table className="table roundedCorners is-fullwidth is-hovered">
          <thead className=" has-background-primary">
            <tr>
              <th className=" has-text-white has-text-weight-semibold pl-5">Điểm sắp hết hạn</th>
              <th className=" has-text-warning has-text-weight-semibold has-text-right pr-5">
                {rewardInfo?.data?.reduce((prev, curr) => prev + curr?.totalValue || 0, 0)} điểm
              </th>
            </tr>
          </thead>
          {rewardInfo?.data?.map((item, index) => (
            <tr key={`table_key_${item?.id || index}`}>
              <td className="pl-5">{`Tháng ${moment(item.expiryDateTime).format('MM-YYYY')}`}</td>
              <td className="pr-5 has-text-right">{item?.totalValue}</td>
            </tr>
          ))}
        </table> */}
      </div>
    </>
  );
};

export default RewardOverview;
