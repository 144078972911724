import React, {useEffect, useMemo, useState} from 'react';
import Layout from '../../components/layout';
import './styles.scss';
import './newStyles.scss';
import Benner from 'images/strongbow/pc/banner-pc.png';
import {toggleLoginModal, toggleOver18} from 'appRedux/slices/appSlice';
import {citizenIdInfoSelector, isLogInSelector} from 'appRedux/selectors/auth';
import {useDispatch, useSelector} from 'react-redux';
import QuickLogin from 'components/quickLogin';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import congrats from 'images/congrats.png';
import * as Yup from 'yup';
import image_benner from 'images/strongbow/banner.png';
import component from 'images/footer_banner.png';
import bottle from 'images/strongbow/bottle.png';
import image_time from 'images/strongbow/image_time.png';
import scanBillBanner from 'images/scan-bill-banner.png';
import step from 'images/strongbow/step.png';
import scanbill from 'images/strongbow/scanbill.png';
import howToScan from 'images/how-to-scan-bill.png';
import warning_popup from 'images/strongbow/pc/warning-popup.png';
import HistoryPrizeList from 'components/historyPrizeList';
import BillUpload from 'components/billUpload';
import classNames from 'classnames';
import ContactButton from 'components/contactButton';
import {
  billDetectionAction,
  getLuckDrawRewardDetailAction,
  getRewardHistoryScanBillAction,
  getRewardInfoAction,
  scanBillValidateLimitItemAction,
} from 'appRedux/actions/app';
import {ROUTE_NAME} from 'constants/routes';
import Resizer from 'react-image-file-resizer';
import {get} from 'lodash';
import {isOver18Selector, limitItemValidSelector, rewardInfoSelector} from 'appRedux/selectors/app';
import {useFormik} from 'formik';
import bill_fail_title from 'images/strongbow/failed_bill_title.png';
import {isMobile} from 'react-device-detect';
import scan_bill_error from 'images/strongbow/scan_bill_error.png';
import {Link} from 'react-router-dom';
import logo from 'images/logo.png';
import bottle_18 from 'images/strongbow/pc/element-18.png';
import home_1 from 'images/strongbow/pc/home1.png';
import home_2 from 'images/strongbow/pc/home_2.png';
import bg_hr from 'images/strongbow/pc/bg_hr.png';
import home_prcode from 'images/strongbow/pc/home_prcode.png';
import icon_18 from 'images/icon_18.png';
import icon_car from 'images/icon_car.png';
import icon_mother from 'images/icon_mother.png';
import island from 'images/strongbow/New/island.png';
import decor__master2 from 'images/strongbow/New/decor__master2.png';
import tnc_letter from 'images/strongbow/New/tnc-letter.png';
import khinh_khi_cau from 'images/strongbow/New/khinh-khi-cau.png';
import emoji from 'images/strongbow/New/emoji.png';
import tnc_cancel_but from 'images/strongbow/New/tnc-cancel-but.png';
import tnc_ok_but from 'images/strongbow/New/tnc-ok-but.png';
import {BUCKET_IMAGES} from 'constants/meoChill';
import balloon from 'images/strongbow/pc/balloon.png';
import {getCitizenIdByMerchantIdAction} from 'appRedux/actions/auth';
import {DiamondSharp} from '@mui/icons-material';

const MODAL_STATES = {
  CONGRATS: 'CONGRATS',
  FAILED: 'FAILED',
  REJECT: 'REJECT',
  REJECT_EXCEED: 'REJECT_EXCEED',
  EXPIRED_DATE: 'EXPIRED_DATE',
  BILL_DUPLICATED: 'BILL_DUPLICATED',
  ID_VERIFY_PENDING: 'ID_VERIFY_PENDING',
  ID_VERIFY_REQUIRED: 'ID_VERIFY_REQUIRED',
};

const HomePageView = () => {
  const {phoneNumber} = useParams();
  const dispatch = useDispatch();
  const isLogIn = useSelector(isLogInSelector);
  const rewardInfo = useSelector(rewardInfoSelector);
  const citizenIdInfo = useSelector(citizenIdInfoSelector);
  const limitItemValid = useSelector(limitItemValidSelector);
  const [dragActive, setDragActive] = React.useState(false);
  const [modalState, setModalState] = React.useState(null);
  const [luckDrawsPageIndex, setLuckDrawsPageIndex] = React.useState(1);
  const [pageSizeLuck, setPageSizeLuck] = React.useState(10);
  const [luckDrawTotalPages, setLuckDrawTotalPages] = React.useState(0);
  const [luckDraws, setLuckDraws] = React.useState([]);
  const [luckPageIndex, setLuckPageIndex] = React.useState(1);
  const [historyScanBill, setHistoryScanBill] = React.useState([]);
  const [historyPageIndex, setHistoryPageIndex] = React.useState(1);
  const [historyTotalPages, setHistorTotalPages] = React.useState(0);
  const [strSearch, setStrSearch] = React.useState('');
  const [strSearchHistory, setStrSearchHistory] = React.useState('');
  const [refreshValidation, setRefreshValidation] = React.useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const handleWarningPolicyEditProfile = () => {
    navigate(ROUTE_NAME.EDIT_PROFILE);
  };

  useEffect(() => {
    dispatch(getRewardInfoAction());
    dispatch(getCitizenIdByMerchantIdAction());
    dispatch(scanBillValidateLimitItemAction());
  }, [refreshValidation]);

  useEffect(() => {
    dispatch(
      getLuckDrawRewardDetailAction({
        body: {
          pageIndex: luckPageIndex,
          pageSize: pageSizeLuck,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          searchText: strSearch,
          sortBy: [
            {
              fieldName: 'CreatedTime',
              ascending: false,
            },
          ],
        },
        callback: (error, res) => {
          const {data} = res;
          setLuckDrawTotalPages(data.numOfPages);
          setLuckDraws(data?.data || []);
        },
      })
    );
  }, [luckPageIndex, strSearch]);

  useEffect(() => {
    dispatch(
      getRewardHistoryScanBillAction({
        body: {
          pageIndex: historyPageIndex,
          pageSize: 10,
          searchText: strSearch,
          programId: process.env.REACT_APP_PROGRAM_ID,
          sortBy: [
            {
              fieldName: 'TotalValue',
              ascending: false,
            },
          ],
        },
        callback: (error, res) => {
          const {data} = res;
          setHistoryScanBill(data.data);
          setHistorTotalPages(data.numOfPages);
        },
      })
    );
  }, [historyPageIndex, strSearch]);

  const [modalData, setModalData] = React.useState({
    value: '',
    total: '',
    numberOfItems: '',
    isValidRuleScanBill: true,
  });
  const [billUploadKey, setBillUploadKey] = useState(0);

  const getRewardInfo = (data) => {
    dispatch(
      getRewardInfoAction({
        callback: (res) => {
          const total = get(res, 'totalRewardsAvailable', 0);
          const modal = {
            value: data.point,
            total: total,
            numberOfItems: data.quantityEarning,
            isValidRuleScanBill: data.quantityReject === 0,
          };
          setModalData(modal);
        },
      })
    );
  };
  // const resizeFile = (file) => {
  //   const SHOULD_RESIZE_FILE_SIZE = 1024 * 4;
  //   const fileSizeKiloBytes = file.size / 1024;
  //   if (fileSizeKiloBytes <= SHOULD_RESIZE_FILE_SIZE) {
  //     return new Promise((resolve) => {
  //       resolve(file);
  //     });
  //   }
  //   return new Promise((resolve) => {
  //     Resizer.imageFileResizer(
  //       file,
  //       1200,
  //       1600,
  //       'jpeg',
  //       100,
  //       0,
  //       (uri) => {
  //         resolve(uri);
  //       },
  //       'file',
  //       300,
  //       400
  //     );
  //   });
  // };

  const validateSelectedFile = (selectedFile) => {
    // const MIN_FILE_SIZE = 1024; // 1MB

    if (!selectedFile) {
      return;
    }
    setSelectedImage(selectedFile);
    return;
  };

  const meoChillBanner = useMemo(() => {
    return (
      <img
        src={'https://storage.googleapis.com/strongbow_miniapp/MeoChill/SB_1.png'}
        style={{
          width: '100%',
        }}
        className=" is-clickable"
        alt="Meo chill banner"
        onClick={() => {
          navigate(ROUTE_NAME.CHILL_MAP);
        }}
      />
    );
  }, [navigate]);

  const onSubmitBillDetection = async (file) => {
    const formData = new FormData();
    // const image = await resizeFile(file);
    formData.append('FormFile', file);
    dispatch(
      billDetectionAction({
        body: formData,
        callback: (res, err) => {
          const resData = res?.data;
          if (!(resData === undefined || resData === null || resData?.length === 0)) {
            resData.point = Number.parseFloat(get(resData, 'point', 0));
          }
          if (!err && res?.isSuccess && resData) {
            getRewardInfo(resData);
            setModalState(MODAL_STATES.CONGRATS);
            setRefreshValidation(refreshValidation + 1);
          } else {
            if (err) {
              //toast(err, {type: 'error'});
              if (err?.data?.isUploaded || err.detail === 'Hóa đơn đã được upload') {
                setModalState(MODAL_STATES.BILL_DUPLICATED);
              } else if (err.detail === 'Vượt quá số lượng chai quy định được tích trong ngày') {
                setModalState(MODAL_STATES.REJECT_EXCEED);
              } else if (err.detail === 'Bạn chỉ có thể quét hóa đơn mua trong ngày') {
                setModalState(MODAL_STATES.EXPIRED_DATE);
              } else {
                setModalState(MODAL_STATES.REJECT);
              }
            } else {
              setModalState(MODAL_STATES.FAILED);
            }
          }
          setSelectedImage(null);
        },
      })
    );
  };

  const handleBillUploadClick = (event) => {
    let continueFlow = true;
    if (rewardInfo?.totalRewardsAvailable > 500) {
      if (citizenIdInfo?.status === 0) {
        setModalState(MODAL_STATES.ID_VERIFY_PENDING);
        continueFlow = false;
      } else if (citizenIdInfo?.status !== 1) {
        setModalState(MODAL_STATES.ID_VERIFY_REQUIRED);
        continueFlow = false;
      }
    } else if (!limitItemValid) {
      setModalState(MODAL_STATES.REJECT_EXCEED);
      continueFlow = false;
    } else {
      // setModalState(MODAL_STATES.NONE);
    }

    if (!continueFlow) {
      setRefreshValidation(refreshValidation + 1);
      event.preventDefault();
    }
  };

  const onChangeImage = (event) => {
    event.preventDefault();
    let files = event.target.files;
    if (event.target.files) {
      let type = event.target.files[0].type;
      if (['image/jpeg', 'image/png', 'image/gif'].includes(type)) {
        validateSelectedFile(files[0]);
      } else {
        toast.error('Vui lòng chọn hình ảnh', {autoClose: 2000, position: 'top-center'});
      }
      event.target.value = '';
    }
  };
  const onDeleteImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedImage(null);
  };
  const handleUploadMoreBill = (event) => {
    setBillUploadKey(billUploadKey + 1);
    setModalState(null);
    onChangeImage(event);
  };
  const handleSearch = (e) => {
    setLuckPageIndex(1);
    setHistoryPageIndex(1);
    setStrSearch(e);
  };
  return (
    <Layout
      headerProps={{
        index: 0,
      }}
      userProfileModalProps={{
        phoneNumber,
      }}
    >
      {isMobile ? (
        <>
          <div
            style={{
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
              }}
            >
              {isLogIn &&
                (citizenIdInfo?.data === null || citizenIdInfo?.data?.status !== 1) &&
                rewardInfo?.totalRewardsAvailable >= 400 &&
                rewardInfo?.totalRewardsAvailable <= 500 && (
                  <span
                    onClick={() => {
                      handleWarningPolicyEditProfile();
                    }}
                  >
                    <img src={warning_popup} class="fix-mobile" />
                  </span>
                )}
              <img src={image_benner} />
            </div>
            <div
              style={{
                position: 'absolute',
                width: 170,
                left: 30,
                zIndex: 1,
                top: 200,
              }}
            >
              <img src={component} />
            </div>
            <div
              style={{
                position: 'absolute',
                width: 27,
                left: 35,
                top: 310,
                zIndex: 1,
              }}
            >
              <img src={bottle} />
            </div>
            <div
              style={{
                position: 'absolute',
                width: 200,
                right: 0,
                top: 240,
              }}
            >
              <img src={image_time} />
            </div>
            {!isLogIn && (
              <div
                style={{
                  position: 'absolute',
                  width: 39,
                  right: 20,
                  top: 350,
                }}
              >
                <img src={bottle} />
              </div>
            )}
          </div>
          <div className="container-home">
            <div className="container">
              <div className="container-content">
                {isLogIn ? (
                  <>
                    <div className=" mx-5  has-text-centered" style={{marginTop: 70}}>
                      {meoChillBanner}
                      <div>
                        <img src={scanBillBanner} />
                      </div>
                    </div>

                    <div className="columns" style={{marginTop: -30}}>
                      <div className="column">
                        <p style={{fontSize: 12}} className=" has-text-centered">
                          Scan ngay QR code trên bao bì hoặc cửa hàng tiện lợi để tham gia. Chiller
                          chụp hóa đơn thật nhiều để tích Chill Koin nhận quà nhé!.
                        </p>
                      </div>
                    </div>
                    <div className="formbg">
                      <div className="columns">
                        <div
                          className="column is-one-third"
                          style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        >
                          <div
                            className="columns"
                            style={{
                              marginBottom: -50,
                              marginTop: -30,
                            }}
                          >
                            <div className="column">
                              <div>
                                <img
                                  src={scanbill}
                                  style={{
                                    width: 184,
                                    margin: 'auto',
                                    display: 'block',
                                  }}
                                  alt="scan bill"
                                />
                              </div>
                            </div>
                          </div>
                          <BillUpload
                            key={`BillUpload_${billUploadKey}`}
                            onSubmitFiles={onSubmitBillDetection}
                            selectedImage={selectedImage}
                            handleChange={onChangeImage}
                            onDeleteImage={onDeleteImage}
                            handleClick={handleBillUploadClick}
                          />
                        </div>
                      </div>
                    </div>
                    <img src={howToScan} alt="how to scan bill" className=" mt-4" />
                  </>
                ) : (
                  <>
                    <div className="" style={{marginTop: 70}}>
                      {meoChillBanner}
                    </div>
                    <div
                      className="column has-text-centered"
                      style={{
                        position: 'relative',
                      }}
                    >
                      <img src={home_1} />
                    </div>
                    <div className="columns">
                      <div className="column ">
                        <div>
                          <img src={step} />
                        </div>
                      </div>
                    </div>
                    <QuickLogin fromHome={true} />
                  </>
                )}
                <HistoryPrizeList
                  data={luckDraws}
                  currentPage={luckPageIndex}
                  totalPages={luckDrawTotalPages}
                  onChangePage={setLuckPageIndex}
                  dataBill={historyScanBill}
                  currentPageHistory={historyPageIndex}
                  totalPagesHistory={historyTotalPages}
                  onChangePageHistory={setHistoryPageIndex}
                  onChangesrSearch={handleSearch}
                />
              </div>
              <div className="mt-3">
                <ContactButton />
              </div>
            </div>

            {/* POPUP SCAN BILL */}
            <div
              className={classNames('modal px-5', {
                'is-active': !!modalState,
              })}
            >
              <div className="modal-background"></div>
              <div className="modal-card ">
                <div style={{height: 50}} />
                <div style={{position: 'absolute', right: 5, top: 10}}>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => setModalState(null)}
                  ></button>
                </div>
                {modalState === MODAL_STATES.CONGRATS && (
                  <>
                    <div className="popup-scanbill-bg">
                      <div className=" px-5 has-text-centered">
                        <img src={congrats} alt="logo" />
                        <div className=" has-text-weight-bold">Chiller đã tích thành công</div>
                        {!!modalData?.value && (
                          <div className=" title is-3 has-text-info is-uppercase has-text-weight-bold mt-2 mb-4">
                            {`${modalData?.value} Chill koin`}
                          </div>
                        )}
                        {!!modalData?.numberOfItems && (
                          <div className=" has-text-weight-semibold mb-3">
                            tương ứng {modalData?.numberOfItems} sản phẩm Strongbow
                          </div>
                        )}
                        {modalData?.isValidRuleScanBill == false && (
                          <div className=" has-text-weight-semibold mb-3">
                            Bạn đã tích đủ 48 chai/ngày
                          </div>
                        )}
                        {!!modalData?.total && (
                          <div className=" has-text-weight-semibold mb-3">
                            Tổng điểm:{' '}
                            <span className=" has-text-info has-text-weight-bold">{`${modalData?.total} Chill Koin`}</span>
                          </div>
                        )}
                        <form className="">
                          <input
                            type="file"
                            capture="camera"
                            id="input-file-upload-bill"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={handleUploadMoreBill}
                            style={{
                              display: 'none',
                            }}
                            onClick={handleBillUploadClick}
                          />
                        </form>
                        <label
                          htmlFor="input-file-upload-bill"
                          className="is-second-custom mt-2 mb-4"
                          style={{
                            color: '#83552D',
                            padding: '10px 30px',
                            fontSize: 16,
                            fontWeight: 700,
                            display: 'block',
                            width: '80%',
                            textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                            margin: 'auto',
                          }}
                        >
                          SCAN BILL TÍCH ĐIỂM
                        </label>
                        <div className="columns">
                          <div className="column is-12">
                            <button
                              className="is-primary-custom is-uppercase"
                              style={{
                                color: '#FFF',
                                padding: '14px 30px',
                                fontSize: 16,
                                fontWeight: 700,
                                width: '80%',
                                textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                              }}
                              onClick={() => navigate(ROUTE_NAME.CHILL)}
                            >
                              ĐỔI ĐIỂM NHẬN QUÀ
                            </button>
                          </div>
                        </div>
                        <div className="columns" style={{marginBottom: 20}}>
                          <div className="column is-12">
                            <div className=" has-text-weight-semibold mb-3">
                              <span
                                style={{
                                  color: '#323130',
                                }}
                              >
                                Xem thêm{' '}
                              </span>
                              <Link
                                to={ROUTE_NAME.INFO}
                                style={{
                                  color: '#2600BF',
                                  fontWeight: '600',
                                  textDecoration: 'underline',
                                }}
                              >
                                Thể lệ chương trình
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {modalState === MODAL_STATES.FAILED && (
                  <>
                    <div className="userbg">
                      <div className=" px-5 has-text-centered pt-6">
                        <div
                          className="px-4"
                          style={{
                            fontSize: 40,
                            color: '#83552D',
                            lineHeight: 1.4,
                            fontWeight: 700,
                          }}
                        >
                          ĐỢI MỘT TÍ NHÉ !
                        </div>
                        <div
                          className="has-text-centered pt-5 pb-5 pl-3"
                          style={{fontSize: 12, fontWeight: 500}}
                        >
                          <div> Strongbow đã nhận thông tin về hóa đơn </div>
                          <div>của bạn. Bộ phận CSKH sẽ liên hệ bạn </div>

                          <div>trong 24h để tích Chillkoin nha!</div>
                        </div>

                        <div
                          className="columns"
                          style={{
                            marginBottom: 20,
                          }}
                        >
                          <div className="column is-12">
                            <button
                              className="is-primary-custom is-uppercase mt-2 mb-3 is-clickable"
                              style={{
                                color: '#FFF',
                                padding: '14px 30px',
                                fontSize: 16,
                                fontWeight: 700,
                                width: '80%',
                                textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                              }}
                              onClick={() => setModalState(null)}
                            >
                              Đóng
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {modalState === MODAL_STATES.REJECT && (
                  <>
                    <div className="popup-scanbill-bg">
                      <div className=" px-4 has-text-centered pt-6">
                        <img src={scan_bill_error} className="pb-4" />
                        <div
                          className="has-text-centered px-4"
                          style={{
                            fontSize: 14,
                          }}
                        >
                          Chiller ơi, hãy kiểm tra lại xem hoá đơn đã đầy đủ thông tin dưới đây chưa
                          nhé
                        </div>
                        <div className="my-4">
                          <img src={bill_fail_title} s />
                        </div>
                        <form className="">
                          <input
                            type="file"
                            id="input-file-upload-bill-fail"
                            accept="image/png, image/gif, image/jpeg"
                            capture="camera"
                            onChange={handleUploadMoreBill}
                            style={{
                              display: 'none',
                            }}
                          />
                        </form>

                        <label
                          htmlFor="input-file-upload-bill-fail"
                          className="is-primary-custom mt-2 mb-6"
                          style={{
                            color: '#fff',
                            padding: '10px 30px',
                            fontSize: 16,
                            fontWeight: 700,
                            display: 'block',
                            width: '80%',
                            textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                            margin: 'auto',
                          }}
                        >
                          CHỤP LẠI
                        </label>
                      </div>
                    </div>
                  </>
                )}
                {/* ......START POPUP BILL_DUPLICATED..... */}
                {modalState === MODAL_STATES.BILL_DUPLICATED && (
                  <>
                    <div style={{height: 50}} />
                    <div className="popup-scanbill-bg-v1">
                      <div
                        style={{
                          position: 'absolute',
                          bottom: -5,
                          left: 10,
                        }}
                      >
                        <img src={balloon} alt="balloon" />
                      </div>
                      <div className=" px-4 has-text-centered pt-6">
                        <img
                          src={BUCKET_IMAGES.SCAN_BILL_DUPLICATED_TITLE}
                          alt="modal title"
                          style={{marginTop: -80, width: '100%'}}
                        />
                        <div className="mb-0 px-4">
                          <img
                            src={BUCKET_IMAGES.SCAN_BILL_DUPLICATED_CONTENT}
                            alt="modal content"
                            style={{width: '90%'}}
                          />
                        </div>
                        <form className="">
                          <input
                            type="file"
                            id="input-file-upload-bill-fail"
                            accept="image/png, image/gif, image/jpeg"
                            capture="camera"
                            onChange={handleUploadMoreBill}
                            style={{
                              display: 'none',
                            }}
                          />
                        </form>

                        <label
                          htmlFor="input-file-upload-bill-fail"
                          className="is-primary-custom mt-2 mb-6"
                          style={{
                            color: '#fff',
                            padding: '10px 30px',
                            fontSize: 16,
                            fontWeight: 700,
                            display: 'block',
                            width: '60%',
                            textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                            margin: 'auto',
                          }}
                        >
                          CHỤP LẠI
                        </label>
                      </div>
                    </div>
                    <div style={{height: 20}} />
                  </>
                )}
                {/* ......START POPUP REJECT_EXCEED..... */}
                {modalState === MODAL_STATES.REJECT_EXCEED && (
                  <>
                    <div style={{height: 50}} />
                    <div className="popup-scanbill-bg-v1">
                      <div
                        style={{
                          position: 'absolute',
                          bottom: -5,
                          left: 10,
                        }}
                      >
                        <img src={balloon} alt="balloon" />
                      </div>
                      <div className=" px-4 has-text-centered pt-6">
                        <img
                          src={BUCKET_IMAGES.CHECK_IN_ALREADY_TITLE}
                          // className=""
                          alt="modal title"
                          style={{marginTop: -90, width: '90%'}}
                        />
                        <div className="mb-0 px-4">
                          {/* <div
                            className="has-text-weight-semibold py-4 mx-auto"
                            style={{
                              width: '90%',
                              borderRadius: 14,
                              color: '#323130',
                              backgroundColor: 'rgba(255, 241, 183, 0.5)',
                              fontSize: 16,
                              fontWeight: 600,
                            }}
                          >
                            <div>Chiller đã tích điểm hơn 48</div>
                            <div>chai trong ngày rồi, hẹn </div>
                            <div>Chiller ngày mai gặp lại</div>
                            <div>nhé!</div>
                          </div> */}
                          {/* SCAN_BILL_REJECTED_EXCEED */}
                          <img
                            src={BUCKET_IMAGES.SCAN_BILL_REJECTED_EXCEED_48_CONTENT}
                            alt="modal content"
                            style={{width: '90%'}}
                          />
                        </div>
                        <div>
                          <button
                            className="button button-custom is-uppercase is-second-custom button-text-mission has-text-info mt-4 mb-5"
                            onClick={() => {
                              setModalState(MODAL_STATES.NONE);
                              navigate(ROUTE_NAME.HOME);
                            }}
                            style={{
                              width: '60%',
                              minWidth: 150,
                              maxWidth: 300,
                              fontSize: 16,
                              fontWeight: 700,
                            }}
                          >
                            Trang Chủ
                          </button>
                        </div>
                      </div>
                    </div>
                    <div style={{height: 20}} />
                  </>
                )}
                {/* .........END POPUP REJECT_EXCEED...... */}

                {/* ......START POPUP EXPIRED_DATE..... */}
                {modalState === MODAL_STATES.EXPIRED_DATE && (
                  <>
                    {/* <div style={{height: 50}} /> */}
                    <div className="popup-scanbill-bg-v1">
                      <div
                        style={{
                          position: 'absolute',
                          bottom: -5,
                          left: 10,
                        }}
                      >
                        <img src={balloon} alt="balloon" />
                      </div>
                      <div className=" px-4 has-text-centered pt-6">
                        <img
                          src={BUCKET_IMAGES.EXPIRED_DATE_TITLE}
                          alt="modal title"
                          style={{marginTop: -80, width: '100%'}}
                        />
                        <div className="mb-0 px-4">
                          <img
                            src={BUCKET_IMAGES.EXPIRED_DATE_CONTENT}
                            alt="modal content"
                            style={{width: '90%'}}
                          />
                        </div>
                        <form className="">
                          <input
                            type="file"
                            id="input-file-upload-bill-fail"
                            accept="image/png, image/gif, image/jpeg"
                            capture="camera"
                            onChange={handleUploadMoreBill}
                            style={{
                              display: 'none',
                            }}
                          />
                        </form>

                        <label
                          htmlFor="input-file-upload-bill-fail"
                          className="is-primary-custom mt-2 mb-6"
                          style={{
                            color: '#fff',
                            padding: '10px 30px',
                            fontSize: 16,
                            fontWeight: 700,
                            display: 'block',
                            width: '60%',
                            textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                            margin: 'auto',
                          }}
                        >
                          CHỤP LẠI
                        </label>
                      </div>
                    </div>
                    <div style={{height: 20}} />
                  </>
                )}
                {/* .........END POPUP EXPIRED_DATE...... */}
                {modalState === MODAL_STATES.ID_VERIFY_PENDING && (
                  <>
                    <div className="popup-scanbill-bg-v1">
                      <div className=" px-4 has-text-centered pt-6">
                        <img
                          src={BUCKET_IMAGES.WAIT_TITLE}
                          style={{marginTop: -90, width: '90%'}}
                          alt="modal title"
                        />
                        <div className="mb-0 px-4">
                          <img
                            src={BUCKET_IMAGES.ID_PENDING_CONTENT}
                            alt="modal content"
                            style={{width: '90%'}}
                          />
                        </div>
                        <div>
                          <button
                            className="button button-custom is-uppercase is-second-custom button-text-mission has-text-info mt-4 mb-5"
                            onClick={() => {
                              setModalState(MODAL_STATES.NONE);
                            }}
                            style={{
                              width: '60%',
                              minWidth: 150,
                              maxWidth: 300,
                              fontSize: 16,
                              fontWeight: 700,
                            }}
                          >
                            Trở về
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'relative',
                        top: '-7%',
                        left: '2%',
                      }}
                    >
                      <img src={balloon} alt="balloon" />
                    </div>
                  </>
                )}
                {modalState === MODAL_STATES.ID_VERIFY_REQUIRED && (
                  <>
                    <div className="popup-scanbill-bg-v1">
                      <div className=" px-4 has-text-centered pt-6">
                        <img
                          src={BUCKET_IMAGES.WAIT_TITLE}
                          style={{marginTop: -90, width: '90%'}}
                          alt="modal title"
                        />
                        <div className="mb-0 px-4">
                          <img
                            src={BUCKET_IMAGES.ID_NOT_VERIFIED_CONTENT}
                            alt="modal content"
                            style={{width: '90%'}}
                          />
                        </div>
                        <div>
                          <button
                            className="button is-uppercase button-custom is-primary-custom button-text-mission mt-2"
                            onClick={() => {
                              setModalState(MODAL_STATES.NONE);
                              navigate(ROUTE_NAME.EDIT_PROFILE);
                            }}
                            style={{
                              width: '60%',
                              minWidth: 150,
                              maxWidth: 300,
                              fontSize: 16,
                              fontWeight: 700,
                            }}
                          >
                            Cập nhật CMND
                          </button>
                          <button
                            className="button button-custom is-uppercase is-second-custom button-text-mission has-text-info mt-4 mb-5"
                            onClick={() => {
                              setModalState(MODAL_STATES.NONE);
                            }}
                            style={{
                              width: '60%',
                              minWidth: 150,
                              maxWidth: 300,
                              fontSize: 16,
                              fontWeight: 700,
                            }}
                          >
                            Trở về
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'relative',
                        top: '-7%',
                        citizenIdInfoleft: '2%',
                      }}
                    >
                      <img src={balloon} alt="balloon" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-chill-pc">
            <div>
              {isLogIn &&
                (citizenIdInfo?.data === null || citizenIdInfo?.data?.status !== 1) &&
                rewardInfo?.totalRewardsAvailable >= 400 &&
                rewardInfo?.totalRewardsAvailable <= 500 && (
                  <span
                    onClick={() => {
                      handleWarningPolicyEditProfile();
                    }}
                  >
                    <img src={warning_popup} class="fix" />
                  </span>
                )}
              <img
                src={Benner}
                style={{
                  width: '100%',
                }}
              />
            </div>

            <div className="container" style={{paddingTop: 0}}>
              <div className="container-content">
                {!isLogIn ? (
                  <>
                    <div className="mb-6">{meoChillBanner}</div>
                    <div className="columns">
                      <div
                        className="column has-text-centered"
                        style={{
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            right: 0,
                            transform: 'rotate(12.91deg)',
                          }}
                        >
                          <img
                            src={bottle}
                            style={{
                              width: 142,
                            }}
                          />
                        </div>
                        <img src={home_1} />
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column has-text-centered">
                        <img src={home_2} />
                      </div>
                    </div>
                    <QuickLogin fromHome={true} />
                  </>
                ) : (
                  <>
                    <div className="mb-5">{meoChillBanner}</div>

                    <div className="has-text-centered">
                      <div>
                        <img src={scanBillBanner} style={{width: '50%'}} />
                      </div>
                    </div>
                    <div className="columns" style={{marginTop: -30}}>
                      <div
                        className="column  has-text-centered"
                        style={{fontSize: 24, color: '#343434'}}
                      >
                        <div>Scan ngay QR code trên bao bì hoặc cửa hàng tiện lợi để tham gia.</div>
                        <div>Chiller chụp hóa đơn thật nhiều để tích Chill Koin nhận quà nhé!.</div>
                      </div>
                    </div>
                    <div className="has-text-centered">
                      <img src={home_prcode} style={{width: '65%'}} />
                    </div>
                    <div className="has-text-centered">
                      <img src={howToScan} style={{width: '65%'}} />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div>
              <img
                src={bg_hr}
                style={{
                  width: '100%',
                  height: 130,
                }}
              />
            </div>
            <div className="container">
              <div>
                <HistoryPrizeList
                  data={luckDraws}
                  currentPage={luckPageIndex}
                  totalPages={luckDrawTotalPages}
                  onChangePage={setLuckPageIndex}
                  dataBill={historyScanBill}
                  currentPageHistory={historyPageIndex}
                  totalPagesHistory={historyTotalPages}
                  onChangePageHistory={setHistoryPageIndex}
                  onChangesrSearch={handleSearch}
                />
              </div>
              <div className="mt-3">
                <ContactButton />
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};
const TermAndConditionContent = () => {
  return (
    <div className=" is-relative">
      <div className="tnc_wrapper" id="tncScroll" style={{height: 300}}>
        <div className="scroll__action">
          <div className="tnc__detail pl-4 pr-5" style={{fontSize: 14}}>
            <p style={{display: ''}}>
              <div className="has-text-centered" style={{fontSize: 20}}>
                <div className="has-text-weight-bold">THỂ LỆ CHƯƠNG TRÌNH</div>

                <strong>SĂN ĐIỂM CHILL, PHIÊU QUÀ XỊN</strong>
              </div>
              <br />
              {/* <br /> */}
              <strong>1. Tên chương trình khuyến mại: “SĂN ĐIỂM CHILL, PHIÊU QUÀ XỊN”</strong>
              <br />
              <br />
              <strong>2. Thời gian khuyến mại:</strong> Từ ngày 20/10/2022 đến ngày 28/02/2023
              <br />
              <br />
              <strong>3. Địa bàn (phạm vi) khuyến mại</strong> Các cửa hàng tiện lợi Circle K, GS25,
              7 – Eleven trên địa bàn TP Hà Nội, TP Hồ Chí Minh
              <br />
              <br />
              <strong>4. Hình thức khuyến mại:</strong> Tích lũy điểm đổi quà và Quay vòng quay may
              mắn
              <br />
              <br />
              <strong>5. Đối tượng tham gia</strong>
              <br />
              <strong>a. Khách hàng của chương trình khuyến mãi: </strong>Công dân có quốc tịch Việt
              Nam trên 18 tuổi, có đủ năng lực hành vi dân sự và hiện đang sinh sống tại Việt Nam.
              <br />
              <strong>b. Đối tượng không được tham gia chương trình khuyến mãi: </strong>Nhân viên
              Công ty TNHH nhà máy Heineken Việt Nam, Công ty TNHH Bia và Nước Giải Khát Heineken
              Việt Nam, Công ty TNHH HVBL Đà Nẵng, Công ty TNHH HVBL Tiền Giang cũng như các Công ty
              con hoặc các Công ty liên doanh, liên kết của HVBL (sau đây gọi chung là “HVBLs”), tất
              cả các đơn vị phân phối chính thức của HVBLs, nhân viên công ty quảng cáo và công ty
              dịch vụ cho HVBLs, cũng như cha, mẹ, vợ, chồng, con của những người này không được
              tham gia chương trình này.
              <span class="fsi">(sau đây gọi tắt là “Người tham gia”)</span>.
              <br />
              <br />
              <strong>6. Cách thức tham gia:</strong>
              <br />
              Bước 1: Mua Strongbow ở các cửa hàng tiện lợi (GS25, Circle K, 7 Eleven)
              <br />
              Bước 2: Scan mã QR trên bao bì Strongbow hoặc các ấn phẩm tại địa điểm bán hàng sau đó
              chụp hóa đơn để tích điểm.
              <br />
              Bước 3: Đổi điểm nhận quà cực phiêu hoặc tham gia vòng xoay may mắn để có cơ hội nhận
              nhiều quà hấp dẫn khác.
              <br />
              <br />
              <strong>7. Cơ cấu giải thưởng</strong>
              <br />
              <strong>Giải may mắn:</strong> Iphone 14 Pro 128 G, Airpod Pro 2022, Máy chiếu phim
              Beecube X Strongbow, Túi tote Strongbow, Mũ Strongbow, Túi chéo Strongbow, Vé xem phim
              CGV
              <br />
              <strong>Giải tích lũy:</strong> Dây đeo thẻ Strongbow, Mũ Strongbow, Túi chéo
              Strongbow, Máy chiếu phim Strongbow, Túi tote Strongbow, Loa Marshall, Iphone 14 Pro,
              MacBook Pro M2 2022 13 inch, Apple Watch Ultra GPS, Airpod Pro 2022, Vé xem phim CGV,
              Voucher GOT IT 10k, Voucher GOT IT 25k, Voucher GOT IT 50k, Voucher TIKI 10k, Voucher
              TIKI 25k, Voucher TIKI 50k
              <br />
              Chi tiết thông tin về Số lượng, trị giá giải thưởng, cách thức quy đổi vui lòng xem
              thông tin chi tiết tại{' '}
              <a href="https://strongbowchillkoin.com/thele" target="_blank" rel="noreferrer">
                strongbowchillkoin.com/thele
              </a>
              <br />
              Tất cả giải thưởng không thể quy đổi thành tiền mặt. Màu sắc của giải thường sẽ tùy
              vào thuộc quyền quyết định của ban tổ chức. Khách hàng không được lựa chọn màu sắc của
              quà tặng. Số lượng quà tặng có hạn, chương trình có thể sẽ kết thúc sớm hơn thời gian
              dự kiến khi quà tặng đã được tặng hết.
              <br />
              <br />
              <strong>8. Nội dung chi tiết thể lệ chương trình khuyến mại</strong>
              <br />
              Trong thời gian khuyến mại, khách hàng mua sản phẩm khuyến mại trên địa bàn khuyến mãi
              sẽ nhận được điểm thưởng tương ứng.
              <br />
              Khách hàng tích lũy điểm thưởng để đổi quà tặng hoặc các lượt quay vòng xoay may mắn
              để có cơ hội nhận được các giải thưởng của chương trình.
              <br />
              Cơ cấu tính điểm thưởng như sau:
              <ul className="ml-4" style={{listStyle: 'disc'}}>
                <li>
                  Mua từ 1 đến 5 chai/lon Strongbow các loại:{' '}
                  <strong>tích 10 điểm/ 1 chai hoặc lon</strong>
                </li>
                <li>
                  Mua từ 6 đến 11 chai/lon Strongbow các loại:{' '}
                  <strong>tích 11 điểm/ 1 chai hoặc lon</strong>
                </li>
                <li>
                  Mua từ 12 đến 23 chai/lon Strongbow các loại:{' '}
                  <strong>tích 12 điểm/ 1 chai hoặc lon</strong>
                </li>
                <li>
                  Mua từ 24 chai/lon Strongbow các loại trở lên:{' '}
                  <strong>tích 15 điểm/ 1 chai hoặc lon</strong>
                </li>
              </ul>
              <br />
              Thời hạn cuối cùng để khách hàng mua hàng, tải hóa đơn lên website để nhận điểm thưởng
              là 23:00 ngày 28/02/2023 và thời hạn cuối cùng tích điểm thưởng là đến hết 23:30 ngày
              28/02/2023.
              <br />
              Số CMND/CCCD Người tham dự nhập trên hệ thống sẽ được Công ty sử dụng để xác nhận tính
              hợp lệ của người trúng thưởng.
              <br />
              Công ty có quyền từ chối trao giải thưởng nếu phát hiện Người tham dự dưới 18 tuổi.
              Giải thưởng bị huỷ sẽ được cộng dồn vào các ngày tiếp theo tuỳ thuộc thời gian chương
              trình diễn ra
              <br />
              Mỗi số điện thoại chỉ được đăng ký cùng một số CMND duy nhất
              <br />
              <br />
              <strong>9. Quy định về bằng chứng xác định trúng thưởng</strong>
              <br />
              Hóa đơn hợp lệ để tham gia chương trình phải là hóa đơn gốc được in ra từ chuỗi cửa
              hàng tiện lợi Circle K, 7-Eleven, Gs25.
              <br />
              Hóa đơn phải còn nguyên vẹn, không rách rời, không cạo sửa, tẩy xóa, bôi, vẽ, hay làm
              mờ hoặc bị những lý do tương tự. Mỗi hóa đơn chỉ được sử dụng để tích điểm 01 lần.
              <br />
              Thông tin hiển thị trên hóa đơn bắt buộc phải hiển thị đầy đủ, rõ ràng bao gồm: thông
              tin mã hóa đơn, logo của cửa hàng tiện lợi đã mua, ngày tháng xuất hóa đơn, tên sản
              phẩm đã mua (hiển thị đầy đủ theo thông tin hệ thống của cửa hàng tiện lợi), số tiền,
              số lượng sản phẩm.
              <br />
              <strong>10. Thời gian, địa điểm và cách thức xác định trúng thưởng</strong>
              <br />
              Đối với phần quà Dây đeo thẻ, Mũ Strongbow, Túi chéo Strongbow, Máy chiếu phim
              Strongbow, Túi tote Strongbow, Loa Marshall: Chuyển phát theo đường bưu điện đến tận
              tay khách hàng. Theo thông tin khách hàng đã đăng ký.
              <br />
              Đối với phần quà Vé xem phim CGV, Voucher Tiki, Voucher GOT IT: Trao thưởng trực tiếp
              vào tài khoản thuê bao/ tài khoản Zalo tham gia dự thưởng
              <br />
              <b>Đối với phần quà Iphone 14 Pro,</b> MacBook Pro M2 2022 13 inch, Apple Watch Ultra
              GPS, <b>Airpod Pro 2022</b>:Trong vòng 05 ngày sau khi thông báo trúng thưởng cho
              người trúng thưởng, công ty sẽ liên lạc trực tiếp đến người trúng thưởng bằng điện
              thoại/zalo theo số điện thoại người trúng thưởng tham gia chương trình để hướng dẫn
              trao giải
              <br />
              Đối với giải thưởng <b>phone 14 Pro,</b> MacBook Pro M2 2022 13 inch, Apple Watch
              Ultra GPS sẽ không có giá trị quy đổi thành tiền mặt, khách hàng cần phải đóng thuế
              thu nhập cá nhân cho khoản thu nhập bất thường là 10% phần giá trị vượt 10 triệu đồng.
              Thủ tục với cơ quan thuế sẽ được công ty dịch vụ của HVBL hỗ trợ thực hiện.
              <br />
              <br />
              <strong>
                11. Đầu mối giải đáp thắc mắc cho khách hàng về các vấn đề liên quan đến chương
                trình khuyến mại:{' '}
              </strong>
              <br />
              Mọi thắc mắc về chương trình, Người tham dự có thể liên hệ với Công ty TNHH Nhà máy
              bia Heineken Việt Nam thông qua Hotline: 19001845 (từ 9:00 đến 17:00 , từ thứ 2 đến
              thứ 6 hàng tuần, trừ ngày lễ), bộ phận chăm sóc người tham dự hoặc tại fanpage:{' '}
              <a href="https://www.facebook.com/StrongbowVN" target="_blank" rel="noreferrer">
                https://www.facebook.com/StrongbowVN
              </a>
              <br />
              <br />
              <strong>12. Các qui định khác:</strong>
              <br />
              <strong>&emsp;12.1 Quyền và trách nhiệm của Người tham dự</strong>
              <ul className="" style={{}}>
                <li>
                  - Người tham dự cần phải đọc và hiểu rõ thể lệ của Chương trình, đồng ý và tuân
                  theo quy định bản thể lệ này. Người tham dự cam kết các thông tin cung cấp cho
                  chương trình là chính xác và hợp pháp.
                </li>
                <li>
                  - Nếu cần, Người tham dự sẽ được yêu cầu đồng ý sử dụng cookie và chia sẻ dữ liệu
                  cá nhân của công ty. Để xem thêm thông tin về những cookie và chính sách bảo vệ
                  quyền riêng tư công ty sử dụng và cách công ty sử dụng chúng, vui lòng xem lại
                  Chính sách cookie riêng biệt của BTC bằng cách nhấn vào đường dẫn
                  Strongbowchillkoin.com /the-le (Chính sách bảo vệ quyền riêng tư).
                </li>
                <li>
                  - Bằng cách tham gia, Người tham dự đồng ý và tuân theo quy định của bản thể lệ
                  này. Trường hợp được Người tham dự trúng thưởng đồng ý, Công ty TNHH Nhà Máy Bia
                  HEINEKEN Việt Nam (HVBL) sẽ sử dụng thông tin cá nhân Người tham dự; sử dụng, sao
                  chép, sửa đổi, cắt ghép, cải biên hình ảnh; truyền đạt hình ảnh hoặc một phần hình
                  ảnh với mục đích truyền thông, quảng cáo thương mại trên các kênh truyền thông
                  liên quan đến nhãn hàng.
                </li>
              </ul>
              <strong>
                &emsp;12.2 Quyền và trách nhiệm của Công ty TNHH Nhà máy bia Heineken Việt Nam
              </strong>
              <br />- Công ty TNHH Nhà máy bia Heineken Việt Nam sẽ không chịu trách nhiệm đối với
              việc giải thưởng bị thất lạc do thông tin Người tham dự cung cấp không chính xác,
              thiếu thông tin hoặc không liên lạc được với Người tham dự khi nhân viên trao giải
              thưởng đến giao quà.
              <br />- Trường hợp xảy ra sự kiện bất khả kháng theo quy định pháp luật (bao gồm nhưng
              không giới hạn do thiên tai, lũ lụt, sét đánh, khủng bố tấn công máy chủ làm thất
              thoát dữ liệu của người dự thi, …), Công ty TNHH Nhà máy bia Heineken Việt Nam có thể
              thay đổi hoặc hủy bỏ Chương trình nếu được cơ quan quản lý nhà nước có thẩm quyền xác
              nhận và thông báo với Người tham dự trong thời gian sớm nhất.
              <br />- Nếu có bất kỳ thay đổi nào về thể lệ của Chương trình, Công ty TNHH Nhà máy
              bia Heineken Việt Nam sẽ cập nhật và thông báo trên website Strongbowchillkoin.com sau
              khi được cơ quan quản lý nhà nước có thẩm quyền xác nhận. Công ty TNHH Nhà máy bia
              Heineken Việt Nam có trách nhiệm bảo mật thông tin cá nhân cho Người tham dự cuộc thi
              này, không chia sẻ cho bên thứ ba và chỉ sử dụng cho mục đích trao đổi thông tin giữa
              Công ty TNHH Nhà máy bia Heineken Việt Nam và Người tham dự theo chính sách bảo vệ
              quyền riêng tư tại trang Strongbowchillkoin.com.
              <br />- Công ty TNHH Nhà máy bia Heineken Việt Nam có toàn quyền gỡ bỏ những hình ảnh/
              tài khoản của Người tham dự nếu phát hiện và chứng minh được thông tin không đúng sự
              thật hoặc có chứa nội dung vi phạm quy định thể lệ này
              <br />
              <strong>&emsp;12.3 Quy định chung</strong>
              <br />- Mọi thắc mắc về chương trình, Người tham dự có thể liên hệ với Công ty TNHH
              Nhà máy bia Heineken Việt Nam thông qua Hotline: 19001845, bộ phận chăm sóc người tham
              dự hoặc tại fanpage:{' '}
              <a href="https://www.facebook.com/StrongbowVN" target="_blank" rel="noreferrer">
                https://www.facebook.com/StrongbowVN
              </a>
              <br />- Khách hàng trúng thưởng cần cung cấp đầy đủ, chính xác các thông tin được yêu
              cầu để phục vụ việc kiểm tra, thẩm định tính hợp lệ của việc trúng thưởng.
              <br />- Công Ty TNHH nhà máy bia Heineken Việt Nam được sử dụng tên, hình ảnh của
              người tiêu dùng trúng thưởng vào mục đích quảng cáo thương mại khi có sự đồng ý của
              Khách hàng.
              <br />- Công Ty TNHH Nhà Máy Bia Heineken Việt Nam hoàn toàn chịu trách nhiệm trong
              việc quản lý khâu in ấn, tính chính xác của bằng chứng xác định trúng thưởng lưu thông
              trong thời gian khuyến mại. Việc tổ chức Chương trình phải đảm bảo tính công bằng,
              minh bạch và khách quan đúng theo quy định của pháp luật hiện hành.
              <br />- Khi phát sinh tranh chấp, khiếu nại liên quan đến Chương trình, Công Ty TNHH
              nhà náy bia Heineken Việt Nam sẽ đứng ra trực tiếp giải quyết theo quy định của pháp
              luật hiện hành.
              <br />- Công Ty TNHH nhà máy bia Heineken Việt Nam có trách nhiệm báo cáo kết quả thực
              hiện khuyến mại trong thời hạn 45 ngày kể từ ngày kết thúc thời hạn trao thưởng, lưu
              trữ và chịu trách nhiệm về chứng từ, tài liệu liên quan đến báo cáo theo đúng quy định
              của pháp luật để phục vụ công tác thanh tra, kiểm tra, giám sát.
              <br />- Đối với những giải thưởng không có người trúng thưởng Công Ty TNHH nhà máy bia
              Heineken Việt Nam có trách nhiệm nộp 50% giá trị đã công bố của giải thưởng đó vào
              ngân sách Nhà nước theo quy định tại khoản 4 Điều 96 Luật Thương Mại./.
            </p>
          </div>
        </div>
      </div>
      {/* <div id="ascrail2000" class="nicescroll-rails nicescroll-rails-vr">
        <div class="nicescroll-cursors"></div>
      </div> */}
    </div>
  );
};
const TermAndCondition = () => {
  useEffect(() => {
    //window.location.reload();
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleConfirm = (bool) => {
    if (bool) {
      dispatch(toggleOver18(bool));
    } else {
      navigate(ROUTE_NAME.SEE_YOU_AGAIN);
    }
  };
  const formik = useFormik({
    initialValues: {
      isAgree: false,
    },
    validationSchema: Yup.object().shape({
      isAgree: Yup.boolean().oneOf([true], 'Bạn chưa đủ 18 tuổi?'),
    }),
    onSubmit: (values) => {
      handleConfirm(true);
    },
  });
  return (
    <>
      {isMobile ? (
        <Layout
          headerProps={{
            index: 0,
          }}
        >
          <div className="container-chill pb-4 px-4 " style={{marginTop: 0, paddingTop: 80}}>
            <div
              className="container tac-bg-home-v1"
              style={{
                paddingRight: 10,
                paddingLeft: 25,
                maxWidth: 450,
                paddingBottom: 24,
              }}
            >
              <div
                className=" bg-white pt-14 pb-10 pr-4"
                style={{
                  paddingTop: 130,
                }}
              >
                <TermAndConditionContent />
                <div className=" flex justify-center flex-direction-column ">
                  <div>
                    <div class="check__item px-2 py-2 mt-4 is-flex is-align-items-center is-justify-content-space-between is-flex-direction-row-reverse">
                      <div class=" ml-2">
                        <input
                          class="is-checkradio has-background-color"
                          id="exampleCheckbox"
                          type="checkbox"
                          name="exampleCheckbox"
                          checked={formik?.values?.isAgree}
                          onChange={(e) => {
                            formik.setFieldTouched('isAgree', true, true);
                            formik.setFieldValue('isAgree', e.target.checked);
                          }}
                        />
                        <label
                          className="has-text-info has-text-weight-semibold  is-size-17"
                          for="exampleCheckbox"
                        >
                          Tôi đã đủ <span>18 tuổi</span>
                        </label>
                      </div>
                      {get(formik.touched, 'isAgree', false) && !!get(formik.errors, 'isAgree') && (
                        <p className="help mb-0 is-danger has-text-danger has-text-weight-bold is-size-8 is-size-5-tablet">
                          <span>{get(formik.errors, 'isAgree')}</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column has-text-centered">
                <button
                  className="button is-second-custom is-uppercase mb-1 mr-1 mb-4"
                  style={{
                    color: '#83552D',
                    padding: '14px 10px',
                    fontSize: 16,
                    fontWeight: 700,
                    textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                    width: 160,
                  }}
                  onClick={() => {
                    handleConfirm(false);
                  }}
                >
                  Không đồng ý
                </button>
                <button
                  className={'button is-primary-custom mb-1 mr-1 is-uppercase'}
                  style={{
                    color: '#FFF',
                    padding: '14px 10px',
                    fontSize: 16,
                    fontWeight: 700,
                    textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                    width: 160,
                  }}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Đồng ý
                </button>
              </div>
            </div>
          </div>
        </Layout>
      ) : (
        <>
          <div className="header">
            <div className="header-inr">
              <a className="logo pic" href="./">
                <img src={logo} width={159} />
              </a>
            </div>
          </div>
          <div className="page">
            <div className="banner-box">
              <div className="tnc__news--wrap">
                <div className="pc pic island island__tnc island__tnc__news">
                  <img className="cm" src={island} alt="Island" />
                  <div className="pc pic decor decor__tnc1">
                    <img
                      class="pc"
                      width="89"
                      height="205"
                      src={decor__master2}
                      alt="Khinh khí cầu"
                    />
                  </div>
                  <div className="pc pic decor decor__tnc3">
                    <img
                      class="pc"
                      width="89"
                      height="205"
                      src={decor__master2}
                      alt="Khinh khí cầu"
                    />
                  </div>
                </div>
                <div className="banner-info tnc-info tnc-info__news">
                  <div className="tnc__news__inner">
                    <div className="pic tnc-letter">
                      <img class="pc" src={tnc_letter} alt="letter" />
                    </div>
                    <div className="brief brief__tnc" id="tncScroll">
                      <div
                        className="scroll__action"
                        style={{overflowY: 'auto', outline: 'none', cursor: 'grab'}}
                      >
                        <div className="tnc__detail" style={{paddingRight: 30}}>
                          <h2>
                            THỂ LỆ CHƯƠNG TRÌNH
                            <br />
                            SĂN ĐIỂM CHILL, PHIÊU QUÀ XỊN
                          </h2>
                          <h3>1. Tên chương trình khuyến mại:</h3>
                          <div>“SĂN ĐIỂM CHILL, PHIÊU QUÀ XỊN”.</div>
                          <br />
                          <h3>2. Thời gian khuyến mại:</h3>
                          <div>Từ ngày 20/10/2022 đến ngày 28/02/2023.</div>
                          <br></br>
                          <h3>3. Địa bàn (phạm vi) khuyến mại:</h3>
                          <div>
                            Các cửa hàng tiện lợi Circle K, GS25, 7 – Eleven trên địa bàn TP Hà Nội,
                            TP Hồ Chí Minh.
                          </div>
                          <br></br>
                          <h3>4. Hình thức khuyến mại:</h3>
                          <div>Tích lũy điểm đổi quà và Quay vòng quay may mắn</div>
                          <br></br>
                          <h3>5. Đối tượng tham gia</h3>
                          <div>
                            a. <strong>Khách hàng của chương trình khuyến mãi:</strong> Công dân có
                            quốc tịch Việt Nam trên 18 tuổi, có đủ năng lực hành vi dân sự và hiện
                            đang sinh sống tại Việt Nam.
                          </div>
                          <div>
                            b.{' '}
                            <strong>Đối tượng không được tham gia chương trình khuyến mãi:</strong>{' '}
                            Nhân viên Công ty TNHH nhà máy Heineken Việt Nam, Công ty TNHH Bia và
                            Nước Giải Khát Heineken Việt Nam, Công ty TNHH HVBL Đà Nẵng, Công ty
                            TNHH HVBL Tiền Giang cũng như các Công ty con hoặc các Công ty liên
                            doanh, liên kết của HVBL (sau đây gọi chung là “HVBLs”), tất cả các đơn
                            vị phân phối chính thức của HVBLs, nhân viên công ty quảng cáo và công
                            ty dịch vụ cho HVBLs, cũng như cha, mẹ, vợ, chồng, con của những người
                            này không được tham gia chương trình này.
                          </div>
                          <br />
                          <h3>6. Cách thức tham gia</h3>
                          <div>
                            Bước 1: Mua Strongbow ở các cửa hàng tiện lợi (GS25, Circle K, 7 Eleven)
                          </div>
                          <div>
                            Bước 2: Scan mã QR trên bao bì Strongbow hoặc các ấn phẩm tại địa điểm
                            bán hàng sau đó chụp hóa đơn để tích điểm.
                          </div>
                          <div>
                            Bước 3: Đổi điểm nhận quà cực phiêu hoặc tham gia vòng xoay may mắn để
                            có cơ hội nhận nhiều quà hấp dẫn khác.
                          </div>
                          <br />
                          <h3>7. Cơ cấu giải thưởng</h3>
                          <div>
                            <strong>Giải may mắn:</strong> Iphone 14 Pro 128 G, Airpod Pro 2022, Máy
                            chiếu phim Beecube X Strongbow, Túi tote Strongbow, Mũ Strongbow, Túi
                            chéo Strongbow, Vé xem phim CGV
                          </div>
                          <div>
                            <strong>Giải tích lũy:</strong> Dây đeo thẻ Strongbow, Mũ Strongbow, Túi
                            chéo Strongbow, Máy chiếu phim Strongbow, Túi tote Strongbow, Loa
                            Marshall, Iphone 14 Pro, MacBook Pro M2 2022 13 inch, Apple Watch Ultra
                            GPS, Airpod Pro 2022, Vé xem phim CGV, Voucher GOT IT 10k, Voucher GOT
                            IT 25k, Voucher GOT IT 50k, Voucher TIKI 10k, Voucher TIKI 25k, Voucher
                            TIKI 50k
                          </div>
                          <div>
                            Chi tiết thông tin về Số lượng, trị giá giải thưởng, cách thức quy đổi
                            vui lòng xem thông tin chi tiết tại Strongbowchillkoin.com/thele
                          </div>
                          <div>
                            Tất cả giải thưởng không thể quy đổi thành tiền mặt. Màu sắc của giải
                            thường sẽ tùy vào thuộc quyền quyết định của ban tổ chức. Khách hàng
                            không được lựa chọn màu sắc của quà tặng. Số lượng quà tặng có hạn,
                            chương trình có thể sẽ kết thúc sớm hơn thời gian dự kiến khi quà tặng
                            đã được tặng hết.
                          </div>
                          <br />
                          <h3>8. Nội dung chi tiết thể lệ chương trình khuyến mại</h3>
                          <div>
                            Trong thời gian khuyến mại, khách hàng mua sản phẩm khuyến mại trên địa
                            bàn khuyến mãi sẽ nhận được điểm thưởng tương ứng.
                          </div>
                          <div>
                            Khách hàng tích lũy điểm thưởng để đổi quà tặng hoặc các lượt quay vòng
                            xoay may mắn để có cơ hội nhận được các giải thưởng của chương trình.
                          </div>
                          <div>Cơ cấu tính điểm thưởng như sau:</div>
                          <div>
                            - Mua từ 1 đến 5 chai/lon Strongbow các loại: tích 10 điểm/ 1 chai hoặc
                            lon
                          </div>
                          <div>
                            - Mua từ 6 đến 11 chai/lon Strongbow các loại: tích 11 điểm/ 1 chai hoặc
                            lon
                          </div>
                          <div>
                            - Mua từ 12 đến 23 chai/lon Strongbow các loại: tích 12 điểm/ 1 chai
                            hoặc lon
                          </div>
                          <div>
                            - Mua từ 24 chai/lon Strongbow các loại trở lên: tích 15 điểm/ 1 chai
                            hoặc lon
                          </div>
                          <div>
                            Thời hạn cuối cùng để khách hàng mua hàng, tải hóa đơn lên website để
                            nhận điểm thưởng là 23:00 ngày 28/02/2023 và thời hạn cuối cùng tích
                            điểm thưởng là đến hết 23:30 ngày 28/02/2023.
                          </div>
                          <div>
                            Số CMND/CCCD Người tham dự nhập trên hệ thống sẽ được Công ty sử dụng để
                            xác nhận tính hợp lệ của người trúng thưởng.
                          </div>
                          <div>
                            Công ty có quyền từ chối trao giải thưởng nếu phát hiện Người tham dự
                            dưới 18 tuổi. Giải thưởng bị huỷ sẽ được cộng dồn vào các ngày tiếp theo
                            tuỳ thuộc thời gian chương trình diễn ra
                          </div>
                          <div>Mỗi số điện thoại chỉ được đăng ký cùng một số CMND duy nhất</div>
                          <br />
                          <h3>9. Quy định về bằng chứng xác định trúng thưởng:</h3>
                          <div>
                            Hóa đơn hợp lệ để tham gia chương trình phải là hóa đơn gốc được in ra
                            từ chuỗi cửa hàng tiện lợi Circle K, 7-Eleven, Gs25.
                          </div>
                          <div>
                            Hóa đơn phải còn nguyên vẹn, không rách rời, không cạo sửa, tẩy xóa,
                            bôi, vẽ, hay làm mờ hoặc bị những lý do tương tự. Mỗi hóa đơn chỉ được
                            sử dụng để tích điểm 01 lần.
                          </div>
                          <div>
                            Thông tin hiển thị trên hóa đơn bắt buộc phải hiển thị đầy đủ, rõ ràng
                            bao gồm: thông tin mã hóa đơn, logo của cửa hàng tiện lợi đã mua, ngày
                            tháng xuất hóa đơn, tên sản phẩm đã mua (hiển thị đầy đủ theo thông tin
                            hệ thống của cửa hàng tiện lợi), số tiền, số lượng sản phẩm.
                          </div>
                          <br />
                          <h3>10. Thời gian, địa điểm và cách thức xác định trúng thưởng</h3>
                          <div>
                            Đối với phần quà Dây đeo thẻ, Mũ Strongbow, Túi chéo Strongbow, Máy
                            chiếu phim Strongbow, Túi tote Strongbow, Loa Marshall: Chuyển phát theo
                            đường bưu điện đến tận tay khách hàng. Theo thông tin khách hàng đã đăng
                            ký.
                          </div>
                          <div>
                            Đối với phần quà Vé xem phim CGV, Voucher Tiki, Voucher GOT IT: Trao
                            thưởng trực tiếp vào tài khoản thuê bao/ tài khoản Zalo tham gia dự
                            thưởng
                          </div>
                          <div>
                            Đối với phần quà Iphone 14 Pro, MacBook Pro M2 2022 13 inch, Apple Watch
                            Ultra GPS, Airpod Pro 2022:Trong vòng 05 ngày sau khi thông báo trúng
                            thưởng cho người trúng thưởng, công ty sẽ liên lạc trực tiếp đến người
                            trúng thưởng bằng điện thoại/zalo theo số điện thoại người trúng thưởng
                            tham gia chương trình để hướng dẫn trao giải
                          </div>
                          <div>
                            Đối với giải thưởng phone 14 Pro, MacBook Pro M2 2022 13 inch, Apple
                            Watch Ultra GPS sẽ không có giá trị quy đổi thành tiền mặt, khách hàng
                            cần phải đóng thuế thu nhập cá nhân cho khoản thu nhập bất thường là 10%
                            phần giá trị vượt 10 triệu đồng. Thủ tục với cơ quan thuế sẽ được công
                            ty dịch vụ của HVBL hỗ trợ thực hiện.
                          </div>
                          <br />
                          <h3>
                            11. Đầu mối giải đáp thắc mắc cho khách hàng về các vấn đề liên quan đến
                            chương trình khuyến mại :
                          </h3>
                          <div>
                            Mọi thắc mắc về chương trình, Người tham dự có thể liên hệ với Công ty
                            TNHH Nhà máy bia Heineken Việt Nam thông qua Hotline: 19001845 (từ 9:00
                            đến 17:00 , từ thứ 2 đến thứ 6 hàng tuần, trừ ngày lễ), bộ phận chăm sóc
                            người tham dự hoặc tại fanpage: https://www.facebook.com/StrongbowVN
                          </div>
                          <br />
                          <h3>12. Các qui định khác:</h3>
                          <h3>12.1. Quyền và trách nhiệm của Người tham dự</h3>
                          <div>
                            Người tham dự cần phải đọc và hiểu rõ thể lệ của Chương trình, đồng ý và
                            tuân theo quy định bản thể lệ này. Người tham dự cam kết các thông tin
                            cung cấp cho chương trình là chính xác và hợp pháp.
                          </div>
                          <div>
                            Nếu cần, Người tham dự sẽ được yêu cầu đồng ý sử dụng cookie và chia sẻ
                            dữ liệu cá nhân của công ty. Để xem thêm thông tin về những cookie và
                            chính sách bảo vệ quyền riêng tư công ty sử dụng và cách công ty sử dụng
                            chúng, vui lòng xem lại Chính sách cookie riêng biệt của BTC bằng cách
                            nhấn vào đường dẫn Strongbowchillkoin.com /the-le (Chính sách bảo vệ
                            quyền riêng tư).
                          </div>
                          <div>
                            Bằng cách tham gia, Người tham dự đồng ý và tuân theo quy định của bản
                            thể lệ này. Trường hợp được Người tham dự trúng thưởng đồng ý, Công ty
                            TNHH Nhà Máy Bia HEINEKEN Việt Nam (HVBL) sẽ sử dụng thông tin cá nhân
                            Người tham dự; sử dụng, sao chép, sửa đổi, cắt ghép, cải biên hình ảnh;
                            truyền đạt hình ảnh hoặc một phần hình ảnh với mục đích truyền thông,
                            quảng cáo thương mại trên các kênh truyền thông liên quan đến nhãn hàng.
                          </div>
                          <br />
                          <h3>
                            12.2. Quyền và trách nhiệm của Công ty TNHH Nhà máy bia Heineken Việt
                            Nam
                          </h3>
                          <div>
                            Công ty TNHH Nhà máy bia Heineken Việt Nam sẽ không chịu trách nhiệm đối
                            với việc giải thưởng bị thất lạc do thông tin Người tham dự cung cấp
                            không chính xác, thiếu thông tin hoặc không liên lạc được với Người tham
                            dự khi nhân viên trao giải thưởng đến giao quà.
                          </div>
                          <div>
                            Trường hợp xảy ra sự kiện bất khả kháng theo quy định pháp luật (bao gồm
                            nhưng không giới hạn do thiên tai, lũ lụt, sét đánh, khủng bố tấn công
                            máy chủ làm thất thoát dữ liệu của người dự thi, …), Công ty TNHH Nhà
                            máy bia Heineken Việt Nam có thể thay đổi hoặc hủy bỏ Chương trình nếu
                            được cơ quan quản lý nhà nước có thẩm quyền xác nhận và thông báo với
                            Người tham dự trong thời gian sớm nhất.
                          </div>
                          <div>
                            Nếu có bất kỳ thay đổi nào về thể lệ của Chương trình, Công ty TNHH Nhà
                            máy bia Heineken Việt Nam sẽ cập nhật và thông báo trên website
                            Strongbowchillkoin.com sau khi được cơ quan quản lý nhà nước có thẩm
                            quyền xác nhận. Công ty TNHH Nhà máy bia Heineken Việt Nam có trách
                            nhiệm bảo mật thông tin cá nhân cho Người tham dự cuộc thi này, không
                            chia sẻ cho bên thứ ba và chỉ sử dụng cho mục đích trao đổi thông tin
                            giữa Công ty TNHH Nhà máy bia Heineken Việt Nam và Người tham dự theo
                            chính sách bảo vệ quyền riêng tư tại trang Strongbowchillkoin.com.
                          </div>
                          <div>
                            Công ty TNHH Nhà máy bia Heineken Việt Nam có toàn quyền gỡ bỏ những
                            hình ảnh/ tài khoản của Người tham dự nếu phát hiện và chứng minh được
                            thông tin không đúng sự thật hoặc có chứa nội dung vi phạm quy định thể
                            lệ này.
                          </div>
                          <br />
                          <h3>12.3. Quy định chung</h3>
                          <div>
                            Mọi thắc mắc về chương trình, Người tham dự có thể liên hệ với Công ty
                            TNHH Nhà máy bia Heineken Việt Nam thông qua Hotline: 19001845, bộ phận
                            chăm sóc người tham dự hoặc tại fanpage:
                            https://www.facebook.com/StrongbowVN
                          </div>
                          <div>
                            Khách hàng trúng thưởng cần cung cấp đầy đủ, chính xác các thông tin
                            được yêu cầu để phục vụ việc kiểm tra, thẩm định tính hợp lệ của việc
                            trúng thưởng.
                          </div>
                          <div>
                            Công Ty TNHH nhà máy bia Heineken Việt Nam được sử dụng tên, hình ảnh
                            của người tiêu dùng trúng thưởng vào mục đích quảng cáo thương mại khi
                            có sự đồng ý của Khách hàng.
                          </div>
                          <div>
                            Công Ty TNHH Nhà Máy Bia Heineken Việt Nam hoàn toàn chịu trách nhiệm
                            trong việc quản lý khâu in ấn, tính chính xác của bằng chứng xác định
                            trúng thưởng lưu thông trong thời gian khuyến mại. Việc tổ chức Chương
                            trình phải đảm bảo tính công bằng, minh bạch và khách quan đúng theo quy
                            định của pháp luật hiện hành.
                          </div>
                          <div>
                            Khi phát sinh tranh chấp, khiếu nại liên quan đến Chương trình, Công Ty
                            TNHH nhà náy bia Heineken Việt Nam sẽ đứng ra trực tiếp giải quyết theo
                            quy định của pháp luật hiện hành.
                          </div>
                          <div>
                            Công Ty TNHH nhà máy bia Heineken Việt Nam có trách nhiệm báo cáo kết
                            quả thực hiện khuyến mại trong thời hạn 45 ngày kể từ ngày kết thúc thời
                            hạn trao thưởng, lưu trữ và chịu trách nhiệm về chứng từ, tài liệu liên
                            quan đến báo cáo theo đúng quy định của pháp luật để phục vụ công tác
                            thanh tra, kiểm tra, giám sát.
                          </div>
                          <div>
                            Đối với những giải thưởng không có người trúng thưởng Công Ty TNHH nhà
                            máy bia Heineken Việt Nam có trách nhiệm nộp 50% giá trị đã công bố của
                            giải thưởng đó vào ngân sách Nhà nước theo quy định tại khoản 4 Điều 96
                            Luật Thương Mại.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="check__item">
                      <input
                        class="is-checkradio has-background-color"
                        id="exampleCheckbox"
                        type="checkbox"
                        name="exampleCheckbox"
                        checked={formik?.values?.isAgree}
                        onChange={(e) => {
                          formik.setFieldTouched('isAgree', true, true);
                          formik.setFieldValue('isAgree', e.target.checked);
                        }}
                      />
                      <label className="check__label" for="exampleCheckbox">
                        Tôi đã đủ <span>18 tuổi</span>
                      </label>
                    </div>
                    {get(formik.touched, 'isAgree', false) && !!get(formik.errors, 'isAgree') && (
                      <div className="age-check show__error">
                        <div className="error">
                          <p>Bạn chưa đủ 18 tuổi?</p>
                        </div>
                      </div>
                    )}
                    <div className="pic decor decor__tnc2">
                      <img width="89" height="205" src={khinh_khi_cau} alt="Khinh khí cầu" />
                    </div>

                    <div className="pic">
                      <img className="tnc-emoji" width="66" height="64" src={emoji} alt="emoji" />
                    </div>
                    <div className="buts">
                      <button
                        type="button"
                        className="but tnc-cancel-but"
                        style={{border: 'none', background: 'none'}}
                        onClick={() => {
                          handleConfirm(false);
                        }}
                      >
                        <img src={tnc_cancel_but} alt="Không đồng ý" />
                      </button>
                      <button
                        type="button"
                        className="but tnc-ok-but"
                        style={{border: 'none', background: 'none'}}
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                      >
                        <img src={tnc_ok_but} alt="Đồng ý" />
                      </button>
                    </div>
                  </div>
                  <div className="pic new__leaf">
                    <img src={bottle_18} width={270} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className=" has-background-primary has-text-centered "
            style={{minHeight: 32, position: 'absolute', bottom: 0, left: 0, right: 0}}
          >
            <span
              className="mr-is-mobile-8 mr-1"
              style={{
                fontSize: 10,
                fontWeight: 700,
                position: 'relative',
                top: -8,
              }}
            >
              NGƯỜI DƯỚI 18 TUỔI KHÔNG ĐƯỢC UỐNG RƯỢU, BIA
            </span>
            <span className="icon is-size-3  mt-2 has-text-white mr-is-mobile-8 mr-1">
              <img src={icon_18} />
            </span>
            <span className="icon is-size-3 has-text-white mr-is-mobile-8 mr-1">
              <img src={icon_mother} />
            </span>
            <span className="icon is-size-3 has-text-white">
              <img src={icon_car} />
            </span>
          </div>
        </>
      )}
    </>
  );
};

const HomePage = () => {
  const [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!!searchParams.get('from')) {
      dispatch(toggleOver18(true));
    }
  }, [searchParams.get('from')]);
  const isOver18 = useSelector(isOver18Selector);
  const isLogin = useSelector(isLogInSelector);

  return isOver18 || isLogin ? <HomePageView /> : <TermAndCondition />;
};

export default HomePage;
