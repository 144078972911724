import AppLayout from 'components/layout';
import React, {useEffect, useMemo, useState} from 'react';
import './styless.scss';
import ContactButton from 'components/contactButton';
import {ROUTE_NAME} from 'constants/routes';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ContentContact from 'components/footerMeoChill/contact';
import QrReader from 'react-camera-qr';
import {useDispatch, useSelector} from 'react-redux';
import {getStoreDetailByCodeAction, validateHuntingCodeAction} from 'appRedux/actions/storeCheckin';
import classNames from 'classnames';
import {BUCKET_IMAGES, MODAL_STATES, QR_CODE_TYPE} from 'constants/meoChill';
import {isMobile} from 'react-device-detect';
import {isValidatingHuntingCodeSelector} from 'appRedux/selectors/storeCheckin';
import {
  resetStoreCheckIn,
  updateCodeType,
  updateModalState,
} from 'appRedux/slices/storeCheckinSlice';
import MeoChillModal from 'components/meoChillModal';
import {isLogInSelector, citizenIdInfoSelector} from 'appRedux/selectors/auth';
import {getRewardInfoAction} from 'appRedux/actions/app';
import {toast} from 'react-toastify';
import {getCitizenIdByMerchantIdAction} from 'appRedux/actions/auth';
import balloon from 'images/strongbow/pc/balloon.png';
import {useCallback} from 'react';
import {localStorageGetItem} from 'utils/storage';
import warning_popup from 'images/strongbow/pc/warning-popup.png';
import {rewardInfoSelector} from 'appRedux/selectors/app';

const MeoChill = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isValidatingHuntingCode = useSelector(isValidatingHuntingCodeSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [isZalo, setIsZalo] = useState(false);
  const [isShowZaloError, setShowZaloError] = useState(false);
  const [huntingCode, setHuntingCode] = useState('');
  const isLogIn = useSelector(isLogInSelector);
  const rewardInfo = useSelector(rewardInfoSelector);
  const citizenIdInfo = useSelector(citizenIdInfoSelector);
  const handleWarningPolicyEditProfile = () => {
    navigate(ROUTE_NAME.EDIT_PROFILE);
  };

  useEffect(() => {
    if (!!(searchParams.get('code') || '')) {
      setHuntingCode(searchParams.get('code'));
      setSearchParams({});
    }
  }, [searchParams.get('code')]);

  useEffect(() => {
    if (!huntingCode) return;
    dispatch(
      validateHuntingCodeAction({
        code: huntingCode,
      })
    );
    setHuntingCode('');
  }, [huntingCode]);

  useEffect(() => {
    dispatch(updateCodeType(QR_CODE_TYPE.HUNTING));
    const userAgent = window?.navigator?.userAgent || '';
    setIsZalo(userAgent.toLowerCase().includes('zalo'));
  }, []);

  const setMeoChillModalState = useCallback(
    (state) => {
      dispatch(updateModalState(state));
    },
    [dispatch]
  );

  const onConfirm = async () => {
    let continueFlow = true;
    if (isLogIn) {
      const [rewardInfo, citizenId] = await Promise.allSettled([
        dispatch(getRewardInfoAction()).unwrap(),
        dispatch(getCitizenIdByMerchantIdAction()).unwrap(),
      ]).then((res) => res.map((item) => item.value));

      // debugger;

      if (
        rewardInfo?.totalRewardsAvailable > 500 &&
        (!citizenId?.data || citizenId?.data?.status === 99)
      ) {
        setMeoChillModalState(MODAL_STATES.ID_VERIFY_REQUIRED);
        continueFlow = false;
      } else if (rewardInfo?.totalRewardsAvailable > 500 && citizenId?.data?.status === 0) {
        setMeoChillModalState(MODAL_STATES.ID_VERIFY_PENDING);
        continueFlow = false;
      } else {
        setMeoChillModalState(MODAL_STATES.NONE);
      }
    }
    if (continueFlow) {
      if (isZalo) {
        setShowZaloError(true);
      } else {
        setIsConfirm(true);
      }
    }
  };

  const handleScan = async (dataRaw) => {
    let data = dataRaw;
    if (data && !isScanning) {
      setIsScanning(true);
      let paths = data?.split?.('/')?.filter((item) => item) || [];
      if (!!paths.length) {
        if (data.includes('bit.ly')) {
          const lastPath = paths[paths.length - 1];
          const temp = await fetch(`https://api-ssl.bitl y.com/v4/bitlinks/bit.ly/${lastPath}`, {
            headers: {
              Authorization: 'Bearer 92b0aed2429ba75bc0a6a03d3f33168933ea08e4',
            },
          });
          const res = await temp.json();
          data = res?.long_url;
        }
        paths = data?.split?.('/')?.filter((item) => item) || [];
        const lastPath = paths[paths.length - 1];
        const codeType = lastPath.includes('?') ? QR_CODE_TYPE.HUNTING : QR_CODE_TYPE.SCAN_LOGO;
        if (codeType === QR_CODE_TYPE.SCAN_LOGO) {
          const storeCode = lastPath;
          !!storeCode &&
            dispatch(
              getStoreDetailByCodeAction({
                storeCode,
                callback: (err, data) => {
                  setIsScanning(false);
                  if (!err && !!data) {
                    navigate(`/meo-chill/${storeCode}`);
                  } else {
                    setIsConfirm(false);
                    setIsShowError(true);
                  }
                },
              })
            );
        } else if (codeType === QR_CODE_TYPE.HUNTING) {
          const splittedPaths = lastPath.split('=');
          const code = splittedPaths[splittedPaths.length - 1];
          !!code && setHuntingCode(code);
          setIsConfirm(false);
          setIsScanning(false);
        }
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const zaloPopUpMemo = useMemo(() => {
    if (!isZalo || !isShowZaloError) return null;

    return (
      <>
        <div
          className={classNames('modal px-5', {
            'is-active': !!isShowZaloError,
          })}
        >
          <div className="modal-background"></div>
          <div className="modal-card ">
            <div style={{position: 'absolute', right: 15, top: 60}}>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setShowZaloError(false);
                }}
              ></button>
            </div>
            <div style={{height: 50}} />
            <div className="popup-scanbill-bg">
              <div className="px-5 has-text-centered pt-6">
                <img
                  src={BUCKET_IMAGES.ZALO_INSTRUCTION_TITLE}
                  // className=""
                  alt="modal title"
                  style={{marginTop: -90}}
                />
                <div className=" has- is-size-7 has-text-black" style={{}}>
                  Chiller ơi, trình duyệt Zalo không hỗ trở mở ứng dụng camera để thực hiện
                  check-in. Chiller hãy mở trình duyệt trên máy để tiếp tục trải nghiệp nhé!
                </div>
                <div className="mb-4 mt-2 is-relative">
                  <img
                    src={BUCKET_IMAGES.ZALO_INSTRUCTION_CONTENT}
                    alt="modal content"
                    style={{width: '100%'}}
                  />
                  <img
                    src={BUCKET_IMAGES.ZALO_INSTRUCTION_APPLE}
                    alt="modal apple"
                    style={{
                      position: 'absolute',
                      width: '30%',
                      bottom: -20,
                      right: -20,
                    }}
                  />
                </div>
                <div>
                  <button
                    className="button is-uppercase button-custom is-primary-custom button-text-mission mt-2 mb-6"
                    onClick={() => {
                      setShowZaloError(false);
                    }}
                    style={{
                      width: '60%',
                      minWidth: 150,
                      maxWidth: 300,
                    }}
                  >
                    Trở về
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }, [isZalo, isShowZaloError]);

  return (
    <AppLayout ignoreOver18Check isFooter={false}>
      {isConfirm ? (
        <div style={{height: '100vh'}} className="container-home-meo-chill-camera">
          <div
            style={{height: 'calc(100vh - 44px)'}}
            className=" is-flex is-align-items-center is-flex-direction-column is-justify-content-center"
          >
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{width: '100%'}}
            />
            <div className="has-text-centered pt-1 pb-1">
              <button
                className="button-custom is-primary-custom button-text-mission mt-4"
                style={{
                  textTransform: 'uppercase',
                  width: 219,
                }}
                onClick={() => setIsConfirm(false)}
              >
                Trở về
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {isMobile ? (
            <>
              {isLogIn &&
                (citizenIdInfo?.data === null || citizenIdInfo?.data?.status !== 1) &&
                rewardInfo?.totalRewardsAvailable >= 400 &&
                rewardInfo?.totalRewardsAvailable <= 500 && (
                  <span
                    onClick={() => {
                      handleWarningPolicyEditProfile();
                    }}
                  >
                    <img src={warning_popup} class="fix-mobile" />
                  </span>
                )}
              <div className="container-home-meo-chill">
                <div className="container pb-0">
                  <div className="container-content content-meo-chill">
                    <div className="has-text-centered pl-2">
                      <img
                        alt={'instruction'}
                        src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Hu%CC%9Bo%CC%9B%CC%81ng%20da%CC%82%CC%83n.png"
                        //style={{maxWidth: '75%'}}
                      />
                    </div>
                    {isMobile ? (
                      <>
                        <div className="has-text-centered pt-2">
                          <img src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Group%2011921.png" />
                        </div>
                        <div className="has-text-centered pt-1 pb-1">
                          <button
                            className={classNames(
                              'button button-custom is-primary-custom button-text-mission',
                              {
                                'is-loading': isValidatingHuntingCode,
                              }
                            )}
                            style={{
                              textTransform: 'uppercase',
                              width: 219,
                            }}
                            onClick={onConfirm}
                            disabled={isValidatingHuntingCode}
                          >
                            LÀM NHIỆM VỤ NGAY
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="has-text-centered"
                          style={{paddingTop: 120, paddingBottom: 120}}
                        >
                          <img
                            src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/QRcode-Meochill.png"
                            // style={{maxWidth: '80%'}}
                          />
                        </div>
                      </>
                    )}

                    <div className="has-text-centered px-6">
                      <img
                        alt="time apply"
                        src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Khung%20gio%CC%9B%CC%80%20va%CC%80ng%203.png"
                        // style={{maxWidth: '70%'}}
                      />
                    </div>
                    <div
                      className="px-6"
                      style={{
                        paddingTop: 240,
                      }}
                    >
                      <ContactButton />
                    </div>
                    <ContentContact />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {isLogIn &&
                (citizenIdInfo?.data === null || citizenIdInfo?.data?.status !== 1) &&
                rewardInfo?.totalRewardsAvailable >= 400 &&
                rewardInfo?.totalRewardsAvailable <= 500 && (
                  <span
                    onClick={() => {
                      handleWarningPolicyEditProfile();
                    }}
                  >
                    <img src={warning_popup} class="fix" />
                  </span>
                )}
              <div className="meo-chill-map">
                <img
                  src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/map%201.png"
                  className="meo-chill-map-img"
                />
              </div>
              <div className="container-home-meo-chill">
                <div className="container pb-0">
                  <div className="container-content content-meo-chill">
                    <div className="has-text-centered pl-2">
                      <img
                        src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Hu%CC%9Bo%CC%9B%CC%81ng%20da%CC%82%CC%83n.png"
                        className="meo-chill-step"
                      />
                    </div>
                    <div className="has-text-centered content-meo-chill-has-padding">
                      <img
                        src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/QRcode-Meochill.png"
                        className="meo-chill-qr"
                      />
                    </div>
                    <div className="has-text-centered px-6">
                      <img
                        alt="time apply"
                        src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Khung%20gio%CC%9B%CC%80%20va%CC%80ng%203.png"
                        className="meo-chill-time"
                      />
                    </div>
                    <div className="meo-chill-contact">
                      <ContactButton />
                      <ContentContact />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {isShowError && (
        <>
          <div
            className={classNames('modal px-5', {
              'is-active': isShowError,
            })}
          >
            <div className="modal-background"></div>
            <div className="modal-card ">
              <div style={{position: 'absolute', right: 15, top: 80}}>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => setIsShowError(false)}
                ></button>
              </div>
              <div style={{height: 50}} />
              <div className="popup-scanbill-bg-v1">
                <div className=" px-4 has-text-centered pt-6">
                  <img
                    src={BUCKET_IMAGES.SCAN_STORE_FAILED_TITLE}
                    // className=""
                    alt="modal title"
                    style={{marginTop: -90, width: '80%'}}
                  />

                  <div className="mb-0 px-4">
                    <img src={BUCKET_IMAGES.SCAN_STORE_FAILED_CONTENT} alt="modal content" />
                  </div>
                  <div>
                    <button
                      className="button is-uppercase button-custom is-primary-custom button-text-mission mt-2"
                      onClick={() => {
                        setIsShowError(false);
                        setIsConfirm(true);
                      }}
                      style={{
                        width: '60%',
                        minWidth: 150,
                        maxWidth: 300,
                      }}
                    >
                      Chụp Lại
                    </button>
                  </div>
                  <div>
                    <button
                      className="button button-custom is-uppercase is-second-custom button-text-mission has-text-info mt-2 mb-5"
                      onClick={() => {
                        navigate(ROUTE_NAME.HOME);
                      }}
                      style={{
                        width: '60%',
                        minWidth: 150,
                        maxWidth: 300,
                      }}
                    >
                      Trang Chủ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {zaloPopUpMemo}

      <MeoChillModal />
    </AppLayout>
  );
};

export default MeoChill;
