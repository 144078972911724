import React from 'react';
import moment from 'moment';
import image_avatar_placeholder from 'images/image_avatar_placeholder.png';
import balloon from 'images/balloon.png';
import pencil_underline from 'images/pencil_underline.png';
import {getGenderText} from 'utils/gender';

const UserInfo = ({userInfo = {}, onEditClick = () => {}, onSignOutClick = () => {}}) => {
  const userData = [
    {
      label: 'SĐT',
      value: userInfo?.phoneNumber,
    },
    {
      label: 'Giới tính',
      value: getGenderText(userInfo?.gender),
    },
    {
      label: 'Ngày sinh',
      value: userInfo?.dateOfBirth ? moment(userInfo?.dateOfBirth).format('DD/MM/YYYY') : '',
    },
    {
      label: 'Email',
      value: userInfo?.email,
    },
    {
      label: 'Địa chỉ',
      value: userInfo?.address,
    },
  ];
  return (
    <div className=" is-flex user-summarize-info">
      <div className=" is-relative has-text-centered">
        <img
          width={100}
          src={userInfo?.avatar ? userInfo?.avatar : image_avatar_placeholder}
          alt="Placeholder"
          className="avatar"
        />
        <img
          width={40}
          src={balloon}
          alt="Placeholder"
          className=""
          style={{position: 'absolute', bottom: 0, left: 0}}
        />
      </div>
      <div
        className=" ml-4 is-flex is-flex-direction-column is-justify-content-center"
        style={{flex: 1, width: '100%'}}
      >
        <div className=" has-text-black has-text-weight-medium is-size-5 has-text-centered-tablet">
          Chiller:
        </div>
        <div className=" is-flex is-align-items-center user-info-name-wrapper">
          <div className=" has-text-info is-size-4 mr-6" style={{fontFamily: 'Americana'}}>
            {userInfo?.fullName || ''}
          </div>
          <img
            // width={20}
            src={pencil_underline}
            alt="Placeholder"
            className=" is-clickable"
            style={{width: 24, height: 24}}
            onClick={onEditClick}
          />
        </div>
      </div>
    </div>
  );
  return (
    <>
      <div className="card">
        <div className="card-content">
          <div className=" has-text-centered">
            <div className="">
              <img
                width={160}
                src={userInfo?.avatar ? userInfo?.avatar : image_avatar_placeholder}
                alt="Placeholder image"
                className="avatar"
              />
            </div>
          </div>

          <div className=" title has-text-centered has-text-primary is-5 mt-3 has-text-weight-bold">
            {userInfo?.fullName}
          </div>
          <div className="subtitle has-text-centered is-6 mt-3 has-text-weight-semibold has-text-grey">
            {'Hạng thành viên:'}
            <span className="ml-2 subtitle is-5 mt-3 has-text-weight-semibold has-text-black">
              {userInfo?.tierName}
            </span>
          </div>

          <table className="table is-fullwidth">
            {userData?.map((item, index) => (
              <tr key={`table_key_${index}`}>
                <td className="pl-0 has-text-weight-semibold has-text-grey">{item?.label}</td>
                <td className="pr-0 has-text-weight-semibold">{item?.value}</td>
              </tr>
            ))}
          </table>
          <button
            className="button is-fullwidth is-primary is-outlined has-text-weight-semibold mb-5"
            onClick={onEditClick}
          >
            {/* <img src={icon_edit} width={16} alt="logo" className="mr-3" /> */}
            <span className="icon m-1">
              <i className="fa fa-light fa-pen-to-square"></i>
            </span>
            Sửa thông tin
          </button>
          <button
            className="button is-fullwidth is-danger is-outlined has-text-weight-semibold"
            onClick={onSignOutClick}
          >
            Đăng xuất
          </button>
        </div>
      </div>
    </>
  );
};

export default UserInfo;
