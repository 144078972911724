import {generateOTPAction, loginStrogBowAction} from 'appRedux/actions/auth';
import {userProfileModalSelector} from 'appRedux/selectors/app';
import {toggleModal} from 'appRedux/slices/appSlice';
import classNames from 'classnames';
import {MODAL_STATES} from 'components/UserProfileModal/constanst';
import {CONTACT_INFO} from 'constants/app';
import {OTP_TYPES} from 'constants/otp';
import {useFormik} from 'formik';
import {get} from 'lodash';
import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import icon_clear from 'images/strongbow/icon_clear.png';
import icon_apple from 'images/strongbow/icon_apple.png';
import bg_form from 'images/strongbow/bg_form.png';
import {Modal} from 'react-bulma-components';
import {set} from 'lodash';
import {toast} from 'react-toastify';
import bottle from 'images/strongbow/bottle.png';
import {isMobile} from 'react-device-detect';
const requireText = 'Bắt buộc';
const LOGIN_FORM_SCHEME = Yup.object().shape({
  phoneNumber: Yup.string()
    .required(requireText)
    .matches(/^[0]/, 'Số điện thoại phải bắt đầu bằng số 0')
    .matches(/^[0][0-9]{0,9}\b|^[0-9][0-9]{0,9}$/, 'Số điện thoại không hợp lệ')
    .test('Is positive?', 'Số điện thoại không hợp lệ', (value) => value >= 0),
});

const QuickLogin = ({fromHome = true}) => {
  const dispatch = useDispatch();
  const userProfileModalData = useSelector(userProfileModalSelector);
  const [showPopupOptionOTP, setShowPopupOptionOTP] = React.useState(false);
  const isLoading = userProfileModalData?.isLoggingIn;
  const inputRef = useRef(null);
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    enableReinitialize: true,

    validationSchema: LOGIN_FORM_SCHEME,
    onSubmit: (values, actions) => {
      let err = {};
      if (values.phoneNumber.length < 10 || values.phoneNumber.length > 10) {
        actions.setErrors({phoneNumber: 'Số điện thoại không hợp lệ'});
      } else {
        handleGenerateOtp(values.phoneNumber);
      }
    },
  });

  const handleGenerateOtp = (phoneNumber) => {
    if (!phoneNumber) return;
    dispatch(
      loginStrogBowAction({
        phoneNumber,
        callback: (otpType) => {
          let modalState = '';
          switch (otpType) {
            case OTP_TYPES.QUICK_LOGIN:
              modalState = MODAL_STATES.OPTIONOTP;
              //setShowPopupOptionOTP(true)
              break;
            case OTP_TYPES.REGISTER:
              modalState = MODAL_STATES.REGISTER;
              break;
            default:
              break;
          }
          if (modalState) {
            dispatch(
              toggleModal({
                show: true,
                modalState,
                phoneNumber,
              })
            );
          }
        },
      })
    );
  };

  useEffect(() => {
    if (inputRef?.current && inputRef?.current?.focus) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <>
      <div className="clearfix">
        <div className="columns">
          <div
            className="column column-is-custom"
            style={{
              position: 'relative',
            }}
          >
            {!isMobile && (
              <>
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                  }}
                >
                  <img
                    src={bottle}
                    style={{
                      width: 104,
                    }}
                  />
                </div>
              </>
            )}
            <div className="form-login-home">
              <div className="loginbg">
                <div className="is-login-form">
                  <div className="field">
                    <div className="field-label is-normal has-text-centered">
                      <label className="">
                        <span className="icon-text">
                          <span className="icon">
                            <img
                              src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Tra%CC%81i%20ta%CC%81o%20(1).png"
                              className="login-home-icon"
                            />
                          </span>
                          <span className="login-home-text">Số điện thoại của bạn là</span>
                        </span>
                      </label>
                    </div>
                    <div class="field-body">
                      <div className="field control has-icons-right has-icons-right-custom">
                        <input
                          className="input control input-is-custom "
                          placeholder="Nhập số điện thoại"
                          maxLength={10}
                          type="tel"
                          pattern="^-?[0-9]\d*\.?\d*$"
                          //ref={(input) => input && input.focus()}
                          value={formik.values.phoneNumber}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                              formik.setFieldTouched('phoneNumber', true, true);
                              formik.setFieldValue('phoneNumber', e.target.value);
                            }
                          }}
                        />

                        {!!formik?.values?.phoneNumber?.length && (
                          <span
                            className="icon is-small is-right icon-phone-search is-clickable"
                            onClick={() => {
                              formik.resetForm();
                            }}
                          >
                            <img
                              src={icon_clear}
                              style={{
                                height: 18,
                              }}
                            />
                          </span>
                        )}
                        <div
                          className="help is-danger"
                          style={{
                            height: 10,
                          }}
                        >
                          {get(formik.touched, 'phoneNumber', false) &&
                            !!get(formik.errors, 'phoneNumber') && (
                              <div>
                                <span>{get(formik.errors, 'phoneNumber')}</span>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="has-text-centered">
                    <button
                      className={classNames('button is-primary-custom button-before-login', {
                        'is-loading': isLoading,
                      })}
                      style={{
                        color: '#FFF',

                        fontSize: 16,
                        fontWeight: 700,
                      }}
                      onClick={() => {
                        formik.submitForm();
                      }}
                      disabled={!formik.isValid || isLoading}
                    >
                      BẮT ĐẦU
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        show={showPopupOptionOTP}
        closeOnBlur
        closeOnEsc
        onClose={handleCloseModal}
      >
        <Modal.Card style={{ maxWidth: 400 }}>
          <Modal.Card.Body
            className="hide-scroll userbg"
            style={{ paddingTop: 35 }}
          >
            <div
              className="has-text-centered"
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: "#343434",
              }}
            >
              <div>Mã xác thực OTP sẽ được gửi qua App</div>
              <div style={{ marginBottom: 24 }}>
                App Zalo của SĐT{" "}
                <span class="has-text-weight-bold" style={{ fontSize: 19 }}>
                  09070070707
                </span>
              </div>
              <div className="columns">
                <div className="column is-12">
                  <button
                    className="is-primary-custom"
                    style={{
                      color: "#FFF",
                      padding: "14px 50px",
                      fontSize: 16,
                      fontWeight: 700,
                      textShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    TIẾP TỤC
                  </button>
                </div>
              </div>
              <div className="columns">
                <div className="column is-12">
                  <button
                    className="is-second-custom"
                    style={{
                      color: "#83552D",
                      padding: "14px 30px",
                      fontSize: 16,
                      fontWeight: 700,
                      textShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    KHÔNG, GỬI QUA SMS
                  </button>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <a
                    className="is-primary has-text-primary is-underlined has-text-weight-bold"
                    onClick={handleCloseModal}
                  >
                    <span>Trở về</span>
                  </a>
                </div>
              </div>
            </div>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal> */}
    </>
  );
};

export default QuickLogin;
