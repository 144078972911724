import {appEndPoint} from 'configs/appConfigs';
import {buildRequestUrl} from 'helpers/apiHelper';
const {END_POINT_DOCUMENT_UPLOAD} = appEndPoint;

const textRecognizer = 'text-recognizer';
export const SCAN_BILL = {
  postHistoryScanBill: buildRequestUrl(END_POINT_DOCUMENT_UPLOAD, `${textRecognizer}/search`, 0),
  validateLimitItem: buildRequestUrl(
    END_POINT_DOCUMENT_UPLOAD,
    `${textRecognizer}/validate/check-limit-item`,
    0
  ),
};

const sb = 'strongbow';
export const STRONG_BOW = {
  scanLogo: buildRequestUrl(END_POINT_DOCUMENT_UPLOAD, `${sb}/scan-logo`, 0),
  checkIn: buildRequestUrl(END_POINT_DOCUMENT_UPLOAD, `${sb}/check-in`, 0),
  shareFB: buildRequestUrl(END_POINT_DOCUMENT_UPLOAD, `${sb}/share-fb`, 0),
};

//#region CitizenIdentification
const citizenIdentificationUri = 'citizen-identification';
export const CITIZEN_IDENTIFICATION = {
  postUploadCitizenIdentification: buildRequestUrl(
    END_POINT_DOCUMENT_UPLOAD,
    `${citizenIdentificationUri}/upload`,
    0
  ),
  getCitizenIdentificationByMerchantId: (merchantId) =>
    buildRequestUrl(
      END_POINT_DOCUMENT_UPLOAD,
      `${citizenIdentificationUri}/details/by-merchant/${merchantId}`,
      0
    ),
};
//#endregion
