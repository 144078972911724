import {CODE_KEY} from 'constants/errors';
import _ from 'lodash';

function convertDataError(error) {
  const {code = {}, message, status, Messages, messages} = error;
  // let errMessage = error.messages[0].content
  // switch (status) {
  //   case CODE_KEY.NOT_FOUND:
  //     code = CODE_KEY.NOT_FOUND;
  //     break;
  // }
  // let result = ERROR_MESSAGE[code];
  // if (result) {
  //   return {code: code, message: result, status};
  // }
  if (message && message.search('Cannot read property') >= 0) {
    return {
      code: CODE_KEY.UNDEFINED,
      message: 'Truy vấn dữ liệu lỗi.',
      status,
    };
  }
  if (message && message.search('Network Error') >= 0) {
    return {
      code: CODE_KEY.ERROR_NETWORK,
      message: 'Không thể kết nối tới server.',
      status,
    };
  }

  if (Array.isArray(messages) && !_.isEmpty(messages)) {
    return {
      code,
      message: messages[0]?.content,
      status,
      type: messages[0]?.type,
    };
  }

  if (Array.isArray(Messages) && !_.isEmpty(Messages)) {
    return {
      code,
      message: Messages[0]?.Content,
      status,
    };
  }

  return error;
}

export function handleErrorMessage(err) {
  const {response} = err;
  if (response?.data) {
    return convertDataError(response.data);
  }
  return convertDataError(err);
}
