import Winwheel from "wheel";
import React, { useRef, useState, useEffect } from "react";

const Index = () => {
  const [wheelSpinning, setWheelSpinning] = useState(false);
  useEffect(() => {}, []);
  //   useEffect(() => {
  //     const theWheel = new Winwheel({
  //       canvasId: "canvas1",
  //       numSegments: 8, // Chia 8 phần bằng nhau
  //       outerRadius: 212, // Đặt bán kính vòng quay
  //       textFontSize: 18, // Đặt kích thước chữ
  //       rotationAngle: 0, // Đặt góc vòng quay từ 0 đến 360 độ.
  //       // Các thành phần bao gồm màu sắc và văn bản.
  //       segments: [
  //         { fillStyle: "#eae56f", text: "Giải 1" },
  //         { fillStyle: "#89f26e", text: "Giải 2" },
  //         { fillStyle: "#7de6ef", text: "Giải 3" },
  //         { fillStyle: "#e7706f", text: "Giải 4" },
  //         { fillStyle: "#eae56f", text: "Giải 5" },
  //         { fillStyle: "#89f26e", text: "Giải 6" },
  //         { fillStyle: "#7de6ef", text: "Giải 7" },
  //         { fillStyle: "#e7706f", text: "Giải 8" },
  //       ],
  //       animation: {
  //         type: "spinToStop",
  //         duration: 5,
  //         spins: 15,
  //         callbackFinished: (indicatedSegment) => {
  //           alert("Chúc mừng bạn trúng: " + indicatedSegment.text);
  //         }, //Hàm hiển thị kết quả trúng giải thưởng
  //       },
  //     });
  //   }, []);

  //   function stopAngle() {
  //     //Ta có: 360 độ chia 8 bằng 45 độ cho mỗi giải thưởng.
  //     //VD: Giải 3 => Có gốc độ từ 91 đến 135.
  //     let start = 91;
  //     let stop = Math.floor(Math.random() * 43); //Giá trị cao nhất 44
  //     let stopAt = start + stop;
  //     theWheel.current.animation.stopAngle = stopAt;
  //   }
  //   const startSpin = () => {
  //     // Ensure that spinning can't be clicked again while already running.
  //     if (wheelSpinning === false) {
  //       //CSS hiển thị button

  //       //Đặt kết quả mong muốn
  //       stopAngle();

  //       //Hàm bắt đầu quay
  //       //theWheel.current.startAnimation();

  //       //Khóa vòng quay
  //       setWheelSpinning(true);
  //     }
  //   };
  //   const resetWheel = () => {
  //     //CSS hiển thị button

  //     theWheel.current.stopAnimation(false); // Stop the animation, false as param so does not call callback function.
  //     theWheel.current.rotationAngle = 0; // Re-set the wheel angle to 0 degrees.
  //     theWheel.current.draw(); // Call draw to render changes to the wheel.

  //     setWheelSpinning(false); // Reset to false to power buttons and spin can be clicked again.
  //   };
  return (
    <>
      {" "}
      ddđ
      <canvas id="canvas" width="434" height="434">
        Sorry, your browser doesn't support canvas. Please try another.
      </canvas>
      <canvas id="canvas1" width="434" height="434">
        Sorry, your browser doesn't support canvas. Please try another.
      </canvas>
    </>
  );
};
export default Index;
