import axios from 'axios';
// import SCENE_NAMES from 'constants/sceneName';
import {handleErrorMessage} from 'helpers/handleError';
// import I18n from 'utils/i18n';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import {appHost} from 'configs/appConfigs';

// import {toggleLoginModal} from 'appRedux/slices/appSlice';

import store from 'stores';
import {signOutAction} from 'appRedux/actions/global';
import {toast} from 'react-toastify';
import {localStorageGetItem} from './storage';
const REQUEST_TIMEOUT = 30 * 1000;

// axios.interceptors.request.use((c) => {
//   console.log('>>>CHECK REQUEST<<<<', c);
//   return c;
// });

// axios.interceptors.response.use((res) => {
//   console.log('>>>CHECK RESPONSE<<<<', res);
//   return res;
// });

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // axios.defaults.timeout = 5000;
  } else delete axios.defaults.headers.common['Authorization'];
};

export const handleTokenExpire = () => {
  const dispatch = store?.dispatch;
  if (dispatch) {
    dispatch(signOutAction({showModal: true}));

    toast('Phiên đăng nhập đã hết hạn. Xin hãy đăng nhập lại!', {type: 'info'});
    window.location.href = '/';
  }
};
export default class APIUtils {
  static accessToken = '';

  endpoint = appHost.apiHost;
  identityServerAddress = appHost.identityHost;

  static setAccessToken(token) {
    this.accessToken = `${token}`;
  }

  static getAccessToken() {
    return this.accessToken;
    // return 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjA0N0QyMDc1RTk5MzNGRTYyNjQyMEQzQ0REQkEwMjBDMkUzQjlGRUFSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IkJIMGdkZW1UUC1ZbVFnMDgzYm9DREM0N24tbyJ9.eyJuYmYiOjE2NjExNTk1ODYsImV4cCI6MTY2MTI0NTk4NiwiaXNzIjoiaHR0cHM6Ly9hY2NvdW50LWFscGhhLmRndmRpZ2l0YWwubmV0IiwiYXVkIjpbIm1lcmNoYW50LW1vYmlsZSIsImRwb2ludC1tb2JpbGUiLCJiYXRjaC1lbmQtdXNlciIsImJhdGNoLWdhbWUiLCJiYXRjaC1tYXN0ZXItZGF0YSIsImJhdGNoLXRyYW5zYWN0aW9uIiwiYmF0Y2gtY21zIiwiYXBpLWNybSIsImFwaS1lbmQtdXNlciIsImFwaS1hZHMiLCJhcGktc2NoZWR1bGVyIiwiYXBpLWNvbW11bmljYXRpb24iLCJhcGktY21zIiwiYXBpLW1hc3Rlci1kYXRhIiwiYXBpLWNkcCIsImFwaS1nYW1lIiwiYXBpLWxveWFsdHkiLCJhcGktc2VhcmNoIiwiYXBpLXRyYW5zYWN0aW9uIiwiYXBpLWlkZW50aXR5IiwiaHR0cHM6Ly9hY2NvdW50LWFscGhhLmRndmRpZ2l0YWwubmV0L3Jlc291cmNlcyJdLCJjbGllbnRfaWQiOiJkcG9pbnQtbW9iaWxlIiwic3ViIjoiNjIxMzFhMjcxMTQ0ZjFmMmZhZmNlMTIyIiwiYXV0aF90aW1lIjoxNjYxMTU5NTg2LCJpZHAiOiJsb2NhbCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiNjIxMzFhMjcxMTQ0ZjFmMmZhZmNlMTIyIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6IjA5MDcwNzA3MDciLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiIwOTA3MDcwNzA3IiwiZnVsbF9uYW1lIjoiTmd1eeG7hW4gSHV5IEhvw6BuZyIsImVtYWlsIjoiZ2doaEBnbWFpbC5jb20iLCJwaG9uZV9udW1iZXIiOiIwOTA3MDcwNzA3IiwiZ2VuZGVyIjoiTyIsInByb3ZpbmNlIjoiNjAiLCJkaXN0cmljdCI6IjU5NSIsIndhcmQiOiIyMjk3MiIsImFkZHJlc3MiOiI4NTEgaGVoZSIsImJpcnRoZGF0ZSI6IjA4LzIyLzIwMjIgMDA6MDA6MDAiLCJqdGkiOiIwMDNGRUNDM0FDOUM3QUE0M0FEOTZBQTI2MTU3NTZGMyIsImlhdCI6MTY2MTE1OTU4Niwic2NvcGUiOlsib3BlbmlkIiwicHJpdmF0ZS1hcGkiLCJwcm9maWxlIiwicHVibGljLWFwaSIsIm9mZmxpbmVfYWNjZXNzIl0sImFtciI6WyJwYXNzd29yZCJdfQ.M4N9bPSwOzg3Hygsq1bHih8LM7yu8L853zeBnXczLPLn9sJrqU8OL6SueJEaI8hCjSDHRHrxTJmtew8T07eu2l-jqsFcZhe9JfnU4qhuOgOwpv5XLNn1zmVb3raROB8TK1GHk77jI4kJDbIRi13RgCiOQkxclwGcGl_IC3yR3MqkrjlmsTOoaCMLtFX0U8kyW5B9sVb9WhxKDik8ld5oYQe8-a0lwvhAaplHNh1YLCYcaZhfkGk_-ulWo-Rr0g0lBOfBt-rx7fvcUdIFyDYs9uEcuQzjjLqPDsFvNBJlihFLFsUwTybK9hjdUnn81XAa4yswvYVtCA0vF_YwfilY0g';
  }

  static setEndpoint(val) {
    this.endpoint = val;
  }

  static setEndpointIdentityServerAddress(val) {
    this.identityServerAddress = val;
  }

  static getEndpoint(url = '') {
    let _endpoint = this.endpoint || appHost.apiHost;
    if (!this.endpoint) this.setEndpoint(_endpoint);
    return _endpoint + url;
  }

  static getEndpointIdentity(url = '') {
    let _endpoint = this.identityServerAddress || appHost.identityHost;
    if (!this.identityServerAddress) this.setEndpointIdentityServerAddress(_endpoint);
    return _endpoint + url;
  }

  static get(uri, params, headers) {
    return new Promise((resolve, reject) =>
      axios
        .get(this.getEndpoint(uri), {
          timeout: REQUEST_TIMEOUT,
          headers: {
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${this.getAccessToken()}`,
          },
          params,
        })
        .then((response) => {
          const {data} = response;
          resolve(data);
        })
        .catch((err) => {
          console.log('[error]', {err});
          this.checkToken(err);

          reject(handleErrorMessage(err));
        })
    );
  }

  static post(uri, postData, headers) {
    return new Promise((resolve, reject) => {
      axios
        .post(this.getEndpoint(uri), postData, {
          timeout: REQUEST_TIMEOUT,
          headers: {
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${this.getAccessToken()}`,
          },
        })
        .then((response) => {
          const {data} = response;
          resolve(data);
        })
        .catch((err) => {
          console.log('[error]', {err});
          this.checkToken(err);
          reject(handleErrorMessage(err));
        });
    });
  }

  static postFormData(uri, postData) {
    return new Promise((resolve, reject) => {
      axios
        .post(this.getEndpoint(uri), postData, {
          timeout: REQUEST_TIMEOUT,
          headers: {
            'Content-Type': 'multipart/form-data',
            //Authorization: `Bearer ${this.getAccessToken()}`,
          },
        })
        .then((response) => {
          const {data} = response;
          resolve(data);
        })
        .catch((err) => {
          console.log('[error]', {err});
          this.checkToken(err);

          reject(handleErrorMessage(err));
        });
    });
  }

  static put(uri, updateData, headers) {
    return new Promise((resolve, reject) =>
      axios
        .put(this.getEndpoint(uri), updateData, {
          timeout: REQUEST_TIMEOUT,
          headers: {
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${this.getAccessToken()}`,
          },
        })
        .then((response) => {
          const {data} = response;
          resolve(data);
        })
        .catch((err) => {
          console.log('[error]', {err});
          this.checkToken(err);
          reject(handleErrorMessage(err));
        })
    );
  }

  static delete(uri, updateData, headers) {
    return new Promise((resolve, reject) =>
      axios
        .delete(this.getEndpoint(uri), {
          timeout: REQUEST_TIMEOUT,
          headers: {
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${this.getAccessToken()}`,
          },
          data: updateData,
        })
        .then((response) => {
          const {data} = response;
          resolve(data);
        })
        .catch((err) => {
          console.log('[error]', {err});
          this.checkToken(err);

          reject(handleErrorMessage(err));
        })
    );
  }

  static checkToken(err) {
    const token = localStorageGetItem('accessToken');
    // handleTokenExpire();

    if ((err?.response?.status === 401 || err?.response?.status === 419) && token) {
      handleTokenExpire();
      console.log('====================================');
      console.log('Token has Expired!!!');
      console.log('====================================');
    }
  }
}
