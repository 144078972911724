import React from "react";
import Layout from "../../components/layout";
import Carousel from "../../components/carousel";
import "./styles.scss";
import icon_user from "images/icon_user.png";
import icon_coins from "images/icon_coins.png";
import icon_next from "images/icon_next.png";
import Benner from "images/benner_2.jpg";
import { Modal } from "react-bulma-components";
import { useDispatch, useSelector } from "react-redux";
import {
  isLogInSelector,
  referralLinkSelector,
  userInfoSelector,
} from "appRedux/selectors/auth";
import Contact from "components/contact";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import {
  postReferralInvite,
  getInviteReferralByMerchant,
} from "appRedux/actions/app";
import QuickLogin from "components/quickLogin";
import ContactButton from "components/contactButton";

const Referral = () => {
  const dispatch = useDispatch();
  const banner = [Benner, Benner, Benner];
  const [showPopupList, setShowPopupList] = React.useState(false);
  const isLogIn = useSelector(isLogInSelector);
  const copyLink = useSelector(referralLinkSelector);
  const userInfo = useSelector(userInfoSelector);
  const [dataListInvite, setDataListInvite] = React.useState([]);
  const [copied, setCopied] = React.useState(false);
  React.useEffect(() => {
    dispatch(
      getInviteReferralByMerchant({
        merchantId: process.env.REACT_APP_MERCHANT_ID,
        callback: (data) => {
          setDataListInvite(data.data);
        },
      })
    );
  }, []);
  const handleViewList = () => {
    setShowPopupList(!showPopupList);
  };
  const handleCloseModal = () => {
    setShowPopupList(!showPopupList);
  };

  return (
    <Layout
      headerProps={{
        index: 2,
      }}
    >
      <Carousel
        axis="horizontal"
        interval="2000"
        showThumbs={false}
        showStatus={false}
        images={banner}
      />
      <div className="container-home">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="head-title head-title-top">
                GIỚI THIỆU BẠN BÈ VÀ NHẬN THƯỜNG TỪ NUTIFOOD
              </div>
              <p>
                Đừng quên giới thiệu bạn bè tham gia chương trình khách hàng
                thân thiết Nutipoint để cùng tận hưởng những ưu đãi đặc quyền
                hấp dẫn dành riêng cho thành viên ngay hôm nay nhé. Đặc biệt,
                Nutifood xin tặng <b>1,000 điểm/lượt giới thiệu thành công</b>
                <br />
                (Lượt giới thiệu thành công được xác nhận khi người được giới
                thiệu đăng ký thông tin thành viên & phát sinh đơn hàng từ
                100,000 VNĐ trở lên)
              </p>
            </div>
          </div>
          {isLogIn ? (
            <>
              <div className="columns">
                <div className="column referral-link">
                  <div className="head-title">GIỚI THIỆU QUA LIÊN KẾT</div>
                  <div className="group-pass-link has-text-centered">
                    <span className="icon-text">
                      <span className="group-pass-link-text mb-3">
                        {copyLink}
                      </span>
                    </span>
                    <CopyToClipboard
                      text={copyLink}
                      onCopy={() => setCopied(true)}
                    >
                      <button className="button-copy">
                        <div className="button-copy-text">
                          {copied ? "Đã sao chép" : "Sao chép"}
                        </div>
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              <div className="tile is-ancestor">
                <div className="tile is-parent">
                  <article
                    className="tile is-child box"
                    style={{
                      borderRadius: 24,
                      border: "1px solid #D2D2D2",
                      paddingTop: 0,
                    }}
                  >
                    <div className="successful-invite-title">
                      Mời thành công
                    </div>
                    <div className="columns is-mobile">
                      <div
                        className="column"
                        style={{
                          borderRight: "1px solid #D2D2D2",
                          paddingLeft: 24,
                        }}
                      >
                        <span className="icon-text">
                          <span className="successful-invite-text">
                            {dataListInvite
                              ?.filter((f) => f.status == 3)
                              .length.toLocaleString()}
                          </span>
                          <span className="icon">
                            <img src={icon_user} />
                          </span>
                        </span>
                        <div className="number-invite-text">
                          Số người đã tham gia
                        </div>
                      </div>
                      <div
                        className="column"
                        style={{
                          paddingLeft: 24,
                        }}
                      >
                        <span className="icon-text">
                          <span className="successful-invite-text">
                            {(
                              dataListInvite?.filter((f) => f.status == 3)
                                .length * 1000
                            ).toLocaleString()}
                          </span>
                          <span className="icon">
                            <img src={icon_coins} />
                          </span>
                        </span>
                        <div className="number-invite-text">
                          Số Điểm bạn được nhận
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="tile is-parent">
                  <article
                    className="tile is-child box"
                    style={{
                      border: "1px solid #D2D2D2",
                      borderRadius: 24,
                      position: "relative",
                      paddingTop: 0,
                    }}
                  >
                    <div className="successful-invite-title">Đang chờ</div>
                    <div className="invite-list-more">
                      <span>
                        <a onClick={handleViewList}>Xem danh sách</a>
                      </span>
                      <span className="icon">
                        <img src={icon_next} />
                      </span>
                    </div>
                    <div className="columns is-mobile">
                      <div
                        className="column"
                        style={{
                          borderRight: "1px solid #D2D2D2",
                          paddingLeft: 24,
                        }}
                      >
                        <span className="icon-text">
                          <span className="successful-invite-text">
                            {dataListInvite
                              ?.filter((f) => f.status == 1)
                              .length.toLocaleString()}
                          </span>
                          <span className="icon">
                            <img src={icon_user} />
                          </span>
                        </span>
                        <div className="number-invite-text">
                          Số người chưa phản hồi
                        </div>
                      </div>
                      <div
                        className="column"
                        style={{
                          paddingLeft: 24,
                        }}
                      >
                        <span className="icon-text">
                          <span className="successful-invite-text">
                            {(
                              dataListInvite?.filter((f) => f.status == 1)
                                .length * 1000
                            ).toLocaleString()}
                          </span>
                          <span className="icon">
                            <img src={icon_coins} />
                          </span>
                        </span>
                        <div className="number-invite-text">
                          Số Điểm có thể nhận
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </>
          ) : (
            // Chưa đăng nhập
            <QuickLogin fromHome={false} />
          )}
          <ContactButton />
        </div>
      </div>

      <Modal
        show={showPopupList}
        closeOnBlur
        closeOnEsc
        onClose={handleCloseModal}
      >
        <Modal.Card>
          <Modal.Card.Body
            className="hide-scroll"
            style={{ padding: 40, borderRadius: 16 }}
          >
            <div className="title is-4 has-text-centered has-text-weight-bold is-uppercase">
              DANH SÁCH ĐÃ MỜI
            </div>
            <div className=" has-text-centered">
              <div
                className="has-text-weight-semibold"
                style={{ marginBottom: 32 }}
              >
                Danh sách thành viên được bạn mời nhưng chưa phát sinh đơn hàng
                từ 100,000 VND trở lên
              </div>
            </div>
            <div className="columns">
              <div
                className="column"
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <table className="table roundedCorners is-fullwidth is-hovered ">
                  <thead className="has-background-grey-light">
                    <tr>
                      <th>Họ và Tên</th>
                      <th>Số điện thoại</th>
                      <th>Ngày đăng ký</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataListInvite
                      ?.filter((f) => f.status == 1)
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.invitedName}</td>
                            <td>{item.invitedPhone}</td>
                            <td>
                              {item?.invitedDateTime
                                ? moment(item.invitedDateTime).format(
                                    "DD/MM/YYYY"
                                  )
                                : ""}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
      <div className="pic-wave-bg"></div>
    </Layout>
  );
};
export default Referral;
