import {createAsyncThunk} from '@reduxjs/toolkit';
import {retryApi} from 'apis';
import {
  getUserInfoApi,
  otpNutiGeneratedApi,
  otpVerifyApi,
  updateUserInfoApi,
  loginStrogBowApi,
  otpStrongBowGeneratedApi,
  getMembershipUserInfoApi,
  updateUserAvatarApi,
} from 'apis/endUser';
import {registerWithFullInfoApi} from 'apis/endUser';
import {setAuthToken} from 'utils/apiUtils';
import {localStorageSetItem} from 'utils/storage';
// import {storeCheckInAction} from './storeCheckin';
import {
  uploadCitizenIdentificationApi,
  getCitizenIdentificationByMerchantIdApi,
} from 'apis/document';

export const getUserProfileAction = createAsyncThunk('auth/getUserProfile', async () => {
  const res = await getMembershipUserInfoApi(process.env.REACT_APP_PROGRAM_ID);

  return res;
});

export const updateUserProfileAction = createAsyncThunk(
  'auth/updateUserProfile',
  async (payload, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await updateUserInfoApi(payload);
      if (result?.isSuccess && result?.data) {
        // const _ = await retryApi(receiptData);

        return fulfillWithValue({...payload, dateOfBirth: undefined});
      } else {
        throw new Error('Cập nhật thông tin thất bại!');
      }
    } catch (error) {
      return rejectWithValue(error?.message || 'Cập nhật thông tin thất bại!');
    }
  }
);

export const updateUserAvatarAction = createAsyncThunk(
  'auth/updateUserAvatar',
  async (payload, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await updateUserAvatarApi(payload);
      if (result?.isSuccess && result?.data) {
        // const _ = await retryApi(receiptData);

        return fulfillWithValue({...payload, dateOfBirth: undefined});
      } else {
        throw new Error('Cập nhật thông tin thất bại!');
      }
    } catch (error) {
      return rejectWithValue(error?.message || 'Cập nhật thông tin thất bại!');
    }
  }
);

export const verifyOTPAction = createAsyncThunk(
  'auth/verifyOTP',
  async ({phoneNumber, otp, callback}, {rejectWithValue, fulfillWithValue, dispatch, getState}) => {
    try {
      const params = {
        phoneNumber,
        otp,
        otpType: 'QUICK_LOGIN',
        clientType: 3,
        deviceId: 'string',
        deviceToken: 'string',
      };
      // const receiptData = await otpVerifyApi(params);
      const data = await otpVerifyApi(params);
      if (data?.isSuccess && data?.data) {
        const res = data?.data;
        if (res?.accessToken) {
          setAuthToken(res?.accessToken);
          localStorageSetItem('accessToken', res?.accessToken);
          localStorageSetItem('refreshToken', res?.refreshToken);
        }
        const link = getState()?.storeCheckin?.logoImage;
        const storeId = getState()?.storeCheckin?.storeDetail?.storeCode;
        if (!!link && storeId) {
          // dispatch(storeCheckInAction({link, storeId}));
        }
        callback && callback(res);
        dispatch(getUserProfileAction());
        return fulfillWithValue(res);
      } else {
        throw new Error('Mã OTP không hợp lệ');
      }
      // if (receiptData?.isSuccess && receiptData?.data) {
      //   const data = await retryApi(receiptData);
      //   if (data?.data?.isSuccess) {
      //     if (data?.data?.data?.accessToken) {
      //       setAuthToken(data?.data?.data?.accessToken);
      //       localStorageSetItem("accessToken", data?.data?.data?.accessToken);
      //     }
      //     callback && callback(data);
      //     dispatch(getUserProfileAction());
      //     return fulfillWithValue(data);
      //   } else {
      //     throw new Error("Mã OTP không hợp lệ");
      //   }
      // } else {
      //   throw new Error("Mã OTP không hợp lệ");
      // }
    } catch (error) {
      callback && callback(error);
      return rejectWithValue(error?.message || 'Mã OTP không hợp lệ');
    }
  }
);

export const generateOTPAction = createAsyncThunk(
  'auth/generateOTP',
  async (
    {phoneNumber, callback, requestType = 'STRONGBOW_SMS'},
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const params = {
        phoneNumber,
        requestType,
        deviceId: 'web',
        deviceType: 1,
        userIPAddress: '',
        requiredMembershipOfMerchant: process.env.REACT_APP_MERCHANT_ID,
      };
      const receiptData = await otpNutiGeneratedApi(params);
      if (receiptData?.isSuccess && receiptData?.data) {
        const otpType = receiptData?.data?.otpType;

        // callback && callback(otpType);
        // return fulfillWithValue(otpType);

        const data = await retryApi(receiptData);

        if (data?.data?.isSuccess) {
          callback && callback(otpType);
          return fulfillWithValue(otpType);
        } else {
          throw new Error('Gửi mã OTP không thành công!');
        }
      } else {
        throw new Error('Gửi mã OTP không thành công!');
      }
    } catch (error) {
      return rejectWithValue(error?.message || 'Gửi mã OTP không thành công!');
    }
  }
);

export const registerAction = createAsyncThunk(
  'auth/register',
  async ({data, callback}, {rejectWithValue, fulfillWithValue, dispatch}) => {
    try {
      const params = {
        ...data,
        gender: data?.gender?.value,
        province: data?.province?.value,
        district: data?.district?.value,
        ward: data?.ward?.value,
        merchantId: process.env.REACT_APP_MERCHANT_ID,
        username: data?.phoneNumber,
      };
      const res = await registerWithFullInfoApi(params);
      const accessToken = res?.data?.accessToken;
      if (accessToken) {
        setAuthToken(accessToken);
        localStorageSetItem('accessToken', accessToken);
      }
      callback && callback(accessToken);
      dispatch(getUserProfileAction());
      return fulfillWithValue(accessToken);
    } catch (error) {
      return rejectWithValue(error?.message || 'Đăng ký không thành công!');
    }
  }
);
//
export const loginStrogBowAction = createAsyncThunk(
  'auth/loginStrogbowApi',
  async ({phoneNumber, callback}, {rejectWithValue}) => {
    try {
      const params = {
        phoneNumber,
        merchantId: process.env.REACT_APP_MERCHANT_ID,
      };
      const receiptData = await loginStrogBowApi(params);
      if (receiptData?.isSuccess && receiptData?.data) {
        const otpType = receiptData?.data?.otpType;
        callback && callback(otpType);
      } else {
        throw new Error('Đăng nhập không thành công!');
      }
    } catch (error) {
      return rejectWithValue(error?.message || 'Đăng nhập không thành công!');
    }
  }
);
export const generateOTPStrongBowAction = createAsyncThunk(
  'auth/generateOTPStrongBowAction',
  async (
    {phoneNumber, requestType, fullName = '', callback},
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const params = {
        phoneNumber,
        requestType,
        deviceId: 'web',
        deviceType: 1,
        userIPAddress: '',
        requiredMembershipOfMerchant: process.env.REACT_APP_MERCHANT_ID,
        source: '',
        fullName: fullName,
      };
      const receiptData = await otpStrongBowGeneratedApi(params);
      if (receiptData?.isSuccess && receiptData?.data) {
        const otpType = receiptData?.data?.otpType;
        //const data = await retryApi(receiptData);
        callback && callback(otpType);
        return fulfillWithValue(otpType);
        // if (data?.data?.isSuccess) {
        //   callback && callback(otpType);
        //   return fulfillWithValue(otpType);
        // } else {
        //   throw new Error("Gửi mã OTP không thành công!");
        // }
      } else {
        throw new Error('Gửi mã OTP không thành công!');
      }
    } catch (error) {
      return rejectWithValue(error?.message || 'Gửi mã OTP không thành công!');
    }
  }
);

export const uploadCitizenIdentificationAction = createAsyncThunk(
  'auth/uploadCitizenIdentification',
  async (payload = {body: {}, callback: null}, {rejectWithValue, fulfillWithValue}) => {
    const {callback, body} = payload;
    try {
      const result = await uploadCitizenIdentificationApi(body);
      if (result?.isSuccess && result?.data) {
        // const _ = await retryApi(receiptData);
        callback && callback(result, null);
        return fulfillWithValue({body});
      } else {
        // throw new Error('Cập nhật thông tin thất bại!');
        callback && callback(null, new Error('Gửi yêu cầu xác thực thất bại!'));
      }
    } catch (error) {
      callback && callback(null, error);
      return rejectWithValue(error?.message || 'Gửi yêu cầu xác thực thất bại!');
    }
  }
);

export const getCitizenIdByMerchantIdAction = createAsyncThunk(
  'auth/getCitizenIdByMerchantId',
  async (payload = {callback: null}) => {
    const {callback} = payload;
    const res = await getCitizenIdentificationByMerchantIdApi(process.env.REACT_APP_MERCHANT_ID);
    callback && callback(res);
    return res;
  }
);
