import React from 'react';
import webBg from 'images/web-maintenance-bg.png';
import webFg from 'images/web-maintenance-fg.png';
import webMg from 'images/web-maintenance-mg.png';

import appBg from 'images/app-maintenance-bg.png';
import appFg from 'images/app-maintenance-fg.png';
import appMg from 'images/app-maintenance-mg.png';
import logo from 'images/logo.png';

import {isMobile, isTablet} from 'react-device-detect';

const Maintenance = () => {
  if (isTablet) {
    return (
      <div style={{height: '100vh', width: '100vw'}} className="has-text-centered is-relative">
        <img
          src={appBg}
          style={{
            objectFit: 'fill',
            height: '100%',
            width: '100%',
          }}
          alt="banner"
        />
        <img
          src={logo}
          style={{
            objectFit: 'fill',
            width: '30vw',
            position: 'absolute',
            top: '5%',
            left: '10%',
          }}
          alt="logo"
        />
        <img
          src={appMg}
          style={{
            height: '100vw',
            maxHeight: '60vh',
            position: 'absolute',
            top: '10%',
            // bottom: '0',
            left: '0',
            right: '0',
            margin: '0 auto',
          }}
          alt="banner"
        />
        <img
          src={appFg}
          style={{
            maxHeight: '100%',
            width: '100%',
            position: 'absolute',
            bottom: -200,
            left: 0,
          }}
          alt="banner"
        />
      </div>
    );
  }
  if (!isMobile) {
    return (
      <div style={{height: '100vh', width: '100vw'}} className="has-text-centered is-relative">
        <img
          src={webBg}
          style={{
            objectFit: 'fill',
            height: '100%',
            width: '100%',
          }}
          alt="banner"
        />
        <img
          src={logo}
          style={{
            objectFit: 'fill',
            width: 240,
            position: 'absolute',
            top: '5%',
            left: '10%',
          }}
          alt="logo"
        />
        <img
          src={webMg}
          style={{
            width: '60vw',
            maxWidth: '110vh',
            position: 'absolute',
            top: '5%',
            left: '10%',
          }}
          alt="banner"
        />
        <img
          src={webFg}
          style={{
            maxHeight: '100%',
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
          }}
          alt="banner"
        />
      </div>
    );
  }
  return (
    <div style={{height: '100vh', width: '100vw'}} className="has-text-centered is-relative">
      <img
        src={appBg}
        style={{
          objectFit: 'fill',
          height: '100%',
          width: '100%',
        }}
        alt="banner"
      />
      <img
        src={logo}
        style={{
          objectFit: 'fill',
          width: '30vw',
          position: 'absolute',
          top: '5%',
          left: '10%',
        }}
        alt="logo"
      />
      <img
        src={appMg}
        style={{
          height: '100vw',
          maxHeight: '60vh',
          position: 'absolute',
          top: '10%',
          // bottom: '0',
          left: '0',
          right: '0',
          margin: '0 auto',
        }}
        alt="banner"
      />
      <img
        src={appFg}
        style={{
          maxHeight: '100%',
          width: '100%',
          position: 'absolute',
          bottom: 0,
          left: 0,
        }}
        alt="banner"
      />
    </div>
  );
};

export default Maintenance;
