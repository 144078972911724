import {isOver18Selector} from 'appRedux/selectors/app';
import {isLogInSelector} from 'appRedux/selectors/auth';
import TermAndCondition from 'components/TermAndCondition';
import {useSelector} from 'react-redux';
const ProtectedRoute = ({children}) => {
  const isOver18 = useSelector(isOver18Selector);
  const isLogin = useSelector(isLogInSelector);
  if (isOver18 || isLogin) {
    return children;
  }

  return <TermAndCondition />;
};

export default ProtectedRoute;
