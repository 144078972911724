import React, {useState} from 'react';
import {ROUTE_NAME} from 'constants/routes';
import {Link} from 'react-router-dom';

const Contact = () => {
  return (
    <>
      <div className="has-text-centered pl-4">
        <div className="pl-4">
          <Link to={ROUTE_NAME.INFO}>
            <div className="text-footer-meo-chill">Thể lệ chương trình</div>
          </Link>
        </div>
        <div className="pl-4 pt-4">
          <Link to={ROUTE_NAME.TERMS_AND_CONDITIONS}>
            <div className="text-footer-meo-chill">Chính sách quyền riêng tư</div>
          </Link>
        </div>
        <div className="pl-4 pt-4">
          <div className="text-footer-meo-chill-copy">Copyright &copy;</div>
        </div>
        {/* <div className="pl-4 pt-4">
          <Link>
            <div className="text-footer-meo-chill">Câu hỏi thường gặp</div>
          </Link>
        </div>
        <div className="pl-4 pt-4 pb-4">
          <Link>
            <div className="text-footer-meo-chill">Liên hệ</div>
          </Link>
        </div> */}
      </div>
    </>
  );
};
export default Contact;
