import React, {useEffect, useState} from 'react';
import Layout from '../../components/layout';
import {useDispatch, useSelector} from 'react-redux';
import {rewardInfoSelector} from 'appRedux/selectors/app';

import image_avatar_placeholder from 'images/image_avatar_placeholder.png';
import gift_chill from 'images/qua-chill.png';
import gift_default from 'images/strongbow/gift_default.png';
import card_1 from 'images/strongbow/card-1.png';
import card_2 from 'images/strongbow/card-2.png';
import voucher_active from 'images/strongbow/e-voucher-active.png';
import voucher from 'images/strongbow/e-voucher.png';
import './styles.scss';
import classNames from 'classnames';
import Lucky from '../../components/lucky';
import ContactButton from 'components/contactButton';
import GIFT_LIST from '../../components/GIFT';
import {
  getGameSettingDetailByMerchant,
  getRewardInfoAction,
  getAllGiftByMerchantAction,
  postAllGiftStrongBowSearchAction,
} from 'appRedux/actions/app';
import {isLogInSelector, referralLinkSelector, userInfoSelector} from 'appRedux/selectors/auth';
import {useSearchParams, useNavigate} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import element_chill_left from 'images/strongbow/pc/element_chill_left.png';
import balloon from 'images/strongbow/pc/balloon.png';
import element_chill_right from 'images/strongbow/pc/element_chill_right.png';
import pencil_underline from 'images/pencil_underline.png';
import {ROUTE_NAME} from 'constants/routes';
import {getUserProfileAction} from 'appRedux/actions/auth';
const ChillPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const isLogIn = useSelector(isLogInSelector);
  const userInfo = useSelector(userInfoSelector);
  const rewardInfo = useSelector(rewardInfoSelector);
  const [tabStatus, setTabStatus] = React.useState(1);
  const [tabGift, setTabGift] = React.useState(1);
  const [numberChill, setNumberChill] = React.useState(6);
  const [numberEvoucherStore, setNumberEvoucherStore] = React.useState(2);
  const [numberEvoucher, setNumberEvoucher] = React.useState(isMobile ? 4 : 8);
  const [numberEvoucherStart, setNumberEvoucherStart] = React.useState(0);
  const [dataGift, setDataGift] = React.useState([]);

  //Qua de chill

  const [chillPageSize, setChillPageSize] = useState(isMobile ? 6 : 12);
  const [chillTotalRecords, setChillTotalRecords] = useState(0);
  const [dataChill, setDataChill] = useState([]);
  const [chillTotalPages, setChillTotalPages] = useState(0);

  //evoucher
  const [voucherPageSize, setVoucherPageSize] = useState(isMobile ? 4 : 8);
  const [voucherTotalRecords, setVoucherTotalRecords] = useState(0);
  const [dataVoucher, setDataVoucher] = useState([]);
  //evoucher
  const [storePageSize, setStorePageSize] = useState(isMobile ? 2 : 4);
  const [storeTotalRecords, setStoreTotalRecords] = useState(0);
  const [dataStore, setDataStore] = useState([]);

  useEffect(() => {
    setTabStatus(Number(searchParams.get('tab') || 1));
  }, [Number(searchParams.get('tab') || 1)]);

  useEffect(() => {
    dispatch(
      postAllGiftStrongBowSearchAction({
        body: {
          pageIndex: 1,
          pageSize: chillPageSize,
          userId: userInfo?.userId,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          giftClassify: 'CHILL_GIFT',
          sortBy: [
            {
              fieldName: 'createdTime',
              ascending: false,
            },
          ],
        },
        callback: (error, res) => {
          const {data = [], numOfRecords = 0, numOfPages = 0} = res;
          setDataChill(data);
          setChillTotalRecords(numOfRecords);
          setChillTotalPages(numOfPages);
        },
      })
    );
  }, [chillPageSize]);
  useEffect(() => {
    dispatch(getUserProfileAction());
    dispatch(
      postAllGiftStrongBowSearchAction({
        body: {
          pageIndex: 1,
          pageSize: 10000,
          userId: userInfo?.userId,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          giftClassify: 'E_VOUCHER',
          sortBy: [
            {
              fieldName: 'createdTime',
              ascending: false,
            },
          ],
        },
        callback: (error, res) => {
          const {data = [], numOfRecords = 0, numOfPages = 0} = res;
          setDataVoucher(data);
          setVoucherTotalRecords(numOfRecords);
        },
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      postAllGiftStrongBowSearchAction({
        body: {
          pageIndex: 1,
          pageSize: storePageSize,
          userId: userInfo?.userId,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          giftClassify: 'STORE_GIFT',
          sortBy: [
            {
              fieldName: 'createdTime',
              ascending: false,
            },
          ],
        },
        callback: (error, res) => {
          const {data = [], numOfRecords = 0, numOfPages = 0} = res;
          setDataStore(data);
          setStoreTotalRecords(numOfRecords);
        },
      })
    );
  }, [storePageSize]);

  // useEffect(() => {
  //   dispatch(getRewardInfoAction());
  //   dispatch(
  //     getAllGiftByMerchantAction({
  //       merchantId: process.env.REACT_APP_MERCHANT_ID,
  //       callback: (data) => {
  //         setDataGift(data);
  //       },
  //     })
  //   );
  // }, []);

  const tabData = [
    {
      key: 1,
      text: 'vòng xoay may mắn',
    },
    {
      key: 2,
      text: 'Tích điểm đổi quà',
    },
  ];
  const onChangeStatus = (id) => {
    setTabStatus(id);
  };
  const handleNext = (item) => {
    setNumberEvoucherStart(numberEvoucherStart + item);
    setNumberEvoucher(numberEvoucher + item);
  };
  const handlePrev = (item) => {
    setNumberEvoucherStart(numberEvoucherStart - item);
    setNumberEvoucher(numberEvoucher - item);
  };
  const onEditClick = () => {
    navigate(ROUTE_NAME.EDIT_PROFILE);
  };
  return (
    <Layout
      headerProps={{
        index: 3,
      }}
    >
      {isMobile ? (
        <>
          <div className="container-chill">
            <div className="container">
              <div className="container-content">
                <div
                  style={{
                    marginTop: 15,
                    marginBottom: 32,
                  }}
                >
                  <div className="userbg">
                    <div className=" is-flex user-summarize-info">
                      <div className="is-relative has-text-centered" style={{paddingLeft: 30}}>
                        <img
                          width={120}
                          src={userInfo?.avatar ? userInfo?.avatar : image_avatar_placeholder}
                          alt="Placeholder image"
                          className="avatar-user"
                        />
                      </div>
                      <div
                        className="pl-5 is-flex is-flex-direction-column is-justify-content-center"
                        style={{
                          paddingLeft: 15,
                          paddingTop: 30,
                          fontWeight: 500,
                          flex: 1,
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            color: '#323130',
                            fontSize: 10,
                            paddingBottom: 5,
                          }}
                        >
                          Chiller:
                        </div>
                        <div
                          style={{
                            color: '#83552D',
                            fontSize: 16,
                            paddingBottom: 12,
                            fontFamily: 'Americana',
                          }}
                        >
                          {userInfo.fullName}
                        </div>
                        <div
                          style={{
                            color: '#323130',
                            fontSize: 10,
                            paddingBottom: 5,
                          }}
                        >
                          Bạn hiện có:
                        </div>
                        <div
                          style={{
                            color: '#83552D',
                            fontSize: 14,
                            paddingBottom: 12,
                          }}
                        >
                          <span className="profile-chill-koin">
                            {rewardInfo?.totalRewardsAvailable
                              ? rewardInfo?.totalRewardsAvailable.toLocaleString()
                              : 0}{' '}
                          </span>{' '}
                          Chill Koin
                        </div>
                      </div>
                    </div>
                    {/* <div className="columns is-multiline is-flex-mobile">
                      <div className="is-6 is-3-desktop is-6-mobile mt-2 is-clickable">
                        <div className="gift-item">
                          <div
                            className="gift-item-profile"
                            style={{
                              paddingLeft: 50,
                              paddingTop: 20,
                            }}
                          >
                            <img
                              width={120}
                              src={userInfo?.avatar ? userInfo?.avatar : image_avatar_placeholder}
                              alt="Placeholder image"
                              className="avatar-user"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="is-6 is-3-desktop is-6-mobile mt-2  is-clickable">
                        <div
                          style={{
                            paddingLeft: 15,
                            paddingTop: 30,
                            fontWeight: 500,
                          }}
                        >
                          <div
                            style={{
                              color: '#323130',
                              fontSize: 10,
                              paddingBottom: 5,
                            }}
                          >
                            Chiller:
                          </div>
                          <div
                            style={{
                              color: '#83552D',
                              fontSize: 16,
                              paddingBottom: 12,
                              fontFamily: 'Americana',
                            }}
                          >
                            {userInfo.fullName}
                          </div>
                          <div
                            style={{
                              color: '#323130',
                              fontSize: 10,
                              paddingBottom: 5,
                            }}
                          >
                            Bạn hiện có:
                          </div>
                          <div
                            style={{
                              color: '#83552D',
                              fontSize: 14,
                              paddingBottom: 12,
                            }}
                          >
                            <span className="profile-chill-koin">
                              {rewardInfo?.totalRewardsAvailable
                                ? rewardInfo?.totalRewardsAvailable.toLocaleString()
                                : 0}{' '}
                            </span>{' '}
                            Chill Koin
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-12 column-is-custom has-text-centered">
                    {tabData.map((item) => (
                      <>
                        <button
                          key={item.key}
                          onClick={() => onChangeStatus(item.key)}
                          className={classNames('button-custom ', {
                            'mr-1 mb-2': !item.isLast,
                            'is-primary-custom ': item.key === tabStatus,
                            'is-second-custom is-second-custom-color': item.key != tabStatus,
                          })}
                          style={{
                            textTransform: 'uppercase',
                            width: 170,
                          }}
                        >
                          {item.text}
                        </button>
                      </>
                    ))}
                  </div>
                </div>
                {tabStatus == 1 && (
                  <>
                    <Lucky rewardInfo={rewardInfo.totalRewardsAvailable} />
                  </>
                )}
                <div className="columns">
                  <div className="column column-is-custom has-text-centered">
                    <img src={gift_chill} />
                  </div>
                </div>
                <GIFT_LIST
                  dataChill={dataChill}
                  chillPageSize={chillPageSize}
                  handleShowMoreChill={setChillPageSize}
                  chillTotalRecords={chillTotalRecords}
                  dataVoucher={dataVoucher}
                  numberEvoucher={numberEvoucher}
                  voucherTotalRecords={voucherTotalRecords}
                  numberEvoucherStart={numberEvoucherStart}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  dataStore={dataStore}
                  storePageSize={storePageSize}
                  handleShowMoreEvoucherStore={setStorePageSize}
                  storeTotalRecords={storeTotalRecords}
                />
                <div>
                  <ContactButton />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-chill">
            <div
              style={{
                position: 'absolute',
                top: 230,
              }}
            >
              <img src={element_chill_left} />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '20%',
                right: 0,
              }}
            >
              <img src={element_chill_right} />
            </div>

            <div className="container">
              <div
                className="container-content"
                style={{
                  marginTop: 85,
                }}
              >
                <div
                  style={{
                    maxWidth: 820,
                    display: 'block',
                    margin: 'auto',
                  }}
                >
                  <div
                    className="user_pc_bg mb-6"
                    style={{
                      marginTop: 100,
                    }}
                  >
                    <div className="columns is-multiline">
                      <div className="column is-5 column-is-custom">
                        <div className="gift-item">
                          <div
                            className="gift-item-profile"
                            style={{
                              paddingLeft: 130,
                              paddingTop: 20,
                              position: 'relative',
                              paddingBottom: 50,
                            }}
                          >
                            <img
                              src={userInfo?.avatar ? userInfo?.avatar : image_avatar_placeholder}
                              alt="Placeholder image"
                              className="avatar"
                              style={{
                                height: 230,
                                width: 230,
                                borderRadius: '50%',
                                objectFit: 'cover',
                                border: '3px solid #FFF',
                                borderWidth: 8,
                              }}
                            />

                            <div
                              style={{
                                position: 'absolute',
                                top: 175,
                              }}
                            >
                              <img src={balloon} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column is-6 column-is-custom">
                        <div
                          className="has-text-centered pr-6"
                          style={{
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{
                              paddingTop: 30,
                              fontSize: 18,
                              fontWeight: 500,
                              color: '#323130',
                            }}
                          >
                            Chiller:
                          </div>
                          <div
                            style={{
                              color: '#83552D',
                              fontSize: 32,
                              paddingBottom: 16,
                              fontWeight: 700,
                              fontFamily: 'Americana',
                            }}
                          >
                            {userInfo.fullName}
                          </div>
                          <div
                            style={{
                              color: '#323130',
                              fontSize: 18,
                              paddingBottom: 5,
                            }}
                          >
                            Bạn hiện có:
                          </div>
                          <div
                            style={{
                              color: '#83552D',
                            }}
                          >
                            <span
                              style={{
                                fontSize: 32,
                                fontWeight: 700,
                              }}
                            >
                              {rewardInfo?.totalRewardsAvailable
                                ? rewardInfo?.totalRewardsAvailable.toLocaleString()
                                : 0}{' '}
                            </span>{' '}
                            <span
                              style={{
                                fontSize: 19,
                                fontWeight: 500,
                              }}
                            >
                              Chill Koin
                            </span>
                          </div>
                          <div
                            style={{
                              position: 'absolute',
                              right: 0,
                              top: 26,
                            }}
                          >
                            <img
                              // width={20}
                              src={pencil_underline}
                              alt="Placeholder"
                              className=" is-clickable"
                              style={{width: 24, height: 24}}
                              onClick={onEditClick}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12 column-is-custom has-text-centered">
                      {tabData.map((item) => (
                        <>
                          <button
                            key={item.key}
                            onClick={() => onChangeStatus(item.key)}
                            className={classNames('button-custom ', {
                              'mr-4 ml-4 mb-2': !item.isLast,
                              'is-primary-custom ': item.key === tabStatus,
                              'is-second-custom is-second-custom-color': item.key != tabStatus,
                            })}
                            style={{
                              textTransform: 'uppercase',
                              width: 320,
                            }}
                          >
                            {item.text}
                          </button>
                        </>
                      ))}
                    </div>
                  </div>
                </div>

                {tabStatus == 1 && (
                  <>
                    <Lucky rewardInfo={rewardInfo.totalRewardsAvailable} />
                  </>
                )}
                <div className="columns">
                  <div className="column column-is-custom mt-4 has-text-centered">
                    <img
                      src={gift_chill}
                      style={{
                        maxHeight: 220,
                        display: 'block',
                        margin: 'auto',
                      }}
                    />
                  </div>
                </div>
                <GIFT_LIST
                  dataChill={dataChill}
                  chillPageSize={chillPageSize}
                  handleShowMoreChill={setChillPageSize}
                  chillTotalRecords={chillTotalRecords}
                  dataVoucher={dataVoucher}
                  numberEvoucher={numberEvoucher}
                  voucherTotalRecords={voucherTotalRecords}
                  numberEvoucherStart={numberEvoucherStart}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  dataStore={dataStore}
                  storePageSize={storePageSize}
                  handleShowMoreEvoucherStore={setStorePageSize}
                  storeTotalRecords={storeTotalRecords}
                />
                <div>
                  <ContactButton />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};
export default ChillPage;
