import React from 'react';
import Layout from '../../components/layout';
import Select from 'react-select';
import {
  getProvinces,
  getDistrictByProvinceCodeAction,
  getWardByDistrictCodeAction,
  getStoreDetailByMerchant,
} from 'appRedux/actions/app';
import {useDispatch, useSelector} from 'react-redux';
import Contact from 'components/contact';
import market from 'images/strongbow/market.png';
import {Pagination} from 'react-bulma-components';
import list_win from 'images/strongbow/store.png';
import image_store_pc from 'images/strongbow/pc/18.png';
import ContactButton from 'components/contactButton';
import bottle_img from 'images/strongbow/bottle.png';
import './styles.scss';
import {isMobile, isDesktop} from 'react-device-detect';

const Store = () => {
  const dispatch = useDispatch();
  const [dataProvince, setDataProvince] = React.useState([]);
  const [dataDistrict, setDataDistrict] = React.useState([]);
  const [dataWard, setDataWard] = React.useState([]);
  const [dataFiter, setDataFilter] = React.useState({});
  const [storeList, setStoreList] = React.useState([]);
  const [storeListFilter, setStoreListFilter] = React.useState([]);
  const [pages, setPages] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(0);

  React.useEffect(() => {
    dispatch(
      getProvinces({
        callback: (data) => {
          let provinces = [
            {
              shortName: 'a',
              value: '',
              label: 'Tất cả',
            },
          ];
          data.map((item) => {
            provinces.push({
              label: item.name,
              value: item.code,
              shortName: item.shortName,
            });
          });
          setDataProvince(provinces.sort((a, b) => a.shortName.localeCompare(b.shortName)));
        },
      })
    );
    dispatch(
      getStoreDetailByMerchant({
        merchantId: process.env.REACT_APP_MERCHANT_ID,
        callback: (data) => {
          setStoreList(data.data);
          setStoreListFilter(data.data);
        },
      })
    );
  }, []);
  const getDistrictByProvinceId = async (provinceCode) => {
    dispatch(
      getDistrictByProvinceCodeAction({
        provinceCode: provinceCode,
        callback: (data) => {
          let districts = [
            {
              label: 'Tất cả',
              value: '',
            },
          ];
          data.map((item) => {
            districts.push({
              label: item.name,
              value: item.code,
            });
          });
          setDataDistrict(districts);
        },
      })
    );
  };
  const getWardByDistrictId = async (districtCode) => {
    dispatch(
      getWardByDistrictCodeAction({
        districtCode: districtCode,
        callback: (data) => {
          let wards = [
            {
              label: 'Tất cả',
              value: '',
            },
          ];
          data.map((item) => {
            wards.push({
              label: item.name,
              value: item.code,
            });
          });
          setDataWard(wards);
        },
      })
    );
  };
  const onChangeSelect = (name) => (event) => {
    setPerPage(0);
    setCurrentPage(1);
    setPages(10);
    if (name == 'province') {
      setDataFilter({
        ...dataFiter,
        province: event.value,
        district: '',
      });
      if (event.value) {
        getDistrictByProvinceId(event.value);
        setStoreListFilter(storeList?.filter((item) => item.location.province == event.value));
      } else {
        setStoreListFilter(storeList);
      }
    }
    if (name == 'district') {
      setDataFilter({
        ...dataFiter,
        district: event.value,
        ward: '',
      });
      if (event.value) {
        getWardByDistrictId(event.value);
        setStoreListFilter(storeList?.filter((item) => item.location.district == event.value));
      } else {
        setStoreListFilter(
          storeList?.filter((item) => item.location.province == dataFiter.province)
        );
      }
    } else if (name == 'ward') {
      setDataFilter({
        ...dataFiter,
        ward: event.value,
      });
      if (event.value) {
        setStoreListFilter(storeList?.filter((item) => item.location.ward == event.value));
      } else {
        setStoreListFilter(
          storeList?.filter((item) => item.location.district == dataFiter.district)
        );
      }
    }
  };
  const onChangePage = (page) => {
    setCurrentPage(page);
    setPages(page * 10);
    setPerPage(page * 10 - 10);
  };
  const totalPages = Math.ceil(storeListFilter.length / 10);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: '#D6BA85 !important',
      borderRadius: 30,
      fontWeight: 500,
      color: '#FFF',
      fontSize: 16,
      boxShadow: state.isFocused ? '0 0 0 0.125em rgb(221 164 16 / 25%)' : 'unset',
    }),
    // placeholder: (base, state) => ({
    //   ...base,
    //   color: '#D6BA85',
    // }),
    singleValue: (base, state) => ({
      ...base,
      color: 'rgb(54,54,54)',
      fontWeight: 400,
    }),
  };

  return isMobile ? (
    <Layout
      headerProps={{
        index: 3,
      }}
    >
      <div className="container-chill">
        <div className="container">
          <div className="container-content">
            <div className="columns">
              <div
                className="column"
                style={{
                  paddingLeft: 40,
                  paddingRight: 40,
                }}
              >
                <img src={list_win} />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <div className="control">
                    <Select
                      placeholder="Chọn Tỉnh/Thành"
                      options={dataProvince}
                      onChange={onChangeSelect('province')}
                      styles={customStyles}
                      className="select-is-custom"
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <Select
                      name="district"
                      id="district"
                      placeholder="Chọn Quận/Huyện"
                      onChange={onChangeSelect('district')}
                      options={dataDistrict}
                      isDisabled={!dataFiter.province}
                      value={
                        dataFiter.district
                          ? dataDistrict.find((option) => option.value === dataFiter.district)
                          : ''
                      }
                      styles={customStyles}
                      className="select-is-custom"
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <Select
                      placeholder="Chọn Phường/Xã"
                      onChange={onChangeSelect('ward')}
                      options={dataWard}
                      isDisabled={!dataFiter.district}
                      value={
                        dataFiter.ward
                          ? dataWard.find((option) => option.value === dataFiter.ward)
                          : ''
                      }
                      styles={customStyles}
                      className="select-is-custom"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div
                className="column-is-custom list-bg-store"
                style={{
                  paddingBottom: 20,
                }}
              >
                <div className="custom-table">
                  {storeListFilter?.length > 0 && (
                    <>
                      <div className="column">
                        <div>
                          <div className="">
                            <div className="columns">
                              <div className="column">
                                <div>
                                  {storeListFilter?.slice(perPage, pages)?.map((item, index) => {
                                    return (
                                      <>
                                        <div className="columns is-border-column">
                                          <div className="column has-text-weight-semibold column-display-mobile-one">
                                            <div
                                              className="has-text-weight-bold"
                                              style={{
                                                color: '#83552D',
                                                fontSize: 12,
                                              }}
                                            >
                                              {item?.name}
                                            </div>
                                            {/* {item?.phone && (
                                            <div className="icon-text">
                                              <span className="icon" style={{color: '#08633D'}}>
                                                <img src={phone} style={{height: 14}} />
                                              </span>
                                              <span
                                                style={{
                                                  color: '#343434',
                                                  fontSize: 12,
                                                }}
                                              >
                                                {item?.phone}
                                              </span>
                                            </div>
                                          )} */}
                                          </div>
                                          <div className="column has-text-weight-semibold column-display-mobile">
                                            <div
                                              style={{
                                                position: 'relative',
                                              }}
                                            >
                                              {item?.location.address && (
                                                <div
                                                  style={{
                                                    position: 'absolute',
                                                    top: '60%',
                                                    transform: 'translate(0, -50%)',
                                                  }}
                                                >
                                                  <img
                                                    src={market}
                                                    style={{
                                                      height: 16,
                                                    }}
                                                  />
                                                </div>
                                              )}
                                              <div
                                                style={{
                                                  marginLeft: 30,
                                                  color: '#343434',
                                                  fontSize: 12,
                                                }}
                                              >
                                                {item?.location.address}
                                                {item?.location.wardName
                                                  ? ', ' + item?.location.wardName
                                                  : ''}
                                                {item?.location.districtName
                                                  ? ', ' + item?.location.districtName
                                                  : ''}
                                                {item?.location.provinceName
                                                  ? ', ' + item?.location.provinceName
                                                  : ''}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div
                  style={{
                    marginTop: -15,
                  }}
                >
                  <Pagination
                    pages={pages}
                    current={currentPage}
                    showFirstLast
                    total={totalPages}
                    delta={1}
                    showPrevNext={false}
                    align={'center'}
                    onChange={(page) => onChangePage(page)}
                  />
                </div>
              </div>
            </div>
            <ContactButton />
          </div>
        </div>
      </div>
    </Layout>
  ) : isDesktop ? (
    <Layout
      headerProps={{
        index: 3,
      }}
    >
      <div className="container-chill-pc is-relative">
        <div className="container">
          <div className="container-content">
            <div className="columns" style={{paddingTop: '5%'}}>
              {/* <div
                className="column"
                style={{
                  paddingLeft: 40,
                  paddingRight: 40,
                }}
              >

              </div> */}
              <div className="column is-6">
                <img src={list_win} style={{width: '70%'}} />
                <div className="field custom-field-input-pc">
                  <div className="control">
                    <Select
                      placeholder="Chọn Tỉnh/Thành"
                      options={dataProvince}
                      onChange={onChangeSelect('province')}
                      styles={customStyles}
                      //className="select-is-custom"
                    />
                  </div>
                </div>
                <div className="field custom-field-input-pc">
                  <div className="control">
                    <Select
                      name="district"
                      id="district"
                      placeholder="Chọn Quận/Huyện"
                      onChange={onChangeSelect('district')}
                      options={dataDistrict}
                      isDisabled={!dataFiter.province}
                      value={
                        dataFiter.district
                          ? dataDistrict.find((option) => option.value === dataFiter.district)
                          : ''
                      }
                      styles={customStyles}
                      //className="select-is-custom"
                    />
                  </div>
                </div>
                <div className="field custom-field-input-pc">
                  <div className="control">
                    <Select
                      placeholder="Chọn Phường/Xã"
                      onChange={onChangeSelect('ward')}
                      options={dataWard}
                      isDisabled={!dataFiter.district}
                      value={
                        dataFiter.ward
                          ? dataWard.find((option) => option.value === dataFiter.ward)
                          : ''
                      }
                      styles={customStyles}
                      //className="select-is-custom"
                    />
                  </div>
                </div>
                <img src={image_store_pc} style={{width: '70%'}} />
              </div>
              <div className="column is-6 column-is-custom">
                <div className="list-bg-store-pc pb-5">
                  <div className="custom-table m" style={{height: 885}}>
                    {storeListFilter?.length > 0 && (
                      <>
                        <div className="column">
                          <div className="columns">
                            <div className="column">
                              <div
                                style={{
                                  height: 840,
                                  overflowY: 'auto',
                                  overflowX: 'hidden',
                                }}
                              >
                                {storeListFilter?.slice(perPage, pages)?.map((item, index) => {
                                  return (
                                    <>
                                      <div className="columns">
                                        <div className="column has-text-weight-semibold column-display-mobile-one">
                                          <div
                                            className="has-text-weight-bold"
                                            style={{
                                              color: '#83552D',
                                              fontSize: 16,
                                              fontWeight: 700,
                                            }}
                                          >
                                            {item?.name}
                                          </div>
                                          {/* {item?.phone && (
                                              <div className="icon-text">
                                                <span className="icon" style={{color: '#08633D'}}>
                                                  <img src={phone} style={{height: 14}} />
                                                </span>
                                                <span
                                                  style={{
                                                    color: '#343434',
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  {item?.phone}
                                                </span>
                                              </div>
                                            )} */}
                                          <div className="has-text-weight-semibold column-display-mobile">
                                            <div
                                              style={{
                                                position: 'relative',
                                              }}
                                            >
                                              {item?.location.address && (
                                                <div
                                                  style={{
                                                    position: 'absolute',
                                                    top: '60%',
                                                    transform: 'translate(0, -50%)',
                                                  }}
                                                >
                                                  <img
                                                    src={market}
                                                    style={{
                                                      height: 16,
                                                    }}
                                                  />
                                                </div>
                                              )}
                                              <div
                                                style={{
                                                  marginLeft: 30,
                                                  color: '#343434',
                                                  fontSize: 14,
                                                }}
                                              >
                                                {item?.location.address}
                                                {item?.location.wardName
                                                  ? ', ' + item?.location.wardName
                                                  : ''}
                                                {item?.location.districtName
                                                  ? ', ' + item?.location.districtName
                                                  : ''}
                                                {item?.location.provinceName
                                                  ? ', ' + item?.location.provinceName
                                                  : ''}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <Pagination
                      pages={pages}
                      current={currentPage}
                      showFirstLast
                      total={totalPages}
                      delta={1}
                      showPrevNext={false}
                      align={'center'}
                      onChange={(page) => onChangePage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <ContactButton />
          </div>
        </div>
        <div style={{position: 'absolute', right: 0, bottom: '10%'}}>
          <img src={bottle_img} style={{width: 150, transform: 'rotate(12deg)'}} />
        </div>
      </div>
    </Layout>
  ) : (
    ''
  );
};
export default Store;
