import {Layout} from 'antd';
import React, {useEffect} from 'react';
import './styles.scss';

import Header from '../header';
import Footer from 'components/footer';
import UserProfileModal from 'components/UserProfileModal';
import AppDrawer from 'components/drawer';
import LoadingOverlay from 'react-loading-overlay';
import {isLoadingSelector, isOver18Selector} from 'appRedux/selectors/app';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {isLogInSelector} from 'appRedux/selectors/auth';
import {ROUTE_NAME} from 'constants/routes';
import FooterMeoChill from 'components/footerMeoChill';
const {Content} = Layout;
const AppLayout = ({
  headerProps = {},
  footerProps = {},
  userProfileModalProps = {},
  ignoreOver18Check = false,
  children,
  isFooter = true,
}) => {
  const isLoading = useSelector(isLoadingSelector);
  const navigate = useNavigate();
  const isOver18 = useSelector(isOver18Selector);
  const isLogin = useSelector(isLogInSelector);

  useEffect(() => {
    if (!isOver18 && !isLogin && !ignoreOver18Check) {
      navigate(ROUTE_NAME.HOME);
    }
  }, [isLogin, isOver18, ignoreOver18Check]);
  return (
    <>
      <Header {...headerProps}></Header>
      <AppDrawer />
      <LoadingOverlay active={isLoading} spinner>
        <Content className="" style={{overflow: 'hidden'}}>
          {children}
        </Content>
      </LoadingOverlay>
      {isFooter ? <Footer {...footerProps} /> : <FooterMeoChill {...footerProps} />}

      <UserProfileModal {...userProfileModalProps} />
    </>
  );
};

export default AppLayout;
