import React, {useState} from 'react';
import logo from 'images/footer_banner.png';
import {Link} from 'react-router-dom';

import './styles.scss';
import {ROUTE_NAME} from 'constants/routes';
import {CONTACT_INFO} from 'constants/app';
import classnames from 'classnames';
import icon_18 from 'images/icon_18.png';
import icon_car from 'images/icon_car.png';
import icon_mother from 'images/icon_mother.png';
const menuItems = [
  {
    id: 1,
    text: 'Điều khoản sử dụng',
    path: '/user',
    show: true,
  },
  {
    id: 2,
    text: 'Chính sách quyền riêng tư',
    path: '/user',
    show: true,
  },
  {
    id: 3,
    text: 'Câu hỏi thường gặp',
    path: '/user',
    show: true,
  },
  {
    id: 4,
    text: 'Liên hệ',
    path: '/user',
    show: true,
  },
  {
    id: 5,
    text: 'Social',
    path: '/user',
    show: true,
  },
];
const AppFooter = () => {
  return (
    <>
      <div className="footer has-background-black clearfix py-4">
        <div className="container container-footer-mobile pb-0">
          <div className=" columns">
            <div className="column  p-0">
              <div className="has-text-centered-tablet is-size-3 has-text-white">
                <div className="content content-footer-mobile">
                  <Link to={ROUTE_NAME.HOME}>
                    <a>
                      <img src={logo} width={160} alt="logo" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="column p-0 has-text-centered">
              <div className="content has-text-centered">
                <div className=" has-text-white">Hotline</div>
                <a
                  className="title  is-size-5 has-text-weight-bold mb-2"
                  style={{
                    color: '#E8C826',
                  }}
                  href={`tel:${CONTACT_INFO.phone}`}
                  alt="contact"
                >
                  <span className="icon mr-1">
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  {CONTACT_INFO.phone}
                </a>
              </div>
              <div className="social-mobile-icon mt-0 mb-2 has-text-white">
                <a
                  href="https://www.facebook.com/Strongbowvietnam/"
                  target="_blank"
                  rel="noopener noopener noreferrer"
                >
                  <span className="icon is-size-3 mr-6  has-text-white">
                    <i className="fa-brands fa-facebook"></i>
                  </span>
                </a>
                <a
                  href="https://www.instagram.com/strongbow/"
                  target="_blank"
                  rel="noopener noopener noreferrer"
                >
                  <span className="icon is-size-3 has-text-white">
                    <i className="fa-brands fa-instagram"></i>
                  </span>
                </a>
              </div>
            </div>

            <div className="column p-0 ">
              <Link to={ROUTE_NAME.INFO}>
                <div className="content has-text-white my-3">
                  <div className="has-text-centered is-size-7 is-size-6-tablet has-text-white has-text-weight-medium">
                    Thể lệ chương trình
                  </div>
                </div>
              </Link>
              <Link to={ROUTE_NAME.TERMS_AND_CONDITIONS}>
                <div className="content has-text-white my-3">
                  <div className=" has-text-centered is-size-7 is-size-6-tablet has-text-white has-text-weight-medium">
                    Chính sách bảo mật
                  </div>
                </div>
              </Link>
              <div className="content has-text-white">
                <div className=" has-text-centered is-size-7 is-size-6-tablet has-text-white ">
                  Copyright &copy;
                </div>
              </div>
            </div>
            {/* <div className="column p-0 ">
            </div>
            <div className="column p-0">
             
            </div> */}
          </div>
        </div>
      </div>
      <div className=" has-background-primary has-text-centered " style={{minHeight: 32}}>
        <span
          className="mr-is-mobile-8 mr-1"
          style={{
            fontSize: 10,
            fontWeight: 700,
            position: 'relative',
            top: -8,
          }}
        >
          NGƯỜI DƯỚI 18 TUỔI KHÔNG ĐƯỢC UỐNG RƯỢU, BIA
        </span>
        <span className="icon is-size-3  mt-2 has-text-white mr-is-mobile-8 mr-1">
          <img src={icon_18} />
        </span>
        <span className="icon is-size-3 has-text-white mr-is-mobile-8 mr-1">
          <img src={icon_mother} />
        </span>
        <span className="icon is-size-3 has-text-white">
          <img src={icon_car} />
        </span>
      </div>
    </>
  );
};

export default AppFooter;
