import utils from 'utils/apiUtils';
import {NUTI_FOOD, STRONGBOW} from './api-definitions';

export const getRewardInfoApi = async () => utils.post(STRONGBOW.getRewardInfo);

export const registerWithFullInfoApi = async (payload) =>
  utils.post(NUTI_FOOD.registerWithFullInfo, payload);

export const searchUserGiftApi = async (payload) => utils.post(NUTI_FOOD.searchUserGift, payload);

export const getUserGiftStrongBowApi = async (payload) =>
  utils.post(STRONGBOW.getUserGiftStrongBow, payload);
export const getAllGiftByMerchantApi = async (merchantId) =>
  utils.get(STRONGBOW.getAllGiftByMerchant(merchantId));
export const getAllGiftByMerchantAnonymousApi = async (merchantId) =>
  utils.get(STRONGBOW.getAllGiftByMerchantAnonymous(merchantId));
export const postAllGiftStrongBowSearchApi = async (payload) =>
  utils.post(STRONGBOW.postAllGiftStrongBowSearch, payload);
  
export const postAllGiftStrongBowAnonymousSearchApi = async (payload) =>
  utils.post(STRONGBOW.postAllGiftStrongBowAnonymousSearch, payload);
  
export const getStoreDetailByCodeApi = async (storeCode) =>
  utils.get(STRONGBOW.getStoreDetailByCode(storeCode));
export const validateHuntingCodeApi = async (code) =>
  utils.get(STRONGBOW.validateHuntingCode(code));
export const createHuntingCodeRewardApi = async (payload) =>
  utils.post(STRONGBOW.createHuntingCodeReward, payload);
export const shareFacebookApi = async (payload) => utils.post(STRONGBOW.shareFacebook, payload);
