import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Layout from '../../components/layout';
import image_benner from 'images/strongbow/banner.png';
import image_banner_pc from 'images/strongbow/pc/banner-pc-1.png';
import image_faq from 'images/strongbow/FAQs.png';
import joinTitle from 'images/join-title.png';
import programBanner from 'images/program-banner.png';
import quaChillBanner from 'images/qua-chill.png';
import splitPage from 'images/strongbow/pc/split-page.png';
import quaChillBannerPc from 'images/qua-chill.png';
import titleChillBannerPc from 'images/strongbow/pc/title_img_without_login_program.png';
import leaf from 'images/strongbow/pc/leaf.png';
import leaf1 from 'images/strongbow/pc/leaf1.png';
import GIFT_LIST from '../../components/GIFT';
import FAQs from '../../components/FAQs';
import {
  postAllGiftStrongBowSearchAction,
  postAllGiftStrongBowAnonymousSearchAction,
} from 'appRedux/actions/app';
import './styles.scss';
import {isLogInSelector, userInfoSelector} from 'appRedux/selectors/auth';
import koin_content from 'images/chill-koin.png';
import koin_content_pc from 'images/strongbow/pc/chill-koin.png';
import {toggleLoginModal} from 'appRedux/slices/appSlice';
import {isMobile, isDesktop} from 'react-device-detect';
import ContactButton from 'components/contactButton';

const STEPS = [
  'Mua Strongbow ở các cửa hàng tiện lợi (GS25, Circle K, 7 Eleven)',
  'Scan mã QR trên bao bì Strongbow hoặc các ấn phẩm tại địa điểm bán hàng và chụp hóa đơn để tích điểm',
  'Đổi điểm nhận quà cực phiêu hoặc tham gia vòng xoay may mắn để có cơ hội nhận nhiều quà hấp dẫn khác',
];

const Index = () => {
  const dispatch = useDispatch();
  const [numberEvoucher, setNumberEvoucher] = React.useState(isMobile ? 4 : 8);
  const [numberEvoucherStart, setNumberEvoucherStart] = React.useState(0);
  const isLogIn = useSelector(isLogInSelector);
  const userInfo = useSelector(userInfoSelector);

  //Qua de chill
  const [chillPageSize, setChillPageSize] = useState(isMobile ? 6 : 12);
  const [chillTotalRecords, setChillTotalRecords] = useState(0);
  const [dataChill, setDataChill] = useState([]);
  const [chillTotalPages, setChillTotalPages] = useState(0);

  //evoucher
  const [voucherTotalRecords, setVoucherTotalRecords] = useState(0);
  const [dataVoucher, setDataVoucher] = useState([]);
  //evoucher
  const [storePageSize, setStorePageSize] = useState(isMobile ? 2 : 4);
  const [storeTotalRecords, setStoreTotalRecords] = useState(0);
  const [dataStore, setDataStore] = useState([]);

  useEffect(() => {
    if (isLogIn) {
      dispatch(
        postAllGiftStrongBowSearchAction({
          body: {
            pageIndex: 1,
            pageSize: chillPageSize,
            userId: userInfo?.userId,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            giftClassify: 'CHILL_GIFT',
            sortBy: [
              {
                fieldName: 'CreatedTime',
                ascending: false,
              },
            ],
          },
          callback: (error, res) => {
            const {data = [], numOfRecords = 0, numOfPages = 0} = res;
            setDataChill(data);
            setChillTotalRecords(numOfRecords);
            setChillTotalPages(numOfPages);
          },
        })
      );
    } else {
      dispatch(
        postAllGiftStrongBowAnonymousSearchAction({
          body: {
            pageIndex: 1,
            pageSize: chillPageSize,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            giftClassify: 'CHILL_GIFT',
            sortBy: [
              {
                fieldName: 'CreatedTime',
                ascending: false,
              },
            ],
          },
          callback: (error, res) => {
            const {data = [], numOfRecords = 0, numOfPages = 0} = res;
            setDataChill(data);
            setChillTotalRecords(numOfRecords);
            setChillTotalPages(numOfPages);
          },
        })
      );
    }
  }, [chillPageSize]);

  useEffect(() => {
    if (isLogIn) {
      dispatch(
        postAllGiftStrongBowSearchAction({
          body: {
            pageIndex: 1,
            pageSize: 10000,
            userId: userInfo?.userId,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            giftClassify: 'E_VOUCHER',
            sortBy: [
              {
                fieldName: 'CreatedTime',
                ascending: false,
              },
            ],
          },
          callback: (error, res) => {
            const {data = [], numOfRecords = 0, numOfPages = 0} = res;
            setDataVoucher(data);
            setVoucherTotalRecords(numOfRecords);
          },
        })
      );
    } else {
      dispatch(
        postAllGiftStrongBowAnonymousSearchAction({
          body: {
            pageIndex: 1,
            pageSize: 10000,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            giftClassify: 'E_VOUCHER',
            sortBy: [
              {
                fieldName: 'CreatedTime',
                ascending: false,
              },
            ],
          },
          callback: (error, res) => {
            const {data = [], numOfRecords = 0, numOfPages = 0} = res;
            setDataVoucher(data);
            setVoucherTotalRecords(numOfRecords);
          },
        })
      );
    }
  }, []);
  useEffect(() => {
    if (isLogIn) {
      dispatch(
        postAllGiftStrongBowSearchAction({
          body: {
            pageIndex: 1,
            pageSize: storePageSize,
            userId: userInfo?.userId,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            giftClassify: 'STORE_GIFT',
            sortBy: [
              {
                fieldName: 'CreatedTime',
                ascending: false,
              },
            ],
          },
          callback: (error, res) => {
            const {data = [], numOfRecords = 0, numOfPages = 0} = res;
            setDataStore(data);
            setStoreTotalRecords(numOfRecords);
          },
        })
      );
    } else {
      dispatch(
        postAllGiftStrongBowAnonymousSearchAction({
          body: {
            pageIndex: 1,
            pageSize: storePageSize,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            giftClassify: 'STORE_GIFT',
            sortBy: [
              {
                fieldName: 'CreatedTime',
                ascending: false,
              },
            ],
          },
          callback: (error, res) => {
            const {data = [], numOfRecords = 0, numOfPages = 0} = res;
            setDataStore(data);
            setStoreTotalRecords(numOfRecords);
          },
        })
      );
    }
  }, [storePageSize]);
  // useEffect(() => {
  //   if (isLogIn) {
  //     dispatch(
  //       getAllGiftByMerchantAction({
  //         merchantId: process.env.REACT_APP_MERCHANT_ID,
  //         callback: (data) => {
  //           setDataGift(data);
  //         },
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       getAllGiftByMerchantAnonymousAction({
  //         merchantId: process.env.REACT_APP_MERCHANT_ID,
  //         callback: (data) => {
  //           setDataGift(data);
  //         },
  //       })
  //     );
  //   }
  // }, []);
  const handleNext = (item) => {
    setNumberEvoucherStart(numberEvoucherStart + item);
    setNumberEvoucher(numberEvoucher + item);
  };
  const handlePrev = (item) => {
    setNumberEvoucherStart(numberEvoucherStart - item);
    setNumberEvoucher(numberEvoucher - item);
  };

  return isMobile ? (
    <Layout>
      <div
        style={{
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
          }}
        >
          <img src={image_benner} />
        </div>
      </div>
      <div className="container-chill">
        <div className="container">
          <div
            className="container-content"
            style={{
              marginTop: 150,
            }}
          >
            <div className="info-page-bg-v1">
              <div className=" has-text-centere">
                <img
                  className=" my-2"
                  src={programBanner}
                  style={{width: '60%', marginLeft: '15%'}}
                  alt="How to join title"
                />
              </div>
              <div className="columns">
                <div className="column column-is-custom">
                  <div className="info-page-content" style={{height: 'unset'}}>
                    <div className="has-text-centered info-page-title">
                      <img
                        className=" my-2"
                        src={joinTitle}
                        style={{width: '60%'}}
                        alt="How to join title"
                      />
                    </div>
                    <div
                      className="info-page-text"
                      style={
                        {
                          // height: 230,
                          // overflow: 'scroll',
                        }
                      }
                    >
                      <div className=" px-5 pb-3">
                        {STEPS.map((step, index) => {
                          return (
                            <div className=" is-flex is-align-items-center mb-2 has-text-weight-medium">
                              <div
                                class="button-change button-change-bg mr-2"
                                // style={{width: 80}}
                              >{`Bước ${index + 1}`}</div>
                              <div style={{flex: 1}}>{step}</div>
                            </div>
                          );
                        })}
                      </div>
                      {/* <p
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                      >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                        dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                        esse cillum dolore eu fugiat nulla pariatur.
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isLogIn && (
              <div className="columns">
                <div className="column has-text-centered">
                  <button
                    className="button is-primary-custom is-uppercase"
                    style={{
                      color: '#FFF',
                      padding: '14px 50px',
                      fontSize: 16,
                      fontWeight: 700,
                      textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                    }}
                    onClick={() => {
                      dispatch(toggleLoginModal(true));
                    }}
                  >
                    Tham gia ngay
                  </button>
                </div>
                <div className="column has-text-centered has-text-weight-semibold">
                  Rinh ngay quà xịn từ Strongbow nào Chiller ơi!
                </div>
              </div>
            )}
            <div className="columns">
              <div className="column">
                <img src={koin_content} />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div>
                  <img src={quaChillBanner} />
                </div>
              </div>
            </div>
            <GIFT_LIST
              // data={dataGift}
              // numberEvoucher={numberEvoucher}
              // numberChill={numberChill}
              // numberEvoucherStore={numberEvoucherStore}

              // handleShowMoreEvoucher={setNumberEvoucher}
              // handleShowMoreEvoucherStore={setNumberEvoucherStore}
              // numberEvoucherStart={numberEvoucherStart}
              // handleNext={handleNext}
              // handlePrev={handlePrev}

              dataChill={dataChill}
              chillPageSize={chillPageSize}
              handleShowMoreChill={setChillPageSize}
              chillTotalRecords={chillTotalRecords}
              dataVoucher={dataVoucher}
              numberEvoucher={numberEvoucher}
              voucherTotalRecords={voucherTotalRecords}
              numberEvoucherStart={numberEvoucherStart}
              handleNext={handleNext}
              handlePrev={handlePrev}
              dataStore={dataStore}
              storePageSize={storePageSize}
              handleShowMoreEvoucherStore={setStorePageSize}
              storeTotalRecords={storeTotalRecords}
            />
            <div className="columns">
              <div className="column">
                <div>
                  <img src={image_faq} />
                </div>
              </div>
            </div>
            <FAQs />
          </div>
        </div>
      </div>
    </Layout>
  ) : isDesktop ? (
    <Layout>
      <div className="container-chill-pc">
        <div>
          <img src={image_banner_pc} style={{width: '100%'}} />
        </div>
        <div
          style={{
            position: 'absolute',
            left: 0,
            marginTop: '25%',
          }}
        >
          <img src={leaf} />
        </div>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
          }}
        >
          <img src={leaf} />
        </div>
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
          }}
        >
          <img src={leaf1} />
        </div>
        <div className="container">
          <div className="container-content mt-0">
            {/* <div className="columns pb-4">
              <div className="column" style={{paddingTop: 110}}>
                <img style={{width: '50%'}} src={programBanner} alt="How to join title" />
              </div>
              <div className="column">
                <div className="info-page-bg-v2" style={{width: '78%'}}>
                  <div className="info-page-content" style={{height: 'unset'}}>
                    <div
                      className="has-text-centered info-page-title"
                      style={{paddingTop: '20%', marginBottom: '10%'}}
                    >
                      <img src={joinTitle} style={{width: '60%'}} alt="How to join title" />
                    </div>
                    <div className="info-page-text">
                      <div className=" px-5 pb-3">
                        {STEPS.map((step, index) => {
                          return (
                            <div className=" is-flex is-align-items-center mb-2 has-text-weight-medium">
                              <div
                                class="button-change button-change-bg mr-2"
                                // style={{width: 80}}
                              >{`Bước ${index + 1}`}</div>
                              <div style={{flex: 1}}>
                                <b>{step}</b>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {!isLogIn && (
              <>
                <div className="columns">
                  <div className="column is-12">
                    <img src={titleChillBannerPc} style={{width: '75%', marginLeft: '15%'}} />
                  </div>
                  <div style={{position: 'absolute', marginTop: '6%', left: '37%', width: '34%'}}>
                    <button
                      className="button is-primary-custom is-uppercase"
                      style={{
                        color: '#FFF',
                        padding: '14px 50px',
                        fontSize: 20,
                        fontWeight: 700,
                        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                      }}
                      onClick={() => {
                        dispatch(toggleLoginModal(true));
                      }}
                    >
                      Tham gia ngay
                    </button>
                  </div>
                </div>
              </>
            )}

            <div className="columns" style={{marginTop: '2%'}}>
              <div className="column has-text-centered">
                <img src={koin_content_pc} style={{width: '80%'}} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="custom-split-page-div-img">
            <img src={splitPage} className="custom-split-page-img" />
          </div>
        </div>
        <div className="container">
          <div className="custom-general-banner-div">
            <img src={quaChillBannerPc} style={{width: '40%', marginBottom: '5%'}} />
          </div>
          <GIFT_LIST
            dataChill={dataChill}
            chillPageSize={chillPageSize}
            handleShowMoreChill={setChillPageSize}
            chillTotalRecords={chillTotalRecords}
            dataVoucher={dataVoucher}
            numberEvoucher={numberEvoucher}
            voucherTotalRecords={voucherTotalRecords}
            numberEvoucherStart={numberEvoucherStart}
            handleNext={handleNext}
            handlePrev={handlePrev}
            dataStore={dataStore}
            storePageSize={storePageSize}
            handleShowMoreEvoucherStore={setStorePageSize}
            storeTotalRecords={storeTotalRecords}
          />
          <div className="custom-general-banner-div">
            <img src={image_faq} style={{width: '30%'}} />
          </div>
          <FAQs />
          <div className="">
            <ContactButton />
          </div>
        </div>
      </div>
    </Layout>
  ) : (
    ''
  );
};
export default Index;
