import React from 'react';
import {Pagination} from 'react-bulma-components';
import {TRANSACTION_TYPE} from 'constants/transaction.type';
import moment from 'moment';
import classNames from 'classnames';
const HistoryReward = ({
  currentPage = 1,
  totalPages = 1,
  onChangePage = () => {},
  data = [],
  dataScan = [],
  currentPageScan = 1,
  totalPageScan = 1,
  onChangePageScan = () => {},
}) => {
  const [tabStatus, setTabStatus] = React.useState(1);
  const [checkNegativeNumber] = React.useState(2);
  const userGiftStatusesData = [
    {
      key: 1,
      text: 'Lịch sử điểm',
    },
    {
      key: 2,
      text: 'Lịch sử scan bill',
      isLast: true,
    },
  ];

  const getTransactionDescription = (item) => {
    switch (item.transactionType) {
      case 'AUDIT_BY_ADMIN':
        return (
          TRANSACTION_TYPE.find((x) => x.value === item.transactionType)?.label +
          ' - Lý do: ' +
          item?.description
        );
      case 'SCAN_QRCODE_STRONGBOW':
        if (item?.description?.includes('facebook')) {
          return 'Share facebook';
        }
        return 'Quét mã tích điểm';
      default:
        return TRANSACTION_TYPE.find((x) => x.value === item.transactionType)?.label;
    }
  };

  const statusType = (status) => {
    let name = '';

    switch (status) {
      case 0:
        name = 'Mới';
        break;
      case 1:
        name = 'Hoàn thành';
        break;
      case 2:
        name = 'Từ chối';
        break;
      case 3:
        name = 'Thành công';
        break;
      default:
        break;
    }
    return name;
  };
  const onChangeStatus = (id) => {
    setTabStatus(id);
  };
  return (
    <>
      <div
        className="has-text-centered pb-2"
        style={{
          marginTop: -15,
        }}
      >
        {userGiftStatusesData.map((item) => (
          <button
            key={item.key}
            onClick={() => onChangeStatus(item.key)}
            className={classNames('button is-size-7 is-size-6-tablet', {
              'mr-6 mr-6-mobile': !item.isLast,
              'button-user-gift-is-primary': item.key === tabStatus,
              'button-user-gift-is-second': item.key !== tabStatus,
            })}
          >
            {item.text}
          </button>
        ))}
      </div>
      {tabStatus == 1 ? (
        <>
          <div className="columns is-justify-content-center">
            <div
              className="column-is-custom list-bg-home"
              style={{
                marginLeft: -10,
                marginRight: -10,
                paddingBottom: 40,
              }}
            >
              <div className="custom-table">
                <table className="table is-fullwidth">
                  <tr>
                    <th className=" has-text-weight-bold">ID</th>
                    <th className=" has-text-weight-bold">Giao dịch </th>
                    <th className=" has-text-weight-bold">Thời gian</th>
                    <th className=" has-text-weight-bold">Điểm</th>
                  </tr>
                  {data?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{item?.transactionNo}</td>
                          <td>{item?.transactionType ? getTransactionDescription(item) : ''}</td>
                          <td>{moment(item?.activeDateTime).format('DD/MM/YYYY')}</td>
                          {item.directionType === checkNegativeNumber && item.value > 0 ? (
                            <td>
                              {'-'} {item.value}
                            </td>
                          ) : (
                            <td>{item.value}</td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
              <Pagination
                current={currentPage}
                showFirstLast
                total={totalPages}
                delta={1}
                showPrevNext={false}
                align={'center'}
                onChange={onChangePage}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="columns is-justify-content-center">
            <div
              className="column-is-custom list-bg-home"
              style={{
                marginLeft: -10,
                marginRight: -10,
                paddingBottom: 40,
              }}
            >
              <div className="custom-table">
                <table className="table is-fullwidth">
                  <tr>
                    <th>Ngày</th>
                    <th>Link hình hóa đơn </th>
                    <th>Trạng thái</th>
                  </tr>
                  {dataScan?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{moment(item?.uploadedTime).format('DD/MM/YYYY')}</td>
                          <td>
                            <a href={item.storageLink} target="_blank" rel="noopener noreferrer">
                              Xem hóa đơn
                            </a>
                          </td>
                          <td>{statusType(item?.status)}</td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
              <Pagination
                current={currentPageScan}
                showFirstLast
                total={totalPageScan}
                delta={1}
                showPrevNext={false}
                align={'center'}
                onChange={onChangePageScan}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HistoryReward;
