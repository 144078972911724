import {combineReducers} from 'redux';
import auth from '../slices/authSlice';
import app from '../slices/appSlice';
import storeCheckin from '../slices/storeCheckinSlice';

const rootReducer = combineReducers({
  auth,
  app,
  storeCheckin,
});
export default rootReducer;
