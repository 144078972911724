export const ROUTE_NAME = {
  HOME: '/',
  GIFT: '/gift',
  REFERRAL: '/referral',
  REFERRAL_PHONE_NUMBER: '/referral-phone-number',
  USER_PROFILE: '/user-profile',
  STORE: '/store',
  CHILL: '/chill',
  LIST_PRIZE: '/listprize',
  PROGRAM: '/program',
  EDIT_PROFILE: '/edit-profile',
  SEE_YOU_AGAIN: '/see-you-again',
  INFO: '/info',
  TERMS_AND_CONDITIONS: '/tnc',
  CHILL_MAP: '/meo-chill',
  CHILL_MAP_CHECK_IN: '/meo-chill/:storeId',
};
