import {createAsyncThunk} from '@reduxjs/toolkit';
import {getLPReferralInfoByPhoneNumberApi} from 'apis/endUser';
import {
  getDistrictCodeApi,
  getProvincesApi,
  getStoreDetailByMerchantApi,
  getWardApi,
} from 'apis/masterData';
import {
  getRewardInfoApi,
  searchUserGiftApi,
  getUserGiftStrongBowApi,
  getAllGiftByMerchantApi,
  getAllGiftByMerchantAnonymousApi,
  postAllGiftStrongBowSearchApi,
  postAllGiftStrongBowAnonymousSearchApi,
} from 'apis/thirdParty';
import {
  generateCouponCodeApi,
  getRewardPointApi,
  createRedeemGiftApi,
  postReferralInviteApi,
  getRedeemedGiftDetailApi,
  getInviteReferralByMerchantApi,
  getDeliveryTransactionDetailApi,
  billDetectionApi,
  getRewardHistoryScanBillApi,
  createDeliveryApi,
  getDetailDeliveryByIdApi,
} from 'apis/transaction';
import {getSelectOptions} from 'helpers';

import {
  getGiftAllByMerchantApi,
  getGiftAllUserByMerchantApi,
  getGiftDetailApi,
  getGiftViewDetailApi,
} from 'apis/gift';

import {
  getGameSettingDetailByMerchantApi,
  getTurnOfPlayGameSettingApi,
  getResultGameApi,
  getLuckDrawRewardDetailApi,
  getTurnOfPlayApi,
} from 'apis/game';
import {postHistoryScanBillApi, scanBillValidateLimitItemApi} from 'apis/document';

import {retryApi, RETRY_TYPES} from 'apis';
import {create, get} from 'lodash';

export const getProvincesAction = createAsyncThunk('app/getAllProvinces', async (callback) => {
  const res = await getProvincesApi();
  const data = getSelectOptions(res?.data || [], 'code', 'name');
  data.sort((a, b) => a.shortName.localeCompare(b.shortName));
  callback && callback(data);
  return data;
});

export const getDistrictByProvinceCodeAction = createAsyncThunk(
  'app/getDistrictByProvinceCode',
  async ({provinceCode = '', callback}) => {
    const res = await getDistrictCodeApi({provinceCode});
    const data = res?.data || [];
    callback && callback(data);
    return data;
  }
);

export const getWardByDistrictCodeAction = createAsyncThunk(
  'app/getWardByDistrictCode',
  async ({districtCode = '', callback}) => {
    const res = await getWardApi({id: districtCode});
    const data = res?.data || [];
    callback && callback(data);
    return data;
  }
);

export const getUserReferralInfoByPhoneNumber = createAsyncThunk(
  'app/getUserReferralInfo',
  async ({phoneNumber = '', callback}) => {
    const res = await getLPReferralInfoByPhoneNumberApi(phoneNumber);
    const data = res?.data || null;
    callback && callback(data);
    return data;
  }
);

export const getRewardInfoAction = createAsyncThunk(
  'app/getRewardInfo',
  async (payload = {callback: null}) => {
    const {callback} = payload;
    const res = (await getRewardInfoApi()) || {};
    const data = {...res, data: res?.data || []};
    callback && callback(data);
    return data;
  }
);

export const getRewardHistoryTransactionsAction = createAsyncThunk(
  'app/getRewardHistoryTransactions',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const res = await getRewardPointApi({payload: body});
    const {data, isSuccess} = res || {};
    if (isSuccess && data) {
      callback && callback(data);
      return data;
    }
  }
);

export const getUserGiftAction = createAsyncThunk(
  'app/getUserGift',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const res = await getUserGiftStrongBowApi(body);
      if (res) {
        callback && callback(null, res);
        return res;
      } else {
        callback && callback(new Error('Some thing went wrong!'), null);
        return null;
      }
    } catch (error) {
      callback && callback(error, null);
      return error;
    }
  }
);

export const getGiftAllByMerchant = createAsyncThunk(
  'app/getGiftAllByMerchant',
  async ({merchantId = '', callback}) => {
    const res = await getGiftAllByMerchantApi(merchantId);
    const data = res || [];
    callback && callback(data);
    return data;
  }
);

export const generateUserGiftCodeAction = createAsyncThunk(
  'app/generateUserGiftCode',
  async ({userGiftId, callback}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const receiptData = await generateCouponCodeApi({
        payload: {userGiftId},
      });
      if (receiptData?.isSuccess && receiptData?.data) {
        const data = await retryApi(receiptData, RETRY_TYPES.TRANSACTION);
        if (data?.data?.isSuccess) {
          const codeData = data?.data?.data;
          callback && callback(codeData);
          return fulfillWithValue(codeData);
        } else {
          throw new Error('Quá trình tạo mã không thành công!');
        }
      } else {
        throw new Error('Quá trình tạo mã không thành công!');
      }
    } catch (error) {
      return rejectWithValue(error?.message || 'Quá trình tạo mã không thành công!');
    }
  }
);
export const getGiftAllUserByMerchant = createAsyncThunk(
  'app/getGiftAllUserByMerchantApi',
  async ({merchantId = '', callback}) => {
    const res = await getGiftAllUserByMerchantApi(merchantId);
    const data = res || [];
    callback && callback(data);
    return data;
  }
);
export const getGiftDetailById = createAsyncThunk(
  'app/getGiftDetail',
  async ({id = '', callback}) => {
    const res = await getGiftDetailApi(id);

    const data = res || [];
    callback && callback(data);
    return data;
  }
);
export const getGiftViewDetailById = createAsyncThunk(
  'app/getGiftViewDetail',
  async ({id = '', callback}) => {
    const res = await getGiftViewDetailApi(id);
    const data = res || [];
    callback && callback(data);
    return data;
  }
);

export const getStoreDetailByMerchant = createAsyncThunk(
  'app/getStoreDetailByMerchant',
  async ({merchantId = '', callback}) => {
    const res = await getStoreDetailByMerchantApi(merchantId);
    if (res) {
      callback && callback(res);
      return res;
    }
  }
);

export const getProvinces = createAsyncThunk('app/getAllProvincesV1', async ({callback}) => {
  const res = await getProvincesApi();
  const data = res?.data || [];
  callback && callback(data);
  return data;
});

export const createRedeemGift = createAsyncThunk(
  'app/createRedeemGift',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const generalError = new Error('Đổi ưu đãi không thành công');
    try {
      const receiptData = await createRedeemGiftApi(body);
      if (receiptData?.isSuccess && receiptData?.data) {
        const userGiftId = receiptData.data.userGiftIds[0];
        if (userGiftId) {
          const userGiftResponse = await getRedeemedGiftDetailApi(userGiftId);

          const userGift = get(userGiftResponse, 'data');
          if (userGift) {
            callback && callback(null, userGift);
            return userGift;
          } else {
            callback(generalError, null);
          }
        } else {
          callback(generalError, null);
        }
      } else {
        callback(generalError, null);
      }
    } catch (error) {
      callback(error.message, null);
    }
  }
);

export const postReferralInvite = createAsyncThunk(
  'app/postReferralInvite',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const res = await postReferralInviteApi(body);
    if (res) {
      callback && callback(res);
      return res;
    }
  }
);

export const getInviteReferralByMerchant = createAsyncThunk(
  'app/getInviteReferralByMerchant',
  async ({merchantId = '', callback}) => {
    const res = await getInviteReferralByMerchantApi(merchantId);
    const data = res || [];
    callback && callback(data);
    return data;
  }
);
export const getDeliveryTransactionDetail = createAsyncThunk(
  'app/getDeliveryTransactionDetail',
  async ({callback}) => {
    try {
      const res = await getDeliveryTransactionDetailApi();
      const data = res.data || [];
      callback && callback(data);
      return data;
    } catch (error) {
      callback && callback(null);
    }
  }
);

export const getGameSettingDetailByMerchant = createAsyncThunk(
  'app/getGameSettingDetailByMerchant',
  async ({merchantId = '', callback}) => {
    const res = await getGameSettingDetailByMerchantApi({merchantId});
    const data = res || [];
    callback && callback(data);
    return data;
  }
);

export const getTurnOfPlayGameSettingAction = createAsyncThunk(
  'app/buyTurnOfPlayGameSettingAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const receiptData = await getTurnOfPlayGameSettingApi(body);
      if (receiptData?.isSuccess && receiptData?.data) {
        const data = await retryApi(receiptData, RETRY_TYPES.GAME);
        if (data?.data?.isSuccess) {
          const codeData = data?.data?.data;
          callback && callback(null, data?.data);
        } else {
          callback && callback(new Error('Fail!!!'), null);
        }
      } else {
        callback && callback(new Error('Fail!!!'), null);
      }
    } catch (error) {
      callback(error, null);
    }
  }
);
export const getResullGameAction = createAsyncThunk(
  'app/buyTurnOfPlayGameSettingAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const receiptData = await getTurnOfPlayGameSettingApi(body);
      if (receiptData?.isSuccess && receiptData?.data) {
        const data = await retryApi(receiptData, RETRY_TYPES.GAME);
        if (data?.data?.isSuccess) {
          const result = data?.data;
          callback && callback(null, result);
        } else {
          callback && callback(data.data.detail, null);
        }
      } else {
        callback && callback(null);
      }
    } catch (error) {
      callback && callback(error, null);
    }
    return;
  }
);

export const getAllGiftByMerchantAction = createAsyncThunk(
  'app/getAllGiftByMerchantAction',
  async ({merchantId = '', callback}) => {
    const res = await getAllGiftByMerchantApi(merchantId);
    const data = res?.data || [];
    callback && callback(data);
    return data;
  }
);

export const getAllGiftByMerchantAnonymousAction = createAsyncThunk(
  'app/getAllGiftByMerchantAction',
  async ({merchantId = '', callback}) => {
    const res = await getAllGiftByMerchantAnonymousApi(merchantId);
    const data = res?.data || [];
    callback && callback(data);
    return data;
  }
);

export const getLuckDrawRewardDetailAction = createAsyncThunk(
  'app/getLuckDrawRewardDetailAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const res = await getLuckDrawRewardDetailApi(body);
      if (res) {
        callback && callback(null, res);
        return res;
      } else {
        callback && callback(new Error('Some thing went wrong!'), null);
        return null;
      }
    } catch (error) {
      callback && callback(error, null);
      return error;
    }
  }
);
export const billDetectionAction = createAsyncThunk(
  'app/billDetectionAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const res = await billDetectionApi(body);
      if (res) {
        callback && callback(res, null);
        return res;
      } else {
        callback && callback(null, new Error('Thất bại'));
      }
    } catch (error) {
      callback && callback(null, error);
    }
  }
);

export const getRewardHistoryScanBillAction = createAsyncThunk(
  'app/getRewardHistoryScanBillAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const res = await getRewardHistoryScanBillApi(body);
      if (res) {
        callback && callback(null, res);
        return res;
      } else {
        callback && callback(new Error('Some thing went wrong!'), null);
        return null;
      }
    } catch (error) {
      callback && callback(error, null);
      return error;
    }
  }
);

export const createDeliveryAction = createAsyncThunk(
  'app/createDeliveryAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const res = await createDeliveryApi(body);
      if (res) {
        callback && callback(null, res);
        return res;
      } else {
        callback && callback(new Error('Some thing went wrong!'), null);
        return null;
      }
    } catch (error) {
      callback && callback(error, null);
      return error;
    }
  }
);
export const getUserGiftDetailByIdAction = createAsyncThunk(
  'app/getUserGiftDetailById',
  async ({id = '', callback}) => {
    const res = await getRedeemedGiftDetailApi(id);

    const data = res || [];
    callback && callback(data);
    return data;
  }
);

export const getDetailDeliveryByIdAction = createAsyncThunk(
  'app/getDetailDeliveryAction',
  async ({id = '', callback}) => {
    try {
      const res = await getDetailDeliveryByIdApi(id);
      const data = res || [];
      callback && callback(data);
      return data;
    } catch (error) {
      callback && callback(null);
    }
  }
);

export const getHistoryScanBillAction = createAsyncThunk(
  'app/getHistoryScanBillAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const res = await postHistoryScanBillApi(body);
      const data = res.data || [];
      if (data) {
        callback && callback(null, data);
        return data;
      } else {
        callback && callback(new Error('Some thing went wrong!'), null);
        return null;
      }
    } catch (error) {
      callback && callback(error, null);
      return error;
    }
  }
);

export const scanBillValidateLimitItemAction = createAsyncThunk(
  'app/limitItemValid',
  async (payload = {callback: null}, {fulfillWithValue}) => {
    const {callback} = payload;
    try {
      const res = await scanBillValidateLimitItemApi();
      if (res) {
        const data = res.data;
        callback && callback(null, data);
        return fulfillWithValue(data);
      } else {
        callback && callback(new Error('Some thing went wrong!'), null);
        return null;
      }
    } catch (error) {
      callback && callback(error, null);
      return error;
    }
  }
);

export const postAllGiftStrongBowSearchAction = createAsyncThunk(
  'app/postgetAllGiftStrongBowSearchAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const res = await postAllGiftStrongBowSearchApi(body);
      if (res) {
        callback && callback(null, res);
        return res;
      } else {
        callback && callback(new Error('Some thing went wrong!'), null);
        return null;
      }
    } catch (error) {
      callback && callback(error, null);
      return error;
    }
  }
);

export const postAllGiftStrongBowAnonymousSearchAction = createAsyncThunk(
  'app/postgetAllGiftStrongBowSearchAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const res = await postAllGiftStrongBowAnonymousSearchApi(body);
      if (res) {
        callback && callback(null, res);
        return res;
      } else {
        callback && callback(new Error('Some thing went wrong!'), null);
        return null;
      }
    } catch (error) {
      callback && callback(error, null);
      return error;
    }
  }
);
export const getTurnOfPlayAction = createAsyncThunk(
  'app/getGameSettingDetailByMerchant',
  async ({gameSettingId = '', callback}) => {
    const res = await getTurnOfPlayApi(gameSettingId);
    const data = res || [];
    callback && callback(data);
    return data;
  }
);
