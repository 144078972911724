// import {REFERRAL_VIA_TYPE} from 'constants/referral';
import utils from 'utils/apiUtils';
import {
  ACCOUNT,
  ACTION_RESULT,
  AUTH,
  CONTACT,
  FAVORITE_BRAND,
  FEEDBACK,
  FILTER_SETTING,
  MEMBERSHIP,
  NOTIFICATION,
  NOTIFICATION_SETTING,
  OTP,
  REFERRAL,
  SUGGESTION_BRAND,
  USER_MEMBERSHIP_ACTIVITY,
} from './api-definitions';

export const actionResultInquireApi = (payload) => {
  const dataPost = {
    receiptNo: payload?.receiptNo,
  };
  return utils.post(ACTION_RESULT.postInquire, dataPost);
};

export const quickLoginApi = (payload) => utils.post(AUTH.postQuickLogin, payload);
export const recaptchaApi = (payload) => utils.post(AUTH.recaptchaLogin, payload);

export const otpVerifyApi = (payload) => utils.post(OTP.postVerify, payload);
export const otpGeneratedApi = (payload) => utils.post(OTP.postGenerate, payload);
export const otpNutiGeneratedApi = (payload) => utils.post(OTP.postNutiGenerate, payload);
export const signUpStep2Api = (payload) => utils.post(OTP.postVerifyRegister, payload);

export const agreeTermsConditionsApi = (payload) => utils.post(ACCOUNT.postTermsAgree, payload);
export const changePasswordApi = (payload) => utils.post(ACCOUNT.postChangePassword, payload);
export const updateUserInfoApi = (payload) => utils.put(MEMBERSHIP.postUpdateProfile, payload);
export const updateUserAvatarApi = (payload) => utils.put(MEMBERSHIP.putUpdateAvatar, payload);
export const signUpStep1Api = (payload) => utils.post(ACCOUNT.postRegister, payload.payload);
export const setPasswordApi = (payload) => utils.post(ACCOUNT.postSetPassword, payload);
export const forgotPasswordStep3Api = (payload) => utils.post(ACCOUNT.postResetPassword, payload);
export const getUserInfoApi = () => utils.get(ACCOUNT.getProfile);
export const authFirstLoginApi = () => utils.put(ACCOUNT.putUpdateFirstLogin);
export const inactiveAccountApi = () => utils.post(ACCOUNT.inactiveAccount);

export const saveGiftFilterSettingApi = (payload) =>
  utils.post(FILTER_SETTING.postSaveGiftFilterSetting, payload);
export const getFilterUserSettingByCategoryApi = ({categoryId}) =>
  utils.get(`${FILTER_SETTING.getGiftFilterSetting}/${categoryId}`);

export const getNotificationsSettingApi = () =>
  utils.get(NOTIFICATION_SETTING.getNotificationSetting);
export const updateNotificationsSettingApi = (payload) =>
  utils.post(NOTIFICATION_SETTING.postUpdateNotificationSetting, payload);

export const postFeedbackApi = (payload) => utils.post(FEEDBACK.postSendFeedback, payload);

export const getFavouriteBrandApi = () => utils.get(FAVORITE_BRAND.getFavoriteBrand);
export const createFavouriteBrandApi = (payload) =>
  utils.post(FAVORITE_BRAND.postCreateFavoriteBrand, payload);
export const removeFavouriteBrandApi = (payload) =>
  utils.put(FAVORITE_BRAND.putRemoveFavoriteBrand, payload);

export const getSuggestionBrandApi = () => utils.get(SUGGESTION_BRAND.getAllSuggestionBrand);
export const createSuggestionBrandApi = (payload) =>
  utils.post(SUGGESTION_BRAND.postCreateSuggestionBrand, payload);
export const removeSuggestionBrandApi = (payload) =>
  utils.delete(SUGGESTION_BRAND.deleteRemoveSuggestionBrand, payload);
export const editSuggestionBrandApi = (payload) =>
  utils.post(SUGGESTION_BRAND.postUpdateSuggestionBrand, payload);

export const getMembershipDefaultApi = () => utils.get(MEMBERSHIP.getMembershipDefault);
export const postMembershipSearchApi = ({payload}) => {
  const dataPost = {
    pageIndex: 1,
    pageSize: 10,
    searchText: '',
    categoryIds: [],
    sortBy: [],
  };
  const data = Object.assign(dataPost, payload);
  return utils.post(MEMBERSHIP.postSearchMembership, data);
};
export const postMembershipSearchAllApi = ({payload}) => {
  const dataPost = {
    pageIndex: 1,
    pageSize: 10,
    searchText: '',
    sortBy: [],
  };
  const data = Object.assign(dataPost, payload);

  return utils.post(MEMBERSHIP.postSearchAllMembership, data);
};
export const postMembershipNotRegisterSearchApi = ({payload}) => {
  const dataPost = {
    searchText: '',
    categoryIds: [],
  };
  const data = Object.assign(dataPost, payload);

  return utils.post(MEMBERSHIP.postSearchUnRegisterdMembership, data);
};
// export const createMembershipApi = (payload) => {
//   const referralCode = payload?.referralCode;
//   const referralType = !referralCode ? '' : REFERRAL_VIA_TYPE.VIA_CODE;
//   return utils.post(MEMBERSHIP.postRegisterMembership, {referralType, ...payload});
// };

export const getMerchantProgramIdApi = ({merchantId}) =>
  utils.get(`${MEMBERSHIP.getMerchantProgramByMerchantId}/${merchantId}`);
export const membershipReferralValidateApi = (payload) =>
  utils.post(MEMBERSHIP.postValidateReferralMembership, payload);
export const getMembershipByIdApi = (id) => utils.get(MEMBERSHIP.getMembershipById(id));
export const membershipUnsubscribeApi = (payload) => utils.put(MEMBERSHIP.postUnsubscribe, payload);
export const getMembershipInactiveApi = ({programId}) => {
  return utils.get(MEMBERSHIP.getInactiveMembership(programId));
};
export const getMembershipUserInfoApi = (programId) =>
  utils.get(MEMBERSHIP.getMembershipProfile(programId));

export const putNotiReadUpdateApi = ({notificationId}) =>
  utils.put(`${NOTIFICATION.putUpdateNotificationReadStatus}/${notificationId}`);
export const putNotiReadAllUpdateApi = () =>
  utils.put(NOTIFICATION.putUpdateAllNotificationReadStatus);
export const putNotiEventTrackingApi = (payload) => utils.put(NOTIFICATION.eventTracking, payload);
export const getNotiDetailApi = ({notificationId}) =>
  utils.get(`${NOTIFICATION.getNotificationDetail}/${notificationId}`);
export const postNotiSaveTokenApi = (payload) =>
  utils.post(NOTIFICATION.postNotificationToken, payload);
export const postNotiSearchApi = ({payload = {}}) => {
  const dataPost = {
    pageIndex: 1,
    pageSize: 20,
    readStatuses: [],
    searchText: '',
    contentTypeCode: '',
    sortBy: [],
  };
  const data = {
    ...dataPost,
    ...payload,
  };

  return utils.post(NOTIFICATION.postNotificationSearch, data);
};
export const getNotiHasUnReadFlagApi = () => utils.get(NOTIFICATION.getNotificationVerifyUnread);

export const getLPReferralInfoApi = () => utils.get(REFERRAL.getReferralInfo);
export const createLPReferralCodeApi = (payload) =>
  utils.post(REFERRAL.postCreateReferral, payload);
export const getLPReferralDynamicLinkApi = (programId) =>
  utils.get(`${REFERRAL.getReferralDynamicLinkByProgramId}/${programId}`);
export const validateLPReferralCodeApi = (code) =>
  utils.get(`${REFERRAL.getValidateReferralCode}/${code}`);
export const getLPReferralInfoByPhoneNumberApi = (phoneNumber) =>
  utils.get(`${REFERRAL.getReferralInfoByPhoneNumber}/${phoneNumber}`);

export const postContactCreateApi = (payload) => utils.post(CONTACT.postCreateContact, payload);
export const postContactSearchApi = ({payload = {}}) => {
  const dataPost = {
    searchText: '',
  };
  const data = {
    ...dataPost,
    ...payload,
  };
  return utils.post(CONTACT.postSearchContact, data);
};

export const postCreateUserMembershipActivityApi = (payload) => {
  return utils.post(USER_MEMBERSHIP_ACTIVITY.createUserMembershipActivity, payload || {});
};

export const registerWithFullInfoApi = async (payload) =>
  utils.post(ACCOUNT.registerWithFullInfo, payload);

  export const loginStrogBowApi = (payload) => utils.post(OTP.loginStrogBow, payload);
  export const otpStrongBowGeneratedApi = (payload) => utils.post(OTP.otpStrongBowGenerated, payload);