import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import lucky_wheel_border from 'images/lucky_wheel_border.png';
import './styles.scss';
import icon_plus from 'images/strongbow/icon_plus.png';
import icon_minus from 'images/strongbow/icon_minus.png';
import planes_image from 'images/strongbow/planes.png';
import icon_win from 'images/icon_win.png';
import {set} from 'lodash';
import {Modal} from 'react-bulma-components';
import bottle from 'images/strongbow/bottle.png';
import bottle_18 from 'images/strongbow/element-18.png';
import selector from 'images/strongbow/selector.png';
import success from 'images/strongbow/success.png';
import game_default from 'images/strongbow/game_default.png';
import classNames from 'classnames';
import {
  getGameSettingDetailByMerchant,
  getTurnOfPlayGameSettingAction,
  getResullGameAction,
  getUserGiftAction,
  getLuckDrawRewardDetailAction,
  getRewardInfoAction,
  getTurnOfPlayAction,
} from 'appRedux/actions/app';
import {isLogInSelector, referralLinkSelector, userInfoSelector} from 'appRedux/selectors/auth';
import {rewardInfoSelector} from 'appRedux/selectors/app';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ROUTE_NAME} from 'constants/routes';
import POPUP from 'components/Popup';
import {isMobile} from 'react-device-detect';

import chill_18 from 'images/strongbow/pc/18.png';

const Lucky = ({rewardInfo = 0}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [showPopupChangeCoin, setShowPopupChangeCoin] = React.useState(false);
  const [showPopupWin, setShowPopupWin] = React.useState(false);
  const [showPopupLose, setShowPopupLose] = React.useState(false);
  const [showPopupChangeCoinSuccess, setShowPopupChangeCoinSuccess] = React.useState(false);
  const [showPopupChangeCoinFail, setShowPopupChangeCoinFail] = React.useState(false);

  const [numberSpins, setNumberSpins] = React.useState(0);
  const [moreSpins, setMoreSpins] = React.useState(1);
  const [totalCoin, setTotalCoin] = React.useState(10);

  const [spinning, setSpinning] = React.useState(false);
  const [listGame, setListGame] = React.useState([]);
  const [gameSceneUrl, setGameSceneUrl] = React.useState('');
  const [gameSettingId, setGameSettingId] = React.useState('');
  const [gameResult, setGameResult] = React.useState({});
  const [gameData, setGameData] = React.useState({});

  const isLogIn = useSelector(isLogInSelector);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLogIn) {
      navigate(ROUTE_NAME.HOME);
    }
  }, [isLogIn]);

  React.useEffect(() => {
    dispatch(
      getGameSettingDetailByMerchant({
        merchantId: process.env.REACT_APP_MERCHANT_ID,
        callback: (data) => {
          setGameSceneUrl(data.data[0].gameSceneUrl);
          setListGame(data.data[0].rewardSettings);
          setNumberSpins(data.data[0].userTurnOfPlay);
          setGameSettingId(data?.data[0]?.id);
          setGameData(data?.data[0]);
          setTotalCoin(data?.data[0].pointToRedeemCode || 0);
          console.log('VXMM', data?.data[0]);
        },
      })
    );
  }, []);

  React.useEffect(() => {
    if (gameData.id) {
      dispatch(
        getTurnOfPlayAction({
          gameSettingId: gameData.id,
          callback: (data) => {
            setNumberSpins(data.data.remainTurnOfPlay);
          },
        })
      );
    }
  }, [spinning]);
  React.useEffect(() => {
    renderWheel();
  }, [gameSceneUrl]);
  React.useEffect(() => {
    dispatch(getRewardInfoAction());
  }, [isLoading]);
  const renderSectorImg = () => {
    let canvas = document.getElementById('wheel');
    if (isMobile) {
      canvas.width = 280 * window.devicePixelRatio;
      canvas.height = 280 * window.devicePixelRatio;
      canvas.style.width = '280px';
      canvas.style.height = '280px';
    } else {
      canvas.width = 484 * window.devicePixelRatio;
      canvas.height = 484 * window.devicePixelRatio;
      canvas.style.width = '484px';
      canvas.style.height = '484px';
    }
    let ctx = canvas.getContext('2d');
    let img = new Image();
    img.src = gameSceneUrl;
    img.onload = function () {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Or at whatever offset you like
    };
    canvas.style.transform = 'rotate(204deg)';
    ctx.save();
  };
  const renderWheel = () => {
    renderSectorImg();
  };
  const getResult = (index) => {
    wheelEl.style.transition = 'none';
    wheelEl.style.transform = 'rotate(0deg)';
    setTimeout(() => {
      wheelEl.style.transition = 'all ease-out 7s';
      const rotMin = sliceSize * index + sliceSize;
      const rotMax = sliceSize * (index + 1) - sliceSize;
      const fullRots = Math.floor(Math.random() * 7) + 7; // minimum 5 rotations max 9
      const rot = fullRots * 360 + Math.floor(Math.random() * (rotMax - rotMin + 1) + rotMin);
      wheelEl.style.transform = `rotate(${rot + 204}deg)`;
      // wheelEl.style.transform = `rotate(${rot}deg)`;
    }, 0);
    setTimeout(() => {
      setSpinning(false);
    }, 9000);
  };
  const wheelEl = document.getElementById('wheel');
  const sliceSize = 360 / listGame.length;
  const Spin = () => {
    setSpinning(true);
    let startSequence = listGame.find((f) => f.rewardType == 3)?.sequence || 0;
    getResult(listGame.length - 1 - startSequence);
    if (numberSpins > 0) {
      dispatch(
        getResullGameAction({
          body: {
            gameSettingId: gameSettingId,
            action: 'finish',
          },
          callback: (error, data) => {
            setNumberSpins(numberSpins - 1);
            if (data.isSuccess) {
              let result = listGame.length - 1 - data.data.sequence;
              getResult(result);
              setGameResult(listGame.find((f) => f.id == data.data.rewardId));

              setTimeout(() => {
                if (data.data.rewardType == 3) {
                  setShowPopupLose(true);
                } else {
                  setShowPopupWin(true);
                }
              }, 8000);
            } else {
              setGameResult(listGame.find((f) => f.rewardType == 3));
              setTimeout(() => {
                setShowPopupLose(true);
              }, 8000);
            }
            if (error) {
              toast.error(error || 'Không thành công! Vui lòng thử lại sau', {
                autoClose: 2000,
                position: 'top-center',
              });
            }
          },
        })
      );
    } else {
      setTimeout(() => {
        setShowPopupLose(true);
      }, 8000);
    }
  };
  const changePlus = () => {
    if (rewardInfo < totalCoin + gameData.pointToRedeemCode) return;
    setMoreSpins(moreSpins + 1);
    setTotalCoin(totalCoin + gameData.pointToRedeemCode);
  };
  const changeMinus = () => {
    if (moreSpins == 0) return;
    setMoreSpins(moreSpins - 1);
    setTotalCoin(totalCoin - gameData.pointToRedeemCode);
  };
  const submitChange = () => {
    setMoreSpins(1);
    setShowPopupChangeCoin(!showPopupChangeCoin);
  };
  const handleCloseModal = () => {
    setShowPopupChangeCoin(!showPopupChangeCoin);
  };
  const SpinModal = () => {
    setShowPopupChangeCoinSuccess(false);
    setShowPopupLose(false);
    setShowPopupWin(false);
    Spin();
  };
  const handleCloseModalChangeCoinSuccess = () => {
    setShowPopupChangeCoinSuccess(!showPopupChangeCoinSuccess);
  };
  const handleCloseModalChangeCoinFail = () => {
    setShowPopupChangeCoinFail(!showPopupChangeCoinFail);
  };
  const exchangeReward = () => {
    setShowPopupChangeCoinFail(!showPopupChangeCoinFail);
    setShowPopupChangeCoin(!showPopupChangeCoin);
  };
  const handleCloseModalWin = () => {
    setShowPopupWin(!showPopupWin);
  };
  const handleCloseModalLose = () => {
    setShowPopupLose(!showPopupLose);
  };
  const handleChangeReward = () => {
    setIsLoading(true);
    dispatch(
      getTurnOfPlayGameSettingAction({
        body: {
          gameSettingId: gameSettingId,
          action: 'buy-turn-of-play',
          quantity: moreSpins,
        },
        callback: (error, res) => {
          setShowPopupChangeCoin(!showPopupChangeCoin);
          //setMoreSpins (1);
          setIsLoading(false);
          setTotalCoin(gameData.pointToRedeemCode);
          if (!error && !!res?.isSuccess) {
            setShowPopupChangeCoinSuccess(!showPopupChangeCoinSuccess);
            setNumberSpins(numberSpins + res?.data?.extraTurnOfPlay);
          } else {
            setShowPopupChangeCoinFail(!showPopupChangeCoinFail);
          }
        },
      })
    );
  };
  const buttonStyle = {
    top: 15,
  };
  return (
    <>
      {isMobile ? (
        <>
          <p
            style={{
              fontSize: 12,
            }}
            className=" has-text-centered"
          >
            Chiller có thể đổi một lượt quay may mắn khi tích được{' '}
            <b>{gameData.pointToRedeemCode} Chill Koin</b>. Thử vận may, rinh ngay quà xịn nhé!
          </p>
          <div className="columns">
            <div
              className="column"
              style={{
                position: 'relative',
              }}
            >
              <div
                className="card-content"
                style={{
                  padding: 0,
                }}
              >
                <div className="has-text-centered example_wheel">
                  {/* <div id="arrow"></div> */}
                  <canvas id="wheel" width="280" height="280" className="the_canvas"></canvas>
                  <div id="arrow-image">
                    <img
                      src={selector}
                      style={{
                        height: 50,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                }}
              >
                <img
                  src={bottle}
                  width={43}
                  style={{
                    transform: 'rotate(-10.61deg)',
                  }}
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                }}
              >
                <img src={bottle_18} width={70} />
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                }}
              >
                <img
                  src={bottle}
                  width={49}
                  style={{
                    transform: 'rotate(15deg)',
                  }}
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                }}
              >
                <img src={bottle_18} width={70} />
              </div>
            </div>
          </div>
          {!gameData.hasReward && (
            <div className="has-text-centered has-text-weight-bold pb-3">
              Rất tiếc, vòng xoay đã hết quà rồi
            </div>
          )}
          <div className="columns">
            <div className="column">
              <div
                className="has-text-centered has-text-weight-semibold"
                style={{
                  fontSize: 18,
                  fontWeight: 50,
                }}
              >
                Bạn có <span style={{fontWeight: 700, fontSize: 24}}>{numberSpins}</span> lượt quay
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="has-text-centered">
                <button
                  className="button is-second-custom"
                  style={{
                    color: '#83552D',
                    padding: '20px 20px',
                    fontSize: 16,
                    fontWeight: 700,
                  }}
                  onClick={submitChange}
                  disabled={
                    rewardInfo < gameData.pointToRedeemCode ||
                    !gameData.hasReward ||
                    gameData.availableStatus == 99
                  }
                >
                  ĐỔI LƯỢT QUAY
                </button>
              </div>
            </div>
          </div>
          <div className="columns mb-0">
            <div className="column">
              <div className="has-text-centered">
                <button
                  className="button is-primary-custom"
                  style={{
                    color: '#FFF',
                    padding: '20px 40px',
                    fontSize: 16,
                    fontWeight: 700,
                  }}
                  onClick={Spin}
                  disabled={
                    spinning ||
                    numberSpins < 1 ||
                    !gameData.hasReward ||
                    gameData.availableStatus == 99
                  }
                >
                  QUAY NGAY
                </button>
              </div>
            </div>
          </div>
          <div
            className="has-text-centered mt-0"
            style={{fontSize: 12, color: '#323130', fontWeight: 500}}
          >
            {gameData.pointToRedeemCode} Koin đổi được 1 lượt quay
          </div>
        </>
      ) : (
        <>
          <div className="columns">
            <div
              className="column"
              style={{
                position: 'relative',
              }}
            >
              <div className="has-text-centered pt-6">
                Chiller có thể đổi một lượt quay may mắn khi tích được{' '}
                <span className="has-text-weight-bold"> {gameData.pointToRedeemCode}</span>
              </div>
              <div className="has-text-centered pb-4">
                <span className="has-text-weight-bold">Chill Koin.</span> Thử vận may, rinh ngay quà
                xịn nhé!
              </div>
              <div
                className="card-content"
                style={{
                  padding: 0,
                }}
              >
                <div className="has-text-centered example_wheel">
                  <canvas id="wheel" width="484" height="484" className="the_canvas"></canvas>
                  <div
                    id="arrow-image"
                    style={{
                      transform: 'translateX(-265%)',
                    }}
                  >
                    <img
                      src={selector}
                      style={{
                        height: 73,
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: '45%',
                  }}
                >
                  <img
                    src={chill_18}
                    style={{
                      height: 240,
                    }}
                  />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: '10%',
                  }}
                >
                  <img
                    src={bottle}
                    width={170}
                    style={{
                      transform: 'matrix(0.99, 0.14, -0.14, 0.99, 0, 0)',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {!gameData.hasReward && (
            <div className="has-text-centered has-text-weight-bold pb-3">
              Rất tiếc, vòng xoay đã hết quà rồi
            </div>
          )}
          <div className="columns">
            <div className="column">
              <div
                className="has-text-centered has-text-weight-semibold"
                style={{
                  fontSize: 18,
                  fontWeight: 50,
                }}
              >
                Bạn có <span style={{fontWeight: 700, fontSize: 24}}>{numberSpins}</span> lượt quay
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column has-text-centered">
              <button
                className="button is-primary-custom mr-2"
                style={{
                  color: '#FFF',
                  padding: '20px 40px',
                  fontSize: 20,
                  fontWeight: 700,
                  width: 242,
                }}
                onClick={Spin}
                disabled={
                  spinning ||
                  numberSpins < 1 ||
                  !gameData.hasReward ||
                  gameData.availableStatus == 99
                }
              >
                QUAY NGAY
              </button>
              <button
                className="button is-second-custom ml-2"
                style={{
                  color: '#83552D',
                  padding: '20px 20px',
                  fontSize: 20,
                  fontWeight: 700,
                  width: 242,
                }}
                onClick={submitChange}
                disabled={
                  rewardInfo < gameData.pointToRedeemCode ||
                  !gameData.hasReward ||
                  gameData.availableStatus == 99
                }
              >
                ĐỔI LƯỢT QUAY
              </button>
            </div>
          </div>
          <div className="has-text-centered mt-0" style={{fontSize: 20, color: '#323130'}}>
            <span className="has-text-weight-bold">{gameData.pointToRedeemCode} Chillkoin </span>{' '}
            đổi được 1 lượt quay
          </div>
        </>
      )}
      {/* Popup thêm lượt quay */}
      <POPUP
        isOpen={showPopupChangeCoin}
        onChange={() => {
          setShowPopupChangeCoin(false);
          setMoreSpins(1);
          setTotalCoin(gameData.pointToRedeemCode);
        }}
        right={10}
      >
        <div className="userbg pt-6 pb-5 pl-6 pr-4">
          <div className="gift-chill-bg">
            <div className="columns">
              <div className="column has-text-centered has-title-koin">
                Đổi thêm lượt quay: <span style={{color: '#D6BA85'}}>{totalCoin} Chill Koin</span>
              </div>
            </div>
          </div>
          <div className="columns is-multiline is-flex-mobile">
            <div className="column is-4 is-12-desktop is-6-mobile">
              <div className="has-text-centered has-name-koin">Chọn số lượng</div>
            </div>
            <div className="column is-4 is-12-desktop is-6-mobile has-text-centered">
              <span className="icon-text">
                <span style={{cursor: 'pointer'}} onClick={changeMinus}>
                  <img src={icon_minus} style={{width: 32}} />
                </span>
                <span
                  style={{
                    paddingTop: 5,
                    paddingLeft: 15,
                    paddingRight: 15,
                    fontSize: 20,
                    fontWeight: 700,
                  }}
                >
                  {moreSpins}
                </span>
                <span style={{cursor: 'pointer'}} onClick={changePlus}>
                  <img src={icon_plus} style={{width: 32}} />
                </span>
              </span>
            </div>
          </div>
          <div className="columns ">
            <div className="column has-text-centered">
              <button
                className={classNames('button is-primary-custom buton-chill-lucky', {
                  'is-loading': isLoading,
                })}
                style={{
                  color: '#FFF',
                }}
                disabled={moreSpins < 1 || isLoading}
                onClick={handleChangeReward}
              >
                ĐỔI NGAY
              </button>
            </div>
          </div>
        </div>
      </POPUP>
      <POPUP
        isOpen={showPopupChangeCoinSuccess}
        onChange={setShowPopupChangeCoinSuccess}
        right={5}
        top={10}
      >
        <div className="hide-scroll popup-scanbill-bg pt-6">
          <div className="columns">
            <div className="column px-6">
              <img src={success} />
            </div>
          </div>
          <div className="columns">
            <div className="column has-text-centered has-text-weight-bold px-6 has-title-koin-success">
              Đổi lượt quay thành công
            </div>
          </div>
          <div className="has-name-koin-success">
            <div className="has-text-centered">
              Bạn có thêm{' '}
              <span style={{color: '#83552D', fontWeight: 700}}>{moreSpins} lượt quay </span>
            </div>
            <div className="has-text-centered">trò chơi vòng xoay may mắn</div>
          </div>
          <div className="columns">
            <div className="column has-text-centered">
              <button
                className="button is-primary-custom buton-chill-lucky"
                style={{
                  color: '#FFF',
                  width: 200,
                }}
                onClick={SpinModal}
                disabled={
                  spinning ||
                  numberSpins < 1 ||
                  gameData.availableStatus == 99 ||
                  !gameData.hasReward
                }
              >
                QUAY NGAY
              </button>
            </div>
          </div>
          <div className="columns">
            <div className="column has-text-centered" style={{marginBottom: 50}}>
              <button
                className="button is-second-custom buton-chill-lucky"
                style={{
                  color: '#83552D',
                  width: 200,
                }}
                onClick={handleCloseModalChangeCoinSuccess}
              >
                TRỞ VỀ
              </button>
            </div>
          </div>
        </div>
      </POPUP>

      <POPUP isOpen={showPopupChangeCoinFail} onChange={setShowPopupChangeCoinFail} right={2}>
        <div className="userbg">
          <div className="columns">
            <div
              className="column has-text-centered has-text-weight-bold text-lucky-fail px-6 pt-6"
              style={{
                fontSize: 40,
                color: '#83552D',
                fontFamily: 'Americana',
                fontWeight: 700,
                fontStyle: 'normal',
              }}
            >
              HẸN BẠN LẦN SAU
            </div>
          </div>
          <div className="columns">
            <div className="column has-text-centered has-text-weight-bold">
              Đổi lượt quay thất bại
            </div>
          </div>
          <div style={{color: '#323130', marginBottom: 40, fontSize: 14}}>
            <div className="has-text-centered px-6">
              Bạn có thể quay lại trò chơi vòng xoay may mắn lần sau
            </div>
          </div>
          <div className="columns">
            <div className="column has-text-centered">
              <button
                className="button is-primary-custom"
                style={{
                  color: '#FFF',
                  padding: '14px 50px',
                  fontSize: 16,
                  fontWeight: 700,
                  textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                  width: 200,
                }}
                onClick={exchangeReward}
              >
                ĐỔI LẠI
              </button>
            </div>
          </div>
          <div className="columns">
            <div className="column has-text-centered" style={{marginBottom: 50}}>
              <button
                className="button is-second-custom"
                style={{
                  color: '#83552D',
                  padding: '14px 50px',
                  fontSize: 16,
                  fontWeight: 700,
                  textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
                  width: 200,
                }}
                onClick={handleCloseModalChangeCoinFail}
              >
                TRỞ VỀ
              </button>
            </div>
          </div>
        </div>
      </POPUP>

      {/* Poup win  */}
      <POPUP isOpen={showPopupWin} onChange={setShowPopupWin} top={65} right={5}>
        <div
          style={{
            position: 'relative',
            zIndex: 1,
          }}
        >
          <div className="columns">
            <div className="column">
              <img
                src={success}
                style={{
                  width: 240,
                  margin: '0 auto',
                  display: 'block',
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="popup-scanbill-bg"
          style={{
            marginTop: -50,
          }}
        >
          <div
            style={{
              paddingTop: 50,
              paddingBottom: 10,
            }}
          >
            <div className="columns">
              <div className="column has-text-centered has-text-weight-bold has-title-koin-success">
                Bạn đã trúng
              </div>
            </div>
            {gameResult.rewardType == 1 ? (
              <>
                <div className="poup-game-win-content">
                  <div className="poup-game-win-bg-content">
                    <div className="columns">
                      <div className="column has-text-centered">
                        <div
                          style={{
                            paddingLeft: 10,
                            paddingRight: 15,
                            maxWidth: 220,
                            margin: 'auto',
                            maxHeight: 232,
                          }}
                        >
                          {gameResult?.giftDetail && gameResult?.giftDetail?.image ? (
                            <>
                              <img
                                src={gameResult?.giftDetail?.image}
                                style={{
                                  transform: 'rotate(357deg)',
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={game_default}
                                style={{
                                  transform: 'rotate(357deg)',
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div
                        className="column has-text-centered has-title-koin-success"
                        style={{
                          paddingTop: 0,
                        }}
                      >
                        {gameResult.name}
                      </div>
                    </div>
                  </div>
                </div>

                {gameResult?.giftId && (
                  <>
                    <div className="columns">
                      <div
                        className="column has-text-centered pt-6"
                        style={{
                          paddingBottom: 0,
                        }}
                      >
                        <a href={`/user-profile?tab=1`}>
                          <button
                            className="button is-primary-custom buton-chill-lucky"
                            style={{
                              color: '#FFF',
                              width: 200,
                            }}
                          >
                            XEM QUÀ
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column has-text-centered" style={{marginBottom: 30}}>
                        <button
                          className="button is-second-custom buton-chill-lucky"
                          style={{
                            color: '#83552D',
                            width: 200,
                          }}
                          onClick={SpinModal}
                          disabled={
                            spinning ||
                            numberSpins < 1 ||
                            gameData.availableStatus == 99 ||
                            !gameData.hasReward
                          }
                        >
                          QUAY TIẾP
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="columns">
                  <div className="column has-text-centered">
                    <div
                      style={{
                        fontSize: 28,
                        color: '#83552D',
                        fontWeight: 700,
                      }}
                    >
                      {gameResult.name}
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column has-text-centered">
                    <button
                      className="button is-primary-custom buton-chill-lucky"
                      style={{
                        color: '#FFF',
                        width: 200,
                      }}
                      onClick={SpinModal}
                      disabled={
                        spinning ||
                        numberSpins < 1 ||
                        gameData.availableStatus == 99 ||
                        !gameData.hasReward
                      }
                    >
                      QUAY TIẾP
                    </button>
                  </div>
                </div>
                <div className="columns">
                  <div className="column has-text-centered" style={{marginBottom: 30}}>
                    <button
                      className="button is-second-custom buton-chill-lucky"
                      style={{
                        color: '#83552D',
                        width: 200,
                      }}
                      onClick={handleCloseModalWin}
                    >
                      TRỞ VỀ
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </POPUP>

      <POPUP isOpen={showPopupLose} onChange={setShowPopupLose} right={2}>
        <div className="userbg pb-5">
          <div className="columns">
            <div
              className="column has-text-centered has-text-weight-bold text-lucky-fail px-6 pt-6"
              style={{
                fontSize: 40,
                color: '#83552D',
                fontFamily: 'Americana',
                fontWeight: 700,
                fontStyle: 'normal',
              }}
            >
              HẸN BẠN LẦN SAU
            </div>
          </div>
          <p
            className="has-text-centered pl-6 pr-4"
            style={{color: '#323130', marginBottom: 40, fontWeight: 500}}
          >
            {gameResult.message}
          </p>
          <div className="columns">
            <div className="column has-text-centered">
              <button
                className="button is-primary-custom buton-chill-lucky"
                style={{
                  color: '#FFF',
                  width: 200,
                }}
                onClick={SpinModal}
                disabled={
                  spinning ||
                  numberSpins < 1 ||
                  gameData.availableStatus == 99 ||
                  !gameData.hasReward
                }
              >
                QUAY TIẾP
              </button>
            </div>
          </div>
          <div className="columns">
            <div className="column has-text-centered">
              <button
                className="button is-second-custom buton-chill-lucky"
                style={{
                  color: '#83552D',
                  width: 200,
                }}
                onClick={handleCloseModalLose}
              >
                DỪNG CHƠI
              </button>
            </div>
          </div>
        </div>
      </POPUP>
    </>
  );
};
export default Lucky;
