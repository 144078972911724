import {FAQ_DATA} from 'constants/faq';
import React from 'react';
import Collapsible from 'react-collapsible';

const FAQS = () => {
  return (
    <>
      {FAQ_DATA.map((item, index) => {
        return (
          <Collapsible
            triggerClassName={'is-size-6 is-size-5-tablet pl-5'}
            triggerOpenedClassName={'is-size-6 is-size-5-tablet'}
            key={`item_${index}`}
            open={index === 0 ? true : false}
            trigger={item.title}
          >
            <div
              className=" is-size-7 is-size-6-tablet"
              // key={`desc_${i}`}
              style={{
                // fontSize: fontSize,
                color: '#323130',
              }}
            >
              {item.description}
            </div>
            {/* {item.description.map((desc, i) => (
              <div
                key={`desc_${i}`}
                style={{
                  fontSize: 12,
                  color: '#323130',
                }}
              >
                {desc}
              </div>
            ))} */}
          </Collapsible>
        );
      })}
    </>
  );
};
export default FAQS;
