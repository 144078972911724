import React, {useState} from 'react';
import logo from 'images/logo.png';
import hambergur from 'images/hambergur.png';

import './styles.scss';
import classNames from 'classnames';
import {ROUTE_NAME} from 'constants/routes';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {toggleLoginModal, toggleShowDrawer} from 'appRedux/slices/appSlice';
import {isLogInSelector, userInfoSelector} from 'appRedux/selectors/auth';

const AppHeader = ({index = 0}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const isLogIn = useSelector(isLogInSelector);

  const handleShowLoginModal = () => dispatch(toggleLoginModal(true));
  const menuItems = [
    {
      id: 0,
      text: 'Trang chủ',
      path: ROUTE_NAME.HOME,
      show: true,
    },
    {
      id: 9,
      text: 'Mẹo Chill',
      path: ROUTE_NAME.CHILL_MAP,
      show: true,
    },
    {id: 1, text: 'Thông tin Chương trình', path: ROUTE_NAME.PROGRAM, show: true},
    {id: 2, text: 'Đổi Chill Koin', path: ROUTE_NAME.CHILL, show: isLogIn},
    {id: 3, text: 'Danh sách trúng quà', path: ROUTE_NAME.LIST_PRIZE, show: true},
    {id: 4, text: 'Cửa hàng', path: ROUTE_NAME.STORE, show: true},
    {
      id: 5,
      text: 'Tài khoản',
      path: ROUTE_NAME.USER_PROFILE,
      isProfile: true,
      // icon: userInfo?.avatarThumbnail ? userInfo?.avatarThumbnail : 'fa-regular fa-circle-user',
      icon: 'fa-regular fa-circle-user',
      show: isLogIn,
    },
    {id: 6, text: 'Đăng nhập / đăng ký', path: '', show: !isLogIn},
  ];
  const [isMenuActive, setIsMenuActive] = useState(false);
  return (
    <div className=" is-relative">
      <nav
        className="navbar custom-navbar py-3 fixed-top pl-4"
        role="navigation"
        aria-label="main navigation"
      >
        <div
          className="container"
          style={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <div
            className="navbar-brand"
            style={{
              marginLeft: 0,
            }}
          >
            <div className="level-item">
              <Link to={ROUTE_NAME.HOME}>
                <img src={logo} width={87} alt="logo" />
              </Link>
            </div>
            {/* {isMenuActive ? (
              <a
                role="button"
                className="navbar-burger custom-navbar-burger"
                aria-label="menu"
                aria-expanded="true"
                data-target="navbarBasicExample"
                onClick={() => setIsMenuActive(!isMenuActive)}
              >
                <i className="fas fa-angle-right fa-2x"></i>
              </a>
            ) : (
              <a
                role="button"
                className="navbar-burger custom-navbar-burger"
                aria-label="menu"
                aria-expanded="true"
                data-target="navbarBasicExample"
                onClick={() => setIsMenuActive(!isMenuActive)}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            )} */}
            <a
              role="button"
              className="navbar-burger custom-navbar-burger"
              aria-label="menu"
              aria-expanded="true"
              data-target="navbarBasicExample"
              onClick={() => dispatch(toggleShowDrawer(true))}
            >
              <img src={hambergur} width={35}></img>
            </a>
          </div>

          <div
            id="navbarBasicExample"
            className={classNames('navbar-menu custom-navbar-menu', {'is-active': false})}
          >
            <div className="navbar-end">
              {menuItems
                .filter((item) => item?.show)
                .map((item) => {
                  if (item.path === ROUTE_NAME.USER_PROFILE) {
                    return (
                      <Link
                        to={item.path}
                        key={item?.id}
                        className={classNames('navbar-item custom-navbar-item', {
                          'is-active': item?.id === index,
                        })}
                      >
                        <div
                          className={classNames(
                            'has-text-weight-semibold is-size-6 has-text-black',
                            {
                              'has-text-warning': item?.id === index,
                            }
                          )}
                        >
                          <button
                            key={item.key}
                            className={classNames('button-custom px-2', {
                              'is-second-custom is-second-custom-color': true,
                            })}
                            style={{
                              textTransform: 'uppercase',
                            }}
                          >
                            {`Hello, ${'Chiller'}`}
                          </button>
                        </div>
                      </Link>
                    );
                  }
                  return item?.path ? (
                    <Link
                      to={item.path}
                      key={item?.id}
                      className={classNames('navbar-item custom-navbar-item', {
                        'is-active': item?.id === index,
                      })}
                    >
                      <div
                        className={classNames('has-text-weight-semibold is-size-6 has-text-black', {
                          'has-text-warning': item?.id === index,
                        })}
                      >
                        {item?.icon ? (
                          <div className="icon-text">
                            {/* {userInfo?.avatarThumbnail ? (
                          <img src={item.icon} alt="Placeholder image" className="avatar" />
                        ) : (
                          <span className="icon">
                            <i className={item.icon}></i>
                          </span>
                        )} */}
                            <span className="icon">
                              <i className={item.icon}></i>
                            </span>
                            <span>{item?.text}</span>
                          </div>
                        ) : (
                          <div>{item?.text}</div>
                        )}
                      </div>
                    </Link>
                  ) : (
                    <>
                      <div
                        to={item.path}
                        key={item?.id}
                        className={classNames('navbar-item custom-navbar-item', {
                          'is-active': item?.id === index,
                        })}
                      >
                        <button
                          key={item.key}
                          className={classNames('button-custom is-clickable px-2', {
                            'is-second-custom is-second-custom-color': true,
                          })}
                          onClick={() => {
                            handleShowLoginModal();
                            setIsMenuActive(!isMenuActive);
                          }}
                          style={{
                            textTransform: 'uppercase',
                            // width: 120,
                          }}
                        >
                          Đăng nhập
                        </button>
                      </div>
                      <div
                        to={item.path}
                        key={item?.id}
                        className={classNames('navbar-item custom-navbar-item', {
                          'is-active': item?.id === index,
                        })}
                      >
                        <button
                          key={item.key}
                          onClick={() => {
                            handleShowLoginModal();
                            setIsMenuActive(!isMenuActive);
                          }}
                          className={classNames('button-custom is-clickable px-2', {
                            'is-primary-custom': true,
                          })}
                          style={{
                            textTransform: 'uppercase',
                            // width: 120,
                          }}
                        >
                          Đăng ký
                        </button>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
  return (
    <div style={{position: 'relative'}}>
      <nav
        className="navbar custom-navbar py-3 fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div
          className=""
          style={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <div
            className="navbar-brand"
            style={{
              marginLeft: 0,
            }}
          >
            <div className="level-item">
              <Link to={ROUTE_NAME.HOME}>
                <img src={logo} width={87} alt="logo" />
              </Link>
            </div>
            <a
              role="button"
              className="navbar-burger custom-navbar-burger"
              aria-label="menu"
              aria-expanded="true"
              data-target="navbarBasicExample"
              onClick={() => dispatch(toggleShowDrawer(true))}
            >
              <img src={hambergur} width={35}></img>
            </a>
            {/* {isMenuActive ? (
              <a
                role="button"
                className="navbar-burger custom-navbar-burger"
                aria-label="menu"
                aria-expanded="true"
                data-target="navbarBasicExample"
                onClick={() => setIsMenuActive(!isMenuActive)}>
                <i className="fas fa-angle-right fa-2x"></i>
              </a>
            ) : (
              <a
                role="button"
                className="navbar-burger custom-navbar-burger"
                aria-label="menu"
                aria-expanded="true"
                data-target="navbarBasicExample"
                onClick={() => setIsMenuActive(!isMenuActive)}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            )} */}
          </div>

          <div
            id="navbarBasicExample"
            className={classNames('navbar-menu custom-navbar-menu', {
              'is-active': isMenuActive,
            })}
          >
            <div className="navbar-end">
              {menuItems
                .filter((item) => item?.show)
                .map((item) => {
                  return item?.path ? (
                    <Link
                      to={item.path}
                      key={item?.id}
                      className={classNames('navbar-item custom-navbar-item', {
                        'is-active': item?.id === index,
                      })}
                    >
                      <div
                        className={classNames('has-text-weight-semibold is-size-5', {
                          'has-text-warning': item?.id === index,
                        })}
                      >
                        {item?.icon ? (
                          <div className="icon-text">
                            {/* {userInfo?.avatarThumbnail ? (
                            <img src={item.icon} alt="Placeholder image" className="avatar" />
                          ) : (
                            <span className="icon">
                              <i className={item.icon}></i>
                            </span>
                          )} */}
                            <span className="icon">
                              <i className={item.icon}></i>
                            </span>
                            <span>{item?.text}</span>
                          </div>
                        ) : (
                          <div>{item?.text}</div>
                        )}
                      </div>
                    </Link>
                  ) : (
                    <div className="has-text-white auth-wrapper">
                      <a
                        className={classNames('navbar-item', {
                          'is-active': item?.id === index,
                        })}
                        onClick={() => {
                          handleShowLoginModal();
                          setIsMenuActive(!isMenuActive);
                        }}
                      >
                        <div className=" has-text-weight-semibold is-size-5 has-text-white">
                          Đăng nhập
                        </div>
                      </a>
                      {'|'}
                      <a
                        className={classNames('navbar-item', {
                          'is-active': item?.id === index,
                        })}
                        onClick={() => {
                          handleShowLoginModal();
                          setIsMenuActive(!isMenuActive);
                        }}
                      >
                        <div className="has-text-weight-semibold is-size-5 has-text-white">
                          Đăng ký
                        </div>
                      </a>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default AppHeader;
