export const TRANSACTION_TYPE = [
  {
    label: 'Quét mã thanh toán',
    value: 'RETAIL',
  },
  {
    label: 'Giới thiệu bạn bè',
    value: 'REFERRAL',
  },
  {
    label: 'Đánh giá sản phẩm',
    value: 'RATING',
  },
  {
    label: 'Đổi quà',
    value: 'REDEEM_GIFT',
  },
  {
    label: 'Trò chơi',
    value: 'GAME',
  },
  {
    label: 'Đăng kí thành viên',
    value: 'MEMBERSHIP_REGISTRATION',
  },
  {
    label: 'Đổi điểm',
    value: 'REWARD_EXCHANGE',
  },
  {
    label: 'Nhiệm vụ',
    value: 'MISSION',
  },
  {
    label: 'Mua dãy số',
    value: 'BUYTICKET',
  },
  {
    label: 'Mã tích điểm',
    value: 'PRODUCT_EXCHANGE_CODE',
  },
  {
    label: 'Check in',
    value: 'CHECKIN',
  },
  {
    label: 'Mời bạn đăng ký LP',
    value: 'REFERRAL_LOYALTY',
  },
  {
    label: 'Scan bill',
    value: 'SCAN_BILL_STRONGBOW',
  },
  {
    label: 'Mua lượt quay',
    value: 'BUY_TURN_OF_PLAY',
  },
  {
    label: 'Chỉnh sửa bởi Admin',
    value: 'AUDIT_BY_ADMIN',
  },
  {
    label: 'Check in',
    value: 'CHECK_IN_STRONGBOW',
  },
  {
    label: 'Share facebook',
    value: 'SHARE_FACEBOOK_STRONGBOW',
  },
  {
    label: 'Quét mã tích điểm',
    value: 'SCAN_QRCODE_STRONGBOW',
  },
];
