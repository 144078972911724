import React, {useState} from 'react';
import icon_delete from 'images/icon_delete_brown.png';
import {toast} from 'react-toastify';
import camare from 'images/strongbow/camera.png';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {isUploadingSelector} from 'appRedux/selectors/app';
const BillUpload = ({
  onSubmitFiles,
  selectedImage = null,
  handleChange = () => {},
  handleClick = (event) => {},
  onDeleteImage = () => {},
}) => {
  const [dragActive, setDragActive] = useState(false);
  //const [selectedImage, setSelectedImage] = useState(image);
  const isUploading = useSelector(isUploadingSelector);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    // if (e.dataTransfer.files) {
    //   for (let i = 0; i < e.dataTransfer.files.length; i++) {
    //     let reader = new FileReader();
    //     let file = e.dataTransfer.files[i];
    //     let type = file.type;
    //     if (['image/jpeg', 'image/png', 'image/gif'].includes(type)) {
    //       reader.onload = (e) => {
    //         let base64String = reader.result
    //           .replace('data:image/jpeg;base64,', '')
    //           .replace('data:image/png;base64,', '');
    //         setImgsSrc([{image: reader.result, url: base64String}]);
    //       };
    //       reader.readAsDataURL(file);
    //     } else {
    //       toast.error('Vui lòng chọn hình ảnh', {
    //         autoClose: 2000,
    //         position: 'top-center',
    //       });
    //     }
    //   }
    // }
  };

  return (
    <div
      className="columns"
      style={{
        paddingLeft: 68,
        paddingRight: 50,
      }}
    >
      <div className="column">
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <input
            type="file"
            id="input-file-upload"
            // multiple={true}
            accept="image/png, image/gif, image/jpeg"
            onChange={handleChange}
            onClick={handleClick}
            capture="camera"
          />
          <label id="label-file-upload" htmlFor="input-file-upload" className=" mt-4">
            {!!selectedImage ? (
              <>
                <div
                  key={'123'}
                  className="card-image"
                  style={{position: 'relative', height: '100%'}}
                >
                  <figure className="" style={{height: '100%'}}>
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      style={{height: '100%'}}
                      alt="receipt"
                    />
                  </figure>
                  <div
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      cursor: 'pointer',
                    }}
                    onClick={(e) => onDeleteImage(e)}
                  >
                    <img
                      src={icon_delete}
                      style={{
                        height: 24,
                      }}
                      alt="remove"
                    />
                  </div>
                </div>
              </>
            ) : (
              <div>
                <span className="icon-text">
                  <span className="icon">
                    <img
                      src={camare}
                      style={{
                        height: 16,
                        width: 16,
                      }}
                      alt="camera"
                    />
                  </span>
                  <span className="upload-button">Chụp hoá đơn</span>
                </span>
              </div>
            )}
          </label>
        </form>
        <div
          style={{
            marginTop: 15,
            textAlign: 'center',
          }}
        >
          <button
            className={classNames('button button-custom mr-3 is-fullwidth py-4', {
              'is-defult-custom': !selectedImage,
              'is-primary-custom': !!selectedImage,
              'is-loading': isUploading,
            })}
            disabled={isUploading || !selectedImage}
            onClick={() => onSubmitFiles(selectedImage)}
          >
            Gửi
          </button>
        </div>
      </div>
    </div>
  );
};
export default BillUpload;
