import React, {useEffect} from 'react';
import icon_clear from 'images/strongbow/icon_clear.png';
import list_win from 'images/strongbow/list_win.png';
import classNames from 'classnames';
import moment from 'moment';
import {Pagination} from 'react-bulma-components';
import {isMobile} from 'react-device-detect';
import bottle from 'images/strongbow/bottle.png';

import './styles.scss';

const HistoryPrizeList = ({
  currentPage = 1,
  totalPages = 1,
  onChangePage = () => {},
  data = [],
  dataBill = [],
  currentPageHistory = 1,
  totalPagesHistory = 1,
  onChangePageHistory = () => {},
  onChangesrSearch = () => {},
}) => {
  const [tabStatus, setTabStatus] = React.useState(1);

  const tabData = [
    {
      key: 1,
      text: 'vòng xoay may mắn',
    },
    {
      key: 2,
      text: 'Tích điểm đổi quà',
    },
  ];
  const onChangeStatus = (id) => {
    setTabStatus(id);
    setStrSearch('');
    onChangesrSearch('');
  };
  const [strSearch, setStrSearch] = React.useState('');
  const onChangeSearch = (event) => {
    setStrSearch(event.target.value);
  };
  const handleSearch = (tab) => {
    onChangesrSearch(strSearch);
  };
  return (
    <>
      {isMobile ? (
        <>
          <div className="columns">
            <div
              className="column"
              style={{
                paddingLeft: 40,
                paddingRight: 40,
              }}
            >
              <img src={list_win} />
            </div>
          </div>
          <div className="columns">
            <div className="column is-12 column-is-custom has-text-centered">
              {tabData.map((item) => (
                <>
                  <button
                    key={item.key}
                    onClick={() => onChangeStatus(item.key)}
                    className={classNames('button-custom ', {
                      'mr-1 mb-2': !item.isLast,
                      'is-primary-custom ': item.key === tabStatus,
                      'is-second-custom is-second-custom-color': item.key != tabStatus,
                    })}
                    style={{
                      textTransform: 'uppercase',
                      width: 170,
                    }}
                  >
                    {item.text}
                  </button>
                </>
              ))}
            </div>
          </div>
          <div className="columns clearfix">
            <div className="column column-is-custom">
              <div className="field is-horizontal ">
                <div className="field-body is-align-items-center">
                  <div className="field is-grouped">
                    <div className="control is-expanded has-icons-right has-icons-right-custom">
                      <input
                        className="input control input-is-custom "
                        type="text"
                        placeholder="Nhập số điện thoại"
                        onChange={onChangeSearch}
                        value={strSearch}
                      />
                      {!!strSearch?.length && (
                        <span
                          className="icon is-small is-right"
                          style={{
                            pointerEvents: 'all',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setStrSearch('');
                          }}
                        >
                          <img
                            src={icon_clear}
                            style={{
                              height: 18,
                            }}
                          />
                        </span>
                      )}
                    </div>
                    <button
                      className="button button-custom is-second-custom"
                      style={{
                        color: '#83552D',
                      }}
                      //disabled={!strSearch}
                      onClick={() => handleSearch(tabStatus)}
                    >
                      Tra cứu
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tabStatus == 1 ? (
            <>
              <div className="columns">
                <div
                  className="column-is-custom list-bg-home"
                  style={{
                    marginLeft: -10,
                    marginRight: -10,
                    paddingBottom: 40,
                  }}
                >
                  <div className="custom-table">
                    <table className="table is-fullwidth">
                      <tr>
                        <th>SĐT</th>
                        <th>Họ và tên</th>
                        <th>Ngày đổi</th>
                        <th>Tên quà</th>
                      </tr>
                      {data?.map((item, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{item?.userName}</td>
                              <td>{item?.userFullName}</td>
                              <td>{moment(item?.createdTime).format('DD/MM/YYYY')}</td>
                              <td>{item?.rewardSettingName}</td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                  <Pagination
                    current={currentPage}
                    showFirstLast
                    total={totalPages}
                    delta={1}
                    showPrevNext={false}
                    align={'center'}
                    onChange={onChangePage}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="columns">
                <div
                  className="column-is-custom list-bg-home"
                  style={{
                    marginLeft: -10,
                    marginRight: -10,
                    paddingBottom: 40,
                  }}
                >
                  <div className="custom-table">
                    <table className="table is-fullwidth">
                      <tr>
                        <th></th>
                        <th>SĐT</th>
                        <th>Họ và tên</th>
                        <th>Điểm khả dụng</th>
                      </tr>
                      {dataBill?.map((item, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td></td>
                              <td>{item?.phoneNumber}</td>
                              <td>{item?.fullName}</td>
                              <td>{item?.totalValue.toLocaleString()} KOIN</td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                  <Pagination
                    current={currentPageHistory}
                    showFirstLast
                    total={totalPagesHistory}
                    delta={1}
                    showPrevNext={false}
                    align={'center'}
                    onChange={onChangePageHistory}
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="columns">
            <div
              className="column column-is-custom is-5 has-text-centered"
              style={{
                position: 'relative',
              }}
            >
              <div>
                <img
                  src={list_win}
                  style={{
                    width: 420,
                  }}
                />
              </div>
              <div className="mt-6">
                {tabData.map((item) => (
                  <>
                    <div className="pb-4">
                      <button
                        key={item.key}
                        onClick={() => onChangeStatus(item.key)}
                        className={classNames('button-custom', {
                          'mr-1 mb-2': !item.isLast,
                          'is-primary-custom ': item.key === tabStatus,
                          'is-second-custom is-second-custom-color': item.key != tabStatus,
                        })}
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '20px !important',
                          width: 335,
                        }}
                      >
                        {item.text}
                      </button>
                    </div>
                  </>
                ))}
              </div>
              <div
                className="field has-text-centered"
                style={{
                  width: 335,
                  margin: 'auto',
                }}
              >
                <div className="field-body is-align-items-center ">
                  <div className="field is-grouped">
                    <div className="control is-expanded has-icons-right has-icons-right-custom">
                      <input
                        className="input control input-is-custom"
                        type="text"
                        placeholder="Nhập số điện thoại"
                        onChange={onChangeSearch}
                        value={strSearch}
                      />
                      {!!strSearch?.length && (
                        <span
                          className="icon is-small is-right"
                          onClick={() => {
                            setStrSearch('');
                          }}
                          style={{
                            pointerEvents: 'all',
                            cursor: 'pointer',
                            marginTop: 4,
                          }}
                        >
                          <img
                            src={icon_clear}
                            style={{
                              height: 18,
                            }}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="has-text-centered mt-4">
                <button
                  className="button button-custom is-second-custom"
                  style={{
                    color: '#83552D',
                    width: 160,
                  }}
                  onClick={() => handleSearch(tabStatus)}
                >
                  TRA CỨU
                </button>
              </div>
            </div>

            <div className="column is-7">
              {tabStatus == 1 ? (
                <>
                  <div
                    className="column-is-custom list-bg-home"
                    style={{
                      paddingBottom: 50,
                    }}
                  >
                    <div className="custom-table" style={{height: 791}}>
                      <table className="table is-fullwidth">
                        <tr className="ml-6">
                          <th>SĐT</th>
                          <th>Họ và tên</th>
                          <th>Ngày đổi</th>
                          <th>Tên quà</th>
                        </tr>
                        {data?.map((item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{item?.userName}</td>
                                {item?.userFullName.length > 20 ? (
                                  <td>
                                    {item.name.substring(0, 20)}
                                    {'...'}
                                  </td>
                                ) : (
                                  <td>{item?.userFullName}</td>
                                )}

                                <td>{moment(item?.createdTime).format('DD/MM/YYYY')}</td>
                                <td>{item?.rewardSettingName}</td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    </div>
                    <Pagination
                      current={currentPage}
                      showFirstLast
                      total={totalPages}
                      delta={1}
                      showPrevNext={false}
                      align={'center'}
                      onChange={onChangePage}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="column-is-custom list-bg-home"
                    style={{
                      paddingBottom: 50,
                    }}
                  >
                    <div className="custom-table">
                      <table className="table is-fullwidth">
                        <tr className="ml-6">
                          <th></th>
                          <th>SĐT</th>
                          <th>Họ và tên</th>
                          <th>Điểm khả dụng</th>
                        </tr>
                        {dataBill?.map((item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td></td>
                                <td>{item?.phoneNumber}</td>
                                <td style={{width: '35%'}}>{item?.fullName}</td>
                                <td>{item?.totalValue.toLocaleString()} KOIN</td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    </div>
                    <Pagination
                      current={currentPageHistory}
                      showFirstLast
                      total={totalPagesHistory}
                      delta={1}
                      showPrevNext={false}
                      align={'center'}
                      onChange={onChangePageHistory}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default HistoryPrizeList;
