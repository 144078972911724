import React, {useState} from 'react';
import logo from 'images/footer_banner.png';
import {Link} from 'react-router-dom';

import {ROUTE_NAME} from 'constants/routes';
import {CONTACT_INFO} from 'constants/app';
import classnames from 'classnames';
import icon_18 from 'images/icon_18.png';
import icon_car from 'images/icon_car.png';
import icon_mother from 'images/icon_mother.png';
const menuItems = [
  {
    id: 1,
    text: 'Điều khoản sử dụng',
    path: '/user',
    show: true,
  },
  {
    id: 2,
    text: 'Chính sách quyền riêng tư',
    path: '/user',
    show: true,
  },
  {
    id: 3,
    text: 'Câu hỏi thường gặp',
    path: '/user',
    show: true,
  },
  {
    id: 4,
    text: 'Liên hệ',
    path: '/user',
    show: true,
  },
  {
    id: 5,
    text: 'Social',
    path: '/user',
    show: true,
  },
];
const MeoChillFooter = () => {
  return (
    <>
      <div className="has-text-centered pt-3 pb-2" style={{background: '#323130'}}>
        <span class="icon-text">
          <span class="icon">
            <img src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Group%2011910%20(3).png" />
          </span>
          <span class="icon">
            <img src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Group%2011909.png" />
          </span>
          <span class="icon">
            <img src="https://storage.googleapis.com/strongbow_miniapp/MeoChill/Group%2011908.png" />
          </span>
          <span
            style={{
              fontSize: 10,
              fontWeight: 700,
              color: '#fff',
            }}
          >
            NGƯỜI DƯỚI 18 TUỔI KHÔNG ĐƯỢC UỐNG RƯỢU, BIA
          </span>
        </span>
      </div>
    </>
  );
};
export default MeoChillFooter;
