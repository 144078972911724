import React, {useEffect, useState} from 'react';
import Layout from 'components/layout';
import './styles.scss';
import {useDispatch, useSelector} from 'react-redux';
import {getUserProfileAction} from 'appRedux/actions/auth';
import {isLogInSelector, userInfoSelector} from 'appRedux/selectors/auth';
import {toggleEditModal, toggleQRCodeModal} from 'appRedux/slices/appSlice';
import {TAB_DATA} from './constanst';
import classNames from 'classnames';
import {
  getRewardHistoryTransactionsAction,
  getRewardInfoAction,
  getUserGiftAction,
  getDeliveryTransactionDetail,
  getHistoryScanBillAction,
} from 'appRedux/actions/app';
import {rewardInfoSelector} from 'appRedux/selectors/app';
import {signOutAction} from 'appRedux/actions/global';
import UserGifts from './components/userGift';
import HistoryReward from './components/historyReward';
import RewardOverview from './components/rewardOverview';
import UserInfo from './components/userInfo';
import {get} from 'lodash';
import {USER_GIFT_STATUSES} from 'constants/userGift';
import ContactButton from 'components/contactButton';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ROUTE_NAME} from 'constants/routes';
import BillStatus from './components/billStatus';
import {toggleIsLoadingOverlay} from 'appRedux/slices/appSlice';
import GiftList from './components/giftList';
import bottle from 'images/strongbow/bottle.png';
import leaf from 'images/strongbow/pc/leaf.png';
import balloon from 'images/strongbow/pc/balloon.png';

const userGiftParser = (item) => {
  const userGift = get(item, 'userGifts.0', {});
  return {...item, ...userGift};
};
const UserProfile = () => {
  const [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector(userInfoSelector);
  const isLogIn = useSelector(isLogInSelector);
  const rewardInfo = useSelector(rewardInfoSelector);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [historyPageIndex, setHistoryPageIndex] = useState(1);
  const [historyTotalPages, setHistoryTotalPages] = useState(0);
  const [historyTransactions, setHistoryTransactions] = useState([]);
  const [billStatus, setBillStatus] = useState([]);
  const [billStatusTotalPages, setBillStatusTotalPages] = useState(0);

  const [giftPageIndex, setGiftPageIndex] = useState(1);
  const [giftTotalPages, setGiftTotalPages] = useState(0);
  const [userGifts, setUserGifts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [userGiftStatus, setUserGiftStatus] = useState(USER_GIFT_STATUSES.ALL);
  const [scanPageIndex, setScanPageIndex] = useState(1);
  const [scanTotalPages, setScanTotalPages] = useState(0);
  const [scanBillHistory, setScanBillHistory] = useState([]);

  //Qua de chill
  const [chillPageIndex, setChillPageIndex] = useState(1);
  const [chillTotalPages, setChillTotalPages] = useState(0);
  const [dataChill, setDataChill] = useState([]);

  //Evoucher
  const [evoucherPageIndex, setEvoucherPageIndex] = useState(1);
  const [evoucherTotalPages, setEvoucherTotalPages] = useState(0);
  const [dataEvoucher, setDataEvoucher] = useState([]);

  const [storePageIndex, setStorePageIndex] = useState(1);
  const [storeTotalPages, setStoreTotalPages] = useState(0);
  const [dataStore, setDataStore] = useState([]);

  const handleShowEditPopup = () => {
    navigate(ROUTE_NAME.EDIT_PROFILE);
  };

  useEffect(() => {
    dispatch(getUserProfileAction());
    dispatch(getRewardInfoAction());
  }, []);

  useEffect(() => {
    setSelectedTabIndex(Number(searchParams.get('tab') || 0));
  }, [Number(searchParams.get('tab') || 0)]);

  useEffect(() => {
    if (!isLogIn) {
      navigate(ROUTE_NAME.HOME);
    }
  }, [isLogIn]);

  const handleSignOut = () => {
    dispatch(signOutAction());
    //navigate(ROUTE_NAME.HOME);
    window.location.href = './';
  };
  useEffect(() => {
    dispatch(
      getUserGiftAction({
        body: {
          pageIndex: chillPageIndex,
          pageSize: 6,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          status: userGiftStatus,
          giftClassify: 'CHILL_GIFT',
          sortBy: [
            {
              fieldName: 'CreatedTime',
              ascending: false,
            },
          ],
          userId: userInfo?.userId,
        },
        callback: (error, res) => {
          const {data = [], numOfPages = 0} = res;
          setChillTotalPages(data.numOfPages);
          setDataChill(data?.data?.map(userGiftParser) || []);
        },
      })
    );
  }, [chillPageIndex, userGiftStatus]);

  useEffect(() => {
    dispatch(
      getUserGiftAction({
        body: {
          pageIndex: evoucherPageIndex,
          pageSize: 4,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          status: userGiftStatus,
          giftClassify: 'E_VOUCHER',
          sortBy: [
            {
              fieldName: 'CreatedTime',
              ascending: false,
            },
          ],
          userId: userInfo?.userId,
        },
        callback: (error, res) => {
          const {data = [], numOfPages = 0} = res;
          setEvoucherTotalPages(data.numOfPages);
          setDataEvoucher(data?.data?.map(userGiftParser) || []);
        },
      })
    );
  }, [evoucherPageIndex, userGiftStatus]);

  useEffect(() => {
    dispatch(
      getUserGiftAction({
        body: {
          pageIndex: storePageIndex,
          pageSize: 4,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          status: userGiftStatus,
          giftClassify: 'STORE_GIFT',
          sortBy: [
            {
              fieldName: 'CreatedTime',
              ascending: false,
            },
          ],
          userId: userInfo?.userId,
        },
        callback: (error, res) => {
          const {data = [], numOfPages = 0} = res;
          setStoreTotalPages(data.numOfPages);
          setDataStore(data?.data?.map(userGiftParser) || []);
        },
      })
    );
  }, [storePageIndex, userGiftStatus]);
  // useEffect(() => {
  //   setIsLoading(true);
  //   dispatch(
  //     getUserGiftAction({
  //       body: {
  //         pageIndex: giftPageIndex,
  //         pageSize: 10,
  //         merchantId: process.env.REACT_APP_MERCHANT_ID,
  //         status: userGiftStatus,
  //         sortBy: [
  //           {
  //             fieldName: 'CreatedTime',
  //             ascending: false,
  //           },
  //         ],
  //         userId: userInfo?.userId,
  //       },
  //       callback: (error, res) => {
  //         setIsLoading(false);
  //         const {data = [], numOfPages = 0} = res;
  //         setGiftTotalPages(data.numOfPages);
  //         setUserGifts(data?.data?.map(userGiftParser) || []);
  //       },
  //     })
  //   );
  // }, [giftPageIndex, userGiftStatus]);

  useEffect(() => {
    dispatch(
      getRewardHistoryTransactionsAction({
        body: {
          pageIndex: historyPageIndex,
          pageSize: 10,
          programIds: [process.env.REACT_APP_PROGRAM_ID],
          sortBy: [
            {
              fieldName: 'CreatedTime',
              ascending: false,
            },
          ],
        },
        callback: (res) => {
          const {data = [], numOfPages = 0} = res;
          setHistoryTotalPages(numOfPages);
          setHistoryTransactions(data);
        },
      })
    );
  }, [historyPageIndex]);
  useEffect(() => {
    dispatch(toggleIsLoadingOverlay(true));
    dispatch(
      getDeliveryTransactionDetail({
        callback: (data) => {
          dispatch(toggleIsLoadingOverlay(false));
          if (data) {
            setBillStatus(data);
            setBillStatusTotalPages(Math.ceil(data.length / 10));
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getHistoryScanBillAction({
        body: {
          pageIndex: scanPageIndex,
          pageSize: 10,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          userId: userInfo?.userId,
        },
        callback: (error, res) => {
          const {data = [], numOfPages = 0} = res;
          setScanTotalPages(numOfPages);
          setScanBillHistory(data);
        },
      })
    );
  }, [scanPageIndex]);
  useEffect(() => {
    setGiftPageIndex(1);
  }, [userGiftStatus]);

  const renderTabContent = () => {
    switch (selectedTabIndex) {
      case 0:
        return <RewardOverview rewardInfo={rewardInfo} />;
      case 1:
        return (
          // <UserGifts
          //   currentPage={giftPageIndex}
          //   totalPages={giftTotalPages}
          //   onChangePage={setGiftPageIndex}
          //   data={userGifts}
          //   userGiftStatus={userGiftStatus}
          //   onChangeStatus={setUserGiftStatus}
          //   onClickUserGift={(userGift) => {
          //     navigate(`/user-gift/${userGift?.giftId}`, {state: {userGift}});
          //     return;
          //   }}
          //   isLoading={isLoading}
          // />
          <GiftList
            userGiftStatus={userGiftStatus}
            onChangeStatus={setUserGiftStatus}
            chillPageIndex={chillPageIndex}
            chillTotalPages={chillTotalPages}
            onChangeChillPage={setChillPageIndex}
            dataChill={dataChill}
            dataEvoucher={dataEvoucher}
            evoucherTotalPages={evoucherTotalPages}
            evoucherPageIndex={evoucherPageIndex}
            onChangeEvoucherPage={setEvoucherPageIndex}
            dataStore={dataStore}
            storePageIndex={storePageIndex}
            storeTotalPages={storeTotalPages}
            onChangeStorePage={setStorePageIndex}
            onClickUserGift={(userGift) => {
              navigate(`/user-gift/${userGift?.giftId}`, {state: {userGift}});
              return;
            }}
          />
        );
      case 2:
        return (
          <HistoryReward
            currentPage={historyPageIndex}
            totalPages={historyTotalPages}
            data={historyTransactions}
            onChangePage={setHistoryPageIndex}
            dataScan={scanBillHistory}
            currentPageScan={scanPageIndex}
            totalPageScan={scanTotalPages}
            onChangePageScan={setScanPageIndex}
          />
        );
      case 3:
        return <BillStatus data={billStatus} />;
      default:
        return null;
    }
  };

  return (
    <Layout
      headerProps={{
        index: 4,
      }}
    >
      <div
        className="container-chill mt-0 is-relative absolute-image-wrapper"
        style={{paddingTop: 40}}
      >
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '60%',
            // right: ,
            left: '5%',
          }}
        >
          <img
            src={bottle}
            width={60}
            style={{
              transform: 'matrix(0.97, -0.23, 0.23, 0.97, 0, 0)',
            }}
          />
        </div>

        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '50%',
            right: 0,
            marginRight: -40,
          }}
        >
          <img src={leaf} width={200} alt="rightLeaf" style={{transform: 'rotate(-118deg)'}} />
        </div>
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '10%',
            left: 0,
            marginLeft: -80,
          }}
        >
          <img src={leaf} width={200} style={{}} alt="leftLeaf" />
        </div>
        <div
          className="absolute-image"
          style={{
            position: 'absolute',
            top: '15%',
            right: '5%',
            // marginLeft: -80,
          }}
        >
          <img src={balloon} width={100} style={{}} alt="balloon" />
        </div>
        <div className="container px-4 user-profile">
          <div className="columns content-wrapper">
            <div className="column is-4-tablet is-4-desktop is-3-fullhd">
              <UserInfo
                userInfo={userInfo}
                onSignOutClick={handleSignOut}
                onEditClick={handleShowEditPopup}
              />
              <div className="tab-container has-text-centered">
                {TAB_DATA.map((item) => (
                  <>
                    <button
                      key={item.key}
                      onClick={() => setSelectedTabIndex(item?.index)}
                      className={classNames('button-custom is-clickable', {
                        'mr-1 mb-2': !item.isLast,
                        'is-primary-custom ': item.index === selectedTabIndex,
                        'is-second-custom is-second-custom-color': item.index != selectedTabIndex,
                      })}
                      style={{
                        textTransform: 'uppercase',
                        // width: 177,
                        // width: '100%',
                      }}
                    >
                      {item.text}
                    </button>
                  </>
                ))}
                <div
                  className="has-text-weight-semibold is-size-5 has-text-black is-underlined my-4 is-hidden-mobile is-clickable"
                  onClick={handleSignOut}
                >
                  <span>Đăng xuất</span>
                </div>
              </div>
            </div>
            <div className=" column">{renderTabContent()}</div>
          </div>
          <ContactButton />
        </div>
      </div>
      <div className="pic-wave-bg"></div>
    </Layout>
  );
};

export default UserProfile;
