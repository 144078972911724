export const FAQ_DATA = [
  {
    title: `Chương trình khuyến mãi "Săn điểm chill, phiêu quà xịn” diễn ra khi nào?`,
    description: (
      <>
        Chào Bạn, chương trình "Săn điểm chill, phiêu quà xịn” được diễn ra từ ngày 20/10/2022 -
        28/02/2023 hoặc cho đến khi hết quà. Mọi thông tin chi tiết và thể lệ chương trình bạn có
        thể truy cập trang web{' '}
        <a href="https://strongbowchillkoin.com" target="_blank" rel="noreferrer">
          Strongbowchillkoin.com
        </a>
      </>
    ),
  },
  {
    title: 'Chương trình được áp dụng tại những địa điểm nào?',
    description: (
      <>
        Chương trình đang được diễn ra tại cửa hàng 7-Eleven, GS25, Circle K
        <br />
        <strong>Các cửa hàng bán lẻ ở khu vực:</strong> Hồ Chí Minh và Hà Nội
      </>
    ),
  },
  {
    title: 'Cách tham dự chương trình như thế nào?',
    description: (
      <>
        Bạn có thể tham gia chương trình bằng cách thực hiện các bước sau:
        <br />
        <strong>Bước 1:</strong> Mua Strongbow ở các cửa hàng tiện lợi (GS25, Circle K, 7 Eleven)
        <br />
        <strong>Bước 2:</strong> Scan mã QR trên bao bì Strongbow hoặc các ấn phẩm tại địa điểm bán
        hàng sau đó chụp hóa đơn để tích điểm.
        <br />
        <strong>Bước 3:</strong> Đổi điểm nhận quà cực phiêu hoặc tham gia vòng xoay may mắn để có
        cơ hội nhận nhiều quà hấp dẫn khác.
      </>
    ),
  },
  {
    title: 'Tìm mã QR ở đâu? ',
    description:
      'Để tham gia chương trình, bạn có thể tìm mã QR tại ấn phẩm tại các cửa hàng tiện lợi hoặc trên bao bì của sản phẩm Strongbow',
  },
  {
    title: 'Điểm được tích lũy thế nào?',
    description: (
      <>
        Trong thời gian khuyến mại, khách hàng mua sản phẩm khuyến mại trên địa bàn khuyến mãi sẽ
        nhận được điểm thưởng tương ứng.
        <br />
        Khách hàng tích lũy điểm thưởng để đổi quà tặng hoặc các lượt quay vòng xoay may mắn để có
        cơ hội nhận được các giải thưởng của chương trình.
        <br />
        &emsp;&emsp;- Cơ cấu tính điểm thưởng như sau:
        <br />
        <div className=" table-container">
          <table
            className="table is-bordered is-fullwidth"
            style={{backgroundColor: 'transparent'}}
          >
            <tr>
              <th>Số lượng sản phẩm mua</th>
              <th>Số điểm thưởng/ 1 sản phẩm</th>
              <th>Ví dụ</th>
            </tr>
            <tr>
              <td>Mua từ 1 đến 5 chai/lon Strongbow các loại</td>
              <td className=" has-text-weight-bold">10 điểm/ 1 chai hoặc lon</td>
              <td>1 lon = 10 điểm</td>
            </tr>
            <tr>
              <td>Mua từ 6 đến 11 chai/lon Strongbow các loại</td>
              <td className=" has-text-weight-bold">11 điểm/ 1 chai hoặc lon</td>
              <td>1 lốc = 6 lon = 66 điểm</td>
            </tr>
            <tr>
              <td>Mua từ 12 đến 23 chai/lon Strongbow các loại</td>
              <td className=" has-text-weight-bold">12 điểm/ 1 chai hoặc lon</td>
              <td>2 lốc = 12 lon = 144 điểm</td>
            </tr>
            <tr>
              <td>Mua từ 24 chai/lon Strongbow các loại trở lên</td>
              <td className=" has-text-weight-bold">15 điểm/ 1 chai hoặc lon</td>
              <td>1 thùng = 24 lon = 360 điểm</td>
            </tr>
          </table>
        </div>
      </>
    ),
  },
  {
    title: 'Quà tặng của chương trình là gì? ',
    description: (
      <>
        Tùy theo hình thức bạn chọn tham dự là tích lũy hay may mắn, sẽ có các phần quà như sau
        <br />
        &emsp;&emsp;<strong>a. Chương trình tích điểm đổi quà:</strong> Mỗi sản phẩm khi mua, bạn sẽ
        tích điểm chillkoin vào ví điểm của mình. Chi tiết cách thức đổi điểm và quà tặng như sau
        <br />
        <div className=" table-container">
          <table
            className="table is-bordered is-fullwidth"
            style={{backgroundColor: 'transparent'}}
          >
            <tr>
              <th>Cơ cấu Giải thưởng</th>
              <th>Nội dung giải thưởng</th>
              <th>Trị giá giải thưởng (VNĐ)</th>
              <th>Số lượng giải thưởng</th>
              <th>Thành tiền (VNĐ)</th>
              <th>Số điểm đổi thưởng tương ứng</th>
            </tr>
            <tr>
              <td rowSpan={17} style={{verticalAlign: 'middle'}}>
                Giải tích lũy
              </td>
              <td>Dây đeo thẻ Strongbow</td>
              <td>50,000</td>
              <td>400</td>
              <td>20,000,000</td>
              <td>150</td>
            </tr>
            <tr>
              <td>Mũ Strongbow</td>
              <td>110,000</td>
              <td>500</td>
              <td>55,000,000</td>
              <td>300</td>
            </tr>
            <tr>
              <td>Túi chéo Strongbow</td>
              <td>150,000</td>
              <td>400</td>
              <td>60,000,000</td>
              <td>350</td>
            </tr>
            <tr>
              <td>Máy chiếu phim Strongbow</td>
              <td>5,500,000</td>
              <td>3</td>
              <td>16,500,000</td>
              <td>18,000</td>
            </tr>
            <tr>
              <td>Túi tote Strongbow</td>
              <td>150,000</td>
              <td>150</td>
              <td>22,500,000</td>
              <td>350</td>
            </tr>
            <tr>
              <td>Loa Marshall</td>
              <td>7,990,000</td>
              <td>3</td>
              <td>23,970,000</td>
              <td>15,000</td>
            </tr>
            <tr>
              <td>Iphone 14 Pro</td>
              <td>30,990,000</td>
              <td>1</td>
              <td>30,990,000</td>
              <td>40,000</td>
            </tr>
            <tr>
              <td>MacBook Pro M2 2022 13 inch</td>
              <td>35,990,000</td>
              <td>2</td>
              <td>71,980,000</td>
              <td>40,500</td>
            </tr>
            <tr>
              <td>Apple Watch Ultra GPS</td>
              <td>23,990,000</td>
              <td>2</td>
              <td>47,980,000</td>
              <td>30,000</td>
            </tr>
            <tr>
              <td>Airpod Pro 2022</td>
              <td>6,990,000</td>
              <td>5</td>
              <td>34,950,000</td>
              <td>10,000</td>
            </tr>
            <tr>
              <td>Vé xem phim CGV</td>
              <td>90,000</td>
              <td>200</td>
              <td>18,000,000</td>
              <td>60</td>
            </tr>
            <tr>
              <td>Voucher GOT IT 10k</td>
              <td>10,000</td>
              <td>300</td>
              <td>3,000,000</td>
              <td>40</td>
            </tr>
            <tr>
              <td>Voucher GOT IT 25k</td>
              <td>25,000</td>
              <td>200</td>
              <td>5,000,000</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Voucher GOT IT 50k</td>
              <td>50,000</td>
              <td>200</td>
              <td>10,000,000</td>
              <td>200</td>
            </tr>
            <tr>
              <td>Voucher TIKI 10k</td>
              <td>10,000</td>
              <td>300</td>
              <td>3,000,000</td>
              <td>40</td>
            </tr>
            <tr>
              <td>Voucher TIKI 25k</td>
              <td>25,000</td>
              <td>200</td>
              <td>5,000,000</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Voucher TIKI 50k</td>
              <td>50,000</td>
              <td>200</td>
              <td>10,000,000</td>
              <td>200</td>
            </tr>
          </table>
        </div>
        &emsp;&emsp;<strong>b. Chương trình vòng quay may mắn: </strong>
        <br />
        Cứ mỗi 50 điểm Chillkoin, bạn có thể đổi một lượt quay may mắn. Chi tiết cách thức đổi điểm
        và quà tặng như sau
        <br />
        <div className=" table-container">
          <table
            className="table is-bordered is-fullwidth"
            style={{backgroundColor: 'transparent'}}
          >
            <tr>
              <th>Cơ cấu Giải thưởng</th>
              <th>Nội dung giải thưởng</th>
              <th>Trị giá giải thưởng (VNĐ)</th>
              <th>Số lượng giải thưởng</th>
              <th>Thành tiền (VNĐ)</th>
            </tr>
            <tr>
              <td colSpan={5} className=" has-text-weight-bold">
                Giải may mắn
              </td>
            </tr>
            <tr>
              <td>May mắn 1</td>
              <td>Iphone 14 Pro 128 G</td>
              <td>30,990,000</td>
              <td>1</td>
              <td>30,990,000</td>
            </tr>
            <tr>
              <td>May mắn 2</td>
              <td>Airpod Pro 2022</td>
              <td>6,990,000</td>
              <td>2</td>
              <td>13,980,000</td>
            </tr>
            <tr>
              <td>May mắn 3</td>
              <td>Máy chiếu phim Beecube X Strongbow</td>
              <td>5,500,000</td>
              <td>2</td>
              <td>11,000,000</td>
            </tr>
            <tr>
              <td>May mắn 4</td>
              <td>Túi tote Strongbow</td>
              <td>150,000</td>
              <td>50</td>
              <td>7,500,000</td>
            </tr>
            <tr>
              <td>May mắn 5</td>
              <td>Mũ Strongbow</td>
              <td>110,000</td>
              <td>500</td>
              <td>55,000,000</td>
            </tr>
            <tr>
              <td>May mắn 6</td>
              <td>Túi chéo Strongbow</td>
              <td>150,000</td>
              <td>100</td>
              <td>15,000,000</td>
            </tr>
            <tr>
              <td>May mắn 7</td>
              <td>Vé xem phim CGV</td>
              <td>90,000</td>
              <td>110</td>
              <td>9,900,000</td>
            </tr>
          </table>
        </div>
      </>
    ),
  },
  {
    title: 'Nếu trúng thưởng, tôi có thể nhận giải thế nào?',
    description: (
      <>
        <ul class="ml-4" style={{listStyle: 'disc'}}>
          <li>
            Đối với phần quà Dây đeo thẻ, Mũ Strongbow, Túi chéo Strongbow, Máy chiếu phim
            Strongbow, Túi tote Strongbow, Loa Marshall:
          </li>
        </ul>
        Trong vòng 05 ngày sau khi nhận được thông báo trúng thưởng từ chương trình, người trúng
        thưởng truy cập vào trang web Strongbowchillkoin.com để đăng ký thông tin nhận quà theo
        đường bưu điện. Công ty sẽ gửi quà đến địa chỉ mà khách hàng đã đăng ký thông tin theo đường
        bưu điện trong vòng 14 ngày sau khi khách hàng điền đầy đủ thông tin theo hệ thống trên
        trang web Strongbowchillkoin.com yêu cầu.
        <br />
        Chúng tôi không chịu trách nhiệm đối với các trường hợp thất lạc giải thưởng do khách hàng
        cung cấp sai thông tin cá nhân. Công ty sẽ được xem là hoàn tất nghĩa vụ trao giải khi đã
        gửi giải thưởng theo đúng thông tin địa chỉ mà người thắng giải đã đăng ký lúc tham gia
        chương trình và có xác nhận gửi đi của bưu điện. Trong trường hợp quà tặng được gửi qua
        đường bưu điện nhưng bị hoàn trả, bưu cục sẽ hoàn trả và tính vào giải thưởng tồn đọng của
        toàn bộ chương trình.
        <br />
        <ul class="ml-4">
          <li>
            - Địa điểm trao thưởng: Chuyển phát theo đường bưu điện đến tận tay khách hàng. Theo
            thông tin khách hàng đã đăng ký.
          </li>
          <li>
            - Cách thức trao thưởng: Chuyển phát bưu điện (Toàn bộ chi phí chuyển phát sẽ được ban
            tổ chức chịu trách nhiệm)
          </li>
          <li>
            - Thủ tục trao thưởng: Khách hàng cung cấp giấy tờ tùy thân (bao gồm CMND/ CCCD) theo
            những thông tin đã đăng ký khi trúng giải của chương trình.
          </li>
        </ul>
        <br />
        <ul class="ml-4" style={{listStyle: 'disc'}}>
          <li>Đối với phần quà Vé xem phim CGV, Voucher Tiki, Voucher GOT IT:</li>
        </ul>
        Trao thưởng trực tiếp vào tài khoản thuê bao/ tài khoản Zalo tham gia dự thưởng trong vòng
        khoảng 5 phút sau khi nhận được thông báo trúng thưởng từ hệ thống
        <br />
        <ul class="ml-4">
          <li>
            - Địa điểm trao thưởng: Trao thưởng trực tiếp vào tài khoản thuê bao/ tài khoản Zalo
            tham gia dự thưởng.
          </li>
          <li>
            - Cách thức trao thưởng và thủ tục trao thưởng: Gửi thông tin về mã Voucher trúng giải
            vào tài khoản thuê bao/ tài khoản Zalo tham gia chương trình của khách hàng
          </li>
        </ul>
        <br />
        <ul class="ml-4" style={{listStyle: 'disc'}}>
          <li>
            <strong>Đối với phần quà Iphone 14 Pro</strong>, MacBook Pro M2 2022 13 inch, Apple
            Watch Ultra GPS, <strong>Airpod Pro 2022:</strong>
          </li>
        </ul>
        Trong vòng 05 ngày sau khi thông báo trúng thưởng cho người trúng thưởng, công ty sẽ liên
        lạc trực tiếp đến người trúng thưởng bằng điện thoại/zalo theo số điện thoại người trúng
        thưởng tham gia chương trình để hướng dẫn trao giải.
        <br />
        Người trúng thưởng cần cung cấp đầy đủ các thông tin theo yêu cầu thông qua Zalo của
        Strongbow trong vòng 03 ngày sau khi nhận được cuộc gọi hướng dẫn nhận giải từ chương trình.
        Các thông tin bao gồm:
        <ul class="ml-4" style={{listStyle: 'circle'}}>
          <li>Họ và tên.</li>
          <li>
            Số điện thoại (Cần cung cấp đúng số điện thoại vì đây là kênh thông tin duy nhất Công ty
            dùng để liên lạc với người tham gia về việc nhận thưởng).
          </li>
          <li>Mã số thuế cá nhân.</li>
          <li>Địa chỉ nhận quà.</li>
          <li>Hình ảnh chụp 2 mặt CMND/Căn cước công dân/Hộ chiếu.</li>
        </ul>
        Trong vòng 14 ngày sau khi tổng đài liên lạc và người trúng thưởng đã cung cấp đầy đủ các
        thông tin theo yêu cầu, giải thưởng sẽ được chuyển phát bưu điện đến tận nhà khách hàng
        trúng thưởng theo thông tin mà người trúng thưởng đã cung cấp.
        <br />
        <br />
        <strong>Quy định về các khoản khấu trừ khi nhận giải:</strong>
        <br />
        Đối với giải thưởng Iphone 14 Pro, MacBook Pro M2 2022 13 inch, Apple Watch Ultra GPS sẽ
        không có giá trị quy đổi thành tiền mặt, khách hàng cần phải đóng thuế thu nhập cá nhân cho
        khoản thu nhập bất thường là 10% phần giá trị vượt 10 triệu đồng. Thủ tục với cơ quan thuế
        sẽ được công ty dịch vụ của HVBL hỗ trợ thực hiện.
        <br />
        Người trúng giải phải thanh toán tất cả mọi khoản thuế, lệ phí có liên quan đến giải thưởng
        theo quy định pháp luật Việt Nam hiện hành, kể cả thuế thu nhập (nếu có) khi nhận giải.
        Ngoài ra người trúng giải phải tự trả mọi khoản phí tổn như đi lại, ăn ở, vận chuyển giải
        thưởng... (nếu có) cho việc nhận giải của mình.
        <br />
        Nếu khách hàng trúng thưởng đồng ý, Công ty TNHH Nhà máy Bia Heineken Việt Nam (HVBL) sẽ sử
        dụng tên, hình ảnh và địa chỉ của mình vào mục đích báo cáo theo quy định và quảng cáo mà
        HVBL không phải trả cho người này bất kỳ một khoản chi phí nào.
        <br />
        Thời hạn kết thúc trao thưởng: Chậm nhất 45 ngày kể từ ngày kết thúc chương trình khuyến mại
        <br />
      </>
    ),
  },
  {
    title: 'Tôi có thể đổi tối đa bao nhiêu món quà?',
    description: (
      <>
        Chào bạn, Đối với chương trình tích lũy điểm, số lượt đổi quà trên mỗi khách hàng là không
        giới hạn, miễn sao đúng với thể lệ chương trình. Tuy nhiên tổng quà tặng của chương trình có
        hạn nên bạn hay nhanh tay tham gia nhé. Chương trình có thể sẽ kết thúc sớm hơn dự kiến
        trong trường hợp quà tặng hết sớm.
      </>
    ),
  },
  {
    title: 'Có thể thay đổi quà sau khi đã bấm đổi không?',
    description: (
      <>
        Hiện tại, bạn không thể thay đổi sang quà khác khi đã xác nhận đổi quà. Bạn vui lòng cân
        nhắc kĩ trước khi chọn đổi quà nhé
      </>
    ),
  },
  {
    title: 'Trong trường hợp không thể nhận thưởng, có thể nhờ người khác nhận thay không?',
    description: (
      <>
        Chào Bạn,trường hợp bất khả kháng, bạn không thể nhận thưởng trong thời gian quy định của
        chương trình, bạn có thể nhờ người nhận thay. Với điều kiện người nhận thay phải xuất trình
        đầy đủ thông tin, CMND trên 18 tuổi và truy cập tài khoản Zalo để tiến hành nhập mã và xác
        nhận nhận quà thì có thể đến nhận giải.
        <br />
        Đối với các quà tặng giá trị, BTC đề nghị người nhận giải là người với thông tin đúng với hệ
        thống đã ghi nhận.
      </>
    ),
  },
  {
    title:
      'Nếu đến ngày 28/02/2023 mà tôi vẫn chưa đổi quà trên hệ thống thì có thể gia hạn thời gian không?',
    description: (
      <>
        Chào bạn, điểm đã tích lũy sẽ được lưu trên hệ thống trong thời gian 12 tháng kể từ ngày
        tích lũy. Tuy nhiên 28/02/2023 là thời điểm cuối cùng của Chương trình “Tích điểm Chill săn
        quà xịn”, do đó một số phần quà sẽ được vô hiệu hóa theo quy định. Nhanh tay tham gia và
        tích điểm để nhận quà thật chill nhé
      </>
    ),
  },
  {
    title:
      'Tôi đã nhấn xác nhận đổi quà nhưng không thấy hệ thống gửi về tin nhắn thì làm thế nào?',
    description: (
      <>
        Chào bạn, trong trường hợp này bạn vui lòng bấm đổi lại quà lần nữa và đợi hệ thống phản hồi
        nhé ạ. Trong trường hợp vẫn không nhận được tin nhắn, bạn vui lòng liên hệ hotline{' '}
        <a
          // className="title  is-size-5 has-text-weight-normal mb-2"
          href={`tel:1900 1845`}
          alt="contact"
        >
          1900 1845
        </a>{' '}
        , hỏi về thể lệ và vấn đề hệ thống để được hướng dẫn chi tiết nhé ạ
      </>
    ),
  },
  {
    title: 'Khi nhận giải, có phải đóng thêm các khoản chi phí nào khi nhận thưởng không?',
    description: (
      <>
        Đối với giải thưởng Iphone 14 Pro, MacBook Pro M2 2022 13 inch, Apple Watch Ultra GPS sẽ
        không có giá trị quy đổi thành tiền mặt, khách hàng cần phải đóng thuế thu nhập cá nhân cho
        khoản thu nhập bất thường là 10% phần giá trị vượt 10 triệu đồng. Thủ tục với cơ quan thuế
        sẽ được công ty dịch vụ của HVBL hỗ trợ thực hiện.
        <br />
        Người trúng giải phải thanh toán tất cả mọi khoản thuế, lệ phí có liên quan đến giải thưởng
        theo quy định pháp luật Việt Nam hiện hành, kể cả thuế thu nhập (nếu có) khi nhận giải.
        Ngoài ra người trúng giải phải tự trả mọi khoản phí tổn như đi lại, ăn ở, vận chuyển giải
        thưởng... (nếu có) cho việc nhận giải của mình.
      </>
    ),
  },
  {
    title:
      'Tôi có thể tham gia cả 2 chương trình “tích điểm đổi quà” và “vòng quay may mắn” cùng lúc được không?',
    description: (
      <>
        Chào bạn, bạn có thể cùng tham gia cả 2 chương trình. Bạn hãy săn nhiều chill koin để có cơ
        hội tham gia cùng lúc nhiều chương trình bạn nhé
      </>
    ),
  },
];
