import React, {useEffect, useState} from 'react';
import './App.scss';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {Routes, Route, useLocation} from 'react-router-dom';
import store, {persistor} from './stores';
import Layout from './components/layout';
import {ROUTE_NAME} from 'constants/routes';
import {PersistGate} from 'redux-persist/integration/react';
import PageNotFound from 'pages/404';
import ReferralPhoneNumber from 'pages/referralPhoneNumber';
import HomePage from 'pages/HomePage';
import ReferralPage from 'pages/Referral';
import UserProfile from 'pages/UserProfile';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GiftPage from 'pages/VoucherList';
import GiftDetail from 'pages/VoucherList/detail';
import {localStorageGetItem} from 'utils/storage';
import {setAuthToken} from 'utils/apiUtils';
import StorePage from 'pages/store';
import ChillPage from 'pages/ChillKoin';
import ListPrizePage from 'pages/PrizeList';
import GiftInfo from 'pages/VoucherList/infoUserGift';
import Program from 'pages/Program';
import UserGiftDetail from 'pages/VoucherList/userGiftDetail';
import EditProfile from 'pages/UserProfile/components/editProfile';
import SeeYouAgain from 'pages/HomePage/components/seeYouAgain';
import TransactionPage from 'pages/VoucherList/transaction';
import {isMobile} from 'react-device-detect';
import Maintenance from 'pages/maintenance';
import Info from 'pages/Info';
import TermsAndConditions from 'pages/TermsAndConditions';
import Benner from 'images/benner_3.jpg';

import HomePagePC from 'pages/HomePage/pc';
import MeoChillHome from 'pages/MeoChill';
import ProtectedRoute from 'components/protectedRoute';
import MeoChillCheckIn from 'pages/MeoChillCheckIn';
import {isLogInSelector} from 'appRedux/selectors/auth';
import {signOutAction} from 'appRedux/actions/global';
import classNames from 'classnames';

const AppHook = () => {
  const isLogin = useSelector(isLogInSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorageGetItem('accessToken');
    if (!!token) {
      setAuthToken(token);
    } else if (isLogin) {
      dispatch(signOutAction());
    }
  }, []);
  return <></>;
};

function App() {
  const location = useLocation();
  const [isMaintenance, _] = useState(false);

  useEffect(() => {
    document?.getElementById?.('app')?.scrollTo && document.getElementById('app').scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Provider store={store}>
      <AppHook />
      <PersistGate loading={null} persistor={persistor}>
        <div
          className={classNames('App', {
            'hide-scroll-bar': !isMaintenance,
          })}
          id="app"
        >
          <Routes>
            {isMaintenance ? (
              <>
                <Route path={'*'} element={<Maintenance />} />
              </>
            ) : (
              <>
                <Route path={ROUTE_NAME.HOME} element={<HomePage />} />
                <Route path={ROUTE_NAME.GIFT} element={<GiftPage />} />
                <Route path={ROUTE_NAME.REFERRAL} element={<ReferralPage />} />
                <Route path={ROUTE_NAME.USER_PROFILE} element={<UserProfile />} />
                <Route path={ROUTE_NAME.REFERRAL_PHONE_NUMBER}>
                  <Route path=":phoneNumber" element={<HomePage />} />
                </Route>

                <Route path="*" element={<PageNotFound />} />
                <Route path="/gift/:id" element={<GiftDetail />} />
                <Route path="/user-gift/:id" element={<UserGiftDetail />} />
                <Route path={ROUTE_NAME.STORE} element={<StorePage />} />
                <Route path={ROUTE_NAME.CHILL} element={<ChillPage />} />
                <Route path={ROUTE_NAME.LIST_PRIZE} element={<ListPrizePage />} />
                <Route path="/info/:id" element={<GiftInfo />} />
                <Route path={ROUTE_NAME.PROGRAM} element={<Program />} />
                <Route path={ROUTE_NAME.EDIT_PROFILE} element={<EditProfile />} />
                <Route path={ROUTE_NAME.SEE_YOU_AGAIN} element={<SeeYouAgain />} />
                <Route path="/transaction/:id" element={<TransactionPage />} />
                <Route path={ROUTE_NAME.INFO} element={<Info />} />
                <Route path={ROUTE_NAME.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
                <Route
                  path={ROUTE_NAME.CHILL_MAP}
                  element={
                    <ProtectedRoute>
                      <MeoChillHome />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/meo-chill/:storeId"
                  element={
                    <ProtectedRoute>
                      <MeoChillCheckIn />
                    </ProtectedRoute>
                  }
                />
              </>
            )}
          </Routes>
          <ToastContainer />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
