import {appEndPoint} from 'configs/appConfigs';
import {buildRequestUrl} from 'helpers/apiHelper';

const {END_POINT_THIRD_PARTY} = appEndPoint;

const nutiFood = 'nuti-food';
export const NUTI_FOOD = {
  getRewardInfo: buildRequestUrl(
    END_POINT_THIRD_PARTY,
    `${nutiFood}/reward-info/${process.env.REACT_APP_MERCHANT_ID}`
  ),
  registerWithFullInfo: buildRequestUrl(
    END_POINT_THIRD_PARTY,
    `${nutiFood}/register/landing-page/full-info`
  ),
  searchUserGift: buildRequestUrl(END_POINT_THIRD_PARTY, `strongbow/user-gift/search`),
};

const strongBow = 'strongbow';
export const STRONGBOW = {
  getUserGiftStrongBow: buildRequestUrl(END_POINT_THIRD_PARTY, `${strongBow}/user-gift/search`),
  getAllGiftByMerchant: (merchantId) =>
    buildRequestUrl(END_POINT_THIRD_PARTY, `${strongBow}/gifts/all-by-merchant/${merchantId}`),
  getAllGiftByMerchantAnonymous: (merchantId) =>
    buildRequestUrl(
      END_POINT_THIRD_PARTY,
      `${strongBow}/gifts/anonymous/all-by-merchant/${merchantId}`
    ),
  getRewardInfo: buildRequestUrl(
    END_POINT_THIRD_PARTY,
    `${strongBow}/reward-info/${process.env.REACT_APP_MERCHANT_ID}`
  ),
  postAllGiftStrongBowSearch: buildRequestUrl(END_POINT_THIRD_PARTY, `${strongBow}/gifts/search`),
  postAllGiftStrongBowAnonymousSearch: buildRequestUrl(
    END_POINT_THIRD_PARTY,
    `${strongBow}/gifts/anonymous/search`
  ),
  getStoreDetailByCode: (storeCode) =>
    buildRequestUrl(END_POINT_THIRD_PARTY, `${strongBow}/store-checkin/${storeCode}`),
  validateHuntingCode: (code) =>
    buildRequestUrl(END_POINT_THIRD_PARTY, `${strongBow}/scan-qrcode/${code}`),
  createHuntingCodeReward: buildRequestUrl(
    END_POINT_THIRD_PARTY,
    `${strongBow}/scan-qrcode/create`
  ),
  shareFacebook: buildRequestUrl(END_POINT_THIRD_PARTY, `${strongBow}/scan-qrcode/share-facebook`),
};

//end
