import React from 'react';
import Layout from '../../components/layout';
import image_banner from 'images/strongbow/termsandconditions/tnc_bg.png';
import './styles.scss';
const Index = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className="container-chill-pc">
        <div className="container">
          <div className="title-image has-text-centered">
            <img
              style={{
                width: '55%',
                maxWidth: 400,
              }}
              src={image_banner}
              alt="title"
            />
          </div>
          <div className="container-content">
            <div className="tnc_container">
              <p>
                <b>1. TỔNG QUAN</b>
                <br />
                Chính sách bảo vệ quyền riêng tư này áp dụng cho trang web tại địa chỉ
                Strongbowchillkoin.com (“Trang Web”) của Công ty TNHH Nhà Máy Bia HEINEKEN Việt Nam
                (“HVN”) dành riêng cho khách hàng sử dụng và tham gia hoạt động của chương trình
                “SĂN ĐIỂM CHILL – PHIÊU QUÀ XỊN” được tổ chức trên trang Web (“Chương trình”), nơi
                mà HVN và các Công ty liên kết và / hoặc Chi nhánh của HVN sẽ thu thập thông tin cá
                nhân nhất định. Vui lòng đọc Chính sách quyền riêng tư này một cách cẩn thận vì nó
                chứa những thông tin quan trọng để giúp bạn hiểu các thông lệ của chúng tôi về bất
                kỳ thông tin cá nhân nào bạn cung cấp cho chúng tôi hoặc chúng tôi thu thập theo
                cách khác trong bối cảnh của Trang Web (“Dữ liệu cá nhân”). Công ty TNHH Nhà Máy Bia
                HEINEKEN Việt Nam (‘chúng tôi hoặc” chúng tôi “) là người kiểm soát Dữ liệu Cá nhân
                được thu thập và xử lý. Tuy nhiên, khi việc xử lý được thực hiện cho mục đích báo
                cáo chiến lược toàn cầu, HEINEKEN International B.V. được coi là Bên điều khiển dữ
                liệu riêng biệt cho Dữ liệu Cá nhân của bạn.
                <br />
                Chúng tôi tôn trọng quyền riêng tư của bạn và chúng tôi cam kết bảo mật Dữ liệu Cá
                nhân của bạn và quản lý dữ liệu bằng trách nhiệm pháp lý của chúng tôi theo luật bảo
                vệ dữ liệu hiện hành.
                <br />
                Trường hợp chúng tôi cần thu thập Dữ liệu Cá nhân theo quy định của luật hoặc theo
                các thỏa thuận của chúng tôi với bạn và nếu bạn không cung cấp dữ liệu đó khi được
                yêu cầu, chúng tôi có thể không thực hiện được các dịch vụ (ví dụ: hoàn thành việc
                trao giải thưởng) nhưng chúng tôi sẽ thông báo cho bạn về việc này.
                <br />
                <br />
                <b>2. DỮ LIỆU THU THẬP VÀ CÁCH SỬ DỤNG</b>
                <br />
                Chúng tôi nhận hoặc thu thập Dữ liệu Cá nhân, như được mô tả bên dưới, khi bạn sử
                dụng các dịch vụ tại trang Web của chúng tôi. Chúng tôi có thể sử dụng Dữ liệu (Cá
                nhân) này để vận hành, cung cấp, cải thiện, tùy chỉnh, hỗ trợ và tiếp thị các dịch
                vụ của chúng tôi dựa trên lợi ích hợp pháp của chúng tôi hoặc để tuân thủ nghĩa vụ
                pháp lý của chúng tôi. Nếu bạn không muốn chúng tôi thu thập và xử lý Dữ liệu (Cá
                nhân) của bạn, bạn không thể sử dụng một số dịch vụ trên trang Web của chúng tôi.
                <br />
                Chúng tôi đã xác định Dữ liệu Cá nhân mà chúng tôi thu thập, mục đích sử dụng Dữ
                liệu Cá nhân và thời hạn chúng tôi sẽ giữ lại Dữ liệu Cá nhân của bạn như sau:
                <br />
                <b>2.1. Bảo trì và tối ưu hóa trang Website của chúng tôi:</b>
                <br />
                Chúng tôi thu thập một số thông tin nhất định khi bạn truy cập trang Web của chúng
                tôi, chẳng hạn như địa chỉ IP của bạn, trang Web nào, tên máy tính của bạn và loại
                trình duyệt internet, nhấp chuột và lượt xem. Chúng tôi sẽ sử dụng Dữ liệu cá nhân
                của bạn để bảo trì và phân tích trang Web của chúng tôi và giải quyết các vấn đề về
                hiệu suất, để cải thiện tính khả dụng và bảo mật trang Web khỏi các vấn đề về gian
                lận (ví dụ: trong trường hợp cố gắng đăng ký và tham gia Chương trình được thực hiện
                khi không tuân thủ các điều khoản và điều kiện của chúng tôi, bao gồm nhưng không
                giới hạn việc đăng ký được thực hiện bởi các cá nhân dưới 18 tuổi). Phân tích cũng
                cho phép chúng tôi kiểm tra xem quy trình đăng ký quá trình trải nghiệm có hoạt động
                hiệu quả không để chúng tôi có thể cải thiện, nếu có thể.
                <br />
                Việc chúng tôi sử dụng Dữ liệu Cá nhân của bạn cho các mục đích này là cần thiết vì
                lợi ích hợp pháp của chúng tôi và sẽ được giữ lại trong thời gian tối đa là 01 năm
                sau khi đóng tài khoản của bạn.
                <br />
                <b>2.2. Tham gia vào các hoạt động nghiên cứu.</b>
                <br />
                Chúng tôi có thể yêu cầu bạn tham gia các hoạt động nghiên cứu như: khảo sát, thử
                nghiệm, hội thảo, nhóm tập trung và các hoạt động nghiên cứu khác. Tùy thuộc vào
                hoạt động nghiên cứu, chúng tôi sẽ thu thập các bộ Dữ liệu cá nhân khác nhau. Chúng
                tôi sẽ luôn thông báo trước cho bạn về hoạt động nghiên cứu Dữ liệu cá nhân nào
                chúng tôi sẽ thu thập và với mục đích gì.
                <br />
                Việc chúng tôi sử dụng Dữ liệu cá nhân của bạn cho các hoạt động nghiên cứu là cần
                thiết vì lợi ích hợp pháp của chúng tôi hoặc dựa trên sự đồng ý trước đó của bạn,
                tùy thuộc vào loại hoạt động nghiên cứu và Dữ liệu Cá nhân chính xác nào chúng tôi
                sẽ thu thập từ bạn. Dữ liệu cá nhân này sẽ được lưu giữ trong thời gian tối đa là 1
                năm.
                <br />
                Chúng tôi sẽ cung cấp các hoạt động nghiên cứu với sự đồng ý của bạn hoặc vì một lợi
                ích hợp pháp, tùy thuộc vào loại và tính chất của hoạt động nghiên cứu.
                <br />
                <b>
                  2.3. Bạn có thể luôn từ chối nhận bản tin hoặc thư trực tiếp của chúng tôi và bạn
                  có thể không đồng ý việc chúng tôi sử dụng Dữ liệu Cá nhân của bạn cho các mục
                  đích tiếp thị trực tiếp.
                </b>
                <br />
                Chúng tôi sử dụng Dữ liệu cá nhân này vì lợi ích hợp pháp của chúng tôi là có thể
                quảng bá sản phẩm và dịch vụ của mình tới khách hàng và khách truy cập trang Web,
                cho phép chúng tôi thu hút nhiều khách hàng hơn, cải thiện dịch vụ của chúng tôi và
                tài trợ cho trang Web của chúng tôi (thông qua quảng cáo trực tuyến). Chúng tôi sẽ
                giữ lại Dữ liệu Cá nhân theo các mục đích có liên quan mà Dữ liệu Cá nhân đã được
                thu thập. Dữ liệu Cá nhân thường sẽ bị xóa hoặc ẩn danh 01 năm sau trên trang Web
                của chúng tôi, trừ khi chúng tôi được yêu cầu hợp pháp để giữ lại Dữ liệu Cá nhân
                hoặc được lưu giữ cho bất kỳ mục đích nào khác.
                <br /> <b>2.4. Mục đích báo cáo chiến lược.</b>
                <br />
                Tất cả Dữ liệu Cá nhân được thu thập bởi HVN được truy cập và sử dụng bởi HEINEKEN
                International B.V. để tạo các báo cáo chiến lược tổng hợp, được sử dụng để xác định
                các cơ hội trên toàn cầu và cải thiện các sản phẩm và dịch vụ của chúng tôi.
                <br />
                HEINEKEN International B.V. sử dụng thông tin này vì đây là lợi ích hợp pháp chung
                của chúng tôi để xác định các cơ hội trên toàn cầu và cải thiện các sản phẩm và dịch
                vụ của chúng tôi. Tất cả thông tin của bạn sẽ được lưu giữ trong thời gian tối đa là
                2 năm.
                <br />
                <b>2.5.</b> Chúng tôi thực hiện những bước hợp lý để bảo đảm rằng bất kỳ Dữ liệu Cá
                nhân nào do chúng tôi thu thập, tiết lộ và sử dụng là chính xác và hoàn chỉnh nếu
                chúng tôi có khả năng sử dụng Dữ liệu Cá nhân của bạn để đưa ra một quyết định ảnh
                hưởng đến bạn, hoặc tiết lộ cho tổ chức khác. Tuy nhiên, điều quan trọng là bạn phải
                cho chúng tôi biết về bất kỳ thay đổi nào với Dữ liệu Cá nhân của bạn hoặc nếu có
                bất kỳ sai sót trong dữ liệu cá nhân về bạn mà chúng tôi có. Chúng tôi không chịu
                bất kỳ trách nhiệm nào khi dựa vào những Dữ liệu Cá nhân không chính xác hoặc không
                hoàn chỉnh phát sinh do việc bạn không cập nhật cho chúng tôi về bất kỳ thay đổi
                trong Dữ liệu Cá nhân của bạn như bạn đã cung cấp cho chúng tôi lúc ban đầu.
                <br />
                Chúng tôi không xử lý bất kỳ Danh mục dữ liệu cá nhân đặc biệt nào về bạn (bao gồm
                thông tin chi tiết về chủng tộc hoặc sắc tộc, tín ngưỡng hoặc triết học, khuynh
                hướng tình dục, quan điểm chính trị, tư cách thành viên công đoàn, thông tin về sức
                khỏe hoặc dữ liệu sinh trắc học của bạn), cũng như chúng tôi không xử lý bất kỳ
                thông tin nào về kết án và tội phạm hình sự. Ngoài ra, Dữ liệu Cá nhân cũng sẽ không
                được sử dụng cho các mục đích khác ngoài quy định tại Chính sách này nếu bạn chưa
                được thông báo rõ ràng về các mục đích này và / hoặc đã được yêu cầu đồng ý trước.
                <br />
                <br />
                <b>3. CÁCH CHÚNG TÔI CHIA SẺ DỮ LIỆU CÁ NHÂN CỦA BẠN</b>
                <br />
                Chúng tôi có thể cần chia sẻ Dữ liệu Cá nhân với các bên thứ ba để giúp chúng tôi
                cung cấp dịch vụ và sản phẩm cho bạn và để chạy trang Web của chúng tôi. Các bên thứ
                ba là:
                <ul class="ml-4" style={{listStyle: 'disc'}}>
                  <li>
                    Các công ty liên kết thuộc tập đoàn HEINEKEN với mục đích lưu trữ Dữ liệu Cá
                    nhân được xử lý qua trang Web, do các hệ thống CNTT được chia sẻ;
                  </li>
                  <li>
                    Nhà cung cấp không gian lưu trữ dữ liệu của chúng tôi có trụ sở tại Việt Nam, là
                    một bên lưu trữ an toàn dữ liệu khách hàng thu được đại diện cho chúng tôi;
                  </li>
                  <li>
                    HEINEKEN International B.V. cho mục đích báo cáo chiến lược, trong đó HEINEKEN
                    International B.V. sẽ hoạt động như một Bên kiểm soát dữ liệu độc lập;
                  </li>
                  <li>
                    Nhà cung cấp dịch vụ khi cần cung cấp dịch vụ cho chúng tôi hoặc (giúp chúng
                    tôi) cung cấp dịch vụ cho bạn khi bạn đăng ký và tham gia hoạt động trên trang
                    Web (bao gồm nhà cung cấp dịch vụ phân phối bên thứ ba của chúng tôi) và cung
                    cấp dịch vụ phân tích dữ liệu. Các nhà cung cấp dịch vụ, Google Analytics;
                  </li>
                  <li>
                    Trong trường hợp HEINEKEN bán tất cả hoặc một số tài sản hoặc cổ phần của tập
                    đoàn HEINEKEN mà Dữ liệu Cá nhân được chuyển cho bên thứ ba, Dữ liệu cá nhân của
                    bạn có thể được cung cấp cho bên thứ ba này;
                  </li>
                  <li>
                    Ngoài ra, chúng tôi có thể sử dụng, tiết lộ hoặc chuyển thông tin của bạn cho
                    một công ty liên kết thuộc tập đoàn HEINEKEN trong trường hợp tái tổ chức, sáp
                    nhập, bán, liên doanh, chuyển nhượng hoặc xử lý khác đối với tất cả hoặc bất kỳ
                    phần nào trong kinh doanh, tài sản hoặc cổ phiếu của chúng tôi (bao gồm cả liên
                    quan đến bất kỳ thủ tục phá sản hoặc thủ tục tương tự).
                  </li>
                  <li>
                    Các bên thứ ba này có thể được đặt tại Liên minh châu u hoặc các quốc gia khác
                    trong Khu vực kinh tế châu u (“EEA”) hoặc các nơi khác trên thế giới. Dữ liệu Cá
                    nhân được chúng tôi lưu trữ bên trong EEA và chúng tôi sẽ đảm bảo mức độ bảo vệ
                    đầy đủ. Chúng tôi yêu cầu các nhà cung cấp dịch vụ sử dụng các biện pháp phù hợp
                    để bảo vệ tính bảo mật và bảo mật của Dữ liệu Cá nhân.
                  </li>
                  <li>
                    Chúng tôi cũng có thể cần cung cấp Dữ liệu Cá nhân cho các cơ quan thực thi pháp
                    luật để tuân thủ mọi nghĩa vụ pháp lý hoặc lệnh của tòa án.
                  </li>
                </ul>
                <br />
                <br />
                <b>4. ĐẢM BẢO AN TOÀN DỮ LIỆU CÁ NHÂN</b>
                <br />
                Chúng tôi đã đưa ra các biện pháp bảo mật thích hợp để ngăn chặn Dữ liệu Cá nhân của
                bạn vô tình bị mất, sử dụng hoặc truy cập một cách trái phép, bị thay đổi hoặc tiết
                lộ. Ngoài ra, chúng tôi giới hạn quyền truy cập vào Dữ liệu Cá nhân của bạn đối với
                những nhân viên, đại lý, nhà thầu và các bên thứ ba khác có nhu cầu cần biết để thực
                hiện công việc. Họ phải chịu trách nhiệm bảo mật.
                <b />
                rTuy nhiên, không có việc truyền thông tin qua mạng internet có thể hoàn toàn an
                toàn và bạn cũng cần lưu ý rằng bảo mật thông tin phụ thuộc một phần vào bảo mật của
                máy tính bạn sử dụng để liên lạc với chúng tôi và bảo mật bạn sử dụng để bảo vệ
                thông tin tài khoản và mật khẩu, vì vậy hãy cẩn thận để bảo vệ thông tin này.
                <br />
                Trang Web của chúng tôi có thể bao gồm các liên kết đến các trang Web, plugin và ứng
                dụng của bên thứ ba. Nhấp vào các liên kết đó hoặc cho phép các kết nối đó có thể
                cho phép các bên thứ ba thu thập hoặc chia sẻ dữ liệu về bạn. Chúng tôi không kiểm
                soát các trang Web, plugin hoặc ứng dụng của bên thứ ba này và không chịu trách
                nhiệm về tuyên bố quyền riêng tư của họ. Chúng tôi khuyến khích bạn đọc thông báo
                bảo mật quyền riêng tư của mọi trang Web bạn truy cập và dịch vụ / ứng dụng của bên
                thứ ba mà bạn sử dụng.
                <br />
                <br />
                <b>5. LƯU GIỮ TOÀN DỮ LIỆU CÁ NHÂN</b>
                <br />
                Chúng tôi sẽ lưu giữ Dữ liệu Cá nhân của bạn theo yêu cầu về mặt pháp lý hoặc cần
                thiết để cung cấp cho bạn bất kỳ dịch vụ nào được yêu cầu hoặc cho bất kỳ mục đích
                nào khác được liệt kê trong Chính sách này. Các điều khoản lưu giữ cụ thể được liệt
                kê trong Chính sách này cho từng mục đích liên quan. Chúng tôi sẽ thực hiện các bước
                hợp lý để hủy hoặc hủy nhận dạng Dữ liệu Cá nhân mà chúng tôi giữ nếu không còn cần
                thiết cho các mục đích nêu trên hoặc sau khi hết thời hạn lưu giữ được xác định.
                <br />
                <br />
                <b>6. QUYỀN RIÊNG TƯ CỦA TRẺ EM</b>
                <br />
                Trang Web này không dành cho những người sử dụng dưới 18 tuổi (hoặc độ tuổi hợp pháp
                để sử dụng các sản phẩm được đề cập). Chúng tôi không cố ý thu thập Dữ liệu Cá nhân
                từ các cá nhân dưới 18 tuổi.
                <br />
                <br />
                <b>7. LỰA CHỌN VÀ QUYỀN CỦA BẠN</b>
                <br />– Nếu dữ liệu cá nhân của bạn đã hoặc phải tuân theo GDPR của Liên Minh Châu u
                (Quy định bảo vệ dữ liệu chung) hoặc trong phạm vi áp dụng theo luật địa phương của
                bạn, bạn có quyền yêu cầu quyền truy cập và xóa dữ liệu cá nhân của mình, để phản
                đối, yêu cầu hạn chế xử lý, để nhận tổng quan về dữ liệu cá nhân mà bạn đã cung cấp
                cho chúng tôi theo định dạng có cấu trúc, thường được sử dụng và có thể đọc bằng
                máy. Tất cả các quyền của bạn phải tuân theo luật bảo vệ dữ liệu hiện hành và các
                luật và quy định có liên quan khác, theo Quy trình bảo mật dữ liệu cá nhân của
                HEINEKEN và các hướng dẫn khác của HEINEKEN. Tuy nhiên, bạn luôn có quyền không đồng
                ý việc chúng tôi sử dụng Dữ liệu Cá nhân của bạn để liên lạc tiếp thị trực tiếp và
                khi bạn làm như vậy, chúng tôi sẽ đáp ứng yêu cầu của bạn. Trường hợp bạn đã đồng ý
                cho chúng tôi sử dụng Dữ liệu Cá nhân của bạn, bạn có quyền rút lại sự đồng ý của
                bạn mà không ảnh hưởng đến tính hợp pháp của việc chúng tôi sử dụng Dữ liệu này
                trước khi bạn yêu cầu rút lại.
                <br />– Xin lưu ý rằng nếu bạn sử dụng (một số) các lựa chọn và quyền của mình, bạn
                không thể sử dụng toàn bộ hoặc một phần các dịch vụ trên trang Web của chúng tôi
                nữa.
                <br />
                <br />
                <b>8. CẬP NHẬT</b>
                <br />
                Chúng tôi sẽ rà soát lại và cập nhật Chính sách quyền riêng tư này theo thời gian.
                Mọi thay đổi đối với Chính sách quyền riêng tư này sẽ được đăng trên trang Web của
                chúng tôi và sẽ được thông báo cho bạn trong khoảng thời gian phù hợp.
                <br />
                <br />
                <b>9. LIÊN LẠC</b>
                <br />
                Nếu bạn muốn thực hiện bất kỳ quyền nào được liệt kê ở trên, bạn có thể liên hệ với
                chúng tôi thông qua hotline 19001845. Xin lưu ý rằng chúng tôi có thể yêu cầu bằng
                chứng về danh tính. Nếu bạn có bất kỳ câu hỏi nào khác, phản đối việc chúng tôi sử
                dụng Dữ liệu Cá nhân của bạn hoặc khiếu nại về Chính sách bảo mật dữ liệu cá nhân
                này hoặc về việc chúng tôi xử lý Dữ liệu Cá nhân của bạn, bạn có thể gọi đến hotline
                19001845.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Index;
